import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  MOVE_KPI: 'kpis/move/:kpiId',
  UPDATE_KPI_PROGRESS: 'kpis/:kpiId/update-value',
  UPDATE_KPI_WEIGHT: 'kpis/:kpiId/weight',
  UPDATE_KPI_WEIGHED: 'kpis/:kpiId/weighed',
  GET_KPI_BY_UNIT: 'kpis/by-unit/:unit',
  GET_KPIS: 'kpis/',
  GET_CHART_DATA: 'widgets/chart-data',
  GET_KPI_UPDATES: 'kpis/:kpiId/updates',
  GET_KPI_BY_GOAL_ID: 'kpis/by-goal/:goalId/:metricId',
  UPDATE_KPI_DATE_RANGE: 'kpis/:kpiId/dates',
  REMOVE_KPI_CONNECTION: 'kpi-connections/:id',
  REMOVE_KPI_MICROSOFT_CONNECTION: 'microsoft-connections/kpi/:id',
};

class KpiService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  moveKpi = (data, kpiId) => {
    return this.httpService.request({
      url: ROUTES.MOVE_KPI.replace(':kpiId', kpiId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getKpisByGoalId = (id, metricId) => {
    return this.httpService.request({
      url: ROUTES.GET_KPI_BY_GOAL_ID.replace(':goalId', id).replace(':metricId', metricId || ''),
      method: HTTP_METHODS.GET,
    });
  };

  getKpisByUnit = (unit) => {
    return this.httpService.request({
      url: ROUTES.GET_KPI_BY_UNIT.replace(':unit', unit),
      method: HTTP_METHODS.GET,
    });
  };
  updateKpiProgress = (data, kpiId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_KPI_PROGRESS.replace(':kpiId', kpiId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  updateKpiWeight = (data, kpiId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_KPI_WEIGHT.replace(':kpiId', kpiId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateKpiWeighed = (data, kpiId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_KPI_WEIGHED.replace(':kpiId', kpiId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getKpis = () => {
    return this.httpService.request({
      url: ROUTES.GET_KPIS,
      method: HTTP_METHODS.GET,
    });
  };

  getCurrentStateOfKpiWidgetData = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_CHART_DATA,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getKpiUpdates = (kpiId) => {
    return this.httpService.request({
      url: ROUTES.GET_KPI_UPDATES.replace(':kpiId', kpiId),
      method: HTTP_METHODS.GET,
    });
  };

  removeKpiConnection = (id) => {
    return this.httpService.request({
      url: ROUTES.REMOVE_KPI_CONNECTION.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  removeKpiMicrosoftConnection = (id) => {
    return this.httpService.request({
      url: ROUTES.REMOVE_KPI_MICROSOFT_CONNECTION.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  updateKpiDateRange = (data, kpiId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_KPI_DATE_RANGE.replace(':kpiId', kpiId),
      method: HTTP_METHODS.PUT,
      data
    });
  };
}

const kpiService = new KpiService(httpService);

export default kpiService;
