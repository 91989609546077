import React from 'react';
import styles from './ProgressBarFooter.module.scss';
import CustomDot from "../Dot/CustomDot";

const ProgressBarFooter = ({progress, title, progressProperties}) => {
  return (
    <div className={styles.container}>
      <h4 className={styles.progress}>{`${progress}%`}</h4>
      <div className={styles.rightContainer}>
        <CustomDot progress={progress} containerStyles={progressProperties().dotStyle} />
        <h4 className={styles.title}>{title}</h4>
      </div>
    </div>
  );
};

export default ProgressBarFooter;
