import React, {forwardRef, memo} from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from './TooltipComponent.module.scss';

const TooltipComponent = ({title, placement, children}) => {
  const RenderComponent = forwardRef((props, ref) => {
    return (
      <div {...props} ref={ref} className={styles.container}>
        {children}
      </div>
    );
  });

  return (
    <Tooltip title={title} placement={placement}>
      <RenderComponent />
    </Tooltip>
  );
};

export default memo(TooltipComponent);
