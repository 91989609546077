import React, {useCallback, useEffect, useState} from 'react';
import {getItemParent, getMoveItemTitle, getRowItemIcon} from "../../utils/perspective";
import styles from './MovePerspectiveItem.module.scss';
import CustomModalFooter from "../Shared/Modals/CustomModalFooter";
import {COLORS} from "../../constants/colors";
import {ReactComponent as CheckedIcon} from '../../assets/icons/checked.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/icons/unchecked.svg';
import {ReactComponent as DefaultScorecardIcon} from '../../assets/icons/default-scorecard.svg';
import OutlinedSelectInput from "../Shared/Inputs/OutlinedSelectInput";
import scorecardService from "../../services/ScorecardService";
import goalService from "../../services/GoalService";
import perspectiveService from "../../services/PerspectiveService";
import {MOVE_ITEM_SELECT_TYPES, ROW_ITEM_TYPES} from "../../constants/strings";
import projectService from "../../services/ProjectService";
import SwitchInput from "../Shared/Inputs/SwitchInput";
import {selectTypes} from "./selectTypes";
import {footerConfig} from "./footerConfig";
import CustomModal from "../Shared/Modals/CustomModal";
import RemoveLinkedItemsContent from "./RemoveLinkedItemsContent";
import {useDispatch, useSelector} from "react-redux";
import {warningModalSelector} from "../../store/helper/selectors";
import {setWarningModal} from "../../store/helper/actions";
import {getScorecardName} from "../../utils/setDataForContributesToComponent";
import {terminologySelector} from "../../store/auth/selectors";


const MovePerspectiveItem = ({data, moveItem, closeModal, isParentGoal, scorecard}) => {
  const dispatch = useDispatch();

  const terminology = useSelector(terminologySelector());

  const [selectedType, setSelectedType] = useState(selectTypes({data, terminology})[0]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [perspectiveOptions, setPerspectiveOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedPerspective, setSelectedPerspective] = useState('');
  const [retainContribution, setRetainContribution] = useState(true);

  const warningModal = useSelector(warningModalSelector());

  const itemParent = getItemParent(data);

  const isSubmitButtonEnabled = () => {
    if (selectedType?.type === MOVE_ITEM_SELECT_TYPES.PLAN) {
      return Boolean(selectedPerspective);
    } else {
      return Boolean(selectedItem);
    }
  };

  const getDynamicSelectOptions = useCallback(async (selectedType) => {
    try {
      let selectItems = [];
      switch (selectedType?.type) {
        case MOVE_ITEM_SELECT_TYPES.PLAN:
          if (selectedType?.type === MOVE_ITEM_SELECT_TYPES.PLAN) {
            selectItems = await scorecardService.getScorecardsByOrganization();
          }
          break;
        case MOVE_ITEM_SELECT_TYPES.PROJECT:
          if (selectedType?.type === MOVE_ITEM_SELECT_TYPES.PROJECT) {
            let options = await projectService.getProjectsByOrganization();
            selectItems = options.map((item) => {
              return {...item, subtitle: getScorecardName(item)}
            })
          }
          break;
        default:
          let options = await goalService.getGoalsByOrganization();
          selectItems = options.map((item) => {
            return {...item, subtitle: getScorecardName(item)}
          })
      }

      const selectOptions = selectItems.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name,
          subtitle: item.subtitle,
        };
      });
      setSelectOptions(selectOptions);
    } catch (error) {
      console.error({error});
    }
  }, []);

  const getPerspectiveOptions = useCallback(async () => {
    try {
      const perspectives = await perspectiveService.getPerspectivesForLibrary({scorecardId: selectedItem});
      const perspectiveOptions = perspectives.map((perspective) => {
        return {
          id: perspective.id,
          value: perspective.id,
          name: perspective.name,
        };
      });
      setPerspectiveOptions(perspectiveOptions);
    } catch (error) {
      console.error({error});
    }
  }, [selectedItem]);

  const handleSelectedTypeChange = (item) => {
    if (item.id !== selectedType?.id) {
      setSelectedType(item);
      getDynamicSelectOptions(item);
      setSelectedPerspective('');
      setSelectedItem('');
    }
  };

  const handleChangeItem = async (e) => {
    setSelectedItem(e.target.value);
    setSelectedPerspective('');
  };

  const handleChangePerspective = (e) => {
    setSelectedPerspective(e.target.value);
  };

  const handleRetainContributionChange = useCallback(() => {
    setRetainContribution(!retainContribution);
  }, [retainContribution]);

  const getSelectInputItemPlaceholder = () => {
    switch (selectedType?.type) {
      case MOVE_ITEM_SELECT_TYPES.PLAN:
        return `Select a ${terminology.plan}`;
      case MOVE_ITEM_SELECT_TYPES.PROJECT:
        return `Select a ${terminology.project}`;
      default:
        return `Select a ${terminology.goal}`;
    }
  };

  const getParent = () => {
    if (itemParent?.type === ROW_ITEM_TYPES.PERSPECTIVE) {
      return {
        icon: <DefaultScorecardIcon width={24} height={24} />,
        title: scorecard?.name,
      };
    } else {
      return {
        icon: getRowItemIcon(itemParent, !itemParent?.goalId),
        title: itemParent?.name,
      };
    }
  };

  const closeWarningModal = () => {
    dispatch(setWarningModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));
  };

  const moveItemAndRemoveLinking = () => {
    moveItem(
      data,
      selectedPerspective || selectedItem,
      selectedPerspective
        ? ROW_ITEM_TYPES.PERSPECTIVE
        : selectedType
          ? selectedType?.type
          : ROW_ITEM_TYPES.GOAL,
      retainContribution,
      isParentGoal,
      true,
    );
  };

  useEffect(() => {
    getDynamicSelectOptions(selectTypes({data, terminology})[0]);
  }, [getDynamicSelectOptions, data]);

  useEffect(() => {
    if (selectedType?.type === MOVE_ITEM_SELECT_TYPES.PLAN) {
      getPerspectiveOptions();
    }
  }, [selectedType, getPerspectiveOptions]);

  return (
    <div>
      <div className={styles.mainContainer}>
        <h4 className={styles.title}>{getMoveItemTitle({item: data, terminology})}</h4>
        <div className={styles.itemContainer}>
          <div className={styles.itemNameContainer}>
            <div className={styles.itemIcon}>
              {getRowItemIcon(data, itemParent?.type === ROW_ITEM_TYPES.PERSPECTIVE)}
            </div>
            <h4 className={styles.itemName}>{data.name}</h4>
          </div>
          <div className={styles.parentNameContainer}>
            <div className={styles.itemIcon}>
              {getParent().icon}
            </div>
            <h4 className={styles.itemName}>{getParent().title}</h4>
          </div>
        </div>
        <h4 className={styles.title}>
          Move it to
        </h4>
        {(data.type === ROW_ITEM_TYPES.GOAL || data.type === ROW_ITEM_TYPES.ACTION) && (
          <div className={styles.selectTypeContainer}>
            {selectTypes({data, terminology}).map((item) => {
              return (
                <div
                  className={styles.selectTypeSubContainer}
                  onClick={() => handleSelectedTypeChange(item)}
                  key={item.id}
                >
                  <div className={styles.iconContainer}>
                    {selectedType?.id === item.id ? (
                      <CheckedIcon color={COLORS.darkestGray} />
                    ) : (
                      <UncheckedIcon color={COLORS.darkestGray} />
                    )}
                  </div>
                  <h4 className={styles.itemName}>{item.title}</h4>
                </div>
              );
            })}
          </div>
        )}
        <OutlinedSelectInput
          options={selectOptions}
          value={selectedItem}
          handleChange={handleChangeItem}
          placeholder={getSelectInputItemPlaceholder()}
        />
        {selectedType?.type === MOVE_ITEM_SELECT_TYPES.PLAN && selectedItem && (
          <div className={styles.secondarySelectItemContainer}>
            <OutlinedSelectInput
              options={perspectiveOptions}
              value={selectedPerspective}
              handleChange={handleChangePerspective}
              placeholder={`Select a ${terminology.perspective}`}
            />
          </div>
        )}
        {data.type === ROW_ITEM_TYPES.GOAL && !isParentGoal && (
          <div>
            <div className={styles.switchContainer}>
              <h4 className={styles.title}>Retain contribution to</h4>
              <SwitchInput checked={retainContribution} handleChange={handleRetainContributionChange} />
            </div>
            <p>
              A contributing relationship to this {terminology.goal} will be so existing alignment is maintained. Turn the toggle to "off" if you want to remove this contributing factor
            </p>
          </div>
        )}
      </div>
      <CustomModalFooter
        config={footerConfig({
          data,
          selectedPerspective,
          selectedItem,
          closeModal,
          moveItem,
          selectedType,
          retainContribution,
          isSubmitButtonEnabled,
          isParentGoal,
        })}
      />
      <CustomModal
        isVisible={warningModal.isVisible}
        title={`Remove Linked ${terminology.plan}s and ${terminology.perspective}s`}
        maxWidth={'sm'}
        closeModal={closeWarningModal}
      >
        <RemoveLinkedItemsContent
          moveItem={moveItemAndRemoveLinking}
          closeModal={closeWarningModal}
        />
      </CustomModal>
    </div>
  );
};

export default MovePerspectiveItem;
