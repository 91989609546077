import { takeLatest, call, put, select } from 'redux-saga/effects';
import timeHorizonsService from "../../services/TimeHorizons";
import {CREATE_TIME_HORIZON_SAGA, DELETE_TIME_HORIZON_SAGA, GET_TIME_HORIZONS_SAGA} from "./actionTypes";
import {getTimeHorizonsSaga, setTimeHorizons} from "./actions";
import {setModal} from "../helper/actions";
import {timeHorizonsSelector} from "./selectors";

export function* getTimeHorizonsSagaFn({payload}) {
  try {
    const data = yield call(timeHorizonsService.getTimeHorizonsGrid, {page: payload.page, perPage: 5, sortField: 'createdAt', sortOrder: 'DESC'});
    yield put(setTimeHorizons(data));
  } catch (error) {
    console.log(error)
  }
}

export function* createTimeHorizonSagaFn({payload}) {
  try {
    const timeHorizons = yield select(timeHorizonsSelector())
    if (payload.isUpdate) {
      yield call(timeHorizonsService.updateTimeHorizon, payload, payload.rowId);
      yield put(getTimeHorizonsSaga({page: timeHorizons.page}));
    } else {
      yield call(timeHorizonsService.createTimeHorizon, payload);
      yield put(getTimeHorizonsSaga({page: 1}));
    }
    yield put(setModal({isVisible: false, component: null, title: null}));
  } catch (error) {
    console.log(error)
  }
}

export function* deleteTimeHorizonSagaFn({horizonId}) {
  try {
    const timeHorizons = yield select(timeHorizonsSelector())
    yield call(timeHorizonsService.deleteTimeHorizon, horizonId);
    yield put(getTimeHorizonsSaga({page: timeHorizons.page}));
    yield put(setModal({isVisible: false, component: null, title: null}));
  } catch (error) {
    console.log(error)
  }
}

export default function* timeHorizonsSaga() {
  yield takeLatest(GET_TIME_HORIZONS_SAGA, getTimeHorizonsSagaFn);
  yield takeLatest(CREATE_TIME_HORIZON_SAGA, createTimeHorizonSagaFn);
  yield takeLatest(DELETE_TIME_HORIZON_SAGA, deleteTimeHorizonSagaFn);
}
