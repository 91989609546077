import React from 'react';
import Rating from '@mui/material/Rating';
import styles from './RatingComponent.module.scss';

const RatingComponent = ({value, setValue }) => {

  const RatingIcon = () => {
    return (
      <div className={styles.square} >
        <div className={styles.innerSquare} />
      </div>
    );
  };

  const EmptyRatingIcon = () => {
    return (
      <div className={styles.square} />
    );
  };

  return (
    <div className={styles.container}>
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        icon={<RatingIcon />}
        emptyIcon={<EmptyRatingIcon />}
      />
    </div>
  );
};

export default RatingComponent;
