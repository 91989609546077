import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";
import authService from "./AuthService";

const ROUTES = {
  GET_ORGANIZATION: '/organizations',
  GET_USERS_ORGANIZATIONS: '/organizations/all',
  UPDATE_ORGANIZATION: '/organizations',
  CREATE_ORGANIZATION: '/organizations/new',
};

class OrganizationService {
  constructor(httpService) {
    this.httpService = httpService;
  }
  getOrganization = () => {
    return this.httpService.request({
      url: ROUTES.GET_ORGANIZATION,
      method: HTTP_METHODS.GET
    });
  };

  getUsersOrganizations = () => {
    return this.httpService.request({
      url: ROUTES.GET_USERS_ORGANIZATIONS,
      method: HTTP_METHODS.GET
    });
  };

  updateOrganization = (formData) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ORGANIZATION,
      method: HTTP_METHODS.POST,
      data: formData
    });
  };

  createOrganization = async (formData) => {
    const res = await this.httpService.request({
      url: ROUTES.CREATE_ORGANIZATION,
      method: HTTP_METHODS.POST,
      data: formData
    });

    await authService.setAuthToken(res.token);

    return res.token;
  };

}

const organizationService = new OrganizationService(httpService);

export default organizationService;
