import React from "react";
import {ReactComponent as AddIcon} from '../../../src/assets/icons/plus-circle.svg';
import styles from "./AppBar.module.scss";
import {COLORS} from "../../constants/colors";

export const addNewItemMenuConfig = ({
                                       onAddGoal,
                                       onCreatePlan,
                                       onCreateGroup,
                                       onInviteTeammates,
                                       onGeneratePlan,
                                       terminology,
                                     }) => {
  return {
    button: {
      icon: (
        <div className={styles.iconButton}>
          <AddIcon width={24} height={24} color={COLORS.iconGray}/>
        </div>
      ),
      styles: {
        padding: 0
      }
    },
    content: {
      menu: {
        items: [
          {
            id: 1,
            title: `Add a ${terminology?.plan}`,
            onClick: onCreatePlan,
          },
          {
            id: 2,
            title: `Add a ${terminology?.goal}`,
            onClick: onAddGoal,
          },
          {
            id: 3,
            title: 'Invite Teammates',
            onClick: onInviteTeammates,
          },
          {
            id: 4,
            title: 'Create a Group',
            onClick: onCreateGroup,
          },
          {
            id: 5,
            title: `Import a ${terminology?.plan}`,
            onClick: () => console.log('import a plan'),
          },
          {
            id: 6,
            title: `Generate a ${terminology?.plan}`,
            onClick: onGeneratePlan,
          },
        ],
      },
    },
  };
};
