import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectStrategyPlan = (state) => state.strategyPlan || initialState;

const strategyPlanSelector = () =>
  createSelector(selectStrategyPlan, (state) => state.strategyPlan);

const strategyPlanWithPerspectivesSelector = () =>
  createSelector(selectStrategyPlan, (state) => state.strategyPlanWithPerspectives);

const itemRowSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.itemRow);

const editGoalModalSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.editGoalModal);

const showEditParentSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.showEditParent);

const optionsForEditParentSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.optionsForEditParent);

const goalsOnHomePageSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.homePageGoals);
const homePageProjectsActionsKpisSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.homePageProjectsActionsKpis);

const homePageAllItemsSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.homePageAllItems);
const itemSortActiveSelector = () =>
    createSelector(selectStrategyPlan, (state) => state.itemSortActive);

export {
  strategyPlanSelector,
  strategyPlanWithPerspectivesSelector,
  editGoalModalSelector,
  itemRowSelector,
  showEditParentSelector,
  optionsForEditParentSelector,
  goalsOnHomePageSelector,
  homePageProjectsActionsKpisSelector,
  homePageAllItemsSelector,
  itemSortActiveSelector
};
