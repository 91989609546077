import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setSnackbar} from '../store/snackbar/actions';
import httpService from './HttpService';
import {logout} from '../store/auth/actions';
import {setLoader} from "../store/loader/actions";
import metricsService from "./MetricsService";
import {setWarningModal} from "../store/helper/actions";
import ConnectionErrorModalContent
  from "../components/Shared/TrackingComponent/SheetIntegration/ConnectionErrorModalContent";
import authService from "./AuthService";

function ErrorHandler(props) {
  const dispatch = useDispatch();
  let spinnerTimer;
  let spinnerTimerCounter = 0;
  let isRefreshing = false;
  let refreshPromise = null;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.config.headers['Authorization'] = `Bearer ${token}`;
        httpService.request(prom.config)
            .then(prom.resolve)
            .catch(prom.reject);
      }
    });

    failedQueue = [];
  };

  const refreshToken = async () => {
    if (!refreshPromise) {
      refreshPromise = new Promise(async (resolve, reject) => {
        try {
          httpService.removeHeaders(['Authorization']);
          const refreshToken = localStorage.getItem('refreshToken');
          const res = await authService.refreshTokenRequest({ refreshToken: JSON.parse(refreshToken) });
          const newToken = res.token;
          const newRefreshToken = res.refreshToken;
          localStorage.setItem('token', JSON.stringify(newToken));
          localStorage.setItem('refreshToken', JSON.stringify(newRefreshToken));
          httpService.attachHeaders({
            Authorization: `Bearer ${newToken}`,
          });
          processQueue(null, newToken);
          resolve(newToken);
        } catch (err) {
          processQueue(err, null);
          reject(err);
        } finally {
          isRefreshing = false;
          refreshPromise = null;
        }
      });
    }
    return refreshPromise;
  };

  const handleRequestStart = () => {
    httpService.addRequestInterceptor((config) => {
      // start spinner
      if (spinnerTimer) clearTimeout(spinnerTimer);
      spinnerTimer = setTimeout(() => {
        if (spinnerTimerCounter === 0) return;
        dispatch(setLoader(true));
      }, config.spinnerTimeout);

      spinnerTimerCounter++;
      return config;
    });
  };

  const handleError = () => {
    httpService.addResponseInterceptors(
        (response) => {
          spinnerTimerCounter = Math.max(0, spinnerTimerCounter - 1);
          if (spinnerTimerCounter === 0) {
            if (spinnerTimer) clearTimeout(spinnerTimer);
            dispatch(setLoader(false));
          }
          return response;
        },
        async (err) => {
          spinnerTimerCounter = Math.max(0, spinnerTimerCounter - 1);
          if (spinnerTimerCounter === 0) {
            if (spinnerTimer) clearTimeout(spinnerTimer);
            dispatch(setLoader(false));
          }

          const originalRequest = err.config;
          if (err.response && err.response.status === 401 && err.response.data === 'access_token_expired') {
            if (!isRefreshing) {
              isRefreshing = true;
              try {
                const newToken = await refreshToken();
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return httpService.request(originalRequest);
              } catch (refreshError) {
                dispatch(logout());
                return Promise.reject(refreshError);
              } finally {
                isRefreshing = false;
              }
            }

            return new Promise((resolve, reject) => {
              failedQueue.push({ config: originalRequest, resolve, reject });
            });
          }

          if (err?.data instanceof ArrayBuffer) {
            err.data.message = JSON.parse(
                new TextDecoder().decode(err?.data)
            ).message;
            dispatch(setSnackbar({
              open: true,
              severity: 'error',
              message: err.message,
            }));
          }

          if (err?.response?.data?.message === 'invalid_google_auth') {
            const closeWarningModal = () => {
              dispatch(setWarningModal({
                isVisible: false,
                component: null,
                title: '',
              }));
            };

            if (err?.response?.data?.additionalErrorProperty?.authForMyself) {
              const authorizeGoogleSheet = async () => {
                try {
                  const response = await metricsService.authorizeGoogleSheetConnections();
                  window.open(response, '_self');
                } catch (error) {
                  console.log({ error });
                }
              }
              authorizeGoogleSheet();
            } else {
              dispatch(setWarningModal({
                isVisible: true,
                title: 'Connection Problem',
                component: (
                    <ConnectionErrorModalContent closeModal={closeWarningModal} />
                ),
                maxWidth: 'sm',
              }));
            }

            throw err;
          }

          if (err?.response?.data?.message === 'invalid_microsoft_auth') {
            const closeWarningModal = () => {
              dispatch(setWarningModal({
                isVisible: false,
                component: null,
                title: '',
              }));
            };

            if (err?.response?.data?.additionalErrorProperty?.authForMyself) {
              const authorizeMicrosoftExcelConnections = async () => {
                try {
                  const response = await metricsService.authorizeMicrosoftExcelConnections();
                  window.open(response, '_self');
                } catch (error) {
                  console.log({error});
                }
              }
              await authorizeMicrosoftExcelConnections();
            } else {
              dispatch(setWarningModal({
                isVisible: true,
                title: 'Connection Problem',
                component: (
                  <ConnectionErrorModalContent closeModal={closeWarningModal} />
                ),
                maxWidth: 'sm',
              }));
            }

            throw err;
          }

          if (err?.data === 'Subscription not found') {
            throw err;
          }

          let error = err.response || err;

          if ((error.status === 400 || error.status === 500 || error.status === 404 || error.status === 403 || error.status === 409 || error.status === 422) && error?.data.message !== 'invalid_grant' && error.data !== 'Subscription not found') {
            dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error?.data?.message
                      ? error?.data?.message
                      : error?.data?.messages
                          ? error?.data?.messages[0]?.message
                          : error?.message
                              ? error?.message
                              : error?.data
                                  ? error?.data
                                  : 'Something went wrong',
                })
            );
            throw err;
          }

          return Promise.reject(err);
        }
    );
  };

  useEffect(() => {
    handleRequestStart();
    handleError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
}

export default ErrorHandler;
