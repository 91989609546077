import {MoreVert} from "@mui/icons-material";
import {COLORS} from "../../../constants/colors";
import React from "react";
import {ReactComponent as SettingsIcon} from "../../../assets/icons/gear.svg";

export const sidebarPlanHeaderMenuConfig = ({
                                                onClick,
                                                item,
                                                terminology,
                                            }) => {
    return {
        button: {
            icon: (
                <MoreVert style={{color: COLORS.lightGray}}/>
            ),
        },
        content: {
            menu: {
                items: [
                    {
                        id: 1,
                        title: `${terminology.plan} Settings`,
                        icon: <SettingsIcon color={COLORS.darkerGray} />,
                        onClick: () => onClick(item, 'plan-settings'),
                    },
                ],
            },
        },
    };
};
