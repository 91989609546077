import React, {memo} from 'react';
import styles from './ItemWrapper.module.scss';
import {ACTIONS, ROW_ITEM_TYPES} from "../../constants/strings";
import AddGoalModalContentComponent from "../../containers/StrategyPlanPage/PerspectiveComponent/AddGoalModalContentComponent";
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrowLeft.svg';
import ActionModalContentComponent
  from "../../containers/StrategyPlanPage/ItemRowComponent/Components/ActionModalContentComponent/ActionModalContentComponent";
import KpiModalContentComponent
  from "../../containers/StrategyPlanPage/ItemRowComponent/Components/KpiModalContentComponent/KpiModalContentComponent";
import AddNewProjectModalContentComponent
  from "../../containers/StrategyPlanPage/RightSidebar/AddNewProjectModalContentComponent";


const ItemWrapper = ({item, goBack, action, type, parentType, scorecardId, onFormSubmit, scorecard}) => {
  const renderContent = () => {
    switch (type) {
      case ROW_ITEM_TYPES.GOAL:
        return (
          <AddGoalModalContentComponent
            parentType={parentType}
            planId={scorecardId}
            onFormSubmit={(values) => onFormSubmit(values, action, type, item)}
            onCancel={goBack}
            scorecard={scorecard}
            canContributesToEditParent={action === ACTIONS.EDIT}
          />
        );
      case ROW_ITEM_TYPES.KPI:
        return (
          <KpiModalContentComponent
            parentType={parentType}
            planId={scorecardId}
            onFormSubmit={(values) => onFormSubmit(values, action, type, item)}
            onCancel={goBack}
          />
        );
      case ROW_ITEM_TYPES.PROJECT:
        return (
          <AddNewProjectModalContentComponent
            parentType={parentType}
            planId={scorecardId}
            onFormSubmit={(values) => onFormSubmit(values, action, type, item)}
            onCancel={goBack}
          />
        );
      case ROW_ITEM_TYPES.ACTION:
        return (
          <ActionModalContentComponent
            parentType={parentType}
            planId={scorecardId}
            onFormSubmit={(values) => onFormSubmit(values, action, type, item)}
            onCancel={goBack}
          />
        );
      default:
        return console.log('no matching type selected');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div onClick={goBack} className={styles.button}>
          <ArrowLeft />
          <h4 className={styles.backButtonText}>Back</h4>
        </div>
      </div>
      <div className={styles.listContainer}>
        {renderContent()}
      </div>
    </div>
  );
};

export default memo(ItemWrapper);
