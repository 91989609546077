import React from "react";
import { ReactComponent as GoalArrowIcon } from '../assets/icons/goal-arrow.svg';
import { ReactComponent as GoalIcon } from '../assets/icons/goal-parent.svg';
import { ReactComponent as GoalSharedIcon } from '../assets/icons/goal-shared.svg';
import { ReactComponent as ActionIcon } from '../assets/icons/dotted-circle.svg';
import { ReactComponent as SpeedometerIcon } from '../assets/icons/speedometer.svg';
import { ReactComponent as ProjectIcon } from '../assets/icons/project.svg';
import AllOutIcon from '@mui/icons-material/AllOut';
import {ITEM_ROW_LINKED_TYPES, ROW_ITEM_TYPES, TRACKING, TRACKING_TYPE, UnitsContants} from "../constants/strings";
import {COLORS} from "../constants/colors";
import scorecardService from "../services/ScorecardService";
import goalService from "../services/GoalService";
import {getRangePercentage} from "./format";
import usersService from "../services/UsersService";
import {helperService} from "../services/HelperService";

export const getRowItemIcon = (item, isParentGoal) => {
  switch (item?.type) {
    case ROW_ITEM_TYPES.GOAL:
      if (item.linkedType) {
        switch (item.linkedType) {
          case ITEM_ROW_LINKED_TYPES.SHARE:
            return (
              <GoalSharedIcon width={24} height={24} />
            );
          case ITEM_ROW_LINKED_TYPES.CONTRIBUTOR:
            return (
              <GoalArrowIcon />
            );
          default:
            return (
              <GoalSharedIcon width={24} height={24} />
            );
        }
      } else {
        if (isParentGoal) {
          return (
            <GoalIcon width={24} height={24} />
          );
        }
        return (
          <GoalArrowIcon />
        );
      }
    case ROW_ITEM_TYPES.ACTION:
      return (
        <ActionIcon />
      );
    case ROW_ITEM_TYPES.KPI:
      return (
        <SpeedometerIcon />
      );
    case ROW_ITEM_TYPES.PROJECT:
      return (
        <ProjectIcon />
      );
    case ROW_ITEM_TYPES.PERSPECTIVE:
      return (
        <AllOutIcon />
      );
    default:
      return (
        <GoalArrowIcon />
      );
  }
};

export const goalStatus = (status) => {
  switch (status) {
    case 'Late':
      return {title: 'Late', color: COLORS.red};
    case 'At Risk':
      return {title: 'At Risk', color: COLORS.brightOrange};
    case 'On Track':
      return {title: 'On Track', color: COLORS.brightGreen};
    case 'Exceeded':
      return {title: 'Exceeded', color: COLORS.purple};
    case 'Achieved':
      return {title: 'Achieved', color: COLORS.blue};
    default:
      return {title: '', color: null}
  }
};

export const linkedGoalTooltip = ({itemRow, scorecardId, terminology}) => {
  const planName = () => {
    if (itemRow.perspective.scorecard.id === scorecardId) {
      return `another ${terminology.perspective}`;
    }

    return `${itemRow.perspective.scorecard.name} ${terminology.plan}`;
  };

  switch (itemRow.linkedType) {
    case ITEM_ROW_LINKED_TYPES.SHARE:
      return `This ${terminology.goal} is shared with ${planName()}`;
    case ITEM_ROW_LINKED_TYPES.CONTRIBUTOR:
      return `This ${terminology.goal} contributes to ${planName()}`;
    default:
      return `This ${terminology.goal} is shared with ${planName()}`;
  }
};

export const getMoveItemTitle = ({item, modal, terminology}) => {
  return `Move ${modal ? 'a' : 'this'} ${getItemTypeTerminology({type: item.type, terminology})}`;
};

export const getMarksForSlider = (data, hideLabel = true) => {
  let marks = [];
  switch (data.tracking) {
    case TRACKING.MANUALLY:
    case TRACKING.METRIC:
    case TRACKING.MICROSOFT_EXCEL:
    case TRACKING.GOOGLE_SHEETS:
      switch (data.trackingType) {
        case TRACKING_TYPE.BETWEEN:
          marks = [{label: !hideLabel && data.lowerTarget, value: data.lowerTarget}, {label: !hideLabel && data.upperTarget, value: data.upperTarget}]
          break;
        case TRACKING_TYPE.EXCEED:
          marks = [{label: !hideLabel && data.lowerTarget, value: data.lowerTarget}]
          break;
        case TRACKING_TYPE.BELOW:
          marks = [{label: !hideLabel && data.upperTarget, value: data.upperTarget}]
          break;
        case TRACKING_TYPE.ACHIEVED:
          marks = []
          break;
        default:
          marks = [];
      }
      break;
    case TRACKING.MILESTONE:
      let arr = data?.milestones?.map((item, index) => {
        return {label: !hideLabel && ((index === 0 || index === data.milestones.length - 1) ? '' : item.percentage), value: item.percentage};
      })
      marks = arr;
      break;
  }


  return marks;
};

export const formatUnit = (unit, forChart = false) => {
  switch (unit) {
    case 'dollar':
      return '$';
    case 'pound':
      return '£';
    case 'percentage':
      return '%';
    case 'seconds':
      return `${forChart ? '' : ' '}secs`;
    case 'minutes':
      return `${forChart ? '' : ' '}mins`;
    case 'hours':
      return `${forChart ? '' : ' '}hours`;
    case 'days':
      return `${forChart ? '' : ' '}days`;
    case 'weeks':
      return `${forChart ? '' : ' '}weeks`;
    case 'months':
      return `${forChart ? '' : ' '}months`;
    case 'years':
      return `${forChart ? '' : ' '}years`;
    case 'customers':
      return `${forChart ? '' : ' '}cus`;
    case 'no-unit':
      return `${forChart ? '' : ' '}`;
    default:
      return unit;
  }
};

export const formatExpectedProgress = (item) => {
  switch (true) {
    case Boolean((item.lowerTarget && item.upperTarget)):
      return `of ${item.lowerTarget}${formatUnit(item.unit)}${item.upperTarget && ` - ${item.upperTarget}${formatUnit(item.unit)}`}`;
    case Boolean(item.lowerTarget):
      return `of ${item.lowerTarget}${formatUnit(item.unit)}`;
    case Boolean(item.upperTarget):
      return `of ${item.upperTarget}${formatUnit(item.unit)}`;
    default:
      console.log('no matching condition');
  }
};

export const formatUnitAndValue = (unit, value) => {
    switch (unit) {
      case UnitsContants.DOLLAR:
        return `$${value}`;

      case UnitsContants.POUND:
        return `£${value}`;

      case UnitsContants.PERCENTAGE:
        return `${value}%`;

      case UnitsContants.SECONDS:
        return `${value}s`;

      case UnitsContants.MINUTES:
        return `${value}min`;

      case UnitsContants.HOURS:
        return `${value}hr`;

      case UnitsContants.DAYS:
        return `${value} ${value === 1 ? 'day' : 'days'}`;

      case UnitsContants.WEEKS:
        return `${value} ${value === 1 ? 'week' : 'weeks'}`;

      case UnitsContants.MONTHS:
        return `${value} ${value === 1 ? 'month' : 'months'}`;

      case UnitsContants.YEARS:
        return `${value} ${value === 1 ? 'year' : 'years'}`;

      case UnitsContants.CUSTOMERS:
        return `${value} ${value === 1 ? 'customer' : 'customers'}`;

      case UnitsContants.USERS:
        return `${value} ${value === 1 ? 'user' : 'users'}`;

      case UnitsContants.NPS_SCORE:
        return `${value}NPS`;

      case UnitsContants.NUMBER:
        return `${value} numbers`;

      case UnitsContants.NO_UNIT:
        return `${value}`;

      default:
        return `${value} %`;
    }
}


export const formatProgressString = (item, value) => {
  switch (item.unit) {
    case 'dollar':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `$${value}`, expectedProgress:` of $${item.lowerTarget} - $${item.upperTarget}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `$${value}`, expectedProgress:` of $${item.lowerTarget}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `$${value}`, expectedProgress:` of $${item.upperTarget}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'pound':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `£${value}`, expectedProgress:` of £${item.lowerTarget} - £${item.upperTarget}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `£${value}`, expectedProgress:` of £${item.lowerTarget}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `£${value}`, expectedProgress:` of £${item.upperTarget}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'percentage':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}%`, expectedProgress:` of ${item.lowerTarget}% - ${item.upperTarget}%`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}%`, expectedProgress:` of ${item.lowerTarget}%`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}%`, expectedProgress:` of ${item.upperTarget}%`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'seconds':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}s`, expectedProgress:` of ${item.lowerTarget}s - ${item.upperTarget}s`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}s`, expectedProgress:` of ${item.lowerTarget}s`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}s`, expectedProgress:` of ${item.upperTarget}s`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'minutes':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}min`, expectedProgress:` of ${item.lowerTarget}min - ${item.upperTarget}min`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}min`, expectedProgress:` of ${item.lowerTarget}min`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}min`, expectedProgress:` of ${item.upperTarget}min`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'hours':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}hr`, expectedProgress:` of ${item.lowerTarget}hr - ${item.upperTarget}hr`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}hr`, expectedProgress:` of ${item.lowerTarget}hr`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}hr`, expectedProgress:` of ${item.upperTarget}hr`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'days':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'day' : 'days'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'day' : 'days'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'day' : 'days'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'weeks':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'week' : 'weeks'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'week' : 'weeks'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'week' : 'weeks'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'months':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'month' : 'months'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'month' : 'months'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'month' : 'months'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'years':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'year' : 'years'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'year' : 'years'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'year' : 'years'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'customers':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'customer' : 'customers'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'customer' : 'customers'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'customer' : 'customers'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'users':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget} ${item.upperTarget === 1 ? 'user' : 'users'}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} ${item.lowerTarget === 1 ? 'user' : 'users'}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget} ${item.upperTarget === 1 ? 'user' : 'users'}`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'nps-score':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}NPS`, expectedProgress:` of ${item.lowerTarget}NPS - ${item.upperTarget}NPS`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}NPS`, expectedProgress:` of ${item.lowerTarget}NPS`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}NPS`, expectedProgress:` of ${item.upperTarget}NPS`};
        default:
          console.log('no matching condition');
          return '';
      }

    case 'no-unit':
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget} - ${item.upperTarget}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.lowerTarget}`};
        case Boolean(item.upperTarget !== null):
          return {currentValue: `${value}`, expectedProgress:` of ${item.upperTarget}`};
        default:
          console.log('no matching condition');
          return '';
      }

    default:
      switch (true) {
        case Boolean((item.lowerTarget !== null && item.upperTarget !== null)):
          return {currentValue: `${value} ${item.unit}`, expectedProgress:` of ${item.lowerTarget} ${item.unit} - ${item.upperTarget} ${item.unit}`};
        case Boolean(item.lowerTarget !== null):
          return {currentValue: `${value} ${item.unit}`, expectedProgress:` of ${item.lowerTarget} ${item.unit}`};
        case Boolean(item.upperTarget) !== null:
          return {currentValue: `${value} ${item.unit}`, expectedProgress:` of ${item.upperTarget} ${item.unit}`};
        default:
          console.log('no matching condition');
          return '';
      }
  }
};

export const getItemParent = (item) => {
  switch (item.type) {
    case ROW_ITEM_TYPES.GOAL:
      if (item.parentGoal) {
        return {...item.parentGoal, perspective: item.perspective};
      } else {
        return item.perspective;
      }

    case ROW_ITEM_TYPES.ACTION:
      if (item.project) {
        return item.project;
      } else {
        return item.goal;
      }

    default:
      return item.goal;
  }
};

export const getActiveItem = async (item, goalWithItems) => {
  let activeItem;
  switch (item.type) {
    case ROW_ITEM_TYPES.GOAL:
      if (goalWithItems) {
        activeItem = await goalService.getGoalWithItems(item.id);
      } else {
        activeItem = await scorecardService.getGoalById(item.id);
      }
      break;
    case ROW_ITEM_TYPES.KPI:
      activeItem = await scorecardService.getKpiById(item.id);
      break;
    case ROW_ITEM_TYPES.PROJECT:
      activeItem = await scorecardService.getProjectById(item.id);
      break;
    case ROW_ITEM_TYPES.ACTION:
      activeItem = await scorecardService.getActionById(item.id);
      break;
    default:
      console.log('no matching type selected');
  }

  return activeItem;
};

export const getMarksForProgressBar = (data) => {
  let marks = [];
  switch (data.trackingType) {
    case TRACKING_TYPE.BETWEEN:
      marks = [{id: 1, label: data?.lowerTarget, value: getRangePercentage(data?.lowerTarget, data?.min, data?.max)}, {id: 2, label: data?.upperTarget, value: getRangePercentage(data?.upperTarget, data?.min, data?.max)}];
      break;
    case TRACKING_TYPE.EXCEED:
      marks = [{id: 1, label: data?.lowerTarget, value: getRangePercentage(data?.lowerTarget, data?.min, data?.max)}];
      break;
    case TRACKING_TYPE.BELOW:
      marks = [{id: 1, label: data?.upperTarget, value: getRangePercentage(data?.upperTarget, data?.min, data?.max)}];
      break;
    case TRACKING_TYPE.ACHIEVED:
      marks = []
      break;
    default:
      marks = [];
  }

  return marks;
};

export const getItemTypeTerminology = ({type, terminology}) => {
  switch (type) {
    case ROW_ITEM_TYPES.PLAN:
      return terminology.plan;
    case ROW_ITEM_TYPES.PERSPECTIVE:
      return terminology.perspective;
    case ROW_ITEM_TYPES.GOAL:
      return terminology.goal;
    case ROW_ITEM_TYPES.KPI:
      return terminology.kpi;
    case ROW_ITEM_TYPES.PROJECT:
      return terminology.project;
    case ROW_ITEM_TYPES.ACTION:
      return terminology.action;
    case ROW_ITEM_TYPES.OWNER:
      return ROW_ITEM_TYPES.OWNER;
    case ROW_ITEM_TYPES.GROUP:
      return ROW_ITEM_TYPES.GROUP;
    default:
      return '';
  }
};

export const getOwnerSelectOptions = async ({group}) => {
  let users = [];
  if (group) {
    users = await usersService.getUsersByGroup(group.id);
  } else {
    users = await helperService.getUsersByOrganization();
  }

  const options = users.map((user) => {
    return {
      id: user.id,
      value: user.id,
      title: user.name,
      subtitle: user.email,
      image: user.photo,
    };
  });

  return options;
};
