import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHelper = (state) => state.helper || initialState;

const foundationSelector = () =>
    createSelector(selectHelper, (state) => state.foundation);

const organizationActivitySelector = () =>
    createSelector(selectHelper, (state) => state.organizationActivity);

const rightModalSelector = () =>
    createSelector(selectHelper, (state) => state.rightModal);

const logTypeActivitySelector = () =>
    createSelector(selectHelper, (state) => state.logTypeActivity);

const userActivitySelector = () =>
    createSelector(selectHelper, (state) => state.userActivity);

const modalSelector = () =>
  createSelector(selectHelper, (state) => state.modal);

const modalUpdateGroupSelector = () =>
    createSelector(selectHelper, (state) => state.modalGroupUpdate);


const themesSelector = () =>
    createSelector(selectHelper, (state) => state.themes);

const actionModalFromNavbarSelector = () =>
    createSelector(selectHelper, (state) => state.actionModalFromNavbar);
const itemAndParentForActionSelector = () =>
    createSelector(selectHelper, (state) => state.itemAndParentForAction);

const warningModalSelector = () =>
  createSelector(selectHelper, (state) => state.warningModal);

const commentsSelector = () =>
    createSelector(selectHelper, (state) => state.comments);

const refreshSelector = () =>
  createSelector(selectHelper, (state) => state.refresh);

const isDeleteModalVisibleSelector = () =>
  createSelector(selectHelper, (state) => state.isDeleteModalVisible);

const updatesSelector = () =>
  createSelector(selectHelper, (state) => state.updates);

const deleteModalSelector = () =>
  createSelector(selectHelper, (state) => state.deleteModal);

const groupUpdatesSelector = () =>
    createSelector(selectHelper, (state) => state.groupUpdates);

const additionalModalSelector = () =>
  createSelector(selectHelper, (state) => state.additionalModal);

export {
  itemAndParentForActionSelector,
  foundationSelector,
  organizationActivitySelector,
  logTypeActivitySelector,
  rightModalSelector,
  userActivitySelector,
  modalSelector,
  themesSelector,
  actionModalFromNavbarSelector,
  warningModalSelector,
  commentsSelector,
  refreshSelector,
  isDeleteModalVisibleSelector,
  updatesSelector,
  deleteModalSelector,
  modalUpdateGroupSelector,
  groupUpdatesSelector,
  additionalModalSelector,
};
