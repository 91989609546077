import React from 'react';
import styles from './NoUpdatesPlaceholder.module.scss';
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

const NoUpdatesPlaceholder = () => {
  const terminology = useSelector(terminologySelector());

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Keep everyone in the loop</h2>
      <p className={styles.subtitle}>Highlight your wins, share misses, and update your {terminology.kpi}s</p>
    </div>
  );
};

export default NoUpdatesPlaceholder;
