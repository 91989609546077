import produce from 'immer';
import {
    SET_SUBSCRIPTION, SUBSCRIPTION_LOADED,
} from './actionTypes';

export const initialState = {
  subscription: null,
  subscriptionLoaded: false,
};

/* eslint-disable default-case */
const billingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
        case SET_SUBSCRIPTION:
            draft.subscription = action.payload;
            break;
        case SUBSCRIPTION_LOADED:
            draft.subscriptionLoaded = action.payload;
            break;
    }
  });

export default billingReducer;
