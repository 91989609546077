import {call, put, takeLatest} from 'redux-saga/effects';
import {
    CONNECT_GOOGLE_SHEETS_SAGA, CONNECT_JIRA_SAGA, CONNECT_MICROSOFT_EXCEL_SAGA,
    CREATE_KPI_AND_ADD_TO_METRIC_SAGA,
    CREATE_METRIC_SAGA,
    DELETE_METRIC_SAGA,
    DELETE_SINGLE_METRIC_FROM_LIST_SAGA,
    GET_METRIC_SAGA,
    UPDATE_METRIC_AND_ADD_TO_KPI_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import metricsService from "../../services/MetricsService";
import {
    setAllMetrics,
    setIsCreateModalVisible,
    setIsDeleteModalVisible,
    setMetric,
    setSelectedMetricForEdit
} from "./actions";
import {setSnackbar} from "../snackbar/actions";
import {rightModalOpen, setItemAndParentForAction} from "../helper/actions";
import perspectiveService from "../../services/PerspectiveService";
import {METRICS} from "../../routesConstants";
import {push} from "connected-react-router";
import authService from "../../services/AuthService";
import {fetchAuthenticatedUserSuccess, setTerminology} from "../auth/actions";

export function* getMetricSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const metric = yield call(metricsService.getMetric, payload.id);
        yield put(setMetric(metric));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* createMetricSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        if (payload.id) {
            yield call(metricsService.updateMetric, payload, payload.id);
        } else {
            yield call(metricsService.createMetric, payload);
        }
        const metrics = yield call(metricsService.getAllMetrics);
        yield put(setAllMetrics(metrics));
        yield put(setIsCreateModalVisible(false));
        yield put(setSelectedMetricForEdit(null));

        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `Successfully ${payload?.id ? 'updated' : 'created'} metric!`,
        }));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* deleteMetricSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        yield call(metricsService.deleteMetric, payload.id);
        const metrics = yield call(metricsService.getAllMetrics);
        yield put(setAllMetrics(metrics));
        yield put(setIsDeleteModalVisible(false));

        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${payload?.name} has been successfully deleted!`,
        }));

        yield put(rightModalOpen({
            isVisible: false,
            component: null,
            page: null,
        }));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* deleteSingleMetricFromListMetricSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        yield call(metricsService.deleteMetric, payload.id);
        const metrics = yield call(metricsService.getAllMetrics);
        yield put(setAllMetrics(metrics));
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${payload?.name} has been successfully deleted!`,
        }));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* createKpiAndAddToMetricSagaFn({values}) {
    try {
        yield put(setLoader(true));
        const res = yield call(perspectiveService.addNewKPI, values);
        if (res) {
            yield put(setItemAndParentForAction({parent: null, item: null}));
            yield put(push(METRICS));
        }
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* updateKpiAndAddToMetricSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        yield call(perspectiveService.editKpi, payload);
        yield put(push(METRICS));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* connectGoogleSheetsSagaFn() {
    try {
        yield put(setLoader(true));
        const response = yield call(metricsService.authorizeGoogleSheetConnections);
        window.open(response, '_self');
        const res = yield call(authService.fetchAuthenticatedUser)
        yield put(fetchAuthenticatedUserSuccess(res));
        yield put(setTerminology(res.framework));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* connectJiraSagaFn() {
    try {
        yield put(setLoader(true));
        const response = yield call(metricsService.authorizeJiraConnections);
        window.open(response, '_self');
        const res = yield call(authService.fetchAuthenticatedUser)
        yield put(fetchAuthenticatedUserSuccess(res));
        yield put(setTerminology(res.framework));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* connectMicrosoftExcelSagaFn() {
    try {
        yield put(setLoader(true));
        const response = yield call(metricsService.authorizeMicrosoftExcelConnections);
        window.open(response, '_self');
        const res = yield call(authService.fetchAuthenticatedUser)
        yield put(fetchAuthenticatedUserSuccess(res));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export default function* metricsSaga() {
    yield takeLatest(GET_METRIC_SAGA, getMetricSagaFn);
    yield takeLatest(UPDATE_METRIC_AND_ADD_TO_KPI_SAGA, updateKpiAndAddToMetricSagaFn);
    yield takeLatest(CREATE_KPI_AND_ADD_TO_METRIC_SAGA, createKpiAndAddToMetricSagaFn);
    yield takeLatest(CREATE_METRIC_SAGA, createMetricSagaFn);
    yield takeLatest(DELETE_METRIC_SAGA, deleteMetricSagaFn);
    yield takeLatest(DELETE_SINGLE_METRIC_FROM_LIST_SAGA, deleteSingleMetricFromListMetricSagaFn);
    yield takeLatest(CONNECT_GOOGLE_SHEETS_SAGA, connectGoogleSheetsSagaFn);
    yield takeLatest(CONNECT_JIRA_SAGA, connectJiraSagaFn);
    yield takeLatest(CONNECT_MICROSOFT_EXCEL_SAGA, connectMicrosoftExcelSagaFn);
}
