import React from 'react';
import styles from './ChangePasswordComponent.module.scss';
import {Form, Formik} from "formik";
import DefaultButton from "../../../components/Shared/Buttons/DefaultButton";
import PasswordField from "../../../components/Forms/PasswordField";
import usersService from "../../../services/UsersService";
import {setSnackbar} from "../../../store/snackbar/actions";
import {useDispatch} from "react-redux";
import {changePasswordSchema} from "../../../validation/user";

export const ChangePasswordComponent = () => {
    const dispatch = useDispatch();
    const handleSubmit = async (values, formikHelpers) => {
        try {
          await usersService.changePassword(values);
          formikHelpers.resetForm();
          dispatch(setSnackbar({
                open: true,
                severity: 'success',
                message: 'Password successfully updated!',
            }))
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Formik
            initialValues={
                {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                }
            }
            onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={changePasswordSchema}
        >
            {({setFieldValue, isValid, dirty}) => {
                return (
                    <Form>
                        <div className={styles.container}>
                            <div>
                                <p className={styles.flexAndMargin}>Old Password*</p>
                                <PasswordField
                                    name="oldPassword"
                                    placeholder={'Enter password'}
                                    required
                                    onChange={(val) => setFieldValue('oldPassword', val)}
                                />
                            </div>
                            <div>
                                <p className={styles.flexAndMargin}>New Password*</p>
                                <PasswordField
                                    name="newPassword"
                                    placeholder={'Enter new password'}
                                    required
                                    onChange={(val) => setFieldValue('newPassword', val)}
                                />
                            </div>
                            <div>
                                <p className={styles.flexAndMargin}>Confirm Password*</p>
                                <PasswordField
                                    name="confirmPassword"
                                    placeholder={'Confirm new password'}
                                    required
                                    onChange={(val) => setFieldValue('confirmPassword', val)}
                                />
                            </div>
                            <div className={styles.buttonsWrapper}>
                                    <DefaultButton type={'submit'} disabled={!isValid || !dirty} title={'Save'}/>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ChangePasswordComponent;
