import React, {memo} from 'react';
import {MenuItem, Select, styled} from "@mui/material";
import {COLORS} from "../../../constants/colors";

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    height: 32px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
    border: none;
    padding: 6px 12px;
  }
  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const SelectInput = ({valueKey = 'id', fullItemValue= false, options, border, handleChange, value, disabled, width, background = COLORS.gray100}) => {
  return (
    <StyledSelect
      value={value}
      onChange={handleChange}
      disabled={disabled}
      renderValue={!fullItemValue ? null : (p) => p?.name?.length > 25 ? p?.name?.slice(0,25) + '...'  : p.name}
      sx={{
        background: background,
        borderRadius: '8px',
        color: COLORS.darkerGray,
        width: width,
        border: border
      }}
    >
      {options.map((item) => {
        return (
          <MenuItem key={item.id} value={fullItemValue ? item : item[valueKey]}>{item.name}</MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default memo(SelectInput);
