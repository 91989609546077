import React, {memo, useEffect, useState} from 'react';
import styles from './MilestoneChartComponent.module.scss';
import dayjs from "dayjs";
import SingleItem from "./SingleItem";

const MilestoneChartComponent = ({item, showExpanded}) => {

    const [preparedData, setPreparedData] = useState(null);
    const [widthPercentage, setWidthPercentage] = useState(1);
    const calculateDaysBetweenDates = (data) => {
        if (data.length === 0) return 0;

        const startDate = dayjs(data[0].date);
        const endDate = dayjs(data[data.length - 1].date);

        const differenceInDays = endDate.diff(startDate, 'day');

        return differenceInDays;
    }
    const calculateDaysBetweenDates1 = (startDate, endDate) => {
        const start = dayjs(startDate).startOf('day');
        const end = dayjs(endDate).startOf('day');
        return end.diff(start, 'day');
    };
    const addDurationToItems = (data) => {
        return data.map((item, index) => {
            if (index === 0) return item;

            const previousDate = data[index - 1].date;
            const currentDate = item.date;

            const duration = calculateDaysBetweenDates1(previousDate, currentDate);

            return {
                ...item,
                duration
            };
        });
    };
    const processDates = (data) => {
        if (data.length === 0) return {daysBetween: 0, dataWithDuration: []};

        const modifiedData = addDurationToItems(data);

        return {
            modifiedData
        };
    };
    const calculatePercentage = (total) => {
        if (total === 0) return 0;
        return (1 / total) * 100;
    };

    useEffect(() => {
        if (item) {
            setWidthPercentage(calculatePercentage(calculateDaysBetweenDates(item.milestones)));
            setPreparedData(processDates(item.milestones));
        }
    }, [item]);

    const generateDateRange = (startDate, endDate) => {
        const start = dayjs(startDate).startOf('day');
        const end = dayjs(endDate).startOf('day');
        let current = start;
        const dateArray = [];

        while (current.isBefore(end) || current.isSame(end)) {
            dateArray.push(current.format('MMM D'));
            current = current.add(1, 'day');
        }

        return dateArray;
    };

    const DateDisplay = ({startDate, endDate}) => {
        const dateRange = generateDateRange(startDate, endDate);

        return (
            <div className={styles.displayNameWrapper}>
                {dateRange.map(date => (
                    <div className={styles.displayNameInner} key={date}>
                        <p className={styles.displayNameText}>{date}</p>
                    </div>
                ))}
            </div>
        );
    };

    if (preparedData === null) return <div></div>

    return (
        <div className={styles.wrapper}>
            <div className={styles.nameWrapper}>
                {
                    preparedData.modifiedData.slice(1).map((val, index) => {
                        return (
                            <div className={styles.titleWrapper} key={index}>
                                <p className={styles.titleText}>{val.name}</p>
                            </div>
                        )
                    })
                }
            </div>
            {
                preparedData && preparedData.modifiedData && (
                    <div className={styles.contentWrapper}
                         style={{height: `${item.milestones.slice(1).length * 46 + (showExpanded ? 30 : 0)}px`, width: showExpanded ? 'auto' : '85%', overflow: showExpanded ? 'auto' : 'visible'}}>
                        {
                            showExpanded && (
                                <div className={styles.dateWrapper}>
                                    <DateDisplay startDate={preparedData.modifiedData[0].date}
                                                 endDate={preparedData.modifiedData[preparedData.modifiedData.length - 1].date}/>
                                </div>
                            )
                        }
                        <div className={styles.firstDateDiv}>
                            <p className={styles.dateText}>{dayjs(preparedData.modifiedData[0].date).format('MMM-DD/YY')}</p>
                        </div>
                        <div className={styles.contentWrapperInner}>
                            {
                                preparedData.modifiedData.slice(1).map((val, index) => {
                                    return <SingleItem showExpanded={showExpanded} isCompleted={item?.completedMilestone?.percentage} index={index}
                                                       value={val} width={widthPercentage} key={index}/>
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};
export default memo(MilestoneChartComponent);
