import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
    CREATE_RISK: '/risks',
    UPDATE_RISK: '/risks/:id',
    CREATE_MITIGATION: '/mitigations',
    UPDATE_MITIGATION: '/mitigations/:mitigationId',
    DELETE_MITIGATION: '/mitigations/:mitigationId',
    GET_RISKS: '/risks/by-parent',
    DELETE_RISK: '/risks/:id',
    CLOSE_RISK: '/risks/:id/status',
};

class RiskService {
    constructor(httpService) {
        this.httpService = httpService;
    }

    createRisk = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_RISK,
            method: HTTP_METHODS.POST,
            data
        });
    };

    updateRisk = (id, data) => {
        return this.httpService.request({
            url: ROUTES.UPDATE_RISK.replace(':id', id),
            method: HTTP_METHODS.PUT,
            data
        });
    };

    createMitigation = (data) => {
        return this.httpService.request({
            url: ROUTES.CREATE_MITIGATION,
            method: HTTP_METHODS.POST,
            data
        });
    };

    updateMitigation = (mitigationId, data) => {
        return this.httpService.request({
            url: ROUTES.UPDATE_MITIGATION.replace(':mitigationId', mitigationId),
            method: HTTP_METHODS.PUT,
            data
        });
    };

    deleteMitigation = (mitigationId) => {
        return this.httpService.request({
            url: ROUTES.DELETE_MITIGATION.replace(':mitigationId', mitigationId),
            method: HTTP_METHODS.DELETE
        });
    };

    getRisks = (data) => {
        return this.httpService.request({
            url: ROUTES.GET_RISKS,
            method: HTTP_METHODS.POST,
            data
        });
    };

    deleteRisk = (riskId) => {
        return this.httpService.request({
            url: ROUTES.DELETE_RISK.replace(':id', riskId),
            method: HTTP_METHODS.DELETE
        });
    };

    closeRisk = (riskId, data) => {
        return this.httpService.request({
            url: ROUTES.CLOSE_RISK.replace(':id', riskId),
            method: HTTP_METHODS.PUT,
            data
        });
    };

}

const riskService = new RiskService(httpService);

export default riskService;
