import React, {useCallback, useEffect, useState} from 'react';
import SearchInput from "../Shared/Inputs/SearchInput";
import styles from './GoalsLibraryComponent.module.scss';
import scorecardService from "../../services/ScorecardService";
import OutlinedSelectInput from "../Shared/Inputs/OutlinedSelectInput";
import DefaultButton from "../Shared/Buttons/DefaultButton";
import {useDispatch, useSelector} from "react-redux";
import SelectedPerspectiveList from "../PerspectiveLibrary/SelectedPerspectiveList";
import {helperService} from "../../services/HelperService";
import goalService from "../../services/GoalService";
import {shareExistingGoalsSaga} from "../../store/goals/actions";
import GoalList from "./GoalList";
import {terminologySelector} from "../../store/auth/selectors";

const GoalsLibraryComponent = ({scorecardId, goalId, perspectiveId, page}) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [goals, setGoals] = useState(null);
  const [filteredGoals, setFilteredGoals] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedOwner, setSelectedOwner] = useState('');

  const terminology = useSelector(terminologySelector());

  const shareExistingGoals = () => {
    dispatch(shareExistingGoalsSaga(selectedGoals, goalId, perspectiveId, scorecardId, page));
  };

  const handleClearSearch = useCallback(() => {
    setSearchText('')
    }, [setSearchText]);

  const getGoals = useCallback(async () => {
    try {
      const data = {
        goalId,
        currentPerspectiveId: perspectiveId
      };

      const goals = await goalService.getGoalsForLibrary(data);
      setGoals(goals);
      setFilteredGoals(goals);
    } catch (error) {
      console.error({error});
    }
  }, [goalId, perspectiveId]);

  const getOptions = useCallback(async () => {
    try {
      const plans = await scorecardService.getScorecards();
      const users = await helperService.getUsersByOrganization();
      const planOptions = plans?.map((plan) => {
        return {
          id: plan.id,
          value: plan.id,
          name: plan.name,
        };
      });
      const ownerOptions = users?.map((user) => {
        return {
          id: user.id,
          value: user.id,
          name: user.name,
          subtitle: user.email,
          image: user.photo,
          hasImage: true,
          defaultImage: require('../../assets/icons/profile-owner.png'),
        };
      });
      setPlanOptions(planOptions);
      setOwnerOptions(ownerOptions);
    } catch (error) {
      console.error({error});
    }
  }, []);

  const handleSelectPlan = useCallback((value) => {
    setSelectedPlan(value);
    setFilteredGoals(goals.filter((item) => {
      return (!value || item.perspective.scorecard_id === value) && (item.owner.id === selectedOwner) && item.name.includes(searchText);
    }));
  }, [goals, searchText, selectedOwner]);

  const handleSelectOwner = useCallback((value) => {
    setSelectedOwner(value);
    setFilteredGoals(goals.filter((item) => {
      return (!value || item.owner.id === value) && (!selectedPlan || item.perspective.scorecard_id === selectedPlan) && item.name.includes(searchText);
    }));
  }, [goals, searchText, selectedPlan]);

  const onGoalClick = useCallback((item) => {
    setSelectedGoals(prevState => {
      if (prevState.find((stateItem) => stateItem.id === item.id)) {
        return prevState.filter((stateItem) => stateItem.id !== item.id);
      } else {
        return [...prevState, item]
      }
    })
  }, []);

  const removeSelectedGoal = useCallback((item) => {
    setSelectedGoals(prevState => prevState.filter((stateItem) => stateItem.id !== item.id));
  }, []);

  useEffect(() => {
    const searchedGoals = goals?.filter((goal) => {
      return goal.name.toLowerCase().includes(searchText.toLowerCase()) && (!selectedPlan || goal.perspective.scorecard_id === selectedPlan) && (!selectedOwner || goal.owner.id === selectedOwner);
    });
    setFilteredGoals(searchedGoals);
  }, [goals, searchText, selectedPlan, selectedOwner]);

  useEffect(() => {
    getGoals();
    getOptions();
  }, [getGoals, getOptions]);

  return (
    <div className={styles.container}>
      <div>
        <SearchInput
          placeholder={`Search for a ${terminology.goal}`}
          setSearchText={(value) => setSearchText(value)}
          searchText={searchText}
          isRemoveShown={searchText.length > 0}
          onRemoveClick={handleClearSearch}
        />
        <div className={styles.selectGroupContainer}>
          <div className={`${styles.selectContainer} ${styles.leftSelectContainer}`}>
            <OutlinedSelectInput
              value={selectedOwner}
              options={ownerOptions}
              handleChange={(e) => handleSelectOwner(e.target.value)}
              placeholder={'Select by Owner'}
              height={56}
            />
          </div>
          <div className={`${styles.selectContainer} ${styles.rightSelectContainer}`}>
            <OutlinedSelectInput
              value={selectedPlan}
              options={planOptions}
              handleChange={(e) => handleSelectPlan(e.target.value)}
              placeholder={`Select by ${terminology.plan}`}
              height={56}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.listContainer}>
          <div className={styles.listScrollableContainer}>
            {selectedGoals.length > 0 && (
              <SelectedPerspectiveList
                data={selectedGoals}
                removeItem={removeSelectedGoal}
              />
            )}
            {filteredGoals && (
              <GoalList
                perspectiveId={perspectiveId}
                data={filteredGoals}
                selectedItems={selectedGoals}
                onItemClick={onGoalClick}
              />
            )}
            {filteredGoals?.length === 0 && (
              <h4 className={styles.subtitle}>No {terminology.goal}s with this Criteria!</h4>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <DefaultButton
          title={`Add ${terminology.goal}(s)`}
          onClick={shareExistingGoals}
          disabled={selectedGoals.length === 0}
        />
      </div>
    </div>
  );
};

export default GoalsLibraryComponent;
