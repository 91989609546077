import {
  ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA,
  ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA,
  ASSIGN_TO_GROUP_SAGA,
  GET_STRATEGY_PLAN_SAGA,
  SET_STRATEGY_PLAN,
  SET_STRATEGY_PLAN_WITH_PERSPECTIVES,
  DELETE_PERSPECTIVE,
  DELETE_GOAL,
  UPDATE_GOAL,
  SET_IS_OPEN_EDIT_GOAL_MODAL,
  DELETE_KPI,
  DELETE_ACTION,
  DELETE_PROJECT,
  RIGHT_SIDEBAR_SAGA,
  RIGHT_SIDEBAR_SUBMIT_FORM_SAGA,
  RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA,
  GET_RIGHT_SIDEBAR_ITEM_SAGA,
  SET_ITEM_ROW,
  ADD_ACTION_FROM_SIDEBAR_SAGA,
  RIGHT_SIDEBAR_BACK,
  ADD_TRACKING_FROM_SIDEBAR_SAGA,
  BACK_FROM_ADD_TRACKING_SAGA,
  CLOSE_SIDEBAR_SAGA,
  ADD_ACTION_SAGA,
  RIGHT_SIDEBAR_DELETE_ITEM_SAGA,
  ADD_TRACKING_AND_BACK_SAGA,
  SET_SHOW_EDIT_PARENT,
  SET_OPTIONS_FOR_EDIT_PARENT,
  MOVE_ITEM_FROM_EDIT_PARENT_SAGA,
  GET_EDIT_PARENT_DATA_SAGA,
  DELETE_PERSPECTIVE_FROM_WIDGET,
  REORDER_PERSPECTIVE_SAGA,
  EXPAND_PERSPECTIVE_SAGA,
  REORDER_PERSPECTIVE_ITEM,
  ON_REORDER_SAVE_PERSPECTIVE_SAGA,
  SET_GOALS_ON_HOME_PAGE,
  SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE,
  SET_ALL_ITEMS_ON_HOME_PAGE,
  REORDER_ROW_ITEM,
  ITEM_SORT_ACTIVE,
  SAVE_REORDER_ROW_ITEM,

} from './actionTypes';

export function getStrategyPlanSaga(payload) {
  return {
    type: GET_STRATEGY_PLAN_SAGA,
    payload,
  };
}

export function setStrategyPlan(strategyPlan) {
  return {
    type: SET_STRATEGY_PLAN,
    strategyPlan,
  };
}

export function rightSidebarBack() {
  return {
    type: RIGHT_SIDEBAR_BACK,
  };
}

export function backFromTrackingSaga() {
  return {
    type: BACK_FROM_ADD_TRACKING_SAGA,
  };
}

export function addActionFromSidebarSaga(payload) {
  return {
    type: ADD_ACTION_FROM_SIDEBAR_SAGA,
    payload
  };
}

export function addTrackingFromSidebarSaga(payload) {
  return {
    type: ADD_TRACKING_FROM_SIDEBAR_SAGA,
    payload
  };
}

export function addActionSaga(payload) {
  return {
    type: ADD_ACTION_SAGA,
    payload
  };
}

export function setItemRow(itemRow) {
  return {
    type: SET_ITEM_ROW,
    itemRow,
  };
}

export function addTrackingAndBackSaga(payload) {
  return {
    type: ADD_TRACKING_AND_BACK_SAGA,
    payload
  };
}

export function setStrategyPlanWithPerspectives(strategyPlanWithPerspectives) {
  return {
    type: SET_STRATEGY_PLAN_WITH_PERSPECTIVES,
    strategyPlanWithPerspectives,
  };
}
export function addRemoveNewEmptyPerspectiveSaga(action) {
  return {
    type: ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA,
    action
  };
}

export function addNewEmptyItemPerspectiveSaga(payload) {
  return {
    type: ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA,
    payload
  };
}

export function setGoalsOnHomePage(payload) {
  return {
    type: SET_GOALS_ON_HOME_PAGE,
    payload
  };
}

export function setProjectsActionsKpisOnHomePage(payload) {
  return {
    type: SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE,
    payload
  };
}

export function setAllItemsOnHomePage(payload) {
  return {
    type: SET_ALL_ITEMS_ON_HOME_PAGE,
    payload
  };
}

export function reorderPerspectiveSaga(payload) {
  return {
    type: REORDER_PERSPECTIVE_SAGA,
    payload
  };
}
export function setItemSortActive(payload) {
  return {
    type: ITEM_SORT_ACTIVE,
    payload
  };
}

export function onReorderPerspectiveSaga(payload) {
  return {
    type: ON_REORDER_SAVE_PERSPECTIVE_SAGA,
    payload
  };
}

export function reorderPerspectiveItemSaga(payload) {
  return {
    type: REORDER_PERSPECTIVE_ITEM,
    payload
  };
}

export function reorderRowItemSaga(payload) {
  return {
    type: REORDER_ROW_ITEM,
    payload
  };
}

export function expandPerspectiveSaga(payload) {
  return {
    type: EXPAND_PERSPECTIVE_SAGA,
    payload
  };
}

export function assignPlanToGroupSaga(strategyPlanId, data, fromRightSidebar) {
  return {
    type: ASSIGN_TO_GROUP_SAGA,
    strategyPlanId,
    data,
    fromRightSidebar
  };
}

export function deletePerspectiveSaga(perspectiveId, strategyPlanId) {
  return {
    type: DELETE_PERSPECTIVE,
    perspectiveId,
    strategyPlanId,
  };
}

export function deletePerspectiveFromWidgetSaga(perspectiveId, strategyPlanId) {
  return {
    type: DELETE_PERSPECTIVE_FROM_WIDGET,
    perspectiveId,
    strategyPlanId,
  };
}

export function deleteGoalSaga(goal, strategyPlanId, page) {
  return {
    type: DELETE_GOAL,
    goal,
    strategyPlanId,
    page
  };
}
export function deleteKpiSaga(kpiId, strategyPlanId, page) {
  return {
    type: DELETE_KPI,
    kpiId,
    strategyPlanId,
    page,
  };
}

export function deleteActionSaga(actionId, strategyPlanId) {
  return {
    type: DELETE_ACTION,
    actionId,
    strategyPlanId,
  };
}

export function updateGoalSaga(goalId, strategyPlanId, data) {
  return {
    type: UPDATE_GOAL,
    goalId,
    strategyPlanId,
    data
  };
}

export function setEditGoalModal(data) {
  return {
    type: SET_IS_OPEN_EDIT_GOAL_MODAL,
    data,
  };
}

export function deleteProjectSaga(projectId, strategyPlanId) {
  return {
    type: DELETE_PROJECT,
    projectId,
    strategyPlanId,
  };
}

export function rightSidebarSaga(payload) {
  return {
    type: RIGHT_SIDEBAR_SAGA,
    payload,
  };
}

export function getRightSidebarItemSaga(item, parent) {
  return {
    type: GET_RIGHT_SIDEBAR_ITEM_SAGA,
    item,
    parent,
  };
}

export function rightSidebarSubmitFormSaga(values, action, itemType, item, scorecardId, closeSidebar) {
  return {
    type: RIGHT_SIDEBAR_SUBMIT_FORM_SAGA,
    values,
    action,
    itemType,
    item,
    scorecardId,
    closeSidebar,
  };
}

export function rightSidebarOnActionClickSaga(item, parent, action, component) {
  return {
    type: RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA,
    item,
    parent,
    action,
    component,
  };
}

export function rightSidebarDeleteItemSaga(item, scorecardId) {
  return {
    type: RIGHT_SIDEBAR_DELETE_ITEM_SAGA,
    item,
    scorecardId,
  };
}

export function closeSidebarSaga() {
  return {
    type: CLOSE_SIDEBAR_SAGA,
  };
}

export function setShowEditParent(payload) {
  return {
    type: SET_SHOW_EDIT_PARENT,
    payload
  };
}
export function setOptionsForEditParent(payload) {
  return {
    type: SET_OPTIONS_FOR_EDIT_PARENT,
    payload
  };
}

export function getEditParentDataSaga(payload) {
  return {
    type: GET_EDIT_PARENT_DATA_SAGA,
    payload
  };
}
export function moveItemFromEditParentSaga(payload) {
  return {
    type: MOVE_ITEM_FROM_EDIT_PARENT_SAGA,
    payload
  };
}

export function saveReorderRowItemSaga(payload) {
  return {
    type: SAVE_REORDER_ROW_ITEM,
    payload
  };
}
