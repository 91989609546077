export const COLORS = {
  lightestGray: '#F8F9FA',
  expectedGraphColor: 'rgba(100, 100, 100, 0.25)',
  gray100: '#F0F2F4',
  lighterGray: '#E8ECEF',
  legendBlack: '#666',
  lightGray: '#ADB5BD',
  circularBlue: '#56b2fb',
  darkerGray: '#495057',
  darkestGray: '#212529',
  iconGray: '#6C757D',
  lightRed: '#FECBCA',
  graphBlue: '#8884d8',
  projectBlue: '#0D6EFD',
  graphYellow: '#FFBB64',
  graphDarkBlue: '#3652AD',
  red: '#E1122A',
  lightGreen: '#A6F4C5',
  brightGreen: '#12B76A',
  green: '#027A48',
  lightgreen: '#A6F4C5',
  lightOrange:  '#FFE69C',
  brightOrange:  '#FFCD39',
  brightDarkerOrange:  '#FFD557',
  orange: '#984C0C',
  lightGray01: '#888b92',
  lightGray02: '#e2e2e2',
  transparentDarkBlue: 'rgba(13, 110, 253, 0.25)',
  lighterRed: '#FF2E4A',
  lightBlue: '#86B7FE',
  primaryLightGray: '#CED4DA',
  blue: '#407FFF',
  darkerBlue: '#3972E5',
  transparentGray: 'rgba(0, 0, 0, 0.4)',
  white: '#FFFFFF',
  initialTheme: '#7c3e3e',
  progressBarGreen: '#32D583',
  progressBarLightGreen: '#52DB97',
  progressBarRed: '#FF5A68',
  progressBarLightRed: '#FF737F',
  progressBarGray: '#C8C8C8',
  progressBarLightGray: '#D7D7D7',
  lightBlueish: '#02b7ff',
  lightPaleBlue: '#E4F7FF',
  paleBlue: '#C2E3FF',
  randomGreen: '#7bc86c',
  randomYellow: '#f5dd29',
  randomBlue: '#5ba4cf',
  randomOrange: '#ffaf3f',
  randomTurquoise: '#6deca9',
  randomPurple: '#cd8de5',
  randomRed: '#ef7564',
  randomDarkGreen: '#338F11',
  randomDarkBlue: '#172b4d',
  randomLightBlue: '#1cabec',
  darkestBlue: '#0A58CA',
  lightestBlue: '#ACC6FF',
  purple: '#A020F0',
  darkPurple: '#971EE3',
  palePurple: '#D7AFF0',
  timelinePurple: '#9F8FEF',
  timelineBlue: '#579DFF',
  timelineGreen: '#4BCE97',
  timelineYellow: '#F5CD47',
  timelineRed: '#F87168',
  timelineGray: '#8590A2',
  timelineOrange: '#FEA363',
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  projectMainBoxShadow: '10px 3px 8px 6px rgba(0,0,0,0.05)',
  projectBoxShadowSecondary: '0px 6px 8px 2px rgba(0, 0, 0, 0.2)',
  dottedBorder: '1px dotted black',
  solidBorder: '1px solid black',
  highlightedBoxShadow: '0px 0px 3px 3px rgb(241, 231, 64)',
};

export const CHART_COLORS_ARR = [ "#961094",  "#F4D96D",  "#7195D5",  "#8B3365",  "#48B884",  "#FB81E7",  "#208537",  "#AE9A8C",  "#9A23F4",  "#95C4F1",  "#27A995",  "#F7D175",  "#89BC2B",  "#4ABE82",  "#0A4C99",  "#D59B80",  "#E22E7F",  "#E13A8A",  "#0A9C63",  "#A1A6F7",  "#BE59D1",  "#148A5B",  "#E897BF",  "#E86C53",  "#8878E9",  "#F8979E",  "#A48C7C",  "#E87BC2",  "#A7E2ED",  "#7E87E8",  "#E7A74A",  "#60E889",  "#8B0F7D",  "#E8C9B1",  "#E8653F",  "#F1A21A",  "#F57071",  "#E82571",  "#E8B987", "#67F377"];

