import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  UPDATE_CHECKLIST_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import actionService from "../../services/ActionService";
import {refreshPageSaga, setItemAndParentForAction} from "../helper/actions";
import {getItemParent} from "../../utils/perspective";
import scorecardService from "../../services/ScorecardService";


export function* updateChecklistSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(actionService.updateActionChecklist, payload.checklistId, payload.data);
    const action = yield scorecardService.getActionById(payload.actionId);
    yield put(setItemAndParentForAction({parent: getItemParent(action), item: action}));

    yield put(refreshPageSaga({
      page: payload.page,
      actionId: payload.actionId,
      goalId: payload.goalId,
      scorecardId: payload.scorecardId,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* checklistSaga() {
  yield takeLatest(UPDATE_CHECKLIST_SAGA, updateChecklistSagaFn);
}
