import React, {useCallback} from "react";
import TableComponent from "../../Tables";
import {TABLE_CONFIG} from "./TABLE_CONFIG";
import scorecardService from "../../../services/ScorecardService";
import SidebarKpiComponent from "../../RightModal/Kpi/SidebarKpiComponent";
import {openRightModalFromChartDetails} from "../../../store/dashboards/actions";
import {useDispatch} from "react-redux";


function KpiTable({data, onChartClick, fromReport}) {
    const dispatch = useDispatch();
    const editRow = useCallback(async (row) => {
        if (!fromReport) {
            let component;
            let item = await scorecardService.getKpiById(row.id);
            component = (
              <SidebarKpiComponent />
            );

            dispatch(openRightModalFromChartDetails({component, item}));
        }
    }, [dispatch, fromReport]);

    return (
        <div style={{width: '100%', height: '100%'}}>
            {data && <TableComponent
              configTable={TABLE_CONFIG}
              data={data}
              paginated={false}
              rowClickable
              onRowClick={editRow}
            />}
        </div>
    );
}


export default KpiTable;
