import React from 'react';
import styles from './SharingMembers.module.scss';
import {getInitials} from "../../utils/format";
import profileImage from "../../assets/icons/profile-owner.png";

const SharingMembers = ({users, maximumUsers = 4}) => {
  const renderContent = (user) => {
    if (user.photo) {
      return (
        <img src={user.photo} className={styles.image} alt={'avatar'} />
      );
    }

    if (user.name) {
      return (
        <h4 className={styles.initials}>{getInitials(user.name)}</h4>
      );
    }

    return (
      <img src={profileImage} className={styles.image} alt={'avatar'} />
    );
  };

  return (
    <div>
      {users.length === 0 ? (
        <h4 className={styles.subtitle}>No sharing members</h4>
      ) : (
        <div className={styles.container}>
          {users.slice(0, maximumUsers === users.length ? maximumUsers : maximumUsers - 1).map((user) => {
            return (
              <div className={styles.imageContainer} key={user.id}>
                {renderContent(user)}
              </div>
            );
          })}
          {users?.length > maximumUsers && (
            <div className={styles.imageContainer}>
              {`+${users.length - (maximumUsers - 1)}`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SharingMembers;
