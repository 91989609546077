import React, {useCallback, useMemo, useState} from 'react';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check-circle.svg';
import {Circle} from "@mui/icons-material";
import {getScoreColorsAndText, parseTypesString} from "../../../utils/risks";
import ToggleExpandButton from "../../Shared/Buttons/ToggleExpandButton";
import PopupComponent from "../../Shared/Popup/PopupComponent";
import {riskCardOptionsConfig} from "./riskCardOptionsConfig";
import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel} from "@mui/material";
import styles from './RisksTab.module.scss';
import profileImage from "../../../assets/images/user.png";
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import {riskMitigationsOptionsConfig} from "./riskMitigationsOptionsConfig";
import {ACTIONS} from "../../../constants/strings";
import riskService from "../../../services/RiskService";
import {setModal} from "../../../store/helper/actions";
import {useDispatch, useSelector} from "react-redux";
import {modalSelector} from "../../../store/helper/selectors";
import DeleteContent from "../../Shared/Modals/DeleteContent";

const RiskCard = ({
                      risk,
                      onCardMenuClick,
                      individualCardCollapse,
                      onToggleCollapse,
                      mitigations,
                      setMitigations,
                      submitMitigation,
                      checkMitigation,
                      getRisks,
                  }) => {
    const [editingMitigationId, setEditingMitigationId] = useState(null);
    const [editedMitigationName, setEditedMitigationName] = useState('');
    const modal = useSelector(modalSelector());
    const dispatch = useDispatch();
    const handleEditMitigation = async (mitigation) => {
        try {
            await riskService.updateMitigation(editingMitigationId, {
                name: editedMitigationName,
                completed: mitigation.completed
            });
            await getRisks();
            setEditingMitigationId(null);
            setEditedMitigationName('');
        } catch (err) {
            console.log(err);
        }
    }

    const cancelEditMitigation = () => {
        setEditingMitigationId(null);
        setEditedMitigationName('');
    }

    const initialModalState = useMemo(() => ({
        isVisible: false,
        title: '',
        component: null,
    }), []);

    const closeModal = useCallback(() => {
        dispatch(setModal(initialModalState));
    }, [dispatch, initialModalState]);

    const deleteItem = async (itemId) => {
        try {
            await riskService.deleteMitigation(itemId);
            await getRisks();
            closeModal();
        } catch (err) {
            console.log(err);
        }
    }

    const onMitigationMenuClick = async (item, action) => {
        switch (action) {
            case ACTIONS.DELETE:
                dispatch(setModal({
                    isVisible: true,
                    title: 'Delete Mitigation',
                    component: (
                        <DeleteContent
                            text={`Are you sure you want to delete Mitigation: ${item?.name}?`}
                            onPressClose={closeModal}
                            onPressConfirm={() => deleteItem(item.id)}
                        />
                    ),
                }));
                break;
            case ACTIONS.EDIT:
                setEditingMitigationId(item.id);
                setEditedMitigationName(item.name);
                break;
            default:
                console.log('No action found');
        }
    }

    return (
        <div key={risk.id}>
            <div className={styles.cardContainer}>
                <div className={styles.riskCard}>
                    <div className={styles.riskCardUpper}>
                        <div className={styles.riskScoreName}>
                            <Circle className={styles.riskScoreDot}
                                    style={{color: getScoreColorsAndText(risk.score).backgroundColor}}/>
                            <p style={{color: getScoreColorsAndText(risk.score).backgroundColor}}>{getScoreColorsAndText(risk.score).text + ' risk'}</p>
                        </div>
                        <PopupComponent config={riskCardOptionsConfig({
                            item: risk,
                            onClick: onCardMenuClick,
                            isOpenStatus: risk.status === 'open'
                        })}/>
                    </div>
                    <p className={styles.riskName}>{risk.name}</p>
                    <div className={styles.riskCardLower}>
                        <div className={styles.riskOwnerWrapper}>
                            <img src={risk.owner.photo ? risk.owner.photo : profileImage} className={styles.profileImg}
                                 alt={'user'}/>
                            <div>
                                <p>{risk.owner.name}</p>
                                <p className={styles.riskOwnerEmail}>{risk.owner.email}</p>
                            </div>
                        </div>
                        <div className={styles.flexAndCenter}>
                            <CheckIcon width={16} height={16}/>
                            <p className={styles.checkedData}>
                                {risk.mitigations.filter(mitigation => mitigation.completed).length}/{risk.mitigations.length}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.showDetailsLine} onClick={() => onToggleCollapse(risk.id)}>
                    <ToggleExpandButton expanded={individualCardCollapse[risk.id]}/>
                </div>
            </div>
            {
                !individualCardCollapse[risk.id] &&
                <div className={styles.riskCardDetails}>
                    <div className={styles.riskCardDetailsUpper}>
                        <p className={styles.descriptionAndTitles}>{risk.description}</p>
                        <div className={styles.riskTypesWrapper}>
                            {
                                parseTypesString(risk.type).map((el, index) => {
                                    return (
                                        <div className={styles.riskTypeWrapper} key={index}>
                                            <p className={styles.riskTypes}>{el}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className={styles.descriptionAndTitles}>Mitigations</p>
                        {risk.status === 'open' &&
                            <div className={styles.riskCardLower}>
                                <textarea placeholder={'Enter name for new mitigation'} value={mitigations[risk.id] || ''}
                                       className={styles.inputWrapper}
                                       onChange={(e) => setMitigations(prevState => ({
                                           ...prevState,
                                           [risk.id]: e.target.value
                                       }))}/>
                                <DefaultButton disabled={!mitigations[risk.id]} buttonStyles={{marginLeft: 8, height: '40px'}}
                                               title={'Submit'}
                                               onClick={() => submitMitigation(risk)}/>
                            </div>
                        }
                        {
                            risk?.mitigations.map(mitigation => (
                                <div key={mitigation.id} className={styles.mitigationsCheckFields}>
                                    {editingMitigationId === mitigation.id ? (
                                        <input className={styles.inputWrapper}
                                               value={editedMitigationName}
                                               onChange={(e) => setEditedMitigationName(e.target.value)}
                                        />
                                    ) : (
                                        <div className={styles.mitigationsCheckFieldWrapper}>
                                            <FormControlLabel
                                                style={{textDecoration: mitigation.completed && 'line-through'}}
                                                onChange={() => checkMitigation(mitigation)}
                                                control={ risk.status === 'open' ? <Checkbox checked={mitigation?.completed}/> : <div className={styles.mitigationsWithoutCheckField}/>}
                                                label={mitigation.name}
                                            />
                                            {
                                                risk.status === 'open' &&
                                                <PopupComponent config={riskMitigationsOptionsConfig({
                                                    item: mitigation,
                                                    onClick: onMitigationMenuClick
                                                })}/>
                                            }
                                        </div>
                                    )}
                                    {editingMitigationId === mitigation.id && (
                                        <div className={styles.flexAndCenter} style={{margin: 12}}>
                                            <DefaultButton secondary
                                                           buttonStyles={{marginLeft: 8}}
                                                           title={'Cancel'}
                                                           onClick={cancelEditMitigation}
                                            />
                                            <DefaultButton outlined
                                                           buttonStyles={{marginLeft: 8}}
                                                           title={'Save'}
                                                           onClick={() => handleEditMitigation(mitigation)}
                                                           disabled={!editedMitigationName}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default RiskCard;
