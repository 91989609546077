import React from 'react';
import styles from './indexStyle.module.scss';
import TableComponent from "../../Tables";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

const WidgetTableComponent = ({data, showName = true, config, onRowClick}) => {
    const terminology = useSelector(terminologySelector());

    return (
        <div className={styles.container}>
            {
                data && data.map(item => {
                    return (
                        <div key={item.id}>
                            { showName &&
                                <div className={styles.scorecardHeader}>
                                    <p className={styles.scorecardName}>{item.dataSourceName}</p>
                                </div>
                            }
                            <div className={styles.ownerHeader}>
                                <p className={styles.ownerName}>{item.tableHeader}</p>
                            </div>
                            <TableComponent
                                rowClickable={Boolean(onRowClick)}
                                onRowClick={onRowClick}
                                paginated={false}
                                configTable={config}
                                data={item.tableRows}
                                terminology={terminology}
                            />
                        </div>
                    )
                })
            }
        </div>
    );
};

export default WidgetTableComponent;
