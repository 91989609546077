import React, {memo} from 'react';
import {Box, InputLabel, styled, Typography} from '@mui/material';
import {COLORS} from "../../../constants/colors";
import styles from './TextareaField.module.scss';

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin-bottom: 8px;
    font-size: 16px;
    color: ${COLORS.darkestGray};
    font-weight: 400;
    line-height: 24px;
    text-align: start;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    padding-top: 4px;
    font-size: 12px;
    color: ${COLORS.lighterRed};
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {
    height: 32px;
  }
`;

const TextareaField = ({setFieldValue, field, errors, ...props}) => {
    return (
        <Box>
            <StyledInputLabel>
                {props.label}
            </StyledInputLabel>
            <textarea
                value={field.value}
                onChange={(e) => setFieldValue(field.name, e.currentTarget.value.replace(/^\s+/, ''))}
                placeholder={props.placeholder}
                className={styles.container}
                rows={props.options?.minRows ? props.options?.minRows : 1}
            ></textarea>
            <StyledBox>
                {errors[field?.name] && (
                    <StyledTypography>
                        {errors[field?.name]}
                    </StyledTypography>
                )}
            </StyledBox>
        </Box>
    );
};

export default memo(TextareaField);
