import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_METRIC: 'metrics/:metricId',
  GET_METRIC_WITH_CHART_DATA: 'metrics/:metricId/graph-data',
  GET_ALL_METRIC: 'metrics/get-all',
  DELETE_METRIC: 'metrics/:metricId',
  CREATE_METRIC: 'metrics',
  ADD_KPI_TO_METRIC: 'metrics/add-kpi',
  UPDATE_METRIC: 'metrics/:metricId',
  SHOW_DATA_POINTS: 'metrics/:metricId/get-data-points',
  SHOW_DATA_POINTS_FROM_FE: 'metrics/:metricId/get-data-points',
  GET_SHEETS: 'google-sheets/all-sheet-tabs/:spreadsheetId/:googleDriveIntegrationId',
  GET_SPREADSHEETS: 'google-sheets/all-spreadsheet-files/:connectionId/:googleDriveIntegrationId',
  GET_GOOGLE_SHEET_CONNECTIONS: 'google-sheets',
  AUTHORIZE_GOOGLE_SHEET: 'google-sheets/authorize',
  GOOGLE_SHEETS_FOLDERS: 'google-sheets/all-folders/:googleDriveIntegrationId',
  CREATE_GOOGLE_SHEET_CONNECTION: 'google-sheets',
  UPDATE_GOOGLE_SHEET_CONNECTION: 'google-sheets/:id',
  DELETE_GOOGLE_SHEET_CONNECTION: 'google-sheets/:id',
  UPDATE_GOOGLE_CREDENTIALS: 'google-sheets/update-google-credentials',
  UPDATE_MICROSOFT_CREDENTIALS: 'microsoft-integrations/auth',
  UPDATE_JIRA_CREDENTIALS: 'integrations/jira-auth',
  AUTHORIZE_JIRA: 'integrations/jira-get-auth-url',
  JIRA_SITES: 'integrations/jira-sites',
  GET_JIRA_CONNECTIONS: 'jira-connection',
  CREATE_JIRA_CONNECTION: 'jira-connection',
  DELETE_JIRA_CONNECTION: 'jira-connection/:id',
  UPDATE_JIRA_CONNECTION: 'jira-connection/:id',
  REFRESH_METRICS: 'metrics/refresh/:metricId',
  GET_JIRA_PROJECTS: 'integrations/jira-projects/:jiraConnectionId/:siteId',
  GET_JIRA_ISSUES: 'integrations/jira-issues-by-project/:jiraConnectionId/:siteId/:projectId',
  AUTHORIZE_MICROSOFT_EXCEL: 'microsoft-integrations/get-auth-url',
  GET_MICROSOFT_CONNECTIONS: 'microsoft-connections',
  MICROSOFT_EXCEL_FOLDERS: 'microsoft-integrations/folders/:microsoftConnectionId?',
  CREATE_MICROSOFT_CONNECTION: 'microsoft-connections',
  UPDATE_MICROSOFT_CONNECTION: 'microsoft-connections/:id',
  DELETE_MICROSOFT_CONNECTION: 'microsoft-connections/:id',
  GET_MICROSOFT_FILES: 'microsoft-integrations/files/:folderId/:microsoftConnectionId',
  GET_EXCEL_SHEETS: 'microsoft-integrations/sheet-tabs/:fileId/:microsoftConnectionId',
};

class MetricsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getMetric = (metricId) => {
    return this.httpService.request({
      url: ROUTES.GET_METRIC.replace(':metricId', metricId),
      method: HTTP_METHODS.GET,
    });
  };

  getMetricWithChartData = (metricId) => {
    return this.httpService.request({
      url: ROUTES.GET_METRIC_WITH_CHART_DATA.replace(':metricId', metricId),
      method: HTTP_METHODS.GET,
    });
  };

  getGoogleSheetConnections = () => {
    return this.httpService.request({
      url: ROUTES.GET_GOOGLE_SHEET_CONNECTIONS,
      method: HTTP_METHODS.GET,
    });
  };

  showDataPoints = (metricId, data) => {
    return this.httpService.request({
      url: ROUTES.SHOW_DATA_POINTS.replace(':metricId', metricId),
      method: HTTP_METHODS.POST,
      data
    });
  };

  showDataPointsFromFe = (metricId, data) => {
    return this.httpService.request({
      url: ROUTES.SHOW_DATA_POINTS_FROM_FE.replace(':metricId', metricId),
      method: HTTP_METHODS.POST,
      data
    });
  };

  authorizeGoogleSheetConnections = () => {
    return this.httpService.request({
      url: ROUTES.AUTHORIZE_GOOGLE_SHEET,
      method: HTTP_METHODS.GET,
    });
  };

  authorizeJiraConnections = () => {
    return this.httpService.request({
      url: ROUTES.AUTHORIZE_JIRA,
      method: HTTP_METHODS.GET,
    });
  };

  getSpreadSheets = (connectionId, googleDriveIntegrationId) => {
    return this.httpService.request({
      url: ROUTES.GET_SPREADSHEETS
        .replace(':connectionId', connectionId)
        .replace(':googleDriveIntegrationId', googleDriveIntegrationId),
      method: HTTP_METHODS.GET,
    });
  };

  getSheets = (spreadsheetId, googleDriveIntegrationId) => {
    return this.httpService.request({
      url: ROUTES.GET_SHEETS
        .replace(':spreadsheetId', spreadsheetId)
        .replace(':googleDriveIntegrationId', googleDriveIntegrationId || ''),
      method: HTTP_METHODS.GET,
    });
  };

  getAllMetrics = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_METRIC,
      method: HTTP_METHODS.POST,
      data
    });
  };

  createMetric = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_METRIC,
      method: HTTP_METHODS.POST,
      data
    });
  };

  addKpiToMetric = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_KPI_TO_METRIC,
      method: HTTP_METHODS.POST,
      data
    });
  };
  updateMetric = (data, id) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_METRIC.replace(':metricId', id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  deleteMetric = (metricId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_METRIC.replace(':metricId', metricId),
      method: HTTP_METHODS.DELETE,
    });
  };

  getGoogleSheetFolders = (googleDriveIntegrationId) => {
    return this.httpService.request({
      url: ROUTES.GOOGLE_SHEETS_FOLDERS.replace(':googleDriveIntegrationId', googleDriveIntegrationId || ''),
      method: HTTP_METHODS.GET,
    });
  };

  getJiraSites = () => {
    return this.httpService.request({
      url: ROUTES.JIRA_SITES,
      method: HTTP_METHODS.GET,
    });
  };

  createGoogleSheetConnection = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_GOOGLE_SHEET_CONNECTION,
      method: HTTP_METHODS.POST,
      data
    });
  };

  createJiraConnection = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_JIRA_CONNECTION,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updateGoogleSheetConnection = (data, connectionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOOGLE_SHEET_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  deleteGoogleSheetConnection = (connectionId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_GOOGLE_SHEET_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.DELETE,
    });
  };

  updateGoogleCredentials = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOOGLE_CREDENTIALS,
      method: HTTP_METHODS.POST,
      data
    });
  }

  updateMicrosoftCredentials = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_MICROSOFT_CREDENTIALS,
      method: HTTP_METHODS.POST,
      data
    });
  }

  updateJiraCredentials = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_JIRA_CREDENTIALS,
      method: HTTP_METHODS.POST,
      data
    });
  }

  refreshMetrics = (metricId) => {
    return this.httpService.request({
      url: ROUTES.REFRESH_METRICS.replace(':metricId', metricId),
      method: HTTP_METHODS.PUT,
    });
  }

  getJiraConnections = () => {
    return this.httpService.request({
      url: ROUTES.GET_JIRA_CONNECTIONS,
      method: HTTP_METHODS.GET,
    });
  };

  deleteJiraConnection = (connectionId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_JIRA_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.DELETE,
    });
  };

  updateJiraConnection = (data, connectionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_JIRA_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getJiraProjects = (connectionId, jiraConnectionId) => {
    return this.httpService.request({
      url: ROUTES.GET_JIRA_PROJECTS
          .replace(':jiraConnectionId', jiraConnectionId)
          .replace(':siteId', connectionId),
      method: HTTP_METHODS.GET,
    });
  };

  getJiraIssues = (projectId, jiraSiteId, jiraConnectionId) => {
    return this.httpService.request({
      url: ROUTES.GET_JIRA_ISSUES
          .replace(':jiraConnectionId', jiraConnectionId)
          .replace(':siteId', jiraSiteId)
          .replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
    });
  };

  authorizeMicrosoftExcelConnections = () => {
    return this.httpService.request({
      url: ROUTES.AUTHORIZE_MICROSOFT_EXCEL,
      method: HTTP_METHODS.GET,
    });
  };

  getMicrosoftExcelConnections = () => {
    return this.httpService.request({
      url: ROUTES.GET_MICROSOFT_CONNECTIONS,
      method: HTTP_METHODS.GET,
    });
  };

  getMicrosoftExcelFolders = (connectionId) => {
    return this.httpService.request({
      url: ROUTES.MICROSOFT_EXCEL_FOLDERS.replace(':microsoftConnectionId?', connectionId || ''),
      method: HTTP_METHODS.GET,
    });
  };

  createMicrosoftExcelConnection = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_MICROSOFT_CONNECTION,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updateMicrosoftConnection = (data, connectionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_MICROSOFT_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  deleteMicrosoftConnection = (connectionId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_MICROSOFT_CONNECTION.replace(':id', connectionId),
      method: HTTP_METHODS.DELETE,
    });
  };

  getMicrosoftFiles = (folderId, microsoftConnectionId) => {
    return this.httpService.request({
      url: ROUTES.GET_MICROSOFT_FILES
          .replace(':folderId', folderId)
          .replace(':microsoftConnectionId', microsoftConnectionId),
      method: HTTP_METHODS.GET,
    });
  };

  getExcelSheets = (fileId, microsoftConnectionId) => {
    return this.httpService.request({
      url: ROUTES.GET_EXCEL_SHEETS
          .replace(':fileId', fileId)
          .replace(':microsoftConnectionId', microsoftConnectionId || ''),
      method: HTTP_METHODS.GET,
    });
  };

}

const metricsService = new MetricsService(httpService);

export default metricsService;
