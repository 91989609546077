import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_GOALS_ALIGNMENT: 'alignment/goals',
  GET_PLANS_ALIGNMENT: 'alignment/scorecards',
};

class AlignmentService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getGoalsAlignment = () => {
    return this.httpService.request({
      url: ROUTES.GET_GOALS_ALIGNMENT,
      method: HTTP_METHODS.GET,
    });
  };

  getPlansAlignment = () => {
    return this.httpService.request({
      url: ROUTES.GET_PLANS_ALIGNMENT,
      method: HTTP_METHODS.GET,
    });
  };
}

const alignmentService = new AlignmentService(httpService);

export default alignmentService;
