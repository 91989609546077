import {MOVE_ITEM_SELECT_TYPES, ROW_ITEM_TYPES} from "../../constants/strings";

export const selectTypes = ({data, terminology}) => {
  return [
    ...data.type === ROW_ITEM_TYPES.GOAL ? [
      {id: 1, title: terminology.plan, type: MOVE_ITEM_SELECT_TYPES.PLAN},
      {id: 2, title: `Under Another ${terminology.goal}`, type: MOVE_ITEM_SELECT_TYPES.GOAL},
    ] : [],
    ...data.type === ROW_ITEM_TYPES.ACTION ? [
      {id: 3, title: terminology.goal, type: MOVE_ITEM_SELECT_TYPES.GOAL},
      {id: 4, title: terminology.project, type: MOVE_ITEM_SELECT_TYPES.PROJECT},
    ] : [],
  ];
};
