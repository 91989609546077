import React, {memo} from 'react';
import styles from './SelectedPerspectiveItem.module.scss';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const SelectedPerspectiveItem = ({item, removeItem}) => {
  return (
    <div className={styles.container} onClick={() => removeItem(item)}>
      <h4 className={styles.title}>{item.name}</h4>
      <CloseIcon width={8} height={8} />
    </div>
  );
};

export default memo(SelectedPerspectiveItem);
