import {
  ADD_EXISTING_PERSPECTIVES_SAGA,
  ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA,
  ADD_NEW_PERSPECTIVE_SAGA, CHANGE_GOAL_PARENT_SAGA, EDIT_FULL_ITEM_SAGA,
  EDIT_ITEM_FOR_PERSPECTIVE_SAGA, EDIT_ITEM_STATUS_SAGA,
  MOVE_ITEM_SAGA,
  RESET_STATE,
  SET_SHOW_EDIT_PERSPECTIVE_MODAL,
  SET_SHOW_PERSPECTIVE_LIBRARY_MODAL, UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA,
  UPDATE_PERSPECTIVE_SAGA,
} from './actionTypes';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function updatePerspectiveSaga(payload) {
  return {
    type: UPDATE_PERSPECTIVE_SAGA,
    payload,
  };
}

export function updatePerspectiveFromDashboardSaga(payload) {
  return {
    type: UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA,
    payload,
  };
}
export function addNewPerspectiveSaga(payload) {
  return {
    type: ADD_NEW_PERSPECTIVE_SAGA,
    payload,
  };
}

export function addNewItemForPerspectiveSaga(payload) {
  return {
    type: ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA,
    payload,
  };
}
export function editItemForPerspectiveSaga(payload) {
  return {
    type: EDIT_ITEM_FOR_PERSPECTIVE_SAGA,
    payload,
  };
}

export function editFullItemSaga(payload) {
  return {
    type: EDIT_FULL_ITEM_SAGA,
    payload,
  };
}

export function setShowEditPerspectiveModal(payload) {
  return {
    type: SET_SHOW_EDIT_PERSPECTIVE_MODAL,
    payload,
  };
}

export function addExistingPerspectivesSaga(perspectives, scorecardId) {
  return {
    type: ADD_EXISTING_PERSPECTIVES_SAGA,
    perspectives,
    scorecardId,
  };
}

export function setShowPerspectiveLibraryModal(isVisible) {
  return {
    type: SET_SHOW_PERSPECTIVE_LIBRARY_MODAL,
    isVisible,
  };
}

export function editItemStatusSaga(payload) {
  return {
    type: EDIT_ITEM_STATUS_SAGA,
    payload,
  };
}

export function moveItemSaga(item, parentId, parentType, retainContribution, isParentGoal, scorecardId, removeLinks, page) {
  return {
    type: MOVE_ITEM_SAGA,
    item,
    parentId,
    parentType,
    retainContribution,
    isParentGoal,
    scorecardId,
    removeLinks,
    page
  };
}

export function changeGoalParentSaga(item, parentId, parentType, retainContribution, isParentGoal, scorecardId, removeLinks) {
  return {
    type: CHANGE_GOAL_PARENT_SAGA,
    item,
    parentId,
    parentType,
    retainContribution,
    isParentGoal,
    scorecardId,
    removeLinks,
  };
}
