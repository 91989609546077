import React, {memo} from 'react';
import styles from './MilestoneChartComponent.module.scss';
import dayjs from "dayjs";
import {COLORS} from "../../../../constants/colors";

const SingleItem = ({width, value, index, isCompleted, showExpanded}) => {

    return (
        <div className={styles.singleItemWrapper} style={{width: showExpanded ? `${50 * value.duration}px` :  `${width * value.duration}%`}}>
            <div className={styles.firstDateDivSingleItem}>
                <p className={styles.dateText}>{dayjs(value.date).format('MMM-DD/YY')}</p>
            </div>
            <div className={styles.progressOuter} style={{marginTop: `${index * 46}px`}}>
                <div className={styles.progressWrapper} style={{background: isCompleted >= value.percentage ? COLORS.progressBarGreen : COLORS.lightGray02}} />
            </div>
        </div>
    );
};
export default memo(SingleItem);
