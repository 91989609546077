import produce from 'immer';
import {
  SET_ALIGNED_GOALS_ALIGNMENT_NODES,
  SET_ALL_GOALS_ALIGNMENT_NODES,
  SET_GOALS_ALIGNMENT_EDGES,
  SET_GOALS_ALIGNMENT_FILTERS,
  SET_GOALS_ALIGNMENT_MODAL,
  SET_UNALIGNED_GOALS_ALIGNMENT_NODES,
} from './actionTypes';

export const initialState = {
  allGoalsAlignmentNodes: null,
  alignedGoalsAlignmentNodes: null,
  unalignedGoalsAlignmentNodes: null,
  goalsAlignmentEdges: null,
  goalsAlignmentFilters: {
    plans: [],
    perspectives: [],
    health: [],
    id: '',
  },
  goalAlignmentModal: {
    isVisible: false,
    title: '',
    maxWidth: 'md',
    component: null
  },
};

/* eslint-disable default-case */
const alignmentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ALL_GOALS_ALIGNMENT_NODES:
        draft.allGoalsAlignmentNodes = action.payload;
        break;
      case SET_ALIGNED_GOALS_ALIGNMENT_NODES:
        draft.alignedGoalsAlignmentNodes = action.payload;
        break;
      case SET_UNALIGNED_GOALS_ALIGNMENT_NODES:
        draft.unalignedGoalsAlignmentNodes = action.payload;
        break;
      case SET_GOALS_ALIGNMENT_EDGES:
        draft.goalsAlignmentEdges = action.payload;
        break;
      case SET_GOALS_ALIGNMENT_FILTERS:
        draft.goalsAlignmentFilters = action.payload;
        break;
      case SET_GOALS_ALIGNMENT_MODAL:
        draft.goalAlignmentModal = action.payload;
        break;
    }
  });

export default alignmentReducer;
