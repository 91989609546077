import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMetrics = (state) => state.metrics || initialState;

const metricSelector = () =>
  createSelector(selectMetrics, (state) => state.metric);

const isDeleteModalVisibleSelector = () =>
  createSelector(selectMetrics, (state) => state.isDeleteModalVisible);

const isDCreateModalVisibleSelector = () =>
    createSelector(selectMetrics, (state) => state.isCreateModalVisible);

const selectedMetricForEditSelector = () =>
    createSelector(selectMetrics, (state) => state.selectedMetricForEdit);

const allMetricsSelector = () =>
    createSelector(selectMetrics, (state) => state.allMetrics);

const showEditModalFromSidebarSelector = () =>
    createSelector(selectMetrics, (state) => state.showEditModalFromSidebar);


export {
  metricSelector,
  isDeleteModalVisibleSelector,
  isDCreateModalVisibleSelector,
  allMetricsSelector,
  showEditModalFromSidebarSelector,
  selectedMetricForEditSelector
};
