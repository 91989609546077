import React from 'react';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import styles from './ToggleExpandButton.module.scss';
import {COLORS} from '../../../constants/colors';

const ToggleExpandButton = ({expanded, onClick}) => {
  return (
    <button
      onClick={onClick}
      className={styles.container}
    >
      {expanded ? (
        <ChevronUpIcon color={COLORS.darkerGray} />
      ) : (
        <ChevronDownIcon color={COLORS.darkerGray} />
      )}
    </button>
  );
};

export default ToggleExpandButton;
