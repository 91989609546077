import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    getUpdatesSaga,
    refreshPageSaga,
    setComments,
    setFoundation,
    setGroupUpdates,
    setLogTypeActivity,
    setOrganizationActivity,
    setRefresh,
    setSettingsThemes,
    setUpdates,
    setUserActivity,
    updateGroupModalShow,
} from "./actions";
import {
    ADD_GROUP_UPDATE_SAGA,
    GET_COMMENTS_SAGA,
    GET_FOUNDATION_SAGA,
    GET_GROUP_UPDATES,
    GET_LOG_TYPE_ACTIVITY_SAGA,
    GET_ORGANIZATION_ACTIVITY_SAGA,
    GET_SETTINGS_THEMES_SAGA,
    GET_UPDATES_SAGA,
    GET_USER_ACTIVITY_SAGA, POST_UPDATE_FROM_HOME_PROJECT_SAGA,
    POST_UPDATE_SAGA,
    REFRESH_PAGE_SAGA,
    UPDATE_DATE_RANGE_SAGA,
} from "./actionTypes";
import {helperService} from "../../services/HelperService";
import {setLoader} from "../loader/actions";
import {PAGES} from "../../constants/pages";
import {getStrategyPlanSaga} from "../strategyPlan/actions";
import {strategyPlanSelector} from "../strategyPlan/selectors";
import {getGoalsAlignmentSaga} from "../alignment/actions";
import {getHubDataSaga, getHubUpdatesSaga} from "../hub/actions";
import {HUB_ENTITY_ROUTE_TYPE, ROW_ITEM_TYPES} from "../../constants/strings";
import goalService from "../../services/GoalService";
import kpiService from "../../services/KpiService";
import projectService from "../../services/ProjectService";
import actionService from "../../services/ActionService";
import homeService from "../../services/HomeService";
import groupService from "../../services/GroupService";

export function* getFoundationSagaFn() {
    try {
        const foundation = yield call(helperService.getFoundation);
        yield put(setFoundation(foundation));
    } catch (error) {
        console.log({ error });
    }
}

export function* getOrganizationActivitySagaFn({payload}) {
    try {
        const activity = yield call(helperService.getOrganizationActivity, payload.page, payload.perPage);
        yield put(setOrganizationActivity(activity));
    } catch (error) {
        console.log({ error });
    }
}

export function* addGroupUpdateSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        yield call(groupService.addGroupUpdate, payload);
        const res = yield call(homeService.getAllRecentUpdates, payload.groupId);
        yield put(setGroupUpdates(res));
        yield put(updateGroupModalShow({
            isVisible: false,
            title: '',
            maxWidth: 'md',
            component: null,
        }));
    } catch (error) {
        console.log({ error });
    } finally {
        yield put(setLoader(false));
    }
}

export function* getGroupUpdatesSagaFn({payload}) {
    try {
        const res = yield call(homeService.getAllRecentUpdates, payload);
        yield put(setGroupUpdates(res));
    } catch (error) {
        console.log({ error });
    }
}

export function* getLogTypeActivitySagaFn({ payload }) {
    try {
            const activity = yield call(helperService.getLogTypeActivity, payload.logType, payload.page, payload.perPage, payload.sourceId);
            yield put(setLogTypeActivity(activity));
    } catch (error) {
        console.log({ error });
    }
}


export function* getUserActivitySagaFn({payload}) {
    try {
        const activity = yield call(helperService.getUserActivity, payload.userId, payload.logType, payload.page, payload.perPage, payload.sourceId);
        yield put(setUserActivity(activity));
    } catch (error) {
        console.log({ error });
    }
}

export function* getSettingsThemesSagaFn() {
    try {
        const themes = yield call(helperService.getThemes);
        yield put(setSettingsThemes(themes));
    } catch (error) {
        console.log({ error });
    }
}

export function* refreshPageSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const activeScorecard = yield select(strategyPlanSelector());
        switch (payload.page) {
            case PAGES.STRATEGY_PLAN:
                yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
                break;
            case PAGES.SINGLE_DASHBOARD:
                yield put(setRefresh(true));
                break;
            case PAGES.PERSPECTIVE_DASHBOARD:
                yield put(setRefresh(true));
                break;
            case PAGES.HOME:
                yield put(setRefresh(true));
                break;
            case PAGES.STRATEGY_PLAN_AND_ALIGNMENT:
                if (activeScorecard?.id === payload.scorecardId) {
                    yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
                }
                yield put(setRefresh(true));
                break
            case PAGES.GOAL_ALIGNMENT_MAP:
                if (payload?.scorecardId && (activeScorecard?.id === payload?.scorecardId)) {
                    yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
                }
                yield put(getGoalsAlignmentSaga());
                break
            case PAGES.GOAL_HUB:
                yield put(getHubDataSaga({entity: HUB_ENTITY_ROUTE_TYPE.GOAL, id: payload.goalId}));
                break;
            case PAGES.KPI_HUB:
                yield put(getHubDataSaga({entity: HUB_ENTITY_ROUTE_TYPE.KPI, id: payload.kpiId}));
                yield put(getHubUpdatesSaga({entity: payload.entity, id: payload.entityId}));
                break;
            case PAGES.PROJECT_HUB:
                yield put(getHubDataSaga({entity: HUB_ENTITY_ROUTE_TYPE.PROJECT, id: payload.projectId}));
                break;
            case PAGES.ACTION_HUB:
                yield put(getHubDataSaga({entity: HUB_ENTITY_ROUTE_TYPE.ACTION, id: payload.actionId}));
                break;
            case PAGES.METRICS:
                yield put(setRefresh(true));
                break
            case PAGES.HUB_ACTIVITY_TAB:
                yield put(getHubUpdatesSaga({entity: payload.entity, id: payload.entityId}));
                yield put(getHubDataSaga({entity: payload.entity, id: payload.entityId}));
                break;
            case PAGES.MANAGE_CONNECTIONS:
                yield put(setRefresh(true))
                break;
            case PAGES.KANBAN_BOARD:
                yield put(setRefresh(true));
                break;
            default:
                console.log('no matching page selected');
        }
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* getUpdatesSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        let updates;
        switch (payload.entity) {
            case ROW_ITEM_TYPES.GOAL:
                updates = yield call(goalService.getGoalUpdates, payload?.id);
                break;
            case ROW_ITEM_TYPES.KPI:
                updates = yield call(kpiService.getKpiUpdates, payload?.id);
                break;
            case ROW_ITEM_TYPES.PROJECT:
                updates = yield call(projectService.getProjectUpdates, payload?.id);
                break;
            case ROW_ITEM_TYPES.ACTION:
                updates = yield call(actionService.getActionUpdates, payload?.id);
                break;
            default:
                console.log('no matching type selected');
        }
        yield put(setUpdates(updates));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* getCommentsSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const comments = yield call(helperService.getComments, payload.entityType.toLowerCase(), payload.entityId);
        yield put(setComments(comments));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* postUpdateSagaFn({payload}) {
    try {
        const getKeyName = () => {
            switch (payload.type) {
                case ROW_ITEM_TYPES.GOAL:
                    return 'goalId';
                case ROW_ITEM_TYPES.PROJECT:
                    return 'projectId';
                default:
                    return
            }
        }

        const updateData = {
            message: payload.values.message,
            title: payload.values.title,
            [getKeyName()]: payload.data.id,
        };
        switch (payload.type) {
            case ROW_ITEM_TYPES.GOAL:
                yield call(goalService.postGoalUpdate, updateData);
                break;
            case ROW_ITEM_TYPES.KPI:
                yield call(kpiService.updateKpiProgress, payload.values, payload.data?.id);
                break;
            case ROW_ITEM_TYPES.PROJECT:
                yield call(projectService.postProjectUpdate, updateData);
                break;
            case ROW_ITEM_TYPES.ACTION:
                if (payload.values.completedMilestoneId) {
                    yield call(actionService.updateActionMilestone, payload.values, payload.data?.id);
                } else {
                    yield call(actionService.updateActionProgress, payload.values, payload.data?.id);
                }
                break;
            default:
                console.log('no matching type selected');
        }

        yield put(refreshPageSaga({
            page: payload.rightModal.page,
            goalId: payload.rightModal.goalId,
            scorecardId: payload.rightModal.scorecardId,
            entity: payload.rightModal.entity,
            entityId: payload.rightModal.entityId,
        }));

        if (payload.from === PAGES.HUB) {
            yield put(getHubUpdatesSaga({entity: payload.data.type, id: payload.data.id}));
        }

        if (payload.rightModal.page === PAGES.HUB_ACTIVITY_TAB) {
            yield put(getHubUpdatesSaga({entity: payload.rightModal.entity, id: payload.rightModal.entityId}));
        }

        yield put(getUpdatesSaga({
            page: payload.rightModal.page,
            scorecardId: payload.rightModal.scorecardId,
            entity: payload.data?.type,
            id: payload.data?.id,
            goalId: payload.rightModal.goalId,
        }));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* updateDateRangeSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        switch (payload.entity.type) {
            case ROW_ITEM_TYPES.GOAL:
                yield call(goalService.updateGoalDateRange, payload.data, payload.entity.id);
                break;
            case ROW_ITEM_TYPES.KPI:
                yield call(kpiService.updateKpiDateRange, payload.data, payload.entity.id);
                break;
            case ROW_ITEM_TYPES.PROJECT:
                yield call(projectService.updateProjectDateRange, payload.data, payload.entity.id);
                break;
            case ROW_ITEM_TYPES.ACTION:
                yield call(actionService.updateActionDateRange, payload.data, payload.entity.id);
                break;
            default:
                console.log('no matching type selected');
        }

        yield put(refreshPageSaga({
            page: payload.page,
            kpiId: payload.kpiId,
            goalId: payload.goalId,
            projectId: payload.projectId,
            actionId: payload.actionId,
            scorecardId: payload.scorecardId,
        }));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* postUpdateFromHomeProjectSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        yield call(projectService.postProjectUpdate, payload.data)
        yield put(refreshPageSaga({
            page: payload.page,
        }));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}


export default function* helperSaga() {
    yield takeLatest(GET_FOUNDATION_SAGA, getFoundationSagaFn);
    yield takeLatest(ADD_GROUP_UPDATE_SAGA, addGroupUpdateSagaFn);
    yield takeLatest(GET_GROUP_UPDATES, getGroupUpdatesSagaFn);
    yield takeLatest(GET_ORGANIZATION_ACTIVITY_SAGA, getOrganizationActivitySagaFn);
    yield takeLatest(GET_LOG_TYPE_ACTIVITY_SAGA, getLogTypeActivitySagaFn);
    yield takeLatest(GET_USER_ACTIVITY_SAGA, getUserActivitySagaFn);
    yield takeLatest(GET_SETTINGS_THEMES_SAGA, getSettingsThemesSagaFn);
    yield takeLatest(REFRESH_PAGE_SAGA, refreshPageSagaFn);
    yield takeLatest(GET_UPDATES_SAGA, getUpdatesSagaFn);
    yield takeLatest(GET_COMMENTS_SAGA, getCommentsSagaFn);
    yield takeLatest(POST_UPDATE_SAGA, postUpdateSagaFn);
    yield takeLatest(UPDATE_DATE_RANGE_SAGA, updateDateRangeSagaFn);
    yield takeLatest(POST_UPDATE_FROM_HOME_PROJECT_SAGA, postUpdateFromHomeProjectSagaFn);
}
