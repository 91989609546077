import React, {useRef, useState} from 'react';
import styles from './EditUserComponent.module.scss';
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import {Field, Form, Formik} from "formik";
import InputField from "../../../Shared/Inputs/InputField";
import {getInitials} from "../../../../utils/format";
import UserStatusComponent from "../../../Shared/UserStatusComponent/UserStatusComponent";
import {editUserSchema} from "../../../../validation/user";
import usersService from "../../../../services/UsersService";
import {setSnackbar} from "../../../../store/snackbar/actions";
import {useDispatch, useSelector} from "react-redux";
import {fetchAuthenticatedUserSuccess, setTerminology} from "../../../../store/auth/actions";
import {makeSelectUser} from "../../../../store/auth/selectors";
import {ROLES} from "../../../../constants/strings";
import SelectField from "../../../Shared/Inputs/SelectField";

export const EditUserComponent = ({user, closeModal, refreshTable, isAuthMe}) => {
    const me = useSelector(makeSelectUser());
    const [userPhotoRemoved, setUserPhotoRemoved] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const handleUpdatePhoto = () => {
        setUploadError(false);
        fileInputRef.current.click();
    };

    const handleFileChange = (event, setFieldValue) => {
        const maxFileSize = 2 * 1024 * 1024;
        const file = event.currentTarget.files[0];
        if (file && file.size > maxFileSize) {
            setUploadError(true);
        } else {
            setFieldValue('photo', file);
            setUserPhotoRemoved(false);
        }
    };

    const handleRemovePhoto = (setFieldValue, values) => {
        setUploadError(false);
        if (!user.photo || values.photo instanceof File) {
            setFieldValue('photo', null);
        }
        setUserPhotoRemoved(true);
    }

    const handleSubmit = async (values) => {
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('role', values.role);
        formData.append('name', values.fullName);
        formData.append('jobTitle', values.jobTitle);
        if (!userPhotoRemoved) {
            formData.append('photo', values.photo);
        }
        const updatedUser = await usersService.updateUser(formData, user.id);
        if (!isAuthMe) {
            if (me.id === user.id) {
                dispatch(fetchAuthenticatedUserSuccess(updatedUser));
            }
            closeModal();
            refreshTable();
        } else {
            dispatch(fetchAuthenticatedUserSuccess(updatedUser));
            dispatch(setTerminology(updatedUser.framework));
        }
        dispatch(setSnackbar({
            open: true,
            severity: 'success',
            message: `${values.fullName} successfully updated! `,
        }))
    };

    return (
        <Formik
            initialValues={
                {
                    email: user.email,
                    role: user.role,
                    fullName: user.name || '',
                    jobTitle: user.jobTitle || '',
                    photo: user.photo || '',
                }
            }
            onSubmit={handleSubmit}
            validationSchema={editUserSchema}
            enableReinitialize={true}
            validateOnChange={true}
        >
            {({setFieldValue, errors, values, isValid, dirty}) => {
                const isPhotoUploaded = values.photo instanceof File;
                const hasUserPhoto = user.photo && !userPhotoRemoved;

                return (
                    <Form>
                        <div className={styles.container}>
                            <div className={styles.photoSection}>
                                <div className={styles.userPhotoWrapper}>
                                    {isPhotoUploaded ? (
                                        <div className={styles.imageWrapper}>
                                            <img className={styles.userPhoto} src={URL.createObjectURL(values.photo)}
                                                 alt="Uploaded Preview"/>
                                        </div>
                                    ) : hasUserPhoto ? (
                                        <img className={styles.userPhoto} src={user.photo} alt="Active user"/>
                                    ) : (
                                        <p className={styles.userInitials}>{getInitials(user.name ? user.name : 'NN')}</p>
                                    )}
                                </div>
                                <div className={styles.userPhotoTextWrapper}>
                                    <p className={styles.uploadPhotoDescription}>Photos help you teammates recognize
                                        you</p>
                                    <div className={styles.photoOptionsWrapper}>
                                        <DefaultButton title={'Update Photo'} inverted type="button"
                                                       onClick={handleUpdatePhoto}/>
                                        <input
                                            className={styles.hidden}
                                            ref={fileInputRef}
                                            name="photo"
                                            type="file"
                                            onChange={(event) => handleFileChange(event, setFieldValue)}
                                        />
                                        <div className={styles.photoOptionsSeparator}/>
                                        <DefaultButton title={'Remove Photo'} type={'button'} inverted
                                                       onClick={() => handleRemovePhoto(setFieldValue, values)}/>
                                    </div>
                                    <div className={styles.uploadPhotoSizeWrapper}>
                                        <p className={styles.uploadPhotoSizeText}>(Max photo size is 2MB)</p>
                                        {
                                            uploadError && (<p className={styles.uploadPhotoSizeTextError}>Image too big.</p>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <Field
                                component={InputField}
                                name={'email'}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                label={'Login Email'}
                                disabled
                                required
                            />
                            <Field
                                component={SelectField}
                                name={'role'}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                label={'Role'}
                                disabled={me?.role !== 'admin' || user?.id === me?.id}
                                options={{options: ROLES}}
                                canSelectEmpty={false}
                                placeholder={{value: 'Select Role'}}
                                required
                            />
                            <p className={styles.disabledInputsMessage}>Login email and role cannot be changed.</p>
                            <Field
                                component={InputField}
                                name={'fullName'}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                label={'Full Name'}
                                required
                            />
                            <Field
                                component={InputField}
                                name={'jobTitle'}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                label={'Job Title'}
                            />
                            <p className={styles.statusTitle}>Status</p>
                            <UserStatusComponent status={user?.status}/>
                            {/*{user.status === 'invited' &&*/}
                            {/*    <div className={styles.resendButtonWrapper}>*/}
                            {/*        <DefaultButton onClick={handleResendInvite} disabled={isResendButtonDisabled}*/}
                            {/*                       title={resendButtonTitle}/>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            <div className={styles.buttonsWrapper}>
                                {/*<DefaultButton title={'Delete Account'} type={'button'} leftIcon={<DeleteIcon/>}*/}
                                {/*               buttonStyles={{right: 18}} padding={'0 0 0 38px'}*/}
                                {/*               fontColor={COLORS.lighterRed} onClick={() => console.log('DELETE ACC')}*/}
                                {/*               inverted={true}/>*/}
                                <div className={styles.cancelAndSaveButtons}>
                                    {
                                        !isAuthMe && <DefaultButton title={'Cancel'} secondary={true}
                                                                    buttonStyles={{marginRight: 12}}
                                                                    onClick={closeModal}/>
                                    }
                                    <DefaultButton type={'submit'} disabled={!isValid || !dirty} title={'Save'}/>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EditUserComponent;
