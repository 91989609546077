import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectScorecard = (state) => state.scorecard || initialState;

const groupsSelector = () =>
  createSelector(selectScorecard, (state) => state.groups);

const groupsWithScorecardsSelector = () =>
  createSelector(selectScorecard, (state) => state.groupsWithScorecards);

const scorecardTitleSelector = () =>
    createSelector(selectScorecard, (state) => state.scorecardTitle);

const groupSelector = () =>
  createSelector(selectScorecard, (state) => state.group);

const allScorecardsSelector = () =>
    createSelector(selectScorecard, (state) => state.allScorecards);

const rightSidebarScorecardSelector = () =>
    createSelector(selectScorecard, (state) => state.rightSidebarScorecard);

const expandedGroupsSelector = () =>
    createSelector(selectScorecard, (state) => state.expandedGroups);

const isGroupIdsSetSelector = () =>
    createSelector(selectScorecard, (state) => state.isGroupIdsSet);

const deletePlanItemFromWidgetSelector = () =>
    createSelector(selectScorecard, (state) => state.deletePlanItemFromWidget);

export {
  groupsSelector,
  isGroupIdsSetSelector,
  groupsWithScorecardsSelector,
  groupSelector,
  expandedGroupsSelector,
  rightSidebarScorecardSelector,
  allScorecardsSelector,
  deletePlanItemFromWidgetSelector,
  scorecardTitleSelector
};
