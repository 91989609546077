import React, {memo} from 'react';
import styles from './TableViewTabComponent.module.scss';
import {METRIC_DETAILS_LIST} from "./metricDetailsList";
import {Grid} from "@mui/material";
import DefaultButton from "../../../../components/Shared/Buttons/DefaultButton";
import SimpleTableComponent from "../../../../components/Tables/SimpleTableComponent";
import {TABLE_CONFIG} from "./tableConfig";

const TableViewTabComponent = ({item, onAddValues, metric}) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <Grid container>
          {item && METRIC_DETAILS_LIST(metric).map((item) => {
            return (
                <Grid item sm={4} key={item.id}>
                  <h4 className={styles.subtitle} key={item.id}>{`${item.title}:`}</h4>
                  <h4>{item.value}</h4>
                </Grid>
              );
          })}
        </Grid>
        <div className={styles.buttonContainer}>
          <DefaultButton title={'Add Values'} onClick={onAddValues} />
        </div>
        {item && (
          <SimpleTableComponent config={TABLE_CONFIG} data={item} />
        )}
      </div>
    </div>
  );
};

export default memo(TableViewTabComponent);
