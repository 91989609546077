import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  POST_UPDATE_FROM_HOME_GOAL_SAGA,
  SHARE_EXISTING_GOALS_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import {setGoalsLibraryModal} from "./actions";
import goalService from "../../services/GoalService";
import {setSnackbar} from "../snackbar/actions";
import {refreshPageSaga} from "../helper/actions";
import {rightModalSelector} from "../helper/selectors";
import {terminologySelector} from "../auth/selectors";

export function* shareExistingGoalsSaga({goals, goalId, perspectiveId, scorecardId, page}) {
  try {
    yield put(setLoader(true));
    const terminology = yield select(terminologySelector());
    const rightModal = yield select(rightModalSelector());
    if (goalId) {
      const data = {
        contributeGoalIds: goals.map((goal) => goal.id),
        goalId,
      }
      yield call(goalService.shareContributeGoals, data);
    } else {
      const data = {
        sharedGoalIds: goals.map((goal) => goal.id),
        perspectiveId,
      }
      yield call(goalService.shareGoals, data);
    }
    yield put(setGoalsLibraryModal({
      isVisible: false,
      goalId: null,
      scorecardId: null,
      page: null,
    }));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${goals.length} ${terminology.goal}(s) have been shared`,
    }));
    yield put(refreshPageSaga({
      page,
      scorecardId,
      goalId: rightModal.goalId,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* postUpdateFromHomeGoalSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(goalService.postGoalUpdate, payload.data)
    yield put(refreshPageSaga({
      page: payload.page,
      scorecardId: payload.scorecardId
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* goalsSaga() {
  yield takeLatest(SHARE_EXISTING_GOALS_SAGA, shareExistingGoalsSaga);
  yield takeLatest(POST_UPDATE_FROM_HOME_GOAL_SAGA, postUpdateFromHomeGoalSagaFn);
}
