import React, { useState } from 'react';
import styles from './RisksTab.module.scss';
import RisksForm from "./RisksForm";
import RisksContent from "./RisksContent";
import {ACTIONS} from "../../../constants/strings";

const RisksTab = ({ item, rightModal }) => {
    const [formVisible, setFormVisible] = useState(false);
    const [selectedRisk, setSelectedRisk] = useState(null);
    const [reEvaluate, setReEvaluate] = useState(false);

    const toggleForm = (selectedEl, action) => {
        const reEvaluateNeeded = action === ACTIONS.RE_EVALUATE;
        setSelectedRisk(selectedEl);
        setReEvaluate(reEvaluateNeeded);
        setFormVisible(!formVisible);
    };

    return (
        <div className={styles.container}>
            {
                !formVisible ? (
                    <RisksContent
                        item={item}
                        toggleForm={(el, action) => toggleForm(el, action)}
                        rightModal={rightModal}
                    />
                ) : (
                    <RisksForm
                        reEvaluate={reEvaluate}
                        item={item}
                        selectedRisk={selectedRisk}
                        toggleForm={toggleForm}
                        rightModal={rightModal}
                    />
                )
            }
        </div>
    );
};

export default RisksTab;
