import {
  UPDATE_CHECKLIST_SAGA,
} from './actionTypes';

export function updateChecklistSaga(payload) {
  return {
    type: UPDATE_CHECKLIST_SAGA,
    payload,
  };
}
