import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {styled} from "@mui/material";
import {COLORS} from "../../../constants/colors";

export default function CheckboxInput({handleChangeCheck, value, disabled}) {
  const [checked, setChecked] = React.useState(value);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleChangeCheck(event.target.checked)
  };

  const StyledCheckbox = styled(Checkbox)`
    &.Mui-checked {
      color: ${COLORS.blue};
    }
`;

  return (
    <StyledCheckbox disabled={disabled}
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}
