import React, {useEffect, useState} from 'react';
import styles from './Checklist.module.scss'
import DefaultButton from "../../Buttons/DefaultButton";
import ExpectedProgress from "../ExpectedProgress";
import {COLORS} from "../../../../constants/colors";
import dayjs from "dayjs";
import ChecklistListComponent from "./ChecklistListComponent";

export function ChecklistComponent({item, setFormValues, errors, items}) {
    const INITIAL_CHECKLISTS = [{name: 'Checklist 1', date: dayjs(item?.startDate)}, { name: 'Checklist 2', date: dayjs(item?.startDate)}];
    const [checklists, setChecklists] = useState(INITIAL_CHECKLISTS)
    const onHandleChecklists = () => {
        if(checklists?.length > 0) {
            setChecklists([]);
            setFormValues({checklists: []})
        } else {
            setChecklists(INITIAL_CHECKLISTS)
            setFormValues({checklists: INITIAL_CHECKLISTS})
        }
    }

    useEffect(() => {
        if(items?.length > 0) {
            setFormValues({checklists: items})
            setChecklists(items);
        }
    }, [items])

    const onHandleRemoveInput = (val) => {
        let newArr = checklists.filter((item, index) => index !== val)
        setChecklists(newArr)
        setFormValues({checklists: newArr})
    }

    const onHandleSetItem =(val)=> {
        const newArr = [...checklists];
        newArr?.splice(val.index, 1, val.checklist)
        setChecklists(newArr)
        setFormValues({checklists: newArr})

    }

    const onHandleAddNew = () => {
        let newArr = [...checklists]
        newArr.push({name: '', date: dayjs(item?.startDate)})
        setChecklists(newArr)
    }


    return (
        <div className={styles.manuallyContainer}>
            <DefaultButton buttonStyles={{marginBottom: '8px'}} onClick={onHandleChecklists} type={'button'} title={checklists?.length > 0 ? 'Remove All Checklist Items' : 'Add Checklist Items'} border={`1px solid ${COLORS.blue}`} leftIcon={checklists?.length > 0 ? '' : '+'} height={40} width={'50%'} inverted={true}/>
            <ChecklistListComponent errors={errors} onSetItem={onHandleSetItem} items={checklists} onRemoveInput={onHandleRemoveInput}/>
            {checklists?.length > 0 &&
                <DefaultButton buttonStyles={{marginBottom: '8px'}} onClick={onHandleAddNew} type={'button'} title={'Add Checklist'} border={`1px solid ${COLORS.blue}`} leftIcon={'+'} height={40} width={'40%'} inverted={true}/>
            }
            <ExpectedProgress showCheckbox={false} item={item} padding={'0'} title={'Expected progress'}
                              setFormValues={setFormValues}/>
        </div>
    )

}

export default ChecklistComponent;
