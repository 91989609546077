import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  OPEN_ACTION_RIGHT_SIDEBAR,
  UPDATE_ACTION_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import actionService from "../../services/ActionService";
import goalService from "../../services/GoalService";
import {refreshPageSaga, rightModalOpen, setItemAndParentForAction} from "../helper/actions";
import {getItemParent} from "../../utils/perspective";
import scorecardService from "../../services/ScorecardService";
import {getHubUpdatesSaga} from "../hub/actions";
import {PAGES} from "../../constants/pages";


export function* updateActionSagaFn({payload}) {
  try {
    yield put(setLoader(true));

    if (payload.data.completedMilestoneId) {
      yield call(actionService.updateActionMilestone, payload.data, payload.actionId);
    } else {
      yield call(actionService.updateActionProgress, payload.data, payload.actionId);
    }

    if (payload.fromCriteriaTab) {
      const goal = yield call(goalService.getGoalWithItems, payload.parentId);

      yield put(setItemAndParentForAction({
        item: goal,
        parent: getItemParent(goal),
      }));
    }

    if (payload.fromDetailsTab) {
      const action = yield call(scorecardService.getActionById, payload.actionId)
      yield put(setItemAndParentForAction({parent: action.project || action.goal, item: action}))
    }

    if (payload.fromHub) {
      yield put(getHubUpdatesSaga({entity: payload.hub.type, id: payload.hub.id}));
    }

    yield put(refreshPageSaga({
      page: payload.page,
      actionId: payload.actionId,
      goalId: payload.goalId,
      scorecardId: payload.scorecardId,
      parentId: payload.parentId,
      entity: payload.entity,
      entityId: payload.entityId,
      projectId: payload.projectId,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* openActionRightSidebarSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setItemAndParentForAction({parent: getItemParent(payload?.item), item: payload?.item}));
    yield put(rightModalOpen({
      isVisible: true,
      component: payload.component,
      page: PAGES.KANBAN_BOARD,
      scorecardId: null,
    }))
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* actionSaga() {
  yield takeLatest(UPDATE_ACTION_SAGA, updateActionSagaFn);
  yield takeLatest(OPEN_ACTION_RIGHT_SIDEBAR, openActionRightSidebarSagaFn);
}
