import React, {useState} from 'react';
import styles from './ContributesComponent.module.scss';
import OutlinedSelectInput from "../Inputs/OutlinedSelectInput";
import {ReactComponent as DeleteGray} from '../../../assets/icons/deleteGray.svg';
import {COLORS} from "../../../constants/colors";
import goalService from "../../../services/GoalService";
import scorecardService from "../../../services/ScorecardService";
import perspectiveService from "../../../services/PerspectiveService";
import {getScorecardName} from "../../../utils/setDataForContributesToComponent";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

function ContributesForm({
                             currentGoal,
                             onUpdateForm,
                             index,
                             onDeleteForm
}) {
    const [selectedOption, setSelectedOption] = useState('');
    const [secondField, setSecondField] = useState('');
    const [thirdField, setThirdField] = useState('');
    const [fourthField, setFourthField] = useState('');

    let idArray = [currentGoal.id];
    currentGoal.contributionGoals.forEach(item => {
        idArray.push(item.id);
    });

    const [secondFieldOptions, setSecondFieldOptions] = useState([]);
    const [thirdFieldOptions, setThirdFieldOptions] = useState([]);
    const [fourthFieldOptions, setFourthFieldOptions] = useState([]);

    const terminology = useSelector(terminologySelector());

    const firstSelectBox = [
        {
            id: 1,
            value: 'plan',
            name: terminology.plan,
        },
        {
            id: 2,
            value: 'goal',
            name: terminology.goal,
        }
    ];

    const getAllGoals = async () => {
        try {
            const goals = await goalService.getGoalsForLibrary();
            let newArr = goals.map((val, index) => {
                return {...val, subtitle: getScorecardName(val)}
            });
            setSecondFieldOptions(newArr.filter(item => !idArray.includes(item.id)));
        } catch (err) {
            console.log(err)
        }
    }

    const getAllPlans = async () => {
        try {
            const plans = await scorecardService.getScorecards();
            setSecondFieldOptions(plans);
        } catch (err) {
            console.log(err)
        }
    }

    const getPerspectiveByPlanId = async (id) => {
        try {
            const perspectives = await perspectiveService.getPerspectivesForLibrary({scorecardId: id});
            setThirdFieldOptions(perspectives);
        } catch (err) {
            console.log(err)
        }
    }

    const getGoalsByPerspectiveId = async (id) => {
        try {
            const goals = await goalService.getGoalsForLibrary({perspectiveId: id});
            setFourthFieldOptions(goals.filter(item => !idArray.includes(item.id)))
        } catch (err) {
            console.log(err)
        }
    }

    const handleOnChangeSelectField = async (value, index, type) => {
        switch (type) {
            case 'selectedOption':
                setSelectedOption(value);
                setSecondField('')
                setThirdField('')
                setFourthField('')
                Number(value) === 2 ? await getAllGoals() : await getAllPlans();
                break;
            case 'secondField':
                setSecondField(value);
                if (Number(selectedOption) === 1) {
                    await getPerspectiveByPlanId(value);
                }
                break;
            case 'thirdField':
                setThirdField(value);
                await getGoalsByPerspectiveId(value)
                break;
            case 'fourthField':
                setFourthField(value);
                break;
        }
        onUpdateForm(value, index, type)
    }


    return (
        <div className={styles.formWrapper}>
            <div className={styles.titleMainText}>
                <p className={styles.titleMainTextStyle}>{`Contribution ${index + 1}`}</p>
            </div>
            <div className={styles.formUpperView}>
                <div className={styles.leftUpperWrapper}>
                    <OutlinedSelectInput
                        placeholder={`${terminology.plan} or ${terminology.goal}`}
                        value={selectedOption}
                        options={firstSelectBox}
                        handleChange={(e) => handleOnChangeSelectField(e.target.value, index, 'selectedOption')}
                    />
                </div>
                <div className={styles.rightUpperWrapper}>
                    {
                        selectedOption && (
                            <OutlinedSelectInput
                                placeholder={`Select a ${Number(selectedOption) === 1 ? terminology.plan : terminology.goal}`}
                                value={secondField}
                                options={secondFieldOptions}
                                handleChange={(e) => handleOnChangeSelectField(e.target.value, index, 'secondField')}
                            />
                        )
                    }
                </div>
                <button onClick={() => onDeleteForm(index)} className={styles.arrowDownBtnForm}>
                    <DeleteGray color={COLORS.red}/>
                </button>
            </div>
            {
                Number(selectedOption) === 1 && (
                    <div className={styles.formUpperViewSecond}>
                        {
                            secondField && (
                                <OutlinedSelectInput
                                    placeholder={`Select a ${terminology.perspective}`}
                                    value={thirdField}
                                    options={thirdFieldOptions}
                                    handleChange={(e) => handleOnChangeSelectField(e.target.value, index, 'thirdField')}
                                />
                            )
                        }
                    </div>
                )
            }
            {
                thirdField && (
                    <div className={styles.formUpperViewSecond}>
                                <OutlinedSelectInput
                                    placeholder={`Select a ${terminology.goal}`}
                                    value={fourthField}
                                    options={fourthFieldOptions}
                                    handleChange={(e) => handleOnChangeSelectField(e.target.value, index, 'fourthField')}
                                />
                    </div>
                )
            }
        </div>
    );
}


export default ContributesForm;
