import React, {useCallback, useEffect, useState} from 'react';
import styles from './CommentSection.module.scss';
import profileImage from "../../assets/icons/profile-owner.png";
import DefaultButton from "../Shared/Buttons/DefaultButton";
import {helperService} from "../../services/HelperService";
import {useDispatch, useSelector} from "react-redux";
import {setComments} from "../../store/helper/actions";
import {commentsSelector} from "../../store/helper/selectors";
import {timeAgo} from "../../utils/format";
import TextareaFieldNoFormik from "../Shared/Inputs/TextareaFieldNoFormik";
import {makeSelectUser} from "../../store/auth/selectors";

function CommentSection({item}) {
    const dispatch = useDispatch();

    const [newComment, setNewComment] = useState('');
    const [key, setKey] = useState(0);

    const me = useSelector(makeSelectUser());
    const comments = useSelector(commentsSelector());

    const getComments = useCallback(async () => {
        try {
            const comments = await helperService.getComments(item.type.toLowerCase(), item.id);
            dispatch(setComments(comments));
        } catch (err) {
            console.log(err);
        }
    }, [dispatch, item.id, item.type]);

    useEffect(() => {
        getComments();
    }, [getComments]);

    const onSetComment = (value) => {
        setNewComment(value);
    };

    const onPostComment = useCallback(async () => {
        try {
            const data = {
                comment: newComment,
                entityType: item.type.toLowerCase(),
                entityId: item.id
            }
            await helperService.createComment(data);
            setNewComment('');
            setKey((prevKey) => prevKey + 1);
            getComments()
        } catch (err) {
            console.log(err)
        }
    }, [getComments, item.id, item.type, newComment]);

    return (
        <div className={styles.container}>
            <p>Comments</p>
            <div className={styles.addCommentWrapper}>
                <div className={styles.imgAndInputWrapper}>
                    <img src={me.photo || profileImage} className={styles.profileImg} alt={'profileOwner'}/>
                    <div className={styles.inputFieldWrapper}>
                        <TextareaFieldNoFormik key={key} placeholder={'Leave a comment'} value={newComment} onChange={onSetComment}/>
                    </div>
                </div>
                <DefaultButton disabled={newComment.length === 0} type={'submit'} title={'Post'} buttonStyles={{height: 35, marginLeft: 40, marginTop: 8}} onClick={onPostComment}/>
            </div>
            <div className={styles.allCommentsWrapper}>
                {
                    comments.map(comment => {
                        return (
                            <div className={styles.singleCommentWrapper} key={comment.id}>
                                <div>
                                    <img src={comment.user.photo || profileImage} className={styles.profileImg} alt={'profileOwner'}/>
                                </div>
                                <div>
                                    <div className={styles.commentOwnerWrapper}>
                                        <p className={styles.ownerName}>{comment.user.name}</p>
                                        <p className={styles.timeAgo}>{timeAgo(new Date(comment.createdAt))}</p>
                                    </div>
                                    <p>{comment.comment}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}


export default CommentSection;
