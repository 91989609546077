import {ROW_ITEM_TYPES} from "../../../constants/strings";
export const position = { x: 0, y: 0 };
const userPhoto = 'https://media.istockphoto.com/id/1587604256/photo/portrait-lawyer-and-black-woman-with-tablet-smile-and-happy-in-office-workplace-african.webp?b=1&s=170667a&w=0&k=20&c=XocBdVosckQdKjxF2aKNbSWhK9GkX0JrykqAOU5KnbY=';
const userPhoto2 = 'https://community.thriveglobal.com/wp-content/uploads/2018/08/businesswomen.jpg';
export const goalNodes = [
  {
    id: "1",
    data: {
      name: "Increase Profit",
      health: "Late",
      scorecard:{
        name: 'CEO Scorecard'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: userPhoto,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // expanded: false,
      plans: [
        {
          name: "Plan 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto
          },
          ownerId: 1,
          shared: true,
          parent: false
        },
        {
          name: "Plan 2",
          health: "On Track",
          progress: 82,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto
          },
          ownerId: 1,
          shared: false,
          parent: true
        },
      ],
      items: [
        {
          type: ROW_ITEM_TYPES.GOAL,
          name: "Goal 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto2
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.ACTION,
          name: "Action 1",
          health: "On Track",
          progress: 83,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto2
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.PROJECT,
          name: "1Customer Project Customer Project Customer Project",
          health: "Late",
          progress: 23,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto
          },
          ownerId: 1,
          items: [
            {
              type: "Action",
              name: "Action 1",
              owner: {
                id: 1,
                name: "Mark Adams",
                email: "mark@codefinesse.com",
                photo: null
              },
              ownerId: 1,
              health: "Late",
              progress: 16,
            },
            {
              type: "Action",
              name: "Action 2",
              owner: {
                id: 1,
                name: "Mark Adams",
                email: "mark@codefinesse.com",
                photo: userPhoto
              },
              ownerId: 1,
              health: "Late",
              progress: 42,
            },
          ]
        },
        {
          type: ROW_ITEM_TYPES.KPI,
          name: "KPI 2",
          health: "On Track",
          progress: 93,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "2",
    data: {
      name: "Goal Profit",
      health: "On Track",
      scorecard:{
        name: 'CEO Scorecard'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // expanded: false,
      plans: [
        {
          name: "Plan 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: false,
          parent: true
        },

      ],
      items: [
        {
          type: ROW_ITEM_TYPES.GOAL,
          name: "Goal 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto2
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.ACTION,
          name: "Action 1",
          health: "On Track",
          progress: 83,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.KPI,
          name: "KPI 2",
          health: "On Track",
          progress: 93,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "3",
    data: {
      name: "Reduce Costs",
      health: "Late",
      scorecard:{
        name: 'Costs Scorecard'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // expanded: false,
      plans: [
        {
          name: "Plan 2",
          health: "On Track",
          progress: 82,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: false,
          parent: true
        },
      ],
      items: [
        {
          type: ROW_ITEM_TYPES.GOAL,
          name: "Goal 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.ACTION,
          name: "Action 1",
          health: "On Track",
          progress: 83,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.PROJECT,
          name: "1Customer Project Customer Project Customer Project",
          health: "Late",
          progress: 23,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto
          },
          ownerId: 1,
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "4",
    data: {
      name: "Finance",
      health: "Late",
      scorecard:{
        name: 'Finance Scorecard'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // expanded: false,
      plans: [
        {
          name: "Plan 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: true,
          parent: false
        },
      ],
      items: [
        {
          type: ROW_ITEM_TYPES.ACTION,
          name: "Action 1",
          health: "Late",
          progress: 23,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: ROW_ITEM_TYPES.KPI,
          name: "KPI 2",
          health: "Late",
          progress: 13,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: userPhoto2
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "7",
    data: {
      name: "Digital Transformation",
      health: "On Track",
      scorecard:{
        name: 'CEO Scorecard Digital Transformation'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // TODO: vasja
      // expanded: false,
      // setExpanded: (expanded) => {
      //   this.expanded = expanded;
      // },
      plans: [
        {
          name: "Plan 1",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: false,
          parent: true
        },
        {
          name: "Plan 2",
          health: "At Risk",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: true,
          parent: false
        },
      ],
      items: [
        {
          type: "Kpi",
          name: "KPI 1",
          health: "On Track",
          progress: 73,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: "Action",
          name: "Action 1",
          health: "At Risk",
          progress: 53,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
        {
          type: "Project",
          name: "Project 1",
          health: "At Risk",
          progress: 47,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          items: [
            {
              type: "Action",
              name: "Action 1",
              health: "Late",
              progress: 21,
              owner: {
                id: 1,
                name: "Mark Adams",
                email: "mark@codefinesse.com",
                photo: null
              },
              ownerId: 1
            },
            {
              type: "Action",
              name: "Action 2",
              health: "At Risk",
              progress: 63,
              owner: {
                id: 1,
                name: "Mark Adams",
                email: "mark@codefinesse.com",
                photo: null
              },
              ownerId: 1
            },
          ]
        },
        {
          type: "Kpi",
          name: "KPI 2",
          health: "On Track",
          progress: 89,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "8",
    data: {
      name: "Other Goal",
      health: "At Risk",
      scorecard:{
        name: 'Scorecard Digital'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // TODO: vasja
      // expanded: false,
      // setExpanded: (expanded) => {
      //   this.expanded = expanded;
      // },
      plans: [
        {
          name: "Plan 1",
          health: "Late",
          progress: 28,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: false,
          parent: true
        },
      ],
      items: [
        {
          type: "Project",
          name: "Project 1",
          health: "At Risk",
          progress: 53,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          items: [
            {
              type: "Action",
              name: "Action 1",
              health: "At Risk",
              progress: 63,
              owner: {
                id: 1,
                name: "Mark Adams",
                email: "mark@codefinesse.com",
                photo: null
              },
              ownerId: 1
            },
          ]
        },
        {
          type: "Kpi",
          name: "KPI 2",
          health: "On Track",
          progress: 73,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
  {
    id: "9",
    data: {
      name: "Contributing Goal",
      health: "Late",
      scorecard:{
        name: 'Contribute Scorecard'
      },
      owner: {
        id: 1,
        name: "Mark Adams",
        email: "mark@codefinesse.com",
        googleId: null,
        linkedInId: null,
        phoneNumber: null,
        photo: null,
        role: "admin",
        status: "enabled",
        jobTitle: null,
        currentOrganizationId: 1,
        verificationToken: null,
        resetPasswordCode: null,
        invitationToken: null,
        isEmailVerified: true,
        deletedAt: null,
        createdAt: "2024-01-25T14:05:58.000Z",
        updatedAt: "2024-01-25T14:05:58.000Z",
        current_organization_id: 1
      },
      ownerId: 1,
      // TODO: vasja
      // expanded: false,
      // setExpanded: (expanded) => {
      //   this.expanded = expanded;
      // },
      plans: [
        {
          name: "Plan 1",
          health: "Late",
          progress: 48,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1,
          shared: false,
          parent: true
        },
      ],
      items: [
        {
          type: "Kpi",
          name: "KPI 1",
          health: "Late",
          progress: 23,
          owner: {
            id: 1,
            name: "Mark Adams",
            email: "mark@codefinesse.com",
            photo: null
          },
          ownerId: 1
        },
      ],
    },
    type: "goalNode",
    position,
  },
];

export const goalEdges = [
  { id: "1->2", source: "1", target: "2" },
  { id: "1->3", source: "1", target: "3" },
  { id: "2->4", source: "2", target: "4" },
  { id: "2->7", source: "2", target: "7" },
  // { id: "3->7", source: "3", target: "7" },
  // { id: "1->7", source: "1", target: "7" },
  { id: "3->8", source: "3", target: "8" },
  { id: "8->9", source: "8", target: "9" },
];
