import React, {Fragment} from 'react';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import styles from './SliderComponent.module.scss';
import {COLORS} from "../../../constants/colors";
import {ItemHealth, ROW_ITEM_TYPES, TRACKING} from "../../../constants/strings";
import CustomDot from "../Dot/CustomDot";
import {ReactComponent as VerticalDashedLine} from "../../../assets/icons/triangle.svg";
import {formatProgressString, formatUnitAndValue} from "../../../utils/perspective";
import {formatGraphNumber} from "../../../utils/dashboard";

const StyledSlider = styled(Slider)(({sliderstyles}) => ({
  height: 6,
  margin: 0,
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: COLORS.white,
    border: `2px solid ${sliderstyles.backgroundColor}`,
    '& .MuiSlider-marked': {
      padding: 0,
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    height: 1,
    background: COLORS.gray100,
  },
  '& .MuiSlider-track': {
    opacity: 1,
    border: 'none',
    backgroundColor: sliderstyles.backgroundColor,
    backgroundImage: sliderstyles.backgroundImage,
  },
  '& .MuiSlider-mark': {
    backgroundColor: COLORS.darkestGray,
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: COLORS.brightGreen,
    },
  },
  '& .MuiSlider-markLabel': {
    top: -6,
    fontSize: sliderstyles.markLabelFontSize,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: 'bold',
    background: 'unset',
    padding: '4px 8px',
    backgroundColor: sliderstyles.backgroundColor,
    border: `1px solid ${sliderstyles.backgroundColor}`,
    color: sliderstyles.labelFontColor,
    transformOrigin: 'bottom left',
    transform: 'translate(0%, -100%) scale(0)',
    '&:before': {display: 'none'},
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(0%, -100%) scale(1)',
    },
  },
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    opacity: 1,
    '& .MuiSlider-track': {
      border: 'none',
      backgroundColor: sliderstyles.backgroundColor,
      backgroundImage: sliderstyles.backgroundImage,
    },
    '& .MuiSlider-thumb': {
      opacity: 0,
      height: 12,
      width: 12,
      backgroundColor: COLORS.white,
      border: `2px solid ${sliderstyles.backgroundColor}`,
      '& .MuiSlider-marked': {
        padding: 0,
      },
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
}));

const SliderComponent = ({
                           value,
                           min = 0,
                           max = 100,
                           inverted = false,
                           onChange,
                           onChangeCommitted,
                           marks,
                           status,
                           disabled,
                           showHeader,
                           showHeaderStatus,
                           titlePosition = null,
                           expectedProgress,
                           expectedProgressPosition,
                           expectedProgressUnit,
                           sliderWidth,
                           markLabelFontSize = 12,
                           showRange,
                           showValue,
                           item,
                           dateRange,
                         }) => {
  const sliderStyles = () => {
    switch (status) {
      case ItemHealth.LATE:
        return {
          backgroundColor: COLORS.progressBarRed,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.progressBarLightRed}, ${COLORS.progressBarLightRed} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.white,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
      case ItemHealth.AT_RISK:
        return {
          backgroundColor: COLORS.brightOrange,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.brightDarkerOrange}, ${COLORS.brightDarkerOrange} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.darkerGray,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
      case ItemHealth.ON_TRACK:
        return {
          backgroundColor: COLORS.progressBarGreen,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.progressBarLightGreen}, ${COLORS.progressBarLightGreen} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.white,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
      case ItemHealth.ACHIEVED:
        return {
          backgroundColor: COLORS.darkerBlue,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.blue}, ${COLORS.blue} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.white,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
      case ItemHealth.EXCEEDED:
        return {
          backgroundColor: COLORS.darkPurple,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.purple}, ${COLORS.purple} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.white,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
      default:
        return {
          backgroundColor: COLORS.gray100,
          backgroundImage: `repeating-linear-gradient(45deg, ${COLORS.lighterGray}, ${COLORS.lighterGray} ${10}px, transparent ${10}px, transparent ${10 * 2}px)`,
          labelFontColor: COLORS.darkestGray,
          markLabelFontSize: markLabelFontSize,
          sliderWidth: sliderWidth,
        };
    }
  };

  const getExpectedProgress = () => {
    switch (item.type) {
      case ROW_ITEM_TYPES.KPI:
        return formatProgressString(item, inverted ? min + max - value : value);
      case ROW_ITEM_TYPES.ACTION:
        return {
          currentValue: `${value}%`,
          expectedProgress: 'of 100%',
        };
      default:
        console.log('no matching type selected');
    }
  };

  const getValueWithUnit = () => {
    switch (item.type) {
      case ROW_ITEM_TYPES.KPI:
        return formatUnitAndValue(item.unit, inverted ? min + max - value : value);
      case ROW_ITEM_TYPES.ACTION:
        return `${value}%`;
      default:
        console.log('no matching type selected');
    }
  }

  const getExpectedProgressTransformStyle = () => {
    switch (true) {
      case expectedProgressPosition < 10:
        return 'translate(-20px, 0)';
      case expectedProgressPosition > 90:
        return 'translate(calc(-100% + 20px), 0)';
      default:
        return 'translate(-50%, 0)';
    }
  };
  const getSteps = () => {
    switch (true) {
      case item.type === ROW_ITEM_TYPES.ACTION && item.tracking === TRACKING.MILESTONE:
        return null;
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      {showHeader && (
        <div className={styles.headerWrapper}>
          <div className={styles.expectedProgressStatus}>
            <p>{getExpectedProgress().currentValue}</p>
            <p className={styles.expectedProgressGoalString}>{getExpectedProgress().expectedProgress}</p>
          </div>
          {showHeaderStatus && (
            <div className={styles.statusWithDot}>
              <CustomDot backgroundColor={sliderStyles().backgroundColor}/>
              <h4 className={styles.statusTitle}>{status}</h4>
            </div>
          )}
        </div>
      )}
      <div className={styles.sliderContainer}>
        <div className={styles.slider} style={{width: sliderWidth}}>
          {showRange && (
            <div className={styles.rangeContainer}>
              <h4 className={styles.rangeValue}>{formatGraphNumber(inverted ? max : min)}</h4>
              <h4 className={styles.rangeValue}>{formatGraphNumber(inverted ? min : max)}</h4>
            </div>
          )}
          <StyledSlider
            value={(value === undefined || value === null)
              ? 0
              : value
            }
            step={getSteps()}
            onChange={onChange}
            marks={marks}
            min={min}
            max={max}
            valueLabelFormat={(v) => (inverted ? min + max - v : v)}
            valueLabelDisplay="auto"
            sliderstyles={sliderStyles()}
            onChangeCommitted={onChangeCommitted}
            disabled={disabled}
          />
          {!(expectedProgress === null || expectedProgress === undefined) && (
            <Fragment>
              <div className={styles.expectedProgressArrow} style={{left: `${expectedProgressPosition}%`}}>
                <div className={styles.expectedProgressLine}>
                  <VerticalDashedLine width={10} height={10}/>
                </div>
              </div>
              <div className={styles.expectedProgressContainer} style={{left: `${expectedProgressPosition}%`, transform: getExpectedProgressTransformStyle()}}>
                {`Expected progress: ${expectedProgress}${expectedProgressUnit}`}
              </div>
            </Fragment>
          )}
        </div>
        {showValue && (
          <h4 className={styles.value}>{getValueWithUnit()}</h4>
        )}
      </div>
      {titlePosition && (
        <div style={{marginLeft: '20px'}}>
          <p>{value + '%'}</p>
        </div>
      )}
    </div>
  );
};

export default SliderComponent;

