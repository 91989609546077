import React, {useEffect, useState} from "react";
import styles from './riskDistribution.module.scss'
import {
  CartesianGrid, Cell, Legend,
  ResponsiveContainer, Scatter, ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {COLORS} from "../../../constants/colors";
function RiskDistributionScatterPlot({data, onChartClick}) {

  const [chartData, setChartData] = useState(null)
  const getColor = (score) => {
    switch (true) {
      case 0 <= score && score <= 33:
        return COLORS.lightBlueish
      case 33 < score && score <= 66:
        return COLORS.brightOrange
      case 66 < score && score <= 100:
        return COLORS.progressBarRed
      default:
        return 'blue'

    }
  }

  useEffect(() => {
    if(data.length > 0) {
      let newData = data.map((item) => {
        return Object.assign({}, item, {color: getColor(item.score)})
      })
      setChartData(newData)
    }
  }, [data])

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <div className={styles.tooltipHeader}>
            <div className={styles.tooltipHeaderDot} style={{ background: payload[0].payload.color}}/>
            <p>{payload[0].payload.name}</p>
          </div>
          <div className={styles.tooltipData}>
            <p>{`Open Mitigations: ${payload[0].payload.openedMitigations}`}</p>
            <p>{`Closed Mitigations: ${payload[0].payload.closedMitigations}`}</p>
            <p>{`Type: ${payload[0].payload.type}`}</p>
            <p>{`Score: ${payload[0].payload.score}`}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderLegend = () => {
    return (
      <div className={styles.legendWrapper}>
        <div className={styles.legendItem}>
          <div className={styles.tooltipHeaderDot} style={{ background: COLORS.lightBlueish}}/>
          <p>Low</p>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.tooltipHeaderDot} style={{ background: COLORS.brightOrange}}/>
          <p>Moderate</p>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.tooltipHeaderDot} style={{ background: COLORS.progressBarRed}}/>
          <p>High</p>
        </div>
      </div>
    );
  }

    return (
        <div className={styles.wrapper} onClick={onChartClick}>
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart
                  margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                  }}
                >
                    <CartesianGrid />
                    <XAxis ticks={Array.from({length: 10}, (_, i) => i + 1)} tickCount={1} type="number" dataKey="impact" name="Impact" label={{value: 'Impact', position: 'insideBottom', offset: 0,}} />
                    <YAxis ticks={Array.from({length: 10}, (_, i) => i + 1)} tickCount={1} type="number" dataKey="probability" label={{value: 'Probability', angle: -90,offset: 0, style: {fill: COLORS.legendBlack, fontSize: 12}}} name="Probability"/>
                    <Tooltip content={<CustomTooltip/>} cursor={{ strokeDasharray: '3 3' }} />
                  {chartData && <Scatter data={chartData} >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))}
                  </Scatter>}
                    <Legend content={renderLegend} wrapperStyle={{zIndex: 10}}  height={36}/>
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    );
}


export default RiskDistributionScatterPlot;
