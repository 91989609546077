import React, {memo, PureComponent, useEffect, useState} from 'react';
import styles from './ChartWithProgressComponent.module.scss';
import {
    Legend,
    Line,
    LineChart, ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import dayjs from "dayjs";
import {COLORS} from "../../../../constants/colors";
import {ItemHealth, ROW_ITEM_TYPES, TASK_TYPES, UnitsContants} from "../../../../constants/strings";
import {formatGraphNumber} from "../../../../utils/dashboard";

const ChartComponent = ({data, item, showGrid = false, width = 500, height = 300, onChartClick}) => {
    const [dataState, setDataState] = useState([]);
    const [graphDataKeys, setGraphDataKeys] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const dateFormatter = date => {
        return dayjs(new Date(date)).format('MMM-DD/YY');
    };

    useEffect(() => {
        if (data?.length > 0) {
            let newArr = [];
            let expectedArr = [];
            if (!dayjs(item.startDate).isSame(dayjs(data[0]?.createdAt), 'day')) {
                const data = {
                    date: new Date(item.startDate).getTime(),
                    timestamp: new Date(item.startDate).getTime()
                }
                newArr.push(data);
            }
            data.forEach((val) => {
                const data = {
                    value: val.value,
                    date: new Date(val.createdAt).getTime(),
                    timestamp: new Date(val.createdAt).getTime(),
                    health: val.health
                }
                newArr.push(data);
            });
            if (!dayjs(item.endDate).isSame(dayjs(data[data.length - 1].createdAt), 'day')) {
                const data = {
                    date: new Date(item.endDate).getTime(),
                    timestamp: new Date(item.endDate).getTime(),
                }

                newArr.push(data);
            }
            if ((item?.type === 'Kpi' && item?.trackingType === 'achieved') || (item?.type === 'Action')){
                item.expectedProgressOverTime.forEach((val) => {
                    const data = {
                        value: val.expectedValue,
                        date: new Date(val.date).getTime(),
                        timestamp: new Date(val.date).getTime(),
                    }
                    expectedArr.push(data);
                });
            }
            const newFormattedArray = [];
            newFormattedArray.push({
                data: newArr,
                name: item?.name,
                unit: item?.unit
            });
            if (expectedArr) {
                newFormattedArray.push({
                    data: expectedArr,
                    name: 'Expected Progress',
                    unit: item?.unit
                });
            }
            setDataState(newFormattedArray);
        } else {
            setDataState([])
        }
    }, [data]);

    useEffect(() => {
        let formattedData = [];
        let dataKeys = [];

        dataState.forEach((item) => {
            const dataKeyFound = dataKeys.find((el) => el === item.name);
            if (!dataKeyFound) dataKeys.push(item.name);
            setGraphDataKeys(dataKeys)

            item?.data?.forEach((update) => {
                if (!formattedData[update.timestamp]) {
                    formattedData[update.timestamp] = {};
                    formattedData[update.timestamp]["name"] = update.timestamp;
                }
                formattedData[update.timestamp][item.name] = update.value;
                formattedData[update.timestamp]["health"] = update.health;
            });
        });

        let dataForGraph = [];
        for (const [key, value] of Object.entries(formattedData)) {
            dataForGraph.push(value);
        }

        dataForGraph = dataForGraph.sort((a, b) => a.name - b.name);
        setGraphData(dataForGraph);
    }, [dataState]);

    const CustomizedDot = (props) => {
        const {cx, cy} = props;
        const getStrokeColor = () => {
            switch (props.payload.health) {
                case ItemHealth.LATE:
                    return COLORS.red;
                case ItemHealth.ON_TRACK:
                    return COLORS.brightGreen;
                case ItemHealth.AT_RISK:
                    return COLORS.brightOrange;
                case ItemHealth.EXCEEDED:
                    return COLORS.purple;
                case ItemHealth.ACHIEVED:
                    return COLORS.blue;
                default:
                    return COLORS.white
            }
        }

        return (
            <svg
                x={(cx ? cx : 10) - 10}
                y={cy - 10}>
                <circle cx="10" cy="10" r="3" stroke={getStrokeColor()} strokeWidth="2" fill={getStrokeColor()}/>
            </svg>
        );
    };
    const formatProgressString = (unit, value) => {
        switch (unit) {
            case UnitsContants.DOLLAR:
                return `$${value}`;

            case UnitsContants.POUND:
                return `£${value}`;

            case UnitsContants.PERCENTAGE:
                return `${value}%`;

            case UnitsContants.SECONDS:
                return `${value}s`;

            case UnitsContants.MINUTES:
                return `${value}min`;

            case UnitsContants.HOURS:
                return `${value}hr`;

            case UnitsContants.DAYS:
                return `${value} ${value === 1 ? 'day' : 'days'}`;

            case UnitsContants.WEEKS:
                return `${value} ${value === 1 ? 'week' : 'weeks'}`;

            case UnitsContants.MONTHS:
                return `${value} ${value === 1 ? 'month' : 'months'}`;

            case UnitsContants.YEARS:
                return `${value} ${value === 1 ? 'year' : 'years'}`;

            case UnitsContants.CUSTOMERS:
                return `${value} ${value === 1 ? 'customer' : 'customers'}`;

            case UnitsContants.USERS:
                return `${value} ${value === 1 ? 'user' : 'users'}`;

            case UnitsContants.NPS_SCORE:
                return `${value}NPS`;

            default:
                return `${value} %`;
        }
    };

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltipWrapper}>
                    <p className="label">{`${dayjs(new Date(label)).format('MMM-DD/YY')}`}</p>
                    <p className="intro">{formatProgressString(item.unit, payload[0]?.value)}</p>
                </div>
            );
        }

        return null;
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={15} y={-5} dy={16} fontSize={11} textAnchor="end" fill={COLORS.legendBlack} transform="rotate(-25)">
                        {dayjs(new Date(payload.value)).format('MMM-DD/YY')}
                    </text>
                </g>
            );
        }
    }

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload, anchor, color } = props;

        return (
            <text x={x} y={y} textAnchor={anchor} fill={color}>
                {formatGraphNumber(payload.value)}
            </text>
        );
    };

    const CustomLegend = (props) => {
        return (
            <div className={styles.legendWrapper}>
                <div className={styles.legendItemWrapper}>
                    <div className={styles.legendDot} style={{background: COLORS.legendBlack}}/>
                    <span style={{ color: COLORS.legendBlack }}>{props?.payload[0]?.dataKey}</span>
                </div>
                {
                    (item?.upperTarget || item?.lowerTarget) && (
                        <div className={styles.legendItemWrapper} style={{marginLeft: '10px'}}>
                            <div className={styles.legendDot} style={{background: COLORS.red}}/>
                            <span className={styles.legendText}>{item.upperTarget && item.lowerTarget ? 'Targets' : 'Target'}</span>
                        </div>
                    )
                }
            </div>
        );
    };

    const domainYaxis = () => {
        if (item?.type === ROW_ITEM_TYPES.GOAL || item?.type === ROW_ITEM_TYPES.PROJECT) {
            return [0, 100]
        } else {
            let maxValue = item.max ? item.max : 100;
            if (item.updates) {
                item.updates.forEach((item) => {
                    if (item.value > maxValue) {
                        maxValue = item.value;
                    }
                });
            }
            return [(item?.min !== null && item?.min !== undefined) ? item?.min : 'auto', item?.max ? maxValue : 'auto']
        }
    }

    return (
        <div
            className={styles.wrapper}
            style={{position:'relative'}}
            onClick={() => onChartClick(TASK_TYPES.KPI, [data[0]?.kpiId])}
        >
            <ResponsiveContainer width="100%" height="100%">
            <LineChart width={width} height={height} data={graphData} margin={{top: 5, right: 10, bottom: 5, left: 0}}>
                <XAxis
                    tick={<CustomizedAxisTick/>}
                    dataKey="name"
                    scale="time"
                    domain={['auto', 'auto']}
                    type={'number'}
                    tickFormatter={dateFormatter}
                />
                <YAxis
                    domain={domainYaxis()}
                    tick={<CustomizedYAxisTick anchor={'end'} color={COLORS.legendBlack} />}
                />
                <Tooltip content={<CustomTooltip/>}/>
                <Legend content={<CustomLegend />} />
                {dataState.length && dataState.map((s, index) => (
                    <Line
                        isAnimationActive={false}
                        connectNulls
                        type={index === 0 ? 'linear' : 'stepAfter'}
                        stroke={index === 0 ? COLORS.legendBlack : COLORS.expectedGraphColor}
                        dot={index === 0 ? <CustomizedDot/> : { stroke: COLORS.expectedGraphColor, strokeWidth: 2 }}
                        dataKey={graphDataKeys[index]}
                        key={s.name}
                    />
                ))}
                {item?.lowerTarget && (<ReferenceLine strokeDasharray="5 5" y={item.lowerTarget} stroke={COLORS.red} />)}
                {item?.upperTarget && (<ReferenceLine strokeDasharray="5 5" y={item.upperTarget} stroke={COLORS.red} />)}
            </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
export default memo(ChartComponent);
