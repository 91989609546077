import React from 'react';
import {ReactComponent as VncLogoLogin} from "../../assets/icons/vncLogoLogin.svg";
import styles from './WorkspacePage.module.scss';
import DynamicForm from "../../components/Shared/Form/DynamicForm";
import {createWorkspaceFromConfig} from "./createWorkspaceFromConfig";
import {createWorkspaceSchema} from "../../validation/workspace";
import DefaultButton from "../../components/Shared/Buttons/DefaultButton";
import {useHistory} from "react-router-dom";
import {WORKSPACE} from "../../routesConstants";
import {useDispatch, useSelector} from "react-redux";
import {createOrganizationSaga} from "../../store/auth/actions";
import {makeSelectUser} from "../../store/auth/selectors";

function CreateWorkspacePage() {
    const user = useSelector(makeSelectUser())
    const history = useHistory();
    const dispatch = useDispatch();
    const onFormSubmit = (values) => {
        dispatch(createOrganizationSaga(values));
    }

    const goToWorkspaces = () => {
        history.push(WORKSPACE);
    }

    return (
        <main className={styles.createWorkspaceContainer}>
            <div className={styles.sectionRow}>
                <VncLogoLogin/>
                <p className={styles.appName}>Stratify Pro</p>
            </div>
            <div className={styles.createTitleWrapper}>
                <p className={styles.createTitle}>Create Workspace</p>
                <p className={styles.createSubtitle}>Please provide the following information to create your new workspace</p>
            </div>
            <div className={styles.formWrapper}>
                <DynamicForm
                    config={createWorkspaceFromConfig(user)}
                    validationSchema={createWorkspaceSchema}
                    handleOnSubmit={(values) => onFormSubmit(values)}
                />
                <DefaultButton inverted title={'Login into another account'} fontSize={18} onClick={goToWorkspaces}/>
            </div>
        </main>
    );
}

export default CreateWorkspacePage;
