import React from "react";
import styles from './groupsTable.module.scss'
import WidgetTableComponent from "../../Shared/WidgetTableComponent";
import {GROUPS_TABLE_CONFIG} from "../../Shared/WidgetTableComponent/groupsTableConfig";

function GroupsTable({groups}) {
    const newGroups = JSON.parse(JSON.stringify(groups));
    for (const group of newGroups) {
        group.tableHeader = 'Owner: ' + group.owner.name;
        group.tableRows = group.users;
        group.dataSourceName = group.name;
    }

    return (
        <div className={styles.wrapper}>
            <WidgetTableComponent data={newGroups} config={GROUPS_TABLE_CONFIG}/>
        </div>
    );
}


export default GroupsTable;
