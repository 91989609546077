import React, {memo, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Popover from "../../containers/Home/Popover";
import {ListItemIcon, ListItemText} from "@mui/material";
import {ReactComponent as ArrowDownIcon} from "./../../../src/assets/icons/arrowDown.svg";
import styles from './HorizontalTabs.module.scss'

function HorizontalTabs({onTabChange, tabs, selectedTab, backgroundColor, paddingLeft = '30px'}) {
  const [value, setValue] = React.useState(selectedTab ? selectedTab : '1');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSecondEl, setAnchorSecondEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuSecondOpen, setIsMenuSecondOpen] = useState(false);
  const handleChange = (event, newValue) => {
    onTabChange(newValue)
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab])

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorSecondEl(null);
    setIsMenuSecondOpen(false)
    setIsMenuOpen(false)
  };

  const handleTypeTabClick = (value, currentNode, index) => {
    if (index === 0) {
      if (value === '1') {
        setIsMenuOpen(true)
        setAnchorEl(currentNode);
      }
      else{
        setIsMenuOpen(false)
      }
    } else {
      if (value === '1') {
        setIsMenuSecondOpen(true)
        setAnchorSecondEl(currentNode);
      }
      else{
        setIsMenuSecondOpen(false)
      }
    }
  }

  return (
      <Box className={styles.wrapper} style={{backgroundColor: backgroundColor, paddingLeft: paddingLeft}}>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} >
              {tabs.map((item, index) => (
                  <Tab disabled={item.disabled}
                      key={item.id}
                      value={item.id.toString()}
                      label={
                        <div className={styles.tabItem}>
                          {
                            item.icon && (
                                  <ListItemIcon style={{ marginRight: -25 }}>
                                    {item.icon}
                                  </ListItemIcon>
                              )
                          }
                          <ListItemText primary={item.title} />
                          {
                              item.isDropdown && (
                                  <ListItemIcon
                                      onClick={(event) => {
                                        if (item.isDropdown) {
                                          handleTypeTabClick('1', event.currentTarget, index);
                                        }
                                      }}
                                      className={styles.dropdownArrow}>
                                    <ArrowDownIcon />
                                  </ListItemIcon>
                              )
                          }
                        </div>
                      }
                      sx={{ textTransform: 'none', paddingBottom: '0px' }}
                  />
              ))}
            </TabList>
          </Box>
        </TabContext>
        {isMenuOpen && (
            <Popover
                onItemPress={onTabChange}
                options={tabs[0].options}
                currentNode={anchorEl}
                open={isMenuOpen}
                handleClose={handleClose}
            />
        )}
        {isMenuSecondOpen && (
            <Popover
                onItemPress={onTabChange}
                options={tabs[0].options}
                currentNode={anchorSecondEl}
                open={isMenuSecondOpen}
                handleClose={handleClose}
            />
        )}
      </Box>
  );
}


export default memo(HorizontalTabs);
