import React, {memo} from 'react';
import {styled, Switch} from "@mui/material";
import {COLORS} from "../../../constants/colors";

const StyledSwitch = styled(Switch)`
  &.MuiSwitch-root {
    height: 20px;
    width: 40px;
    padding: 0;
  },
  & .MuiSwitch-track {
    border-radius: 30px;
    background: ${COLORS.lightGray};
  },
  & .MuiSwitch-switchBase {
    padding: 3px;
  },
  & .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background: ${COLORS.blue};
    opacity: 1;
  }
  & .MuiSwitch-thumb {
    background: white;
    box-shadow: none;
    width: 14px;
    height: 14px;
  },
`;

const SwitchInput = ({checked, handleChange}) => {
  return (
    <StyledSwitch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default memo(SwitchInput);
