import React, {useCallback, useEffect, useState} from 'react';
import styles from './graphTableList.module.scss';
import profileImage from "../../../../assets/images/user.png";
import {COLORS} from "../../../../constants/colors";
import AllOutIcon from '@mui/icons-material/AllOut';
import {ReactComponent as ArrowDown} from "../../../../assets/icons/chevron-down.svg";
import {getHealthStyle} from "../../../../utils/dashboard";
import PopupComponent from "../../Popup/PopupComponent";
import {menuConfigListItem} from "./menuConfigListItem";
import {ACTIONS} from "../../../../constants/strings";
import {setModal} from "../../../../store/helper/actions";
import {useDispatch, useSelector} from "react-redux";
import scorecardService from "../../../../services/ScorecardService";
import DynamicForm from "../../Form/DynamicForm";
import {editScorecardFormConfig} from "../../../../containers/ScorecardListPage/editScorecardFormConfig";
import {editScorecardSchema} from "../../../../validation/scorecard";
import CustomModal from "../../Modals/CustomModal";
import {
    deleteScorecardFromMultiplePlansSnapshotSaga,
    manageScorecardSaga
} from "../../../../store/scorecard/actions";
import {ALIGNMENT, PERSPECTIVE_DASHBOARDS, STRATEGY_PLAN} from "../../../../routesConstants";
import {useHistory} from "react-router-dom";
import {menuConfigListItemChildren} from "./menuConfigListItemChildren";
import {
    setShowEditPerspectiveModal,
    updatePerspectiveFromDashboardSaga,
} from "../../../../store/perspectives/actions";
import EditPerspectiveModalComponent from "../../../../containers/StrategyPlanPage/editPerspectiveModalComponent";
import DeleteContent from "../../Modals/DeleteContent";
import {modalSelector} from "../../../../store/helper/selectors";
import {showEditModalPerspectiveSelector} from "../../../../store/perspectives/selectors";
import {deletePerspectiveFromWidgetSaga} from "../../../../store/strategyPlan/actions";
import {terminologySelector} from "../../../../store/auth/selectors";
import {getOwnerSelectOptions} from "../../../../utils/perspective";

const GraphTableList = ({data, fromReport}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [collapsedItems, setCollapsedItems] = useState({});
    const [showPlanSettings, setShowPlanSettings] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenDeletePerspectiveModal, setIsOpenDeletePerspectiveModal] = useState(false);
    const [ownerSelectOptions, setOwnerSelectOptions] = useState([]);

    const deletePlanModal = useSelector(modalSelector());
    const showEditModal = useSelector(showEditModalPerspectiveSelector());
    const terminology = useSelector(terminologySelector());

    const toggleCollapse = (itemId) => {
        if (!fromReport) {
            setCollapsedItems(prevState => ({
                ...prevState,
                [itemId]: !prevState[itemId]
            }));
        }
    };

    const onPlanSettings = async (planId) => {
        try {
            const scorecard = await scorecardService.getScorecard(planId);
            const options = await getOwnerSelectOptions({group: scorecard.group});
            setOwnerSelectOptions(options);
            setSelectedItem(scorecard);
            setShowPlanSettings(true);
        } catch (err) {
            console.log(err);
        }
    }

    const manageScorecard = useCallback((values, create, scorecardId) => {
        dispatch(manageScorecardSaga(values, create, scorecardId));
        setShowPlanSettings(false)
    }, [dispatch]);

    const closePlanSettings = useCallback(() => {
        setShowPlanSettings(false)
    }, []);

    const onPressDeletePlan = async (planId) => {
        try {
            const scorecard = await scorecardService.getScorecard(planId);
            setSelectedItem(scorecard);
            dispatch(setModal({isVisible: true}))
        } catch (err) {
            console.log(err);
        }
    }

    const deleteScorecard = useCallback((scorecard, removeLinks) => {
        const isFromForm = history.location.pathname.includes('create-widget');
        dispatch(deleteScorecardFromMultiplePlansSnapshotSaga(scorecard, removeLinks, isFromForm ));
    }, [dispatch]);

    const onPressEditPerspective = (item) => {
        setSelectedItem(item);
        dispatch(setShowEditPerspectiveModal(true))
    }

    const onItemActionsPress = async (action, item) => {
        switch (action) {
            case ACTIONS.VIEW_PLAN:
                history.push(STRATEGY_PLAN.replace(':id', item.id));
                break;
            case ACTIONS.VIEW_ALIGNMENT:
                history.push(ALIGNMENT)
                break;
            case ACTIONS.PLAN_SETTINGS:
                onPlanSettings(item.id);
                break;
            case ACTIONS.DELETE:
                onPressDeletePlan(item.id);
                break;
            case ACTIONS.EDIT:
                onPressEditPerspective(item);
                break;
            case ACTIONS.PERSPECTIVE_DASHBOARD:
                history.push(PERSPECTIVE_DASHBOARDS.replace(':id', item.id).replace(':value', item.scorecardId));
                break;
            case ACTIONS.DELETE_FROM_PLAN:
                setSelectedItem(item);
                setIsOpenDeletePerspectiveModal(true);
                break;
            default:
                console.log('No action selected');
        }
    }

    const handleSubmitEditPerspective = useCallback((val) => {
        const payload = {
            ...val,
            id: selectedItem.id,
            scorecardId: selectedItem.scorecardId
        }
        dispatch(updatePerspectiveFromDashboardSaga(payload));
    },[dispatch, selectedItem])

    const onDeleteConfirm = () => {
        dispatch(deletePerspectiveFromWidgetSaga(selectedItem.id, selectedItem.scorecardId));
        setIsOpenDeletePerspectiveModal(false);
        setSelectedItem(null);
    }

    useEffect(() => {
        const initialCollapsedState = {};
        data.forEach(item => {
            initialCollapsedState[item.id] = collapsedItems[item.id] !== undefined ? collapsedItems[item.id] : true;
        });
        setCollapsedItems(initialCollapsedState);
    }, [data]);

    return (
        <div className={styles.container}>
            {
                data.map(item => {
                    return (
                        <div key={item.id}>
                            <div className={styles.tableRowWrapper} onClick={() => toggleCollapse(item.id)}>
                                <div className={styles.rowLeftWrapper}>
                                    <div className={styles.rowLeftIcon}
                                         style={{transform: `rotate(${!collapsedItems[item.id] ? 180 : 0}deg)`}}>
                                        <ArrowDown color={COLORS.darkestGray}/>
                                    </div>
                                    <p className={styles.itemName}>{item.name}</p>
                                </div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.imageWrapper}>
                                        <img src={item?.owner?.photo ? item?.owner?.photo : profileImage}
                                             className={styles.profileImg}
                                             alt={'user'}/>
                                    </div>
                                    {/*<div className={styles.dateWrapper}>*/}
                                    {/*    <p className={styles.firstDate}>{item.startDate}</p>*/}
                                    {/*    <p className={styles.secondDate}>{item.startDate}</p>*/}
                                    {/*</div>*/}
                                    <div className={styles.healthWrapper}>
                                        {getHealthStyle(item.health)}
                                    </div>
                                    {fromReport ? (
                                      <div className={styles.placeholderDiv} />
                                    ) : (
                                        <PopupComponent
                                            config={menuConfigListItem({
                                                onClick: (action) => {
                                                    onItemActionsPress(action, item)
                                                },
                                                terminology,
                                            })}
                                        />
                                    )}
                                </div>
                            </div>
                            {!collapsedItems[item.id] && (
                                <div className={styles.collapsibleContent}>
                                    {item?.perspectives?.map((child) => (
                                        <div key={child?.id} className={styles.childWrapper}>
                                            <div className={styles.childrenHalf}>
                                                <AllOutIcon/>
                                                <p className={styles.childName}>{child?.name}</p>
                                            </div>
                                            <div className={styles.childrenDescriptionHalf}>
                                                <p className={styles.childDescription}>{child?.description}</p>
                                                {!fromReport && (
                                                    <PopupComponent
                                                        config={menuConfigListItemChildren({
                                                            onClick: (action) => {
                                                                onItemActionsPress(action, child)
                                                            },
                                                            terminology,
                                                        })}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )
                })
            }
            <CustomModal closeModal={closePlanSettings} isVisible={showPlanSettings} title={`${terminology.plan} Settings`}
                         maxWidth={'sm'}>
                <DynamicForm
                    config={editScorecardFormConfig({
                        scorecard: selectedItem,
                        onCancel: closePlanSettings,
                        terminology,
                        options: ownerSelectOptions,
                    })}
                    validationSchema={editScorecardSchema}
                    handleOnSubmit={(values) => manageScorecard(values, false, selectedItem.id)}
                />
            </CustomModal>
            {selectedItem && <CustomModal
                isVisible={showEditModal}
                title={`${terminology.perspective} Details (${selectedItem?.name})`}
                closeModal={() => dispatch(setShowEditPerspectiveModal(false))}
            >
                <EditPerspectiveModalComponent
                    onClose={() => dispatch(setShowEditPerspectiveModal(false))}
                    handleOnSubmit={handleSubmitEditPerspective}
                    perspective={selectedItem}/>
            </CustomModal>}
            <CustomModal closeModal={() => dispatch(setModal({isVisible: false}))} isVisible={deletePlanModal.isVisible}
                         title={`Delete ${terminology.plan}`} maxWidth={'sm'}>
                <DeleteContent
                    onPressConfirm={() => deleteScorecard(selectedItem)}
                    onPressClose={() => dispatch(setModal({isVisible: false}))}
                    text={`You are about to delete ${selectedItem?.name}. Please confirm.`}
                />
            </CustomModal>
            <CustomModal title={`Delete ${terminology.perspective}`}
                         isVisible={isOpenDeletePerspectiveModal}
                         maxWidth={'sm'} closeModal={() => setIsOpenDeletePerspectiveModal(false)}
                         children={<DeleteContent text={`Are you sure you want to delete ${selectedItem?.name} ${terminology.perspective}?`}
                                                  onPressClose={() => setIsOpenDeletePerspectiveModal(false)}
                                                  onPressConfirm={onDeleteConfirm}/>}/>
        </div>
    );
};

export default GraphTableList;
