import React from 'react';
import ProgressBarHeader from "./ProgressBarHeader";
import ProgressBar from "./ProgressBar";
import ProgressBarFooter from "./ProgressBarFooter";
import styles from "./ProgressBarComponent.module.scss";
import {PROGRESS_SCHEDULE} from "../../../constants/progressSchedule";
import {ItemHealth} from "../../../constants/strings";
import ProgressBarDateRange from "./ProgressBarDateRange";

const ProgressBarComponent = ({
                                  flags,
                                  width,
                                  item,
                                  expectedProgress,
                                  showProgressBadge,
                                  notStarted,
                                  colors,
                                  hideValue,
                                  large,
                                  header,
                                  headerValue,
                                  headerIcon,
                                  onHeaderIconClick,
                                  showHeaderBadge,
                                  headerBadgeTitle,
                                  showHeaderProgress = true,
                                  showHeaderUnitProgress,
                                  footer,
                                  footerTitle,
                                  type,
                                  kpiMarks,
                                  dateRange,
                              }) => {
    const progressProperties = () => {
        if (notStarted) {
            return styles.notStarted;
        } else {
            if (item.health) {
                switch (true) {
                    case item.health === ItemHealth.LATE:
                        return {
                            barStyle: styles.lateBar,
                            badgeStyle: styles.lateBadge,
                            dotStyle: styles.lateDot,
                            title: 'Late',
                        };
                    case item.health === ItemHealth.AT_RISK:
                        return {
                            barStyle: styles.atRiskBar,
                            badgeStyle: styles.atRiskBadge,
                            dotStyle: styles.atRiskDot,
                            title: 'At Risk',
                        };
                    case item.health === ItemHealth.ON_TRACK:
                        return {
                            barStyle: styles.onTrackBar,
                            badgeStyle: styles.onTrackBadge,
                            dotStyle: styles.onTrackDot,
                            title: 'On Track',
                        };
                    case item.health === ItemHealth.ACHIEVED:
                      return {
                        barStyle: styles.achievedBar,
                        badgeStyle: styles.achievedBadge,
                        dotStyle: styles.achievedDot,
                        title: item.health === ItemHealth.ACHIEVED ? 'Achieved' : 'Exceeded',
                      };
                    case item.health === ItemHealth.EXCEEDED:
                        return {
                            barStyle: styles.exceededBar,
                            badgeStyle: styles.exceededBadge,
                            dotStyle: styles.exceededDot,
                            title: item.health === ItemHealth.ACHIEVED ? 'Achieved' : 'Exceeded',
                        };
                    default:
                        return {
                            barStyle: styles.onTrackBar,
                            badgeStyle: styles.onTrackBadge,
                            dotStyle: styles.onTrackDot,
                            title: 'On Track',
                        };
                }
            } else {
                switch (true) {
                    case item.actualProgress >= PROGRESS_SCHEDULE.LATE[0] && item.actualProgress <= PROGRESS_SCHEDULE.LATE[1]:
                        return {
                            barStyle: styles.lateBar,
                            badgeStyle: styles.lateBadge,
                            dotStyle: styles.lateDot,
                            title: 'Late',
                        };
                    case item.actualProgress >= PROGRESS_SCHEDULE.AT_RISK[0] && item.actualProgress <= PROGRESS_SCHEDULE.AT_RISK[1]:
                        return {
                            barStyle: styles.atRiskBar,
                            badgeStyle: styles.atRiskBadge,
                            dotStyle: styles.atRiskDot,
                            title: 'At Risk',
                        };
                    case item.actualProgress >= PROGRESS_SCHEDULE.ON_TRACK[0] && item.actualProgress <= PROGRESS_SCHEDULE.ON_TRACK[1]:
                        return {
                            barStyle: styles.onTrackBar,
                            badgeStyle: styles.onTrackBadge,
                            dotStyle: styles.onTrackDot,
                            title: 'On Track',
                        };
                    case item.actualProgress >= PROGRESS_SCHEDULE.ACHIEVED[0] && item.actualProgress <= PROGRESS_SCHEDULE.ACHIEVED[1]:
                        return {
                            barStyle: styles.achievedBar,
                            badgeStyle: styles.achievedBadge,
                            dotStyle: styles.achievedDot,
                            title: 'Achieved',
                        };
                    case item.actualProgress >= PROGRESS_SCHEDULE.EXCEEDED[0] && item.actualProgress <= PROGRESS_SCHEDULE.EXCEEDED[1]:
                        return {
                            barStyle: styles.exceededBar,
                            badgeStyle: styles.exceededBadge,
                            dotStyle: styles.exceededDot,
                            title: 'Exceeded',
                        };
                    default:
                        return {
                            barStyle: styles.onTrackBar,
                            badgeStyle: styles.onTrackBadge,
                            dotStyle: styles.onTrackDot,
                            title: 'On Track',
                        };
                }
            }

        }
    };

    return (
        <div>
            {header && headerValue !== null && (
                <ProgressBarHeader
                    value={headerValue}
                    icon={headerIcon}
                    showHeaderBadge={showHeaderBadge}
                    badgeTitle={progressProperties()?.title || headerBadgeTitle}
                    progressProperties={progressProperties}
                    onClick={onHeaderIconClick}
                    item={item}
                    showHeaderProgress={showHeaderProgress}
                    showHeaderUnitProgress={showHeaderUnitProgress}
                    type={type}
                />
            )}
            <ProgressBar
                flags={flags}
                width={width}
                progress={item.actualProgress || 0}
                showProgressBadge={showProgressBadge}
                badgeTitle={progressProperties()?.title}
                notStarted={notStarted}
                colors={colors}
                hideValue={hideValue}
                large={large}
                progressProperties={progressProperties}
                expectedProgress={expectedProgress}
                type={type}
                kpiMarks={kpiMarks}
            />
            {footer && (
                <ProgressBarFooter
                    progress={item.actualProgress}
                    title={progressProperties()?.title || footerTitle}
                    progressProperties={progressProperties}
                />
            )}
            {dateRange && (
                <ProgressBarDateRange item={item} />
            )}
        </div>
    );
};

export default ProgressBarComponent;
