export const METRICS = '[Metrics]';
export const GET_METRIC_SAGA = `${METRICS} GET_METRIC_SAGA`;
export const SET_METRIC = `${METRICS} SET_METRIC`;
export const SET_ALL_METRICS = `${METRICS} SET_ALL_METRICS`;
export const DELETE_METRIC_SAGA = `${METRICS} DELETE_METRIC_SAGA`;
export const DELETE_SINGLE_METRIC_FROM_LIST_SAGA = `${METRICS} DELETE_SINGLE_METRIC_FROM_LIST_SAGA`;
export const SET_IS_DELETE_MODAL_VISIBLE = `${METRICS} SET_IS_DELETE_MODAL_VISIBLE`;
export const SET_IS_CREATE_MODAL_VISIBLE = `${METRICS} SET_IS_CREATE_MODAL_VISIBLE`;
export const CREATE_METRIC_SAGA = `${METRICS} CREATE_METRIC_SAGA`;
export const CREATE_KPI_AND_ADD_TO_METRIC_SAGA = `${METRICS} CREATE_KPI_AND_ADD_TO_METRIC_SAGA`;
export const UPDATE_METRIC_AND_ADD_TO_KPI_SAGA = `${METRICS} UPDATE_METRIC_AND_ADD_TO_KPI_SAGA`;
export const SET_SHOW_EDIT_MODAL_FROM_SIDEBAR = `${METRICS} SET_SHOW_EDIT_MODAL_FROM_SIDEBAR`;
export const SET_SELECTED_METRIC_FOR_EDIT = `${METRICS} SET_SELECTED_METRIC_FOR_EDIT`;
export const CONNECT_GOOGLE_SHEETS_SAGA = `${METRICS} CONNECT_GOOGLE_SHEETS_SAGA`;
export const CONNECT_JIRA_SAGA = `${METRICS} CONNECT_JIRA_SAGA`;
export const CONNECT_MICROSOFT_EXCEL_SAGA = `${METRICS} CONNECT_MICROSOFT_EXCEL_SAGA`;
