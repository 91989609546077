import React from 'react';
import styles from './DefaultButton.module.scss';

const DefaultButton = ({
  title,
  onClick,
  disabled,
  backgroundColor,
  fontColor,
  borderRadius,
  fontSize = 16,
  border,
  width,
  height,
  padding,
  buttonStyles,
  leftIcon,
  rightIcon,
  inverted,
  secondary,
  outlined,
  type,
                         name,
                         fontWeight,
                         tabindex
}) => {
  return (
    <button
      tabIndex={tabindex}
      onClick={onClick}
      className={`
        ${styles.container}
        ${secondary ? styles.secondary : null}
        ${disabled ? styles.disabled : null}
        ${inverted ? styles.inverted : null}
        ${outlined ? styles.outlined : null}
      `}
      style={{
        ...{
          backgroundColor: backgroundColor,
          color: fontColor,
          borderRadius: borderRadius,
          padding: padding,
          fontSize: fontSize,
          border: border,
          width: width,
          height: height,
        },
        ...buttonStyles,
      }}
      disabled={disabled}
      type={type}
      name={name}
    >
      {leftIcon && (
        <div className={`${styles.icon} ${styles.leftIcon}`}>
          {leftIcon}
        </div>
      )}
      <h4 className={styles.title} style={{fontSize, fontWeight}}>
        {title}
      </h4>
      {rightIcon && (
        <div className={`${styles.icon} ${styles.rightIcon}`}>
          {rightIcon}
        </div>
      )}
    </button>
  );
};
export default DefaultButton;
