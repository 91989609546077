import {KPI} from "../kpis/actionTypes";

export const STRATEGY_PLAN = '[Strategy_Plan]';

export const GET_STRATEGY_PLAN_SAGA = `${STRATEGY_PLAN} GET_STRATEGY_PLAN_SAGA`;
export const SET_STRATEGY_PLAN = `${STRATEGY_PLAN} SET_STRATEGY_PLAN`;
export const SET_STRATEGY_PLAN_WITH_PERSPECTIVES = `${STRATEGY_PLAN} SET_STRATEGY_PLAN_WITH_PERSPECTIVES`;
export const ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA = `${STRATEGY_PLAN} ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA`;
export const ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA = `${STRATEGY_PLAN} ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA`;
export const ASSIGN_TO_GROUP_SAGA = `${STRATEGY_PLAN} ASSIGN_TO_GROUP_SAGA`;
export const DELETE_PERSPECTIVE = `${STRATEGY_PLAN} DELETE_PERSPECTIVE`;
export const DELETE_GOAL = `${STRATEGY_PLAN} DELETE_GOAL`;
export const DELETE_KPI = `${STRATEGY_PLAN} DELETE_KPI`;
export const DELETE_ACTION = `${STRATEGY_PLAN} DELETE_ACTION`;
export const SET_IS_OPEN_EDIT_GOAL_MODAL = `${STRATEGY_PLAN} SET_IS_OPEN_EDIT_GOAL_MODAL`;
export const UPDATE_GOAL = `${STRATEGY_PLAN} UPDATE_GOAL`;
export const DELETE_PROJECT = `${STRATEGY_PLAN} DELETE_PROJECT`;
export const RIGHT_SIDEBAR_SAGA = `${STRATEGY_PLAN} RIGHT_SIDEBAR_SAGA`;
export const GET_RIGHT_SIDEBAR_ITEM_SAGA = `${STRATEGY_PLAN} GET_RIGHT_SIDEBAR_ITEM_SAGA`;
export const SET_ITEM_ROW = `${STRATEGY_PLAN} SET_ITEM_ROW`;
export const RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA = `${STRATEGY_PLAN} RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA`;
export const RIGHT_SIDEBAR_SUBMIT_FORM_SAGA = `${STRATEGY_PLAN} RIGHT_SIDEBAR_SUBMIT_FORM_SAGA`;
export const ADD_ACTION_FROM_SIDEBAR_SAGA = `${STRATEGY_PLAN} ADD_ACTION_FROM_SIDEBAR_SAGA`;
export const BACK_FROM_ADD_TRACKING_SAGA = `${STRATEGY_PLAN} BACK_FROM_ADD_TRACKING_SAGA`;
export const ADD_TRACKING_FROM_SIDEBAR_SAGA = `${STRATEGY_PLAN} ADD_TRACKING_FROM_SIDEBAR_SAGA`;

export const ADD_TRACKING_AND_BACK_SAGA = `${KPI} ADD_TRACKING_AND_BACK_SAGA`;
export const ADD_ACTION_SAGA = `${STRATEGY_PLAN} ADD_ACTION_SAGA`;
export const RIGHT_SIDEBAR_BACK = `${STRATEGY_PLAN} RIGHT_SIDEBAR_BACK`;
export const RIGHT_SIDEBAR_DELETE_ITEM_SAGA = `${STRATEGY_PLAN} RIGHT_SIDEBAR_DELETE_ITEM_SAGA`;
export const CLOSE_SIDEBAR_SAGA = `${STRATEGY_PLAN} CLOSE_SIDEBAR_SAGA`;
export const SET_SHOW_EDIT_PARENT = `${STRATEGY_PLAN} SET_SHOW_EDIT_PARENT`;
export const SET_OPTIONS_FOR_EDIT_PARENT = `${STRATEGY_PLAN} SET_OPTIONS_FOR_EDIT_PARENT`;
export const GET_EDIT_PARENT_DATA_SAGA = `${STRATEGY_PLAN} GET_EDIT_PARENT_DATA_SAGA`;
export const MOVE_ITEM_FROM_EDIT_PARENT_SAGA = `${STRATEGY_PLAN} MOVE_ITEM_FROM_EDIT_PARENT_SAGA`;
export const DELETE_PERSPECTIVE_FROM_WIDGET = `${STRATEGY_PLAN} DELETE_PERSPECTIVE_FROM_WIDGET`;
export const REORDER_PERSPECTIVE_SAGA = `${STRATEGY_PLAN} REORDER_PERSPECTIVE_SAGA`;
export const EXPAND_PERSPECTIVE_SAGA = `${STRATEGY_PLAN} EXPAND_PERSPECTIVE_SAGA`;
export const REORDER_PERSPECTIVE_ITEM = `${STRATEGY_PLAN} REORDER_PERSPECTIVE_ITEM`;
export const REORDER_ROW_ITEM = `${STRATEGY_PLAN} REORDER_ROW_ITEM`;
export const SAVE_REORDER_ROW_ITEM = `${STRATEGY_PLAN} SAVE_REORDER_ROW_ITEM`;
export const ON_REORDER_SAVE_PERSPECTIVE_SAGA = `${STRATEGY_PLAN} ON_REORDER_SAVE_PERSPECTIVE_SAGA`;
export const SET_GOALS_ON_HOME_PAGE = `${STRATEGY_PLAN} SET_GOALS_ON_HOME_PAGE`;
export const SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE = `${STRATEGY_PLAN} SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE`;
export const SET_ALL_ITEMS_ON_HOME_PAGE = `${STRATEGY_PLAN} SET_ALL_ITEMS_ON_HOME_PAGE`;
export const ITEM_SORT_ACTIVE = `${STRATEGY_PLAN} ITEM_SORT_ACTIVE`;
