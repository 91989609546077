import { fork } from 'redux-saga/effects';
import HelperSagas from './helper/sagas';
import AuthSagas from './auth/sagas';
import ScorecardSagas from './scorecard/sagas';
import StrategyPlanSagas from './strategyPlan/sagas';
import perspectivesSagas from './perspectives/sagas';
import goalsSagas from './goals/sagas';
import kpisSagas from './kpis/sagas';
import dashboardsSagas from './dashboards/sagas';
import perspectiveActionsSagas from './perspectiveActions/sagas';
import alignmentSagas from './alignment/sagas';
import metricsSagas from './metrics/sagas';
import hubSagas from './hub/sagas';
import actionSagas from './action/sagas';
import checklistSagas from './checklist/sagas';
import reportsSagas from './reports/sagas';
import timeHorizonsSagas from './timeHorizons/sagas';
import billingSagas from './billing/sagas';

export default function* rootSaga() {
  yield fork(AuthSagas);
  yield fork(ScorecardSagas);
  yield fork(HelperSagas);
  yield fork(StrategyPlanSagas);
  yield fork(perspectivesSagas);
  yield fork(goalsSagas);
  yield fork(dashboardsSagas);
  yield fork(kpisSagas);
  yield fork(perspectiveActionsSagas);
  yield fork(alignmentSagas);
  yield fork(metricsSagas);
  yield fork(hubSagas);
  yield fork(actionSagas);
  yield fork(checklistSagas);
  yield fork(reportsSagas);
  yield fork(timeHorizonsSagas);
  yield fork(billingSagas);
}
