import produce from 'immer';
import {SET_TIME_HORIZONS} from './actionTypes';

export const initialState = {
    timeHorizons: {
        data: null,
        page: 1,
        perPage: 5,
    },
};

/* eslint-disable default-case */
const timeHorizonsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_TIME_HORIZONS:
                draft.timeHorizons = action.data;
                break;
        }
    });

export default timeHorizonsReducer;
