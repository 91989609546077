import React, {useCallback, useEffect} from 'react';
import styles from './ItemUpdatesComponent.module.scss';
import ItemUpdatesHeader from "./ItemUpdatesHeader";
import NoUpdatesPlaceholder from "./NoUpdatesPlaceholder";
import ItemUpdatesComponentList from "./ItemUpdatesComponentList";
import {useDispatch, useSelector} from "react-redux";
import {getUpdatesSaga, postUpdateSaga, setUpdates} from "../../store/helper/actions";
import {rightModalSelector} from "../../store/helper/selectors";
import {PAGES} from "../../constants/pages";
import {getHubUpdatesSaga} from "../../store/hub/actions";

const ItemUpdatesComponent = ({data, updates, from}) => {
  const dispatch = useDispatch();

  const rightModal = useSelector(rightModalSelector());

  const getUpdates = useCallback(() => {
    if (from === PAGES.HUB) {
      dispatch(getHubUpdatesSaga({entity: data.type, id: data.id}));
    }

    if (rightModal.page === PAGES.HUB_ACTIVITY_TAB) {
      dispatch(getHubUpdatesSaga({entity: rightModal.entity, id: rightModal.entityId}));
    }

    dispatch(getUpdatesSaga({
      page: rightModal.page,
      scorecardId: rightModal.scorecardId,
      entity: data?.type,
      id: data?.id,
      goalId: rightModal.goalId,
    }));
  }, [data.id, data.type, dispatch, from, rightModal.entity, rightModal.entityId, rightModal.goalId, rightModal.page, rightModal.scorecardId]);

  const handleUpdate = async (type, values) => {
    dispatch(postUpdateSaga({
      values,
      data,
      type,
      from,
      rightModal,
    }));
  };

  useEffect(() => {
    getUpdates();

    return () => {
      dispatch(setUpdates(null));
    }
  }, [dispatch, getUpdates]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ItemUpdatesHeader data={data} handleUpdate={handleUpdate} />
      </div>
      <div className={styles.listContainer}>
        {updates && updates?.length > 0 && (
          <ItemUpdatesComponentList data={updates} type={data?.type} />
        )}
        {updates && updates?.length === 0 && (
          <NoUpdatesPlaceholder />
        )}
      </div>
    </div>
  );
};

export default ItemUpdatesComponent;
