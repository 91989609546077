import * as Yup from 'yup';

export const editUserSchema = Yup.object().shape({
    fullName: Yup.string().required('This field is required'),
});

export const editOrganizationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
});

export const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('This field is required'),
    newPassword: Yup.string().required('This field is required'),
    confirmPassword: Yup.string()
        .required('This field is required')
        .oneOf([Yup.ref('newPassword'), null], '*Passwords must match')
});
