import React, {useState} from 'react';
import styles from './MetricDetails.module.scss';
import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrowDown.svg';
import {COLORS} from "../../../../constants/colors";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import {formatUnitAndValue} from "../../../../utils/perspective";
import {ArrowCircleDown, ArrowCircleUp} from "@mui/icons-material";
import {FORMAT_TIME_INTERVAL} from "../../../../utils/metrics";

function MetricDetails({item, onClickMetric}) {
  const [show, setShow] = useState(true);

  const onBoxShow = () => {
    setShow(!show);
  }

  return (
    <div className={styles.wrapper} style={{height: show ? 'auto' : '70px'}}>
      <div className={styles.titleWrapper}>
        <div className={styles.leftTitleWrapper}>
          <StackedBarChartIcon style={{color: COLORS.darkestGray}} />
          <p className={styles.titleText}>Metric Detail</p>
        </div>
        {item?.metrics.length > 0 && <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn}
                 style={{transform: show ? 'rotate(180deg)' : 'rotate(0deg)'}}>
          <ArrowDown/>
        </button>}
      </div>

      {show &&
        <div className={styles.metricWrapper}>
          {item?.metrics.map((metric, index) => {
            return (
              <div className={item.metrics.length - 1 !== index && styles.metricItemWrapper} key={metric.id}>
                <div className={styles.singleMetric}><p onClick={() => onClickMetric(metric)} className={styles.metricName}> {metric.name}</p></div>
                <p className={styles.fileValueName}>{formatUnitAndValue(metric.unit, metric.value || 0)}</p>
                <div className={styles.fileContentRowSecond}>
                  {metric.increased ? (
                    <ArrowCircleUp color={'success'} />
                  ) : (
                    <ArrowCircleDown color={'warning'} />
                  )}
                  <p className={styles.fileValueSubName}>{(metric.percentage ? Math.abs(metric.percentage) : '0') + '% vs last ' + FORMAT_TIME_INTERVAL(metric.timeInterval)}</p>
                </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
}


export default MetricDetails;
