import React, {memo} from 'react';
import styles from './TooltipComponent.module.scss';
import {MenuItem} from "@mui/material";
import {getInitials} from "../../../utils/format";

const TooltipMembersContent = ({title= 'Members', items, onItemClick}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={styles.contentTitleContainer}>
        <h2 className={styles.contentTitle}>
          {title}
        </h2>
      </div>
      <div className={styles.contentContainer}>
        {items.map((item) => {
          return (
            <MenuItem onClick={(e) => onItemClick(e, item)} key={item.id}>
              <div className={styles.menuItemWrapper}>
                  {item.photo ? <div className={styles.iconContainer}>
                    <img className={styles.imgContainer} src={item.photo}/>
                    </div> :
                    <div className={styles.imageContainer}>
                      <h4 className={styles.initials}>{getInitials(item.name)}</h4>
                    </div>}

                <div className={styles.itemContainer}>
                  <h4 className={styles.itemTitle} >{item.name}</h4>
                  <p className={styles.itemSubtitle} >{item.email}</p>
                </div>
              </div>
            </MenuItem>
          );
        })}
      </div>
    </div>
  )
};

export default memo(TooltipMembersContent);
