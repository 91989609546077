import React, {Fragment, memo} from 'react';
import {Menu, MenuItem, styled} from '@mui/material';
import styles from './PopupComponent.module.scss';

const PopupComponent = ({config, returnItem, toggle = (value) => {}, transitionDuration = 'auto', disabled}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    toggle(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    toggle(false);
  };

  const onItemClick = (e, item) => {
    e.stopPropagation();
    item.onClick(returnItem || item);
    if (item?.keepOpen) return
    handleClose(e);
  }

  const buttonContent = () => {
    if (config.button?.title) {
      return (
        <h4 className={styles.buttonTitle}>
          {config.button.title}
        </h4>
      );
    }

    if (config.button?.icon) {
      return (
        <div className={styles.centerItem}>
          {config.button.icon}
        </div>
      );
    }

    return (
      <Fragment>
        {config.button}
      </Fragment>
    );
  };

  const popupContent = () => {
    if (config.content?.menu) {
      return (
        <div className={styles.contentContainer}>
          {config.content.menu.items.map((item) => {
            return (
              <MenuItem disabled={item.disabled} onClick={(e) => onItemClick(e, item)} key={item.id}>
                <div>
                  <div className={styles.itemContainer}>
                    {item.icon && <div className={styles.iconContainer}>{item.icon}</div>}
                    <h4 className={styles.itemTitle} style={{color: item.titleColor}}>{item.title}</h4>
                  </div>
                  <p className={styles.itemSubtitle} style={{color: item.titleColor}}>{item.subtitle}</p>
                </div>
              </MenuItem>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        {config.content?.component}
      </div>
    );
  };

  const StyledMenu = styled(Menu)`
    & .MuiPaper-root {
      border-radius: 8px;
    }

    & .MuiList-root {
      margin: 0;
      padding: 0;
    }
  `;

  return (
    <div className={styles.container} style={{...config?.container?.styles || null}}>
      <button
        onClick={handleClick}
        className={`${styles.button} ${config.button?.title && styles.buttonWithTitle}`}
        style={{...config?.button?.styles || null}}
        disabled={disabled}
      >
        {buttonContent()}
      </button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        transitionDuration={transitionDuration}
      >
        {config.content?.title && (
          <div className={styles.contentTitleContainer}>
            <h2 className={styles.contentTitle}>
              {config.content.title}
            </h2>
          </div>
        )}
        {popupContent()}
      </StyledMenu>
    </div>
  );
};

export default memo(PopupComponent);
