import React, {useCallback, useEffect, useState} from 'react';
import styles from './DetailsTab.module.scss';
import {ReactComponent as AddIcon} from '../../../../assets/icons/plusBlue.svg';
import DetailsComponent from "../../DetailsComponent";
import {DETAILS_TYPE, TRACKING} from "../../../../constants/strings";
import ContributesToComponent from "../../../Shared/ContributesToComponent";
import {useDispatch, useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import {COLORS} from "../../../../constants/colors";
import {addTrackingFromSidebarSaga} from "../../../../store/strategyPlan/actions";
import ChartWithProgressComponent from "../../../Shared/ChartWithProgressComponent/ChartWithProgressComponent";
import {setParentDataForContributesToComponent} from "../../../../utils/setDataForContributesToComponent";
import {Menu, styled} from "@mui/material";
import UpdateProgressForm from "../../../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {strategyPlanSelector} from "../../../../store/strategyPlan/selectors";
import {updateKpiSaga} from "../../../../store/kpis/actions";
import CommentSection from "../../../CommentSection";
import MetricDetails from "../MetricDetails/MetricDetails";
import {rightModalOpen} from "../../../../store/helper/actions";
import MetricsSidebarComponent
    from "../../../../containers/MetricsPage/MetricsSidebarComponent/MetricsSidebarComponent";
import {ReactComponent as ExcelIcon} from "../../../../assets/icons/external-sources/microsoft-excel-icon.svg";
import {ReactComponent as GoogleSheetIcon} from "../../../../assets/icons/external-sources/google-sheets-logo-icon.svg";
import {ReactComponent as Profile} from "../../../../assets/icons/profile.svg";
import TooltipComponent from "../../../Shared/Tooltip/TooltipComponent";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const DetailsTab = ({data, rightModal, page}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [sliderValue, setSliderValue] = useState(null);
    const strategyPlan = useSelector(strategyPlanSelector());
    const itemWithParent = useSelector(itemAndParentForActionSelector());

    const detailList = [
        {id: 1, type: DETAILS_TYPE.OWNER, title: 'Owner', name: data.owner.name || data.owner.email, photo: data.owner.photo},
        {id: 2, type: DETAILS_TYPE.OWNERS, title: 'Sharing With', users: data.owners},
        {id: 3, type: DETAILS_TYPE.TIME_FRAME, title: 'Time Frame', startDate: data.startDate, endDate: data.endDate, alignment: 'start'},
        {id: 4, type: DETAILS_TYPE.DESCRIPTION, title: 'Description', description: data.description, alignment: 'start'},
        {id: 5, type: DETAILS_TYPE.UPDATE_SCHEDULE, title: 'Update Schedule', date: data.endDate},
    ];
    const onAddTracking = () => {
        dispatch(addTrackingFromSidebarSaga(data));
    }

    const handleClick = (e) => {
        if (!anchorEl) {
            setAnchorEl(anchorEl ? null : e.currentTarget);
        }
    };

    const handleUpdateProgress = (values) => {
        dispatch(updateKpiSaga({
            data: values,
            kpiId: data?.id,
            scorecardId: strategyPlan?.id,
            goalId: rightModal.goalId,
            page,
            fromDetailsTab: true,
            entity: rightModal.entity,
            entityId: rightModal.entityId,
        }));
    };

    const onUpdateProgress = (values) => {
        if(values.actualValue) {
            setSliderValue(Number(values.actualValue));
        }
        setAnchorEl(null);
        handleUpdateProgress(values);
    };

    const cancelUpdate = () => {
        setAnchorEl(null);
        setSliderValueFn();
    };

    const onProgressChange = (value) => {
        setSliderValue(Number(value));
    };

    const setSliderValueFn = useCallback(() => {
        setSliderValue(data?.inverted ? data?.min + data?.max - data?.actualValue : data?.actualValue);
    }, [data.actualValue]);

    useEffect(() => {
        setSliderValueFn();
    }, [setSliderValueFn]);

    const onHandleMetric = (metric) => {
        dispatch(
            rightModalOpen({
                isVisible: true,
                page: '',
                component: (
                    <MetricsSidebarComponent item={metric} />
                ),
            })
        );
    };

    const getIcon = () => {
        let component;
        let title;
        switch (data?.tracking) {
            case TRACKING.MICROSOFT_EXCEL:
                component = (
                    <ExcelIcon className={styles.userIcon}/>
                );
                title = 'Microsoft Excel tracking';
                break;
            case TRACKING.GOOGLE_SHEETS:
                component = (
                    <GoogleSheetIcon className={styles.userIcon}/>
                );
                title = 'Google Sheet tracking';
                break;
            default:
                component = (
                    <Profile className={styles.userIcon}/>
                );
                title = 'Manually tracking';
        }

        return (
            <TooltipComponent title={title} placement={'bottom'}>
                {component}
            </TooltipComponent>
        );
    }

  return (
    <div className={styles.container}>
        <div className={styles.detailsWithoutComments}>
            {
                data.trackingType === null && (
                    <div className={styles.addActionWrapper}>
                        <p>This KPI needs Tracking</p>
                        <DefaultButton
                            onClick={onAddTracking}
                            title={'Add Tracking'}
                            outlined={true}
                            leftIcon={<AddIcon color={COLORS.blue}/>}
                            padding={'0 16px 0 40px'}
                            height={34}
                            type={'button'}
                        />
                    </div>
                )
            }
            {data.trackingType && (
                <div className={styles.updateProgressButtonWrapper}>
                    {getIcon()}
                    <DefaultButton title={'Update progress'}
                                   inverted border={'1px solid blue'}
                                   onClick={(e) => handleClick(e)}
                                   disabled={data?.tracking === TRACKING.MICROSOFT_EXCEL || data?.tracking === TRACKING.GOOGLE_SHEETS}
                    />
                    <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                        <UpdateProgressForm
                            min={data?.min}
                            max={data?.max}
                            progress={data?.inverted ? data?.min + data?.max - sliderValue : sliderValue}
                            status={data?.status}
                            expected={data?.expectedValue}
                            expectedUnit={data.unit}
                            handleOnSubmit={onUpdateProgress}
                            cancelUpdate={cancelUpdate}
                            onProgressChange={onProgressChange}
                            item={data}
                        />
                    </StyledMenu>
                </div>
            )}
            {data?.metrics.length > 0  && (
                <MetricDetails onClickMetric={onHandleMetric} item={data}/>
            )}
            {
                data.trackingType !== null && (
                    <div className={styles.trackingWrapper}>
                        <ChartWithProgressComponent
                            page={page}
                            showHeader
                            fromRightSidebar={true}
                            data={data}
                            showSlider={true}
                            // showRange
                            expectedProgress={data?.expectedProgress ? data?.expectedProgress : 0}/>
                    </div>
                )}
            <div className={styles.infoWrapper}>
                <DetailsComponent data={data} updateDate={data.nextUpdateDate} isBtnVisible={data.needsUpdate} items={detailList}/>
                <ContributesToComponent
                    item={data}
                    parent={setParentDataForContributesToComponent(itemWithParent.parent, itemWithParent?.parent?.strategyPlanName )}
                    showForms={false}
                    canEditParent={false}
                />
            </div>
        </div>
        <div className={styles.commentSectionWrapper}>
            <CommentSection item={itemWithParent.item}/>
        </div>
    </div>
  );
};

export default DetailsTab;
