import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";
import axios from 'axios';

const ROUTES = {
  GET_RECENTLY_VIEWED_REPORTS: '/reports/recently-viewed',
  GET_REPORTS_GRID: '/reports/grid',
  DELETE_REPORT: '/reports/:reportId',
  CREATE_REPORT: '/reports',
  EDIT_REPORT: '/reports/:reportId',
  GET_REPORT: '/reports/:reportId',
  DUPLICATE_REPORT: '/reports/duplicate/:reportId',
  SHARE_REPORT: 'reports/share/:reportId',
  CREATE_REPORT_ELEMENT: 'reports/:reportId/add-element',
  EDIT_REPORT_ELEMENT: 'reports/:reportId/edit-element/:elementId',
  DELETE_REPORT_ELEMENT: 'reports/delete-element/:elementId',
  UPDATE_REPORT_ELEMENTS: 'reports/:reportId/update-elements',
  GET_REPORT_FOLDERS: 'reports/folders',
  GET_REPORT_SNAPSHOTS: 'reports/snapshots',
  CREATE_REPORT_SNAPSHOT: 'reports/snapshots',
  GET_SNAPSHOT: '/reports/load-snapshot',
  EXPORT_REPORT_PDF: 'reports/export-pdf/:reportId',
  EXPORT_SNAPSHOT_PDF: 'reports/export-snapshot-pdf/:folderName/:snapshotName',
};

class ReportsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getRecentlyViewedReports = () => {
    return this.httpService.request({
      url: ROUTES.GET_RECENTLY_VIEWED_REPORTS,
      method: HTTP_METHODS.GET,
    });
  };

  getReportsGrid = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_REPORTS_GRID,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteReport = (reportId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.DELETE,
    });
  };

  createReport = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_REPORT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  editReport = (data, reportId) => {
    return this.httpService.request({
      url: ROUTES.EDIT_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getReport = (reportId) => {
    return this.httpService.request({
      url: ROUTES.GET_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.GET,
    });
  };

  duplicateReport = (reportId, data) => {
    return this.httpService.request({
      url: ROUTES.DUPLICATE_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  shareReport = (data, reportId) => {
    return this.httpService.request({
      url: ROUTES.SHARE_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  createReportElement = (data, reportId) => {
    return this.httpService.request({
      url: ROUTES.CREATE_REPORT_ELEMENT.replace(':reportId', reportId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  editReportElement = (data, reportId, elementId) => {
    return this.httpService.request({
      url: ROUTES.EDIT_REPORT_ELEMENT.replace(':reportId', reportId).replace(':elementId', elementId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteReportElement = (elementId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_REPORT_ELEMENT.replace(':elementId', elementId),
      method: HTTP_METHODS.DELETE,
    });
  };

  updateReportElements = (data, reportId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_REPORT_ELEMENTS.replace(':reportId', reportId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getReportFolders = () => {
    return this.httpService.request({
      url: ROUTES.GET_REPORT_FOLDERS,
      method: HTTP_METHODS.GET,
    });
  };

  getReportSnapshots = () => {
    return this.httpService.request({
      url: ROUTES.GET_REPORT_SNAPSHOTS,
      method: HTTP_METHODS.GET,
    });
  };

  createSnapshot = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_REPORT_SNAPSHOT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getSnapshot = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_SNAPSHOT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getReportWithToken = async (reportId, token) => {
    return await axios.request({
      url: process.env.REACT_APP_EXPORT_PDF_API_BASE_URL + ROUTES.GET_REPORT.replace(':reportId', reportId),
      method: HTTP_METHODS.GET,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getSnapshotWithToken = async (data, token) => {
    return await axios.request({
      url: process.env.REACT_APP_EXPORT_PDF_API_BASE_URL + ROUTES.GET_SNAPSHOT,
      method: HTTP_METHODS.POST,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  exportReportToPDF = (reportId) => {
    return this.httpService.request({
      url: ROUTES.EXPORT_REPORT_PDF.replace(':reportId', reportId),
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    });
  };

  exportSnapshotToPDF = (folderName, snapshotName) => {
    return this.httpService.request({
      url: ROUTES.EXPORT_SNAPSHOT_PDF
        .replace(':folderName', folderName)
        .replace(':snapshotName', snapshotName),
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    });
  };
}

const reportsService = new ReportsService(httpService);

export default reportsService;
