import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConnectedRouter} from "connected-react-router";
import history from "./utils/history";
import {Provider} from "react-redux";
import configureStore from "./store";
import '../src/styles/main.scss';
import ErrorHandler from './../src/services/ErrorHandler';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Import the required stylesheets
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const initialState = {};
const store = configureStore(initialState, history);
const theme = createTheme({
    typography: {
        fontFamily: 'Figtree, sans-serif',
    },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ErrorHandler>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </ConnectedRouter>
        </ErrorHandler>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
