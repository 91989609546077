import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAlignment = (state) => state.alignment || initialState;

const allGoalsAlignmentNodesSelector = () =>
  createSelector(selectAlignment, (state) => state.allGoalsAlignmentNodes);

const alignedGoalsAlignmentNodesSelector = () =>
  createSelector(selectAlignment, (state) => state.alignedGoalsAlignmentNodes);

const unalignedGoalsAlignmentNodesSelector = () =>
  createSelector(selectAlignment, (state) => state.unalignedGoalsAlignmentNodes);

const goalsAlignmentEdgesSelector = () =>
  createSelector(selectAlignment, (state) => state.goalsAlignmentEdges);

const goalsAlignmentFiltersSelector = () =>
  createSelector(selectAlignment, (state) => state.goalsAlignmentFilters);
const goalAlignmentModalSelector = () =>
  createSelector(selectAlignment, (state) => state.goalAlignmentModal);

export {
  allGoalsAlignmentNodesSelector,
  alignedGoalsAlignmentNodesSelector,
  unalignedGoalsAlignmentNodesSelector,
  goalsAlignmentEdgesSelector,
  goalsAlignmentFiltersSelector,
  goalAlignmentModalSelector
};
