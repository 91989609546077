import {
  CREATE_TIME_HORIZON_SAGA, DELETE_TIME_HORIZON_SAGA,
  GET_TIME_HORIZONS_SAGA, SET_TIME_HORIZONS,
} from './actionTypes';

export function getTimeHorizonsSaga(payload) {
  return {
    type: GET_TIME_HORIZONS_SAGA,
    payload
  };
}

export function setTimeHorizons(data) {
  return {
    type: SET_TIME_HORIZONS,
    data
  };
}

export function createTimeHorizon(payload) {
  return {
    type: CREATE_TIME_HORIZON_SAGA,
    payload
  };
}

export function deleteTimeHorizon(horizonId) {
  return {
    type: DELETE_TIME_HORIZON_SAGA,
    horizonId
  };
}

