import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_USERS_GRID: '/users/grid',
  DELETE_USER: '/users/:userId',
  REACTIVATE_USER: '/users/restore/:userId',
  GET_LEADERS: '/users/leaders',
  RESEND_USER_INVITE: '/users/resend-invite',
  GET_USERS_GRID_BY_GROUP: '/users/groups/grid',
  GRID_FILTERS_DATA: '/users/grid-filters-data',
  REMOVE_FROM_GROUP: '/users/remove-from-group/:userId/:groupId',
  FILTER_USERS: '/users/filter/:entity/:entityId/:term',
  INVITE_USERS: '/users/invite-new-members',
  INVITE_SINGLE_USER: '/users/invite-member',
  GET_USER: '/users/:id',
  UPDATE_USER: '/users/:id',
  GET_OWNERS_WITH_KPIS: '/users/owners-with-kpis',
  CHANGE_PASSWORD: '/users/change-password',
  ACCEPT_INVITE: '/users/accept-invite/:invitationToken',
  GET_USERS_BY_SCORECARD: '/users/by-scorecard/:scorecardId',
  GET_USERS_BY_GROUP: '/users/by-group/:groupId',
};

class UsersService {
  constructor(httpService) {
    this.httpService = httpService;
  }
  getUsersGrid = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_USERS_GRID,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getOwnersWithKpis = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_OWNERS_WITH_KPIS,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getLeaders = () => {
    return this.httpService.request({
      url: ROUTES.GET_LEADERS,
      method: HTTP_METHODS.GET,
    });
  };

  getUsersByScorecard = (scorecardId) => {
    return this.httpService.request({
      url: ROUTES.GET_USERS_BY_SCORECARD.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.GET,
    });
  };

  acceptInvitation = (invitationToken) => {
    return this.httpService.request({
      url: ROUTES.ACCEPT_INVITE.replace(':invitationToken', invitationToken),
      method: HTTP_METHODS.GET,
    });
  };

  resendUserInvite = (data) => {
    return this.httpService.request({
      url: ROUTES.RESEND_USER_INVITE,
      method: HTTP_METHODS.POST,
      data
    });
  };

  deleteUser = (userId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_USER.replace(':userId', userId),
      method: HTTP_METHODS.DELETE
    });
  };

  reactivateUser = (userId, data) => {
    return this.httpService.request({
      url: ROUTES.REACTIVATE_USER.replace(':userId', userId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getUsersGridByGroup = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_USERS_GRID_BY_GROUP,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getGridFiltersData = () => {
    return this.httpService.request({
      url: ROUTES.GRID_FILTERS_DATA,
      method: HTTP_METHODS.GET,
    });
  };

  removeFromGroup = (userId, groupId) => {
    return this.httpService.request({
      url: ROUTES.REMOVE_FROM_GROUP.replace(':userId', userId).replace(':groupId', groupId),
      method: HTTP_METHODS.DELETE,
    });
  };

  filterUsers = (entity, entityId, term) => {
    return this.httpService.request({
      url: ROUTES.FILTER_USERS
        .replace(':entity', entity)
        .replace(':entityId', entityId)
        .replace(':term', term || ''),
      method: HTTP_METHODS.GET,
    });
  };

  inviteUsers = (payload) => {
    return this.httpService.request({
      url: ROUTES.INVITE_USERS,
      method: HTTP_METHODS.POST,
      data: payload
    });
  };

  inviteSingleUser = (payload) => {
    return this.httpService.request({
      url: ROUTES.INVITE_SINGLE_USER,
      method: HTTP_METHODS.POST,
      data: payload
    });
  };

  updateUser = (formData, userId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_USER.replace(':id', userId ),
      method: HTTP_METHODS.POST,
      data: formData
    });
  };

  getUserById = (userId) => {
    return this.httpService.request({
      url: ROUTES.GET_USER.replace(':id', userId),
      method: HTTP_METHODS.GET,
    });
  };

  changePassword = (data) => {
    return this.httpService.request({
      url: ROUTES.CHANGE_PASSWORD,
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getUsersByGroup = (groupId) => {
    return this.httpService.request({
      url: ROUTES.GET_USERS_BY_GROUP.replace(':groupId', groupId),
      method: HTTP_METHODS.GET,
    });
  };
}

const usersService = new UsersService(httpService);

export default usersService;
