import produce from 'immer';
import {SET_HUB_DATA, SET_HUB_UPDATES} from './actionTypes';

export const initialState = {
  hubData: null,
  hubUpdates: null,
};

/* eslint-disable default-case */
const hubReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_HUB_DATA:
        draft.hubData = action.payload;
        break;
      case SET_HUB_UPDATES:
        draft.hubUpdates = action.payload;
        break;
    }
  });

export default hubReducer;
