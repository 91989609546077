import React from "react";
import InitiativesComponent from "../GraphTableComponent/RowComponents/InitiativesComponent";
import OverallHealthComponent from "../GraphTableComponent/RowComponents/OverallHealthComponent";
import UserStatusComponent from "../UserStatusComponent/UserStatusComponent";

export const GROUPS_TABLE_CONFIG = (row) => {
    return [
        {
            id: 1,
            type: 'text',
            label: 'Name',
            value: row?.name ? 'name' : 'email',
        },
        {
            id: 2,
            type: 'text',
            label: 'Job Title',
            value: 'jobTitle',
        },
        {
            id: 3,
            label: 'Status',
            value: 'status',
            type: 'component',
            component: (
                <UserStatusComponent status={row?.status} />
            ),
        },
        {
            id: 4,
            label: 'Initiatives',
            value: 'initiatives',
            type: 'component',
            component: (
              <InitiativesComponent data={row}/>
            ),
        },
        {
            id: 5,
            label: 'Overall Health',
            value: 'overallHealth',
            type: 'component',
            component: (
                <OverallHealthComponent data={row}/>
            ),
        },
    ];
};
