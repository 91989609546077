import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import styles from './RisksTab.module.scss';
import DefaultButton from '../../Shared/Buttons/DefaultButton';
import {ReactComponent as RiskIcon} from '../../../assets/icons/warning-risk.svg';
import TextareaField from "../../Shared/Inputs/TextareaField";
import InputField from "../../Shared/Inputs/InputField";
import RatingComponentFormik from "../../Shared/Inputs/RatingComponentFormik";
import AutocompleteField from "../../Shared/Inputs/AutocompleteField";
import SelectField from "../../Shared/Inputs/SelectField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {getScoreColorsAndText, parseTypesString, riskTypes} from "../../../utils/risks";
import {ReactComponent as AlignIcon} from "../../../assets/icons/share.svg";
import {ReactComponent as ArrowDown} from "../../../assets/icons/arrowDown.svg";
import riskService from "../../../services/RiskService";
import {validationSchemaStep1, validationSchemaStep2} from "../../../validation/risk";
import dayjs from "dayjs";
import {capitalizeFirstLetter} from "../../../utils/format";
import usersService from "../../../services/UsersService";
import {useDispatch, useSelector} from "react-redux";
import {strategyPlanSelector} from "../../../store/strategyPlan/selectors";
import {getStrategyPlanSaga} from "../../../store/strategyPlan/actions";
import {refreshPageSaga} from "../../../store/helper/actions";
import {getItemScorecard} from "../../../utils/dashboard";
import {terminologySelector} from "../../../store/auth/selectors";

const RisksForm = ({toggleForm, item, selectedRisk, reEvaluate, rightModal}) => {
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);
    const [showSharingWith, setShowSharingWith] = useState(false);
    const [combinedValues, setCombinedValues] = useState(null);
    const [userOptions, setUserOptions] = useState([]);
    const [ownerIdValue, setOwnerIdValue] = useState(selectedRisk?.ownerId || null);

    const terminology = useSelector(terminologySelector());

    const initialValuesStep1 = combinedValues || {
        riskName: selectedRisk?.name || '',
        riskDescription: selectedRisk?.description || '',
        likelihood: selectedRisk?.likelihood || null,
        weight: selectedRisk?.impact || null,
    };

    const getDateSameAsGoal = () => {
        if (item?.parentGoal) {
            return item?.parentGoal?.endDate;
        } else if (item?.goal) {
            return item?.goal?.endDate;
        } else if (item?.project?.goal) {
            return item?.project?.goal?.endDate;
        }
        else {
            return item.endDate;
        }
    }
    const initialValuesStep2 = {
        ownerId: selectedRisk?.ownerId || null,
        owners: selectedRisk?.owners?.map((user) => {
                return {
                    id: user.id,
                    value: user.id,
                    title: user.name,
                    subtitle: user.email,
                    image: user.photo
                };
            })
            || [],
        type: parseTypesString(selectedRisk?.type).map((type) => ({
            id: riskTypes.find((riskType) => riskType.value === type)?.id || null,
            title: capitalizeFirstLetter(type),
            value: type
        })) || null,

        timeExpected: selectedRisk?.date ? dayjs(selectedRisk?.date) : null,
    };

    const onBoxShow = () => {
        setShowSharingWith(!showSharingWith);
    }

    const handleDateChange = (newDate, setFieldValue, setSameAsGoal) => {
        if (newDate) {
            setFieldValue('timeExpected', newDate);
        } else if (setSameAsGoal) {
            setFieldValue('timeExpected', dayjs(getDateSameAsGoal()));
        }
    }

    const onSubmitStep1 = async (values, {setSubmitting}) => {
        if (reEvaluate && selectedRisk) {
            const data = {
                name: values.riskName,
                description: values.riskDescription,
                likelihood: values.likelihood,
                impact: values.weight,
                ownerId: initialValuesStep2.ownerId,
                owners: initialValuesStep2.owners,
                date: initialValuesStep2.timeExpected,
                type: initialValuesStep2.type.map((el) => el.value),
                parentType: item.type.toLowerCase(),
                parentId: item.id,
            }
            await riskService.updateRisk(selectedRisk?.id, data)
            toggleForm();
        } else {
            setStep(2);
            setCombinedValues({
                ...combinedValues,
                ...values
            });
            setSubmitting(false);
        }
    };

    const onSubmitStep2 = async (values) => {
        try {
            const ownerIds = values.owners && values.owners.map((owner) => owner.id);
            const types = values.type.map((el) => el.value);
            const data = {
                name: combinedValues.riskName,
                description: combinedValues.riskDescription,
                likelihood: combinedValues.likelihood,
                impact: combinedValues.weight,
                ownerId: values.ownerId,
                owners: ownerIds,
                date: values.timeExpected,
                type: types,
                parentType: item.type.toLowerCase(),
                parentId: item.id,
            }

            if (selectedRisk?.id) {
                await riskService.updateRisk(selectedRisk?.id, data);
            } else {
                await riskService.createRisk(data);
                dispatch(refreshPageSaga({
                    page: rightModal?.page,
                    scorecardId: rightModal?.scorecardId,
                    goalId: item.goalId,
                    kpiId: item.kpiId,
                    projectId: item.projectId,
                    actionId: item.actionId,
                }));
            }
            toggleForm();
        } catch (err) {
            console.error(err);
        }
    };

    const getUsers = async () => {
        try {
            if (getItemScorecard(item)?.id) {
                const res = await usersService.getUsersByScorecard(getItemScorecard(item)?.id);
                const owner = res.find((user) => user.id === item?.owner?.id);
                if (item?.owner && !owner) {
                    res.push({...item?.owner, disabled: true});
                }
                setUsersForOptions(res);
            }
        } catch (e) {
            console.log('err', {e});
        }
    };

    const setUsersForOptions = (users) => {
        setUserOptions(users.map((user) => {
            return {
                id: user.id,
                value: user.id,
                title: user.title ? user.title : user.name,
                subtitle: user.subtitle ? user.subtitle : user.email,
                disabled: user.id === ownerIdValue,
                image: user.image ? user.image : user.photo,
            }
        }))
    }

    useEffect(() => {
        getUsers()
    }, [])

    useEffect(() => {
        setUsersForOptions(userOptions)
    }, [ownerIdValue])

    const setNewOwner = (id, values, setValues) => {
        setOwnerIdValue(id)
        let newArr = values.owners.filter((owner) => owner.id !== id)
        setValues({...values, owners: [...newArr], ownerId: id})
    }


    return (
        <div className={styles.container}>
            {step === 1 && (
                <Formik
                    validateOnMount
                    initialValues={initialValuesStep1}
                    validationSchema={validationSchemaStep1}
                    onSubmit={onSubmitStep1}
                >
                    {({setFieldValue, errors, values, isValid}) => (
                        <Form>
                            <div className={styles.formContainer}>
                                <div className={styles.formHeader}>
                                    <RiskIcon width={26} height={26}/>
                                    {
                                        reEvaluate ?
                                            <div>
                                                <p className={styles.formTitle}>Re-evaluate</p>
                                            </div>
                                            :
                                            <div className={styles.flexAndCenter}>
                                                <p className={styles.formTitle}>Add a Risk -</p>
                                                <p className={styles.formSteps}>Step {step} of 2</p>
                                            </div>
                                    }
                                </div>
                                <div className={styles.formGroup}>
                                    {
                                        !reEvaluate &&
                                        <div>
                                            <div>
                                                <Field
                                                    component={InputField}
                                                    name={'riskName'}
                                                    setFieldValue={setFieldValue}
                                                    errors={errors}
                                                    label={'Risk name'}
                                                    placeholder={'Risk name'}
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    component={TextareaField}
                                                    name={'riskDescription'}
                                                    setFieldValue={setFieldValue}
                                                    errors={errors}
                                                    placeholder={'Description'}
                                                    label={'Description'}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.scoreSectionsWrapper}>
                                        <div>
                                            <div>
                                                <p className={styles.fieldLabel}>Likelihood of happening</p>
                                                <RatingComponentFormik
                                                    maxValue={10}
                                                    name="likelihood"
                                                    score={values['likelihood'] * values['weight']}
                                                    showScaleNames={true}
                                                />
                                                <div style={{height: 14}}>
                                                    {errors.likelihood &&
                                                        <p className={styles.errorText}>{errors.likelihood}</p>}
                                                </div>
                                            </div>
                                            <div>
                                                <p className={styles.fieldLabelWeight}>Weight (Impact)</p>
                                                <RatingComponentFormik
                                                    maxValue={10}
                                                    name="weight"
                                                    score={values['likelihood'] * values['weight']}
                                                    showScaleNames={true}
                                                />
                                                <div style={{height: 14}}>
                                                    {errors.weight &&
                                                        <p className={styles.errorText}>{errors.weight}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p>Score</p>
                                            <div className={styles.scoreBox} style={{
                                                background: getScoreColorsAndText(values['likelihood'] * values['weight']).backgroundColor,
                                                borderColor: getScoreColorsAndText(values['likelihood'] * values['weight']).borderColor
                                            }}>
                                                <p className={styles.scoreValue}
                                                   style={{color: getScoreColorsAndText(values['likelihood'] * values['weight']).borderColor}}>{values['likelihood'] * values['weight']}</p>
                                                <p className={styles.scoreValueName}
                                                   style={{color: getScoreColorsAndText(values['likelihood'] * values['weight']).borderColor}}>{getScoreColorsAndText(values['likelihood'] * values['weight']).text}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.formActions}>
                                        <DefaultButton type='button' buttonStyles={{marginRight: 12}} outlined
                                                       onClick={toggleForm} title="Cancel"/>
                                        <DefaultButton type="submit" title={reEvaluate ? 'Submit' : 'Next'} disabled={!isValid}/>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}

            {step === 2 && (
                <Formik
                    validateOnMount
                    initialValues={initialValuesStep2}
                    validationSchema={validationSchemaStep2}
                    onSubmit={onSubmitStep2}
                >
                    {({setFieldValue, errors, values, isValid, setValues}) => (
                        <Form>
                            <div className={styles.formContainer}>
                                <div className={styles.formHeader}>
                                    <RiskIcon width={26} height={26}/>
                                    <p className={styles.formTitle}>Add a Risk -</p>
                                    <p className={styles.formSteps}>Step {step} of 2</p>
                                </div>
                                <div className={styles.formGroup}>
                                    <div className={styles.timeExpectedWrapper}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker name={'timeExpected'} value={values.timeExpected || null}
                                                        onChange={(e) => handleDateChange(e, setFieldValue)}
                                                        label="Time Expected"
                                            />
                                        </LocalizationProvider>
                                        <DefaultButton type={'button'} inverted title={`Same as ${terminology.goal}`} buttonStyles={{marginLeft: 8}}
                                                       onClick={() => handleDateChange(null, setFieldValue, true)}/>
                                    </div>
                                    <div className={styles.errorTextWrapper}>
                                        {errors.timeExpected &&
                                            <p className={styles.errorText}>{errors.timeExpected}</p>}
                                    </div>
                                    <Field
                                        component={AutocompleteField}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        name={'type'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        label={'Type'}
                                        placeholder={{
                                            value: 'Select Type'
                                        }}
                                        options={{
                                            options: riskTypes
                                        }}
                                    />
                                    <Field
                                        component={SelectField}
                                        name={'ownerId'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: 'Risk Owner',
                                            icon: require('../../../assets/icons/profile-owner.png'),
                                        }}
                                        label={'Risk Owner'}
                                        options={{
                                            options: userOptions,
                                            imageKey: 'photo',
                                            defaultImage: require('../../../../src/assets/icons/profile-owner.png'),
                                            titleKey: 'name',
                                            subtitleKey: 'email',
                                        }}
                                        getFieldVal={(val) => setNewOwner(val, values, setValues)}
                                    />
                                    <div className={styles.wrapper} style={{height: showSharingWith ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <AlignIcon/>
                                                <p className={styles.titleText}>Sharing with</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn}
                                                    style={{transform: showSharingWith ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                        <Field
                                            component={AutocompleteField}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            name={'owners'}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            placeholder={{
                                                value: 'Start typing name or email',
                                            }}
                                            label={`Add teammates to collaborate with this ${terminology.goal}. Search for a user or individual a new user by email`}
                                            options={{options: userOptions, disabledText: 'Owner', subtitleKey: 'email', imageKey: 'photo', defaultImage: require('../../../../src/assets/icons/profile-owner.png')}}
                                        />
                                    </div>
                                    <div className={styles.formActionsWrapper}>
                                        <DefaultButton type={'button'} inverted title={'Go back'}
                                                       onClick={() => setStep(1)}/>
                                        <div className={styles.formActions}>
                                            <DefaultButton type={'button'} buttonStyles={{marginRight: 12}} outlined
                                                           onClick={toggleForm} title="Cancel"/>
                                            <DefaultButton disabled={!isValid} type="submit" title="Submit"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default RisksForm;
