import {
  CREATE_GROUP_SAGA,
  DELETE_GROUP_SAGA,
  DELETE_SCORECARD_SAGA, DELETE_SCORECARD_SAGA_FROM_WIDGET,
  EDIT_GROUP_SAGA,
  GET_GROUP_SAGA, GET_RIGHT_SIDEBAR_SCORECARD_SAGA,
  GET_SCORECARD_LIST_DATA_SAGA, IS_GROUP_IDS_SET, MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA,
  MANAGE_SCORECARD_SAGA, REORDER_SCORECARD_ITEMS_SAGA,
  RESET_STATE, SET_ALL_GROUP_IDS, SET_ALL_SCORECARDS, SET_DELETE_PLAN_ITEM, SET_EXPANDED_GROUPS,
  SET_GROUP,
  SET_GROUPS,
  SET_GROUPS_WITH_SCORECARDS, SET_RIGHT_SIDEBAR_SCORECARD, SET_SCORECARD_TITLE,
} from './actionTypes';

export function getScorecardListDataSaga(snackbar) {
  return {
    type: GET_SCORECARD_LIST_DATA_SAGA,
    snackbar,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function setGroups(groups) {
  return {
    type: SET_GROUPS,
    groups,
  };
}

export function reorderScorecardsItemsSaga(payload) {
  return {
    type: REORDER_SCORECARD_ITEMS_SAGA,
    payload,
  };
}

export function setScorecardTitle(payload) {
  return {
    type: SET_SCORECARD_TITLE,
    payload,
  };
}

export function setAllScorecards(payload) {
  return {
    type: SET_ALL_SCORECARDS,
    payload,
  };
}

export function createGroupSaga(groupData) {
  return {
    type: CREATE_GROUP_SAGA,
    groupData,
  };
}

export function setGroupsWithScorecards(groupsWithScorecards) {
  return {
    type: SET_GROUPS_WITH_SCORECARDS,
    groupsWithScorecards,
  };
}

export function editGroupSaga(groupData, groupId) {
  return {
    type: EDIT_GROUP_SAGA,
    groupData,
    groupId,
  };
}

export function manageScorecardSaga(scorecardData, create, scorecardId) {
  return {
    type: MANAGE_SCORECARD_SAGA,
    scorecardData,
    create,
    scorecardId,
  };
}

export function manageRightSidebarScorecardSaga(scorecardData, scorecardId) {
  return {
    type: MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA,
    scorecardData,
    scorecardId,
  };
}

export function getGroupSaga(groupId) {
  return {
    type: GET_GROUP_SAGA,
    groupId,
  };
}

export function setGroup(group) {
  return {
    type: SET_GROUP,
    group,
  }
}

export function getRightSidebarScorecardSaga(scorecardId) {
  return {
    type: GET_RIGHT_SIDEBAR_SCORECARD_SAGA,
    scorecardId,
  };
}

export function setRightSidebarScorecard(scorecard) {
  return {
    type: SET_RIGHT_SIDEBAR_SCORECARD,
    scorecard,
  }
}

export function deleteGroupSaga(group) {
  return {
    type: DELETE_GROUP_SAGA,
    group,
  };
}

export function deleteScorecardSaga(scorecard, removeLinks) {
  return {
    type: DELETE_SCORECARD_SAGA,
    scorecard,
    removeLinks,
  };
}

export function deleteScorecardFromMultiplePlansSnapshotSaga(scorecard, removeLinks, isFromForm) {
  return {
    type: DELETE_SCORECARD_SAGA_FROM_WIDGET,
    scorecard,
    removeLinks,
    isFromForm
  };
}

export function setExpandedGroups(group) {
  return {
    type: SET_EXPANDED_GROUPS,
    group,
  };
}

export function setGroupIds(ids) {
  return {
    type: SET_ALL_GROUP_IDS,
    ids
  };
}

export function isGroupIdsSet(payload) {
  return {
    type: IS_GROUP_IDS_SET,
    payload
  };
}

export function setDeletePlanItemFromWidget(scorecard) {
  return {
    type: SET_DELETE_PLAN_ITEM,
    scorecard,
  }
}
