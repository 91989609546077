import React, {useEffect, useState} from 'react';
import styles from './TrackingComponent.module.scss'
import CustomTextInput from "../../CustomInputs/TextInput";

export function AchieveTarget({errors, item, setFormValues, clearProp, padding = '0', type}) {
  const dataObj = {
    lowerTarget: '',
    upperTarget: '',
    min: '',
    max: ''
  }
  const [limits, setLimits] = useState(dataObj);

  useEffect(() => {
    setLimits(dataObj)
    setFormValues({...dataObj})
  }, [type])

  useEffect(() => {
    if (item) {
      let itemValues = {
        lowerTarget: item.lowerTarget !== null ? item.lowerTarget : '',
        min: (item.inverted ? item.max : item.min) ?? '',
      }
      setLimits({...itemValues})
      setFormValues({...itemValues})
    }
  }, [item])

  useEffect(() => {
    if(clearProp){
      setLimits(dataObj)
      setFormValues({...dataObj})
    }
  }, [clearProp])

  const onHandleLimits = (value, propType) => {
    Object.defineProperty(limits, propType, {value: Number(value), writable: true})
    setLimits({...limits})
    setFormValues({...limits})
  }

  const onBlur = (value, propType) => {
    if (propType === 'lowerTarget' && value < limits.min && type !== 1) {
      Object.defineProperty(limits, propType, {value: limits.min, writable: true})
    }
    if (propType === 'min' && value > limits.lowerTarget) {
      // Object.defineProperty(limits, 'lowerTarget', {value: limits.min, writable: true})
    }
    setLimits({...limits})
    setFormValues({...limits})
  }

  const onFocus = (type) => {
    let clearLimits = {...limits}
    Object.defineProperty(clearLimits, type, {value: clearLimits[type] === 0 ? '' : clearLimits[type], writable: true})
    setLimits(clearLimits)
  }


  return (
    <div className={styles.manuallyContainer} style={{padding: padding, marginTop: '16px'}}>

      <div className={styles.targetLimits}>
        <div style={{width: '48%'}}><span>{type === 1 ? 'Initial value' : 'Lowest Value(Min)'}<span
          className={styles.errorMsg}>{errors?.min && ` ${errors?.min}`}</span></span><CustomTextInput
          onBlur={(val) => onBlur(val.target.value, 'min')} height={40} value={limits.min}
          // onFocus={() => onFocus('min')}
          handleInputChange={(val) => onHandleLimits(val.target.value, 'min')} placeholder={type === 1 ? 'Initial value' : 'Lowest Value(Min)'}/>
        </div>
        <div style={{width: '48%'}}><span>Target<span
          className={styles.errorMsg}>{errors?.lowerTarget && ` ${errors?.lowerTarget}`}</span></span><CustomTextInput
          onBlur={(val) => onBlur(val.target.value, 'lowerTarget')} height={40} value={limits.lowerTarget}
          // onFocus={() => onFocus('lowerTarget')}
          handleInputChange={(val) => onHandleLimits(val.target.value, 'lowerTarget')} placeholder={'Target'}/>
        </div>

      </div>
    </div>
  )

}

export default AchieveTarget;
