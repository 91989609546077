import {Role, SIDE_BAR_ITEMS} from "../../constants/strings";
import {
  ALIGNMENT,
  DASHBOARDS,
  HOME,
  METRICS,
  INTEGRATIONS,
  PROFILE,
  REPORTS,
  SCORECARDS,
  SETTINGS,
  VNC_ACADEMY, RELEASE_NOTES,
} from "../../routesConstants";
import profileImage from "../../assets/images/user.png";
import styles from "./SideBar.module.scss";
import React from "react";
import {ReactComponent as Home} from '../../assets/icons/side-bar/home.svg';
import {ReactComponent as Scorecards} from '../../assets/icons/side-bar/scorecards.svg';
import {ReactComponent as Dashboards} from '../../assets/icons/side-bar/dashboards.svg';
import {ReactComponent as Integrations} from '../../assets/icons/side-bar/integrations.svg';
import {ReactComponent as ConnectMetrics} from '../../assets/icons/side-bar/metrics.svg';
import {ReactComponent as Reports} from '../../assets/icons/side-bar/reports.svg';
import {ReactComponent as VNCAcademy} from '../../assets/icons/side-bar/vnc-academy.svg';
import {ReactComponent as Settings} from '../../assets/icons/side-bar/settings.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import {COLORS} from "../../constants/colors";
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import {NewReleases} from "@mui/icons-material";

export const TOP_SIDE_BAR_ITEMS = ({terminology}) => {
  return [
    {
      id: 1,
      title: SIDE_BAR_ITEMS({terminology}).HOME,
      path: HOME,
      icon: <Home />,
    },
    {
      id: 2,
      title: SIDE_BAR_ITEMS({terminology}).SCORECARDS,
      path: SCORECARDS,
      icon: (<Scorecards/>),
    },
    {
      id: 3,
      title: SIDE_BAR_ITEMS({terminology}).ALIGNMENT,
      path: ALIGNMENT,
      icon: (<FormatAlignCenterIcon style={{color: COLORS.lightGray}} fontSize='small'/>),
    },
    {
      id: 4,
      path: DASHBOARDS,
      title: SIDE_BAR_ITEMS({terminology}).DASHBOARDS,
      icon: <Dashboards />,
    },
    {
      id: 5,
      path: INTEGRATIONS,
      title: SIDE_BAR_ITEMS({terminology}).INTEGRATIONS,
      icon: <Integrations />,
    },
    {
      id: 6,
      title: SIDE_BAR_ITEMS({terminology}).CONNECT_METRICS,
      path: METRICS,
      icon: <ConnectMetrics />,
    },
    {
      id: 7,
      title: SIDE_BAR_ITEMS({terminology}).REPORTS,
      path: REPORTS,
      icon: <Reports />,
    },
  ];
};

export const BOTTOM_SIDE_BAR_ITEMS = ({user, terminology, subscribed}) => {
  return (
    [
      ...subscribed ? [{
        id: 1,
        title: SIDE_BAR_ITEMS({terminology}).RELEASE_NOTES,
        path: RELEASE_NOTES,
        icon: <NewReleases/>,
        isProfile: false
      }] : [],
      ...subscribed ? [{
        id: 2,
        title: SIDE_BAR_ITEMS({terminology}).VNC_ACADEMY,
        path: VNC_ACADEMY,
        icon: <VNCAcademy/>,
        isProfile: false
      }] : [],
      ...(user.role === Role.ADMIN ? [{
        id: 3,
        title: SIDE_BAR_ITEMS({terminology}).SETTINGS,
        path: SETTINGS,
        icon: (<Settings/>),
        isProfile: false
      }] : []),
      {
        id: 4,
        title: 'Logout',
        path: 'login',
        icon: (<LogoutIcon style={{color: COLORS.lightGray}} fontSize='small'/>),
        isProfile: false
      },
      {
        id: 5,
        title: user.name || user.email,
        path: PROFILE,
        icon: <img src={user.photo ? user.photo : profileImage} className={styles.profileImg} alt={'user'}/>,
        isProfile: true
      },
    ]
  )
}
