import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
export const progressScheduleData = [
  {
    id: 1,
    name: 'Weekly',
    options: [
      {
        id: 1,
        name: 'every Monday',
        value: 'everyMonday'
      },
      {
        id: 2,
        name: 'every Tuesday',
        value: 'everyTuesday'
      },
      {
        id: 3,
        name: 'every Wednesday',
        value: 'everyWednesday'
      },
      {
        id: 4,
        name: 'every Thursday',
        value: 'everyThursday'
      },
      {
        id: 5,
        name: 'every Friday',
        value: 'everyFriday'
      },
      {
        id: 6,
        name: 'every Saturday',
        value: 'everySaturday'
      },
      {
        id: 7,
        name: 'every Sunday',
        value: 'everySunday'
      },
    ]
  },
  {
    id: 2,
    name: 'Monthly',
    options: [
      {
        id: 1,
        name: '1st day of month',
        value: 'firstDayOfMonth'
      },
      {
        id: 2,
        name: '1st Monday of month',
        value: 'firstMondayOfMonth'
      },
      {
        id: 3,
        name: 'Last day of month',
        value: 'lastDayOfMonth'
      },
      {
        id: 4,
        name: 'Last Friday of month',
        value: 'lastFridayOfMonth'
      },
    ]
  },
  {
    id: 3,
    name: 'Quarterly',
    options: [
      {
        id: 1,
        name: '1st day of Qtr',
        value: 'firstDayOfQuarterly'
      },
      {
        id: 2,
        name: '1st Monday of Qtr',
        value: 'firstMondayOfQuarterly'
      },
      {
        id: 3,
        name: 'Last day of Qtr',
        value: 'lastDayOfQuarterly'
      },
      {
        id: 4,
        name: 'Last Friday of Qtr',
        value: 'lastFridayOfQuarterly'
      },
    ]
  },
  {
    id: 4,
    name: 'Annually',
    options: [
      {
        id: 1,
        name: '1st day of year',
        value: 'FirstDayOfYear'
      },
      {
        id: 2,
        name: '1st Monday of year',
        value: 'firstMondayOfYear'
      },
      {
        id: 3,
        name: 'Last day of year',
        value: 'lastDayOfYear'
      },
      {
        id: 4,
        name: 'Last Friday of year',
        value: 'lastFridayOfYear'
      },
    ]
  }
];

export const targetTypeData = [
  {
    id: 1,
    name: 'Achieve Target',
    icon: <span style={{transform: 'rotate(90deg)', alignItems:'center', display:'flex', marginRight:'8px'}}><VerticalAlignCenterIcon/></span>
  },
  {
    id: 2,
    name: 'Exceed Target',
    icon: <span style={{alignItems:'center', display:'flex', marginRight:'8px'}}><VerticalAlignTopIcon/></span>
  },
  {
    id: 3,
    name: 'Below Target',
    icon: <span style={{alignItems:'center', display:'flex', marginRight:'8px'}}><VerticalAlignBottomIcon/></span>
  },
  {
    id: 4,
    name: 'In-between Target',
    icon: <span style={{alignItems:'center', display:'flex', marginRight:'8px'}}><VerticalAlignCenterIcon/></span>
  }
]

export const measurementData = [
  {
    id: 1,
    name: 'No Unit',
    value: 'no-unit'
  },
  {
    id: 2,
    name: '$',
    value: 'dollar'
  },
  {
    id: 3,
    name: '£',
    value: 'pound'
  },
  {
    id: 4,
    name: '%',
    value: 'percentage'
  },
  {
    id: 5,
    name: 'numbers',
    value: 'number'
  },
  {
    id: 6,
    name: 'seconds',
    value: 'seconds'
  },
  {
    id: 7,
    name: 'minutes',
    value: 'minutes'
  },
  {
    id: 8,
    name: 'hours',
    value: 'hours'
  },
  {
    id: 9,
    name: 'days',
    value: 'days'
  },
  {
    id: 10,
    name: 'weeks',
    value: 'weeks'
  },
  {
    id: 11,
    name: 'months',
    value: 'months'
  },
  {
    id: 12,
    name: 'years',
    value: 'years'
  },
  {
    id: 13,
    name: 'customers',
    value: 'customers'
  },
  {
    id: 14,
    name: 'users',
    value: 'users'
  },
  {
    id: 15,
    name: 'employees',
    value: 'employees'
  },
  {
    id: 16,
    name: 'NPS (Score)',
    value: 'nps-score'
  },
]

export const trendData = [
  {
    id: 1,
    name: 'No trend',
    value: 'noTrend'
  },
  {
    id: 2,
    name: 'Lower is best',
    value: 'lowerIsBest'
  },
  {
    id: 3,
    name: 'Higher is best',
    value: 'higherIsBest'
  }
]

export const inputFormatData = [
  {
    id: 2,
    name: 'Cumulative',
    subtitle: 'Each value is added to the previous',
    value: 'cumulative'
  },
  {
    id: 3,
    name: 'Non-Cumulative',
    subtitle: 'Each value is independent from the others',
    value: 'nonCumulative'
  }
]

export const totalTypeData = [
  {
    id: 1,
    name: 'Most Recent Value',
    subtitle: `The metric's current total`,
    value: 'mostRecentValue'
  },
  {
    id: 2,
    name: 'Sum of Values',
    subtitle: 'All values are summed and grouped by defined period',
    value: 'sumOfValues'
  },
  {
    id: 3,
    name: 'Avg of values',
    subtitle: 'All values are averaged and grouped by defined period',
    value: 'avgOfValues'
  }
]

export const dataIntervalsData = [
  {
    id: 1,
    name: 'Daily',
    subtitle: 'One data point per day',
    value: 'daily'
  },
  {
    id: 2,
    name: 'Weekly',
    subtitle: 'One data point per week',
    value: 'weekly'
  },
  {
    id: 3,
    name: 'Monthly',
    subtitle: 'One data point per month',
    value: 'monthly'
  },
  {
    id: 4,
    name: 'Quarterly',
    subtitle: 'One data point per quarter',
    value: 'quarterly'
  },
  {
    id: 5,
    name: 'SemiAnnually',
    subtitle: 'One data point per 6 months',
    value: 'semiAnnually'
  },
  {
    id: 6,
    name: 'Annually',
    subtitle: 'One data point per year',
    value: 'annually'
  }
]
