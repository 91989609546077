import React from 'react';
import styles from './Checklist.module.scss'
import ChecklistListItemComponent from "./ChecklistListItemComponent";

export function ChecklistListComponent({items,onRemoveInput, onSetItem ,errors}) {
    return (
        <div className={styles.checklistList}>
            {items?.length > 0 && items.map((item, index) => {
                return <ChecklistListItemComponent errors={errors?.[index]} onSetItem={onSetItem} items={items} item={item} key={index} index={index} onRemoveInput={onRemoveInput}/>
            })}
            {<p className={styles.errMessage}>{!Array.isArray(errors) && errors}</p>}
        </div>
    )

}

export default ChecklistListComponent;
