import httpService from './HttpService';

import {HTTP_METHODS, HTTP_STATUS_CODES} from "../constants/http";
import {getItem, removeItem, setItem} from "../utils/localStorage";
import {jwtDecode} from "jwt-decode";

const ROUTES = {
  LOGIN: '/auth/login',
  LOGIN_GOOGLE: '/auth/google',
  LOGIN_LINKEDIN: '/auth/linkedin',
  SELECT_ORGANIZATION: '/organizations/select/:id',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  ME: '/users/my-profile',
  LOGOUT: '/auth/logout',
  TOKEN_REFRESH: '/auth/refresh-access-token',
  VERIFY_ACCOUNT: '/auth/verify-account/:verificationToken',
  TWO_FA: '/auth/verify-two-factor-authentication',
  AUTH_MICROSOFT: '/auth/microsoft',
  MICROSOFT_LOGIN: '/auth/microsoft-login',
  GET_SINGLE_ORGANIZATION: '/subscriptions/get-organization-subscription',
};

class AuthService {
  constructor(httpService) {
    this.httpService = httpService;
    this.init();
  }

  init = () => {
    this.setAuthToken(this.getAccessToken());
    this.httpService.addRequestInterceptor(this.checkTokenExpiration);
    this.httpService.addResponseInterceptors(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
  };

  getAccessToken = () => {
    return getItem('token');
  };

  setAuthToken = (token) => {
    if (token) {
      setItem('token', token);
      this.httpService.attachHeaders({
        Authorization: `Bearer ${token}`,
      });
    }
  };

  setRefreshToken = (token) => {
    if (token) {
      setItem('refreshToken', token);
    }
  };

  refreshToken = async (prevToken) => {
    const { accessToken: newToken } = await this.httpService.request({
      url: ROUTES.TOKEN_REFRESH,
      method: HTTP_METHODS.POST,
      headers: {
        Authorization: `Bearer ${prevToken}`,
      },
    });

    this.setAuthToken(newToken);

    return newToken;
  };

  login = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.LOGIN,
      method: HTTP_METHODS.POST,
      data,
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);

    return res.token;
  };

  setAllTokens = async (data) => {
    this.setAuthToken(data.token);
    this.setRefreshToken(data.refreshToken);
  }

  authMicrosoft = async () => {
    const res = await this.httpService.request({
      url: ROUTES.AUTH_MICROSOFT,
      method: HTTP_METHODS.POST,
    });

    return res;
  };

  refreshTokenRequest = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.TOKEN_REFRESH,
      method: HTTP_METHODS.POST,
      data
    });

    return res;
  };

  microsoftLogin = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.MICROSOFT_LOGIN,
      method: HTTP_METHODS.POST,
      data
    });

    return res;
  };

  selectOrganization = async (id) => {
    const res = await this.httpService.request({
      url: ROUTES.SELECT_ORGANIZATION.replace(':id', id),
      method: HTTP_METHODS.GET
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);

    return res.token;
  };

  twoFARequest = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.TWO_FA,
      method: HTTP_METHODS.POST,
      data
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);

    return res.token;
  };

  verifyAccount = async (verificationToken) => {
    const res = await this.httpService.request({
      url: ROUTES.VERIFY_ACCOUNT.replace(':verificationToken', verificationToken),
      method: HTTP_METHODS.GET
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);

    return res.token;
  };

  loginGoogle = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.LOGIN_GOOGLE,
      method: HTTP_METHODS.POST,
      data,
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);
    return res.token;
  };

  loginLinkedIn = async (data) => {
    const res = await this.httpService.request({
      url: ROUTES.LOGIN_LINKEDIN,
      method: HTTP_METHODS.POST,
      data,
    });

    this.setAuthToken(res.token);
    this.setRefreshToken(res.refreshToken);
    return res.token;
  };

  register = async (data) => {
    await this.httpService.request({
      url: ROUTES.REGISTER,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  forgotPassword = async (data) => {
    await this.httpService.request({
      url: ROUTES.FORGOT_PASSWORD,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  resetPassword = async (data) => {
    await this.httpService.request({
      url: ROUTES.RESET_PASSWORD,
      method: HTTP_METHODS.POST,
      data,
    });
  };


  logout = async () => {
    await this.httpService.request({
      url: ROUTES.LOGOUT,
      method: HTTP_METHODS.POST,
    });

    this.destroySession();
  };

  fetchAuthenticatedUser = () => {
    return this.httpService.request({
      url: ROUTES.ME,
      method: HTTP_METHODS.GET,
    });
  };

  destroySession = () => {
    removeItem('token');
    removeItem('refreshToken');
    this.httpService.removeHeaders(['Authorization']);
  };

  checkTokenExpiration = async (request) => {
    if (request.url === ROUTES.TOKEN_REFRESH) {
      return request;
    }

    // const token = this.getAccessToken();

    // if (token && Date.now() / 1000 >= jwtDecode(token).exp) {
    //   const newToken = await this.refreshToken(token);
    //   request.headers.Authorization = `Bearer ${newToken}`;
    //
    //   return request;
    // }

    return request;
  };

  decodeJwt = async (token) => {
    return jwtDecode(token);
  };

  handleSuccessResponse = (response) => {
    return response.data;
  };

  handleErrorResponse = async (error) => {
    try {
      const { status, data } = error.response;

      const originalRequest = error.config;
      /* eslint-disable default-case */
      switch (status) {
        case HTTP_STATUS_CODES.UNAUTHORIZED:
          if (data !== 'access_token_expired') {
            this.destroySession();
            window.location = '/login';
          } else {
            if (originalRequest.url === ROUTES.ME) {
              this.httpService.removeHeaders(['Authorization']);
              const refreshToken = localStorage.getItem('refreshToken');
              try {
                const res = await this.refreshTokenRequest({refreshToken: JSON.parse(refreshToken)});
                const newToken = res.token;
                const newRefreshToken = res.refreshToken;
                localStorage.setItem('token', JSON.stringify(newToken));
                localStorage.setItem('refreshToken', JSON.stringify(newRefreshToken));
                this.httpService.attachHeaders({
                  Authorization: `Bearer ${newToken}`,
                });
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return this.httpService.request(originalRequest);
              } catch (e) {
                this.destroySession();
                window.location = '/login';
              }
            }
          }
          break;
      }

      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(error);
    }
  };
}

const authService = new AuthService(httpService);

export default authService;
