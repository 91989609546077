import { call, takeLatest, put } from 'redux-saga/effects';
import {
  CREATE_UPDATE_WIDGET_SAGA,
  DASHBOARD_DUPLICATE_SAGA,
  DASHBOARD_SETTINGS_SAGA,
  DELETE_DASHBOARD_SAGA,
  DELETE_WIDGET_SAGA,
  GET_CHART_DETAILS_SAGA,
  GET_DASHBOARD_SAGA,
  GET_DASHBOARDS_GRID_SAGA,
  OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import dashboardsService from "../../services/DashboardsService";
import {
  getDashboardSaga,
  getDashboardsGridSaga, setChartDetails,
  setDashboard,
  setDashboardsGrid,
  setVisibilityDashboardActionModal
} from "./actions";
import {PAGES} from "../../constants/pages";
import {push} from "connected-react-router";
import {DASHBOARDS, SINGLE_DASHBOARD, SINGLE_REPORT} from "../../routesConstants";
import {setSnackbar} from "../snackbar/actions";
import {rightModalOpen, setDeleteModal, setItemAndParentForAction} from "../helper/actions";
import {getItemParent} from "../../utils/perspective";
import {setItemRow} from "../strategyPlan/actions";

export function* getDashboardsGridSagaFn({payload}) {
  try {
    yield setLoader(true);
    const dashboardGrid = yield call(dashboardsService.getDashboardsGrid, payload);
    yield put(setDashboardsGrid(dashboardGrid));
  } catch (error) {
    yield put(setLoader(false));
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* dashboardSettingsSagaFn({payload}) {
  try {
    yield call(dashboardsService.updateDashboard, payload.val.id, payload.val);

    switch (payload.fromPage) {
      case PAGES.DASHBOARDS:
        yield put(getDashboardsGridSaga({...payload.pagination}));
        break;
      case PAGES.SINGLE_DASHBOARD:
        yield put(getDashboardSaga({dashboardId: payload.val.id}));
        break;
      default:
        console.log('no matching type selected');
    }

    yield put(setVisibilityDashboardActionModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${payload.val.title} Dashboard was successfully updated! `,
    }));
  } catch (error) {
    yield put(setLoader(false));
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}
export function* dashboardDuplicateSagaFn({payload}) {
  try {
    yield call(dashboardsService.duplicateDashboard, payload.val.id, payload.val);
    switch (payload.fromPage) {
      case PAGES.DASHBOARDS:
        yield put(getDashboardsGridSaga({...payload.pagination}));
        break;
      case PAGES.SINGLE_DASHBOARD:
        break;
      default:
        console.log('no matching type selected');
    }

    yield put(setVisibilityDashboardActionModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `Dashboard was successfully duplicated! `,
    }));
  } catch (error) {
    yield put(setLoader(false));
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteDashboardSagaFn({payload}) {
  try {
    yield call(dashboardsService.deleteDashboard, payload.val.id);

    switch (payload.fromPage) {
      case PAGES.DASHBOARDS:
        yield put(getDashboardsGridSaga({...payload.pagination}));
        break;
      case PAGES.SINGLE_DASHBOARD:
        yield put(push(DASHBOARDS));
        break;
      default:
        console.log('no matching type selected');
    }

    yield put(setVisibilityDashboardActionModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${payload.val.title} Dashboard was successfully deleted! `,
    }));
  } catch (error) {
    yield put(setLoader(false));
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getDashboardSagaFn({payload}) {
  try {
    const dashboard = yield call(dashboardsService.getDashboardView, payload.dashboardId, payload.data);
    yield put(setDashboard(dashboard));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getChartDetailsSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    const chartDetails = yield call(dashboardsService.getChartDetailsTableData, payload);
    yield put(setChartDetails(chartDetails));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* openRightModalFromChartDetailsSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setItemRow(payload.item));
    yield put(setItemAndParentForAction({parent: getItemParent(payload.item), item: payload.item}));
    yield put(rightModalOpen({
      isVisible: true,
      component: payload.component,
      page: PAGES.SINGLE_DASHBOARD
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteWidgetSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(dashboardsService.deleteWidget, payload.widgetId);
    yield put(getDashboardSaga({dashboardId: payload.dashboardId, data: payload.dashboardDateRange}));
    yield put(setDeleteModal({
      isVisible: false,
      widget: null,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* createUpdateWidgetSagaFn({payload}) {
  try {
    yield put(setLoader(true));

    if (payload.widgetId) {
      yield call(dashboardsService.updateWidget, payload.data, payload.widgetId);
    } else {
      yield call(dashboardsService.createWidget, payload.data);
    }

    if (payload.reportId) {
      yield put(push(SINGLE_REPORT.replace(':reportId', payload.reportId)));
    } else {
      yield put(push(SINGLE_DASHBOARD.replace(':dashboardId', payload.dashboardId)));
    }
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* perspectivesSaga() {
  yield takeLatest(GET_DASHBOARDS_GRID_SAGA, getDashboardsGridSagaFn);
  yield takeLatest(DASHBOARD_SETTINGS_SAGA, dashboardSettingsSagaFn);
  yield takeLatest(DASHBOARD_DUPLICATE_SAGA, dashboardDuplicateSagaFn);
  yield takeLatest(DELETE_DASHBOARD_SAGA, deleteDashboardSagaFn);
  yield takeLatest(GET_DASHBOARD_SAGA, getDashboardSagaFn);
  yield takeLatest(GET_CHART_DETAILS_SAGA, getChartDetailsSagaFn);
  yield takeLatest(OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA, openRightModalFromChartDetailsSagaFn);
  yield takeLatest(DELETE_WIDGET_SAGA, deleteWidgetSagaFn);
  yield takeLatest(CREATE_UPDATE_WIDGET_SAGA, createUpdateWidgetSagaFn);
}
