import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_GROUPS: '/groups',
  CREATE_GROUP: '/groups',
  GET_GROUPS_WITH_SCORECARDS: '/groups/with-scorecards',
  EDIT_GROUP: '/groups/:groupId',
  GET_SCORECARDS: '/scorecards',
  GET_TEMPLATES: '/templates',
  GET_SCORECARD: '/scorecards/:scorecardId',
  GET_SCORECARDS_WITH_PERSPECTIVES: '/scorecards/with-perspectives',
  CREATE_SCORECARD: '/scorecards',
  BULK_CREATE_SCORECARD: '/scorecards/bulk',
  EDIT_SCORECARD: '/scorecards/:scorecardId',
  GET_GROUP: '/groups/:groupId',
  DELETE_GROUP: '/groups/:groupId',
  DELETE_SCORECARD: '/scorecards/:scorecardId',
  SHARE_SCORECARD: '/scorecards/share/:scorecardId',
  MOVE_SCORECARD_TO_GROUP: '/scorecards/move/:scorecardId',
  GET_STRATEGY_PLAN: 'scorecards/strategy-plan/:strategyPlanId',
  GET_STRATEGY_PLAN_WITH_DATE_RANGE: 'scorecards/strategy-plan',
  GET_PERSPECTIVES: 'scorecards/perspectives',
  DELETE_PERSPECTIVE: '/perspectives/:perspectiveId',
  CREATE_GOAL: '/goals',
  UPDATE_GOAL: '/goals/:goalId',
  DELETE_GOAL: '/goals/:goalId',
  DELETE_LINKED_GOAL: '/goals/link/:id/:linkedGoalId',
  DELETE_SHARED_GOAL: '/goals/shared/:id/:sharedGoalId',
  DELETE_KPI: '/kpis/:id',
  DELETE_ACTION: '/actions/:id',
  GET_GOAL: '/goals/:goalId',
  GET_KPI: '/kpis/:id',
  GET_SCORECARDS_BY_ORGANIZATION: 'scorecards/by-organization',
  GET_ACTION: '/actions/:id',
  GET_PROJECT: '/projects/:id',
  DELETE_PROJECT: '/projects/:id',
  UPDATE_PROJECT: '/projects/:projectId',
  ADD_KPI_TRACKING: '/kpis/:id/add-tracking',
  PLAN_PARENTS: 'scorecards/parents/:scorecardId',
  REORDER_GROUP: 'groups/reorder',
  REORDER_PERSPECTIVE: 'scorecards/reorder',
  REORDER_ROW_ITEM: 'scorecards/reorder-item',
};

class ScorecardService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getGroups = () => {
    return this.httpService.request({
      url: ROUTES.GET_GROUPS,
      method: HTTP_METHODS.GET,
    });
  };

  reorderGroups = (data) => {
    return this.httpService.request({
      url: ROUTES.REORDER_GROUP,
      method: HTTP_METHODS.PUT,
      data
    });
  };

  reorderPerspectives = (data) => {
    return this.httpService.request({
      url: ROUTES.REORDER_PERSPECTIVE,
      method: HTTP_METHODS.PUT,
      data
    });
  };

  reorderRowItems = (data) => {
    return this.httpService.request({
      url: ROUTES.REORDER_ROW_ITEM,
      method: HTTP_METHODS.PUT,
      data
    });
  };

  createGroup = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_GROUP,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getGroupsWithScorecards = () => {
    return this.httpService.request({
      url: ROUTES.GET_GROUPS_WITH_SCORECARDS,
      method: HTTP_METHODS.GET,
    });
  };

  editGroup = (data, groupId) => {
    return this.httpService.request({
      url: ROUTES.EDIT_GROUP.replace(':groupId', groupId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getScorecards = () => {
    return this.httpService.request({
      url: ROUTES.GET_SCORECARDS,
      method: HTTP_METHODS.GET,
    });
  };

  getTemplates = () => {
    return this.httpService.request({
      url: ROUTES.GET_TEMPLATES,
      method: HTTP_METHODS.GET,
    });
  };

  getScorecard = (scorecardId) => {
    return this.httpService.request({
      url: ROUTES.GET_SCORECARD.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.GET,
    });
  };

  getScorecardsWithPerspectives = () => {
    return this.httpService.request({
      url: ROUTES.GET_SCORECARDS_WITH_PERSPECTIVES,
      method: HTTP_METHODS.GET,
    });
  };

  createScorecard = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_SCORECARD,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  bulkCreateScorecard = (data) => {
    return this.httpService.request({
      url: ROUTES.BULK_CREATE_SCORECARD,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  editScorecard = (data, scorecardId) => {
    return this.httpService.request({
      url: ROUTES.EDIT_SCORECARD.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getGroup = (groupId) => {
    return this.httpService.request({
      url: ROUTES.GET_GROUP.replace(':groupId', groupId),
      method: HTTP_METHODS.GET,
    });
  };

  deleteGroup = (groupId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_GROUP.replace(':groupId', groupId),
      method: HTTP_METHODS.DELETE,
    });
  };

  deleteScorecard = (scorecardId, data) => {
    return this.httpService.request({
      url: ROUTES.DELETE_SCORECARD.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.DELETE,
      data,
    });
  };

  shareScorecard = (data, scorecardId) => {
    return this.httpService.request({
      url: ROUTES.SHARE_SCORECARD.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  moveScorecardToGroup = (data, scorecardId) => {
    return this.httpService.request({
      url: ROUTES.MOVE_SCORECARD_TO_GROUP.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getStrategyPlan = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_STRATEGY_PLAN_WITH_DATE_RANGE,
      method: HTTP_METHODS.POST,
      data
    });
  };

  deletePerspective = (perspectiveId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_PERSPECTIVE.replace(':perspectiveId', perspectiveId),
      method: HTTP_METHODS.DELETE,
    });
  };

  createGoal = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_GOAL,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  updateGoal = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOAL.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  updateProject = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROJECT.replace(':projectId', data.id),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  deleteGoal = (goalId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_GOAL.replace(':goalId', goalId),
      method: HTTP_METHODS.DELETE,
    });
  };

  deleteLinkedGoal = (id, linkedGoalId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_LINKED_GOAL.replace(':id', id).replace(':linkedGoalId', linkedGoalId),
      method: HTTP_METHODS.DELETE,
    });
  };

  deleteSharedGoal = (id, sharedGoalId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_SHARED_GOAL.replace(':id', id).replace(':sharedGoalId', sharedGoalId),
      method: HTTP_METHODS.DELETE,
    });
  };

  deleteKpi = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_KPI.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  deleteAction = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_ACTION.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  getGoalById = (goalId) => {
    return this.httpService.request({
      url: ROUTES.GET_GOAL.replace(':goalId', goalId),
      method: HTTP_METHODS.GET,
    });
  };
  getKpiById = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_KPI.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };

  addTrackingForKpi = (id, data) => {
    return this.httpService.request({
      url: ROUTES.ADD_KPI_TRACKING.replace(':id', id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getScorecardsByOrganization = () => {
    return this.httpService.request({
      url: ROUTES.GET_SCORECARDS_BY_ORGANIZATION,
      method: HTTP_METHODS.GET,
    });
  };

  getActionById = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_ACTION.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };
  getProjectById = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECT.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };

  deleteProject = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_PROJECT.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  getPlanParents = (scorecardId) => {
    return this.httpService.request({
      url: ROUTES.PLAN_PARENTS.replace(':scorecardId', scorecardId),
      method: HTTP_METHODS.GET,
    });
  };
}

const scorecardService = new ScorecardService(httpService);

export default scorecardService;
