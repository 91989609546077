export const NOTIFICATIONS_TYPES = {
  ASSIGNED: 'assigned',
  UPDATED: 'updated',
  INBOX: 'inbox',
  SHARED_REPORT: 'sharedReport',
  MENTIONS: 'mentions',
  ASSIGNED_RISK: 'assignedRisk'
};

export const NOTIFICATIONS = [
  {id: 1, key: NOTIFICATIONS_TYPES.ASSIGNED, label: 'Something is assigned to me'},
  {id: 2, key: NOTIFICATIONS_TYPES.UPDATED, label: 'Something I own/collaborate is updated'},
  {id: 3, key: NOTIFICATIONS_TYPES.INBOX, label: 'Inbox (Comments & Reminders)'},
  {id: 4, key: NOTIFICATIONS_TYPES.SHARED_REPORT, label: 'A Report is shared with me'},
  {id: 5, key: NOTIFICATIONS_TYPES.MENTIONS, label: 'Someone mentions me'},
  {id: 6, key: NOTIFICATIONS_TYPES.ASSIGNED_RISK, label: 'A risk is assigned to me'},
];

export const NOTIFICATIONS_SEND_TYPE = {
  IN_APP: 'inApp',
  EMAIL: 'email',
};
