import React, {useCallback, useEffect, useState} from 'react';
import styles from './SidebarPlanComponent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {
  closeSidebarSaga,
} from "../../../store/strategyPlan/actions";
import {ACTIONS, ROW_ITEM_TYPES} from "../../../constants/strings";
import { ReactComponent as PlanIcon } from '../../../assets/icons/default-scorecard.svg';
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import AssignModalComponent from "../../../containers/StrategyPlanPage/ContentHeader/AssignModalComponent";
import CustomModal from "../../Shared/Modals/CustomModal";
import CustomTooltip from "../../Shared/Tooltip/CustomTooltip";
import TooltipMembersContent from "../../Shared/Tooltip/TooltipMemebersContent";
import {Divider} from "@mui/material";
import PerspectiveStatusComponent
    from "../../../containers/StrategyPlanPage/PerspectiveComponent/PerspectiveStatusComponent";
import {getItemIcon} from "../../AlignmentComponent/GoalTabView/GoalNode";
import ToggleExpandButton from "../../Shared/Buttons/ToggleExpandButton";
import {PERSPECTIVE_DASHBOARDS, STRATEGY_PLAN} from "../../../routesConstants";
import {useHistory} from "react-router-dom";
import PopupComponent from "../../Shared/Popup/PopupComponent";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import {COLORS} from "../../../constants/colors";
import {sidebarPlanHeaderMenuConfig} from "./SidebarPlanHeaderMenuConfig";
import DynamicForm from "../../Shared/Form/DynamicForm";
import {editScorecardFormConfig} from "../../../containers/ScorecardListPage/editScorecardFormConfig";
import {editScorecardSchema} from "../../../validation/scorecard";
import {
    getRightSidebarScorecardSaga,
    manageRightSidebarScorecardSaga,
} from "../../../store/scorecard/actions";
import {rightSidebarScorecardSelector} from "../../../store/scorecard/selectors";
import {terminologySelector} from "../../../store/auth/selectors";
import {getOwnerSelectOptions} from "../../../utils/perspective";


const SidebarPlanComponent = ({planId}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedPlan = useSelector(rightSidebarScorecardSelector());
  const terminology = useSelector(terminologySelector());

  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false)
  const [showPerspectives, setShowPerspectives] = useState(true)
  const [showPlanSettings, setShowPlanSettings] = useState(false);
  const [ownerSelectOptions, setOwnerSelectOptions] = useState([]);

  const closeAssignModal = () => {
      setIsAssignModalVisible(false);
  }

  useEffect(() => {
      dispatch(getRightSidebarScorecardSaga(planId))
  }, [])

  const closeSidebar = () => {
    dispatch(closeSidebarSaga());
  };

  const getScorecard = async (id) => {
      if (id === selectedPlan.id) {
        await history.push(STRATEGY_PLAN.replace(':id', id));
      }
      closeSidebar();
  };

    const onCardMenuClick = async (item, action) => {
        switch (action) {
            case ACTIONS.PLAN_SETTINGS:
                const options = await getOwnerSelectOptions({group: selectedPlan?.group});
                setOwnerSelectOptions(options);
                setShowPlanSettings(true);
                break;
            default:
                console.log('No action found');
        }
    }

    const manageScorecard = useCallback((values, create, scorecardId) => {
        dispatch(manageRightSidebarScorecardSaga(values, scorecardId));
        setShowPlanSettings(false)
    }, [dispatch]);

    const closePlanSettings = useCallback(() => {
        setShowPlanSettings(false)
    }, []);

    const onNavigateToDashboard = (item) => {
        localStorage.setItem('strategyPlanSelectedTab', '2');
        history.push(PERSPECTIVE_DASHBOARDS.replace(':id', item.id).replace(':value', selectedPlan.id));
        closeSidebar();
    }

  return (
    <div className={styles.container}>
        <CustomModal closeModal={closePlanSettings} isVisible={showPlanSettings} title={`${terminology.plan} Settings`} maxWidth={'sm'}>
            <DynamicForm
                config={editScorecardFormConfig({
                    scorecard: selectedPlan,
                    onCancel: closePlanSettings,
                    terminology,
                    options: ownerSelectOptions,
                    group: selectedPlan?.group,
                })}
                validationSchema={editScorecardSchema}
                handleOnSubmit={(values) => manageScorecard(values, false, selectedPlan?.id)}
            />
        </CustomModal>
      <div className={styles.mainHeader}>
          <div className={styles.displayFlex}>
              <PlanIcon width={32} height={32} />
              <p className={styles.planName}>{selectedPlan?.name}</p>
          </div>
          <div className={styles.displayFlex}>
              <PopupComponent config={sidebarPlanHeaderMenuConfig({
                  item: selectedPlan,
                  onClick: onCardMenuClick,
                  terminology,
              })}/>
              <button className={styles.button} onClick={closeSidebar}>
                  <CloseIcon color={COLORS.lightGray} />
              </button>
          </div>
      </div>
      <div className={styles.header}>
          {!selectedPlan?.groupId ?
              <DefaultButton inverted title={'Assign to Group'} onClick={() => setIsAssignModalVisible(true)}>Assign to
                  Group
              </DefaultButton>
          :
              <div className={styles.membersWrapper}>
                  <CustomTooltip  title={<p className={styles.groupName}>{selectedPlan?.group?.name}</p>} children={<TooltipMembersContent onItemClick={(e, item) => console.log('item', item)} items={selectedPlan?.group?.users}/>}  />
              </div>
          }
          <DefaultButton title={`View ${terminology.plan}`} onClick={() => getScorecard(selectedPlan?.id)}/>
          <CustomModal title={'Assign to group'} isVisible={isAssignModalVisible} maxWidth={'sm'} closeModal={closeAssignModal} children={<AssignModalComponent planId={selectedPlan?.id} closeModal={closeAssignModal} fromRightSidebar/>}/>
      </div>
      <Divider className={styles.divider}/>
        <div>
            <div className={styles.perspectivesSection}>
                <div className={styles.displayFlex}>
                    <p className={styles.perspectivesText}>{terminology.perspective}s</p>
                    <div className={styles.itemIconAndCount}>
                        {getItemIcon(ROW_ITEM_TYPES.PERSPECTIVE, 24)}
                        <p className={styles.itemsLength}> {selectedPlan?.perspectives?.length}</p>
                    </div>
                </div>
                <ToggleExpandButton onClick={() => setShowPerspectives(!showPerspectives)} expanded={showPerspectives}/>
            </div>
            {   showPerspectives &&
                selectedPlan?.perspectives?.map((item) => {
                    return <div key={item.id} className={styles.perspectiveListItem} onClick={() => onNavigateToDashboard(item)}>
                        <div className={styles.cardNameAndStatus}>
                            <p>{item.name}</p>
                            <PerspectiveStatusComponent perspective={item}/>
                        </div>
                        <div className={styles.itemIconAndCountWrapper}>
                            <div className={styles.itemIconAndCount}>
                                {getItemIcon(ROW_ITEM_TYPES.GOAL, 24)}
                                <p className={styles.itemsLength}> {item?.goals?.length + item?.sharedGoals?.length}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
  );
};

export default SidebarPlanComponent;
