import dayjs from "dayjs";

export const removeEmptyAttributesFromObject = (data) => Object.fromEntries(
  Object.entries(data).filter(([_, value]) => value !== '')
);

export const replacePayloadEmptyStringWithNull = (data) => {
  const result = {};
  for (const key in data) {
    result[key] = data[key] === '' ? null : data[key];
  }
  return result;
}

export const getMinMaxDatesFromItemAndParent = (itemAndParentForAction) => {
  let startDate = null;
  let endDate = null;
  if(itemAndParentForAction?.item?.startDate && itemAndParentForAction?.item?.endDate) {
    startDate = dayjs(itemAndParentForAction?.item?.startDate);
    endDate = dayjs(itemAndParentForAction?.item?.endDate);
  } else if(itemAndParentForAction?.parent?.startDate && itemAndParentForAction?.parent?.endDate) {
    startDate = dayjs(itemAndParentForAction?.parent?.startDate);
    endDate = dayjs(itemAndParentForAction?.parent?.endDate);
  }

  return {startDate, endDate}
}
