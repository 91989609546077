import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  CREATE_GROUP_SAGA,
  DELETE_GROUP_SAGA,
  DELETE_SCORECARD_SAGA,
  DELETE_SCORECARD_SAGA_FROM_WIDGET,
  EDIT_GROUP_SAGA,
  GET_GROUP_SAGA,
  GET_RIGHT_SIDEBAR_SCORECARD_SAGA,
  GET_SCORECARD_LIST_DATA_SAGA,
  MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA,
  MANAGE_SCORECARD_SAGA,
  REORDER_SCORECARD_ITEMS_SAGA,
} from './actionTypes';
import {
  getRightSidebarScorecardSaga, isGroupIdsSet, setAllScorecards, setDeletePlanItemFromWidget,
  setExpandedGroups,
  setGroup, setGroupIds,
  setGroupsWithScorecards,
  setRightSidebarScorecard
} from './actions';
import {refreshPageSaga, setActionModalFromNavbar, setModal, setRefresh, setWarningModal} from '../helper/actions';
import {replacePayloadEmptyStringWithNull} from "../../utils/objects";
import scorecardService from '../../services/ScorecardService';
import {setSnackbar} from "../snackbar/actions";
import {push} from "connected-react-router";
import {SCORECARDS} from "../../routesConstants";
import {getOrganizationActivitySagaFn} from "../helper/sagas";
import {expandedGroupsSelector, isGroupIdsSetSelector} from "./selectors";
import {setLoader} from "../loader/actions";
import {rightModalSelector} from "../helper/selectors";
import {setStrategyPlan} from "../strategyPlan/actions";
import {terminologySelector} from "../auth/selectors";


export function* getScorecardListDataSaga(payload) {
  try {
    const groupsWithScorecards = yield call(scorecardService.getGroupsWithScorecards);
    const isGroupIdsSetUp = yield select(isGroupIdsSetSelector());
    if (!isGroupIdsSetUp) {
      let groupIds = [];
      groupsWithScorecards.groups.forEach((item) => {
        if(item.scorecards.length > 0) {
          groupIds.push(item.id);
        }
      })
      yield put(isGroupIdsSet(true));
      yield put(setGroupIds(groupIds));
    }
    const allScorecards = groupsWithScorecards.groups.concat(groupsWithScorecards.scorecards)
    allScorecards.sort(function(a, b) {
      return a.position - b.position;
    });
    yield put(setAllScorecards(allScorecards));
    yield put(setGroupsWithScorecards(groupsWithScorecards));
    yield call(getOrganizationActivitySagaFn, {payload: {page: 1, perPage: 5}});
    if (payload?.snackbar) {
      yield put(setSnackbar(payload.snackbar));
    }
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* reorderScorecardsItemSagaFn({payload}) {
  try {
    let groups = [];
    let scorecards = [];

    payload.forEach((item, index) => {
      if (item.scorecards) {
        groups.push(item)
      } else {
        scorecards.push(item)
      }
    });

    const data = {
      groups: groups,
      scorecards: scorecards
    }
    yield call(scorecardService.reorderGroups, data);
    const groupsWithScorecards = yield call(scorecardService.getGroupsWithScorecards);
    const allScorecards = groupsWithScorecards.groups.concat(groupsWithScorecards.scorecards)
    allScorecards.sort(function(a, b) {
      return a.position - b.position;
    });
    yield put(setAllScorecards(allScorecards));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* createGroupSaga({groupData}) {
  try {
    const data = {
      ...groupData,
      memberIds: groupData.memberIds.length > 0 ? groupData.memberIds.map((member) => member.id) : []
    };
    yield call(scorecardService.createGroup, data);

    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setActionModalFromNavbar({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${groupData.name} group has been created`,
    }));

    yield call(getScorecardListDataSaga);
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* editGroupSaga({groupData, groupId}) {
  try {
    yield call(scorecardService.editGroup, groupData, groupId);

    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${groupData.name} group has been changed`,
    }));

    yield put(push(SCORECARDS));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* manageScorecardSaga({scorecardData, create, scorecardId}) {
  try {
    const openedGroups = yield select(expandedGroupsSelector());
    const terminology = yield select(terminologySelector());
    const data = replacePayloadEmptyStringWithNull(scorecardData);

    if (create) {
      yield call(scorecardService.createScorecard, data);
    } else {
      const res = yield call(scorecardService.editScorecard, data, scorecardId);
      yield put(setStrategyPlan(res));
      yield put(setRefresh(true));
    }

    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setActionModalFromNavbar({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${scorecardData.name} ${terminology.plan} has been ${create ? 'created' : 'changed'}`,
    }));

    yield call(getScorecardListDataSaga);
    if (!openedGroups.includes(scorecardData.groupId)) {
      yield put(setExpandedGroups(scorecardData?.groupId));
    }
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* manageRightSidebarScorecardSaga({scorecardData, scorecardId}) {
  try {
    const terminology = yield select(terminologySelector());
    const data = replacePayloadEmptyStringWithNull(scorecardData);
    yield call(scorecardService.editScorecard, data, scorecardId);

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${scorecardData.name} ${terminology.plan} has been changed`,
    }));
    const rightModal = yield select(rightModalSelector());
    yield put(getRightSidebarScorecardSaga(scorecardId));
    yield put(refreshPageSaga({page: rightModal.page, scorecardId}))
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* getGroupSaga({groupId}) {
  try {
    const group = yield call(scorecardService.getGroup, groupId);
    yield put(setGroup(group));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* getRightSidebarScorecardSagaFn({scorecardId}) {
  try {
    const scorecard = yield call(scorecardService.getScorecard, scorecardId);
    yield put(setRightSidebarScorecard(scorecard));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* deleteGroupSaga({group}) {
  try {
    yield call(scorecardService.deleteGroup, group.id);

    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${group.name} group has been deleted`,
    }));

    yield call(getScorecardListDataSaga);
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* deleteScorecardSaga({scorecard, removeLinks}) {
  try {
    yield put(setLoader(true));
    const terminology = yield select(terminologySelector());
    const data = {
      removeLinks: removeLinks,
    };

    yield call(scorecardService.deleteScorecard, scorecard.id, data);

    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${scorecard.name} ${terminology.plan} has been deleted`,
    }));

    yield call(getScorecardListDataSaga);
    yield put(setWarningModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));
  } catch (error) {
    console.log({error});
    if (error.response.data?.additionalErrorProperty?.cannotBeDeleted) {
      yield put(setWarningModal({
        isVisible: true,
        title: '',
        maxWidth: 'md',
        component: null,
        cannotBeDeleted: true,
      }));
    }

    if (error.response.data?.additionalErrorProperty?.scorecardHasLinks) {
      yield put(setWarningModal({
        isVisible: true,
        title: '',
        maxWidth: 'md',
        component: null,
        scorecardHasLinks: true,
      }));
    }
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteScorecardFromMultiplePlansSnapshotSaga({scorecard, removeLinks, isFromForm}) {
  try {
    yield put(setLoader(true));
    const terminology = yield select(terminologySelector());
    const data = {
      removeLinks: removeLinks,
    };

    yield call(scorecardService.deleteScorecard, scorecard.id, data);
    if (!isFromForm) {
      yield put(setRefresh(true));
    }
    yield put(setDeletePlanItemFromWidget(scorecard));
    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${scorecard.name} ${terminology.plan} has been deleted`,
    }));

  } catch (error) {
    console.log({error});
    if (error.response.data?.additionalErrorProperty?.cannotBeDeleted) {
      yield put(setWarningModal({
        isVisible: true,
        title: '',
        maxWidth: 'md',
        component: null,
        cannotBeDeleted: true,
      }));
    }

    if (error.response.data?.additionalErrorProperty?.scorecardHasLinks) {
      yield put(setWarningModal({
        isVisible: true,
        title: '',
        maxWidth: 'md',
        component: null,
        scorecardHasLinks: true,
      }));
    }
  } finally {
    yield put(setLoader(false));
  }
}

export default function* scorecardSaga() {
  yield takeLatest(GET_SCORECARD_LIST_DATA_SAGA, getScorecardListDataSaga);
  yield takeLatest(REORDER_SCORECARD_ITEMS_SAGA, reorderScorecardsItemSagaFn);
  yield takeLatest(CREATE_GROUP_SAGA, createGroupSaga);
  yield takeLatest(EDIT_GROUP_SAGA, editGroupSaga);
  yield takeLatest(MANAGE_SCORECARD_SAGA, manageScorecardSaga);
  yield takeLatest(MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA, manageRightSidebarScorecardSaga);
  yield takeLatest(GET_GROUP_SAGA, getGroupSaga);
  yield takeLatest(GET_RIGHT_SIDEBAR_SCORECARD_SAGA, getRightSidebarScorecardSagaFn);
  yield takeLatest(DELETE_GROUP_SAGA, deleteGroupSaga);
  yield takeLatest(DELETE_SCORECARD_SAGA, deleteScorecardSaga);
  yield takeLatest(DELETE_SCORECARD_SAGA_FROM_WIDGET, deleteScorecardFromMultiplePlansSnapshotSaga);
}
