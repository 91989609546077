export const PERSPECTIVE = '[Perspective]';
export const UPDATE_PERSPECTIVE_SAGA = `${PERSPECTIVE} UPDATE_PERSPECTIVE_SAGA`;
export const UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA = `${PERSPECTIVE} UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA`;
export const ADD_NEW_PERSPECTIVE_SAGA = `${PERSPECTIVE} ADD_NEW_PERSPECTIVE_SAGA`;
export const SET_SHOW_EDIT_PERSPECTIVE_MODAL = `${PERSPECTIVE} SET_SHOW_EDIT_PERSPECTIVE_MODAL`;
export const RESET_STATE = `${PERSPECTIVE} RESET_STATE`;
export const ADD_EXISTING_PERSPECTIVES_SAGA = `${PERSPECTIVE} ADD_EXISTING_PERSPECTIVES_SAGA`;
export const SET_SHOW_PERSPECTIVE_LIBRARY_MODAL = `${PERSPECTIVE} SET_SHOW_PERSPECTIVE_LIBRARY_MODAL`;
export const ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA = `${PERSPECTIVE} ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA`;
export const EDIT_ITEM_FOR_PERSPECTIVE_SAGA = `${PERSPECTIVE} EDIT_ITEM_FOR_PERSPECTIVE_SAGA`;
export const EDIT_FULL_ITEM_SAGA = `${PERSPECTIVE} EDIT_FULL_ITEM_SAGA`;
export const MOVE_ITEM_SAGA = `${PERSPECTIVE} MOVE_ITEM_SAGA`;
export const CHANGE_GOAL_PARENT_SAGA = `${PERSPECTIVE} CHANGE_GOAL_PARENT_SAGA`;
export const EDIT_ITEM_STATUS_SAGA = `${PERSPECTIVE} EDIT_ITEM_STATUS_SAGA`;
