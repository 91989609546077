import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_HOME_DATA: '/home/:id',
  GET_ALL_UPCOMING_GOALS: '/home/all-upcoming-goals',
  GET_ALL_RECENT_UPDATES: '/home/all-updates/:id',
  GET_ALL_UPCOMING_PROJECTS_AND_ACTIONS: '/home/all-upcoming-projects-actions',
  GET_ALL_UPCOMING_PROJECTS_KPIS_ACTIONS: '/home/all-actions-projects-kpis',
};

class HomeService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getHomeData = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_HOME_DATA.replace(':id', id ? id : ''),
      method: HTTP_METHODS.GET,
    });
  };

  getAllUpcomingGoals = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_UPCOMING_GOALS,
      method: HTTP_METHODS.GET,
    });
  };

  getAllRecentUpdates = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_RECENT_UPDATES.replace(':id', id ? id : ''),
      method: HTTP_METHODS.GET,
    });
  };

  getAllUpcomingProjectsAndActions = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_UPCOMING_PROJECTS_AND_ACTIONS,
      method: HTTP_METHODS.GET,
    });
  };

  getAllUpcomingProjectsKpisActions = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_UPCOMING_PROJECTS_KPIS_ACTIONS,
      method: HTTP_METHODS.GET,
    });
  };
}

const homeService = new HomeService(httpService);

export default homeService;
