import React from 'react';
import styles from './CustomModalFooter.module.scss';
import DefaultButton from "../Buttons/DefaultButton";

const CustomModalFooter = ({config}) => {
  return (
    <div className={styles.container}>
      {config.map((button) => {
        return (
          <DefaultButton
            title={button.title}
            secondary={button.secondary}
            type={button.type}
            key={button.id}
            buttonStyles={{marginLeft: 8, height: 40}}
            onClick={button.onClick}
            disabled={button.disabled}
            backgroundColor={button.backgroundColor}
          />
        );
      })}
    </div>
  );
};

export default CustomModalFooter;
