import React from 'react';
import styles from './CustomDot.module.scss';

const CustomDot = ({
  radius = 8,
  backgroundColor,
  medium,
  large,
  containerStyles,
}) => {
  const getRadius = () => {
    switch (true) {
      case medium:
        return 12;
      case large:
        return 16;
      default:
        return radius;
    }
  };

  return (
    <div
      className={`${styles.container} ${containerStyles}`}
      style={{
        width: getRadius(), height: getRadius(),
        ...(backgroundColor && {backgroundColor: backgroundColor}),
      }}
    />
  );
};

export default CustomDot;
