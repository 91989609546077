import React from 'react';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning.svg';
import styles from './RemoveLinkedItemsContent.module.scss';
import CustomModalFooter from "../Shared/Modals/CustomModalFooter";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

const RemoveLinkedItemsContent = ({moveItem, closeModal}) => {
  const terminology = useSelector(terminologySelector());

  const footerConfig = [
    {id: 1, title: 'Cancel', secondary: true, onClick: closeModal, type: 'button'},
    {id: 2, title: 'Save', onClick: moveItem, type: 'button'},
  ];

  return (
    <div className={styles.container}>
      <WarningIcon width={48} height={48} />
      <h2 className={styles.title}>Remove Linked {terminology.plan}s and {terminology.perspective}s?</h2>
      <div className={styles.descriptionContainer}>
        <p className={styles.description}>Nested {terminology.goal}s inherit {terminology.plan}s and {terminology.perspective}s from the parent {terminology.goal}.</p>
        <p className={styles.description}>Adding or changing a parent {terminology.goal} will remove existing contributions</p>
      </div>
      <CustomModalFooter config={footerConfig} />
    </div>
  );
};

export default RemoveLinkedItemsContent;
