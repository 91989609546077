import React from "react";
import {MoreVert} from "@mui/icons-material";
import {ACTIONS} from "../../../../constants/strings";
import {COLORS} from "../../../../constants/colors";
import {ReactComponent as SettingsIcon} from '../../../../assets/icons/gear.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/icons/trash-can.svg';
import {ReactComponent as AlignIcon} from '../../../../assets/icons/manage.svg';

export const menuConfigListItem = ({onClick, terminology}) => {

    return {
        button:  (
            <div>
                <MoreVert style={{color: COLORS.darkestGray}} />
            </div>
        ),
        content: {
            menu: {
                items: [
                    {
                        id: 1,
                        title: `View ${terminology.plan}`,
                        icon: <AlignIcon/>,
                        onClick: () => onClick(ACTIONS.VIEW_PLAN),
                    },
                    {
                        id: 2,
                        title: 'View Alignment',
                        icon: <AlignIcon/>,
                        onClick: () => onClick(ACTIONS.VIEW_ALIGNMENT),
                    },
                    {
                        id: 3,
                        title: `${terminology.plan} Settings`,
                        onClick: () => onClick(ACTIONS.PLAN_SETTINGS),
                        icon: <SettingsIcon/>,
                    },
                    {
                        id: 4,
                        title: 'Delete',
                        onClick: () => onClick(ACTIONS.DELETE),
                        icon: <DeleteIcon color={COLORS.red}/>
                    }
                ],
            },
        },
    };
};
