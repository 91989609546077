import React, {useCallback, useEffect, useState} from 'react';
import styles from './JiraIntegration.module.scss'
import OutlinedSelectInput from "../../Inputs/OutlinedSelectInput";
import metricsService from "../../../../services/MetricsService";
import {useDispatch, useSelector} from "react-redux";
import {makeSelectUser} from "../../../../store/auth/selectors";
import CustomModal from "../../Modals/CustomModal";
import {warningModalSelector} from "../../../../store/helper/selectors";
import {setWarningModal} from "../../../../store/helper/actions";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {FormControl} from "@mui/material";
import CustomTextInput from "../../../CustomInputs/TextInput";

export function JiraIntegration({item, setFormValues, clearProp, errors, formValues, validateForm}) {
    const dispatch = useDispatch();
    const [connections, setConnections] = useState([])
    const [jiraProjects, setJiraProjects] = useState([])
    const [issues, setIssues] = useState([])
    const [formState, setFormState] = useState({})
    const user = useSelector(makeSelectUser());
    const warningModal = useSelector(warningModalSelector());
    const getConnections = async () => {
        try {
            const res = await metricsService.getJiraConnections()
            setConnections(res);
            return res
        } catch (e) {
            console.log('err', {e});
        }
    }

    const onChangeForm = (values) => {
        setFormState(prevState => ({...prevState, ...values}))
    }

    const onSelectConnection = async (val, setState = true, connections) => {
        let connection = connections?.find(item => item.id === val);
        try {
            if (setState) {
                await onChangeForm({jiraConnectionId: connection.id, jiraProjectId: '', jiraIssueId: ''})
            }
            return await getJiraProjects(connection.resourceId, connection.id);
        } catch (e) {
            console.log('err', {e});
        }
    }
    const onSelectProject = async (val) => {
        let connection = connections?.find(item => item.id === formState['jiraConnectionId']);
        try {
            onChangeForm({jiraProjectId: val, jiraIssueId: ''})
            setIssues([])
            let siteId = item?.actionJiraConnection?.jiraConnection.resourceId || connection.resourceId;
            let jiraConnectionId = item?.actionJiraConnection?.jiraConnection.id || formState['jiraConnectionId'];

            const issues = await metricsService.getJiraIssues(val, siteId, jiraConnectionId);

            const updatedIssues = issues.map(issue => {
                return {
                    id: issue.id,
                    name: issue.key,
                    subtitle: issue.fields.summary
                }
            }, [])
            setIssues(updatedIssues)
            return updatedIssues;
        } catch (e) {
            console.log('err', {e});
        }
    }

    const getJiraProjects = async (resourceId, connectionId) => {
        try {
            const res = await metricsService.getJiraProjects(resourceId, connectionId);
            setJiraProjects(res);
            return res;
        } catch (e) {
            console.log('err', {e});
        }
    }

    const findItemInsideArray = (itemId, array) => {
        return array.find(item => item?.id === itemId)?.id;
    }

    const setForEdit = async () => {
        const connections = await getConnections()
        const projects = await onSelectConnection(item?.actionJiraConnection?.jiraConnectionId, false, connections)
        const issues = await onSelectProject(item?.actionJiraConnection?.jiraProjectId)
        let form = {
            jiraConnectionId: item?.actionJiraConnection?.jiraConnectionId,
            jiraProjectId: findItemInsideArray(item?.actionJiraConnection?.jiraProjectId, projects),
            jiraIssueId: findItemInsideArray(item?.actionJiraConnection?.jiraIssueId, issues),
            jiraSearch: !item?.actionJiraConnection?.jiraLink,
            jiraLink: item?.actionJiraConnection?.jiraLink || '',
        }
        setFormState(prevState => ({...prevState, ...form}))
    }

    useEffect(() => {
        if (!item?.actionJiraConnection) {
            getConnections()
        }

        return () => {
            closeWarningModal();
        }
    }, []);

    useEffect(() => {
        if (item?.actionJiraConnection) {
            setForEdit()
        }
    }, [item])


    useEffect(() => {
        setFormValues(formState)
    }, [formState])

    useEffect(() => {
        if (!item?.actionJiraConnection) {
            onChangeForm({
                jiraSearch: false,
            });
            getConnections();
        }
    }, []);

    const closeWarningModal = useCallback(() => {
        dispatch(setWarningModal({
            isVisible: false,
            title: '',
            component: null,
        }));
    }, [dispatch]);

    const onRadioChange = (val) => {
        onChangeForm({
            jiraSearch: val === 'true',
        });
    };

    const getValue = () => {
        return formState['jiraSearch'] === true
    }

    useEffect(() => {
        if (formValues.jiraConnectionId || formValues.jiraProjectId || formValues.jiraIssueId) {
            validateForm();
        }
    }, [formValues]);

    return (
        <div className={styles.manuallyContainer}>
            <div>
                <p className={styles.firstStepSelectLabel}>Select connection<span
                    className={styles.errorMsg}>{errors?.jiraConnectionId && ` ${errors?.jiraConnectionId}`}</span>
                </p>
                <OutlinedSelectInput height={40} options={connections} value={formState['jiraConnectionId']}
                                     handleChange={(val) => onSelectConnection(val.target.value, true, connections)}/>
                {!user.jiraIntegration && <span
                    className={styles.errorMsg}>{`Please go to Integration section and create connection`}</span>}
                <FormControl>
                    <RadioGroup
                        value={getValue()}
                        onChange={(val) => onRadioChange(val.target.value)}
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                    >
                        <div style={{display: 'flex', padding: 12}}>
                            <FormControlLabel className={styles.height30px} value={false} control={<Radio/>}
                                              label="Paste Jira Issues Link"/>
                            <FormControlLabel className={styles.height30px} value={true}
                                              control={<Radio/>} label="Search Issue"
                            />
                        </div>
                    </RadioGroup>
                </FormControl>
                {
                  !formState['jiraSearch'] ?
                      <div>
                         <span
                             className={styles.errorMsg}>{errors?.jiraLink && ` ${errors?.jiraLink}`}</span>
                        <CustomTextInput placeholder={'https://'} value={formState['jiraLink']} handleInputChange={(val) => onChangeForm({jiraLink: val.target.value })} />
                      </div> :
                      <div>
                          <p className={styles.firstStepSelectLabel}>Select Project<span
                              className={styles.errorMsg}>{errors?.jiraProjectId && ` ${errors?.jiraProjectId}`}</span></p>
                          <OutlinedSelectInput disabled={!formState['jiraConnectionId']} height={40} options={jiraProjects}
                                               value={formState['jiraProjectId']}
                                               handleChange={(val) => onSelectProject(val.target.value)}/>
                          <p className={styles.firstStepSelectLabel}>Select Issue<span
                              className={styles.errorMsg}>{errors?.jiraIssueId && ` ${errors?.jiraIssueId}`}</span></p>
                          <OutlinedSelectInput disabled={!formState['jiraProjectId']} height={54} options={issues}
                                               value={formState['jiraIssueId']}
                                               handleChange={(val) => onChangeForm({jiraIssueId: val.target.value})}/>
                      </div>
                }
                </div>
            <CustomModal
                isVisible={warningModal.isVisible}
                title={warningModal.title}
                closeModal={closeWarningModal}
                children={warningModal.component}
                maxWidth={warningModal.maxWidth}
            />
        </div>
    )

}

export default JiraIntegration;

