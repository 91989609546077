import React, { memo } from 'react';
import styles from './indexStyle.module.scss';
import { ReactComponent as KpiIcon } from '../../../../../../src/assets/icons/taskTypes/kpi.svg';
import { ReactComponent as GoalIcon } from '../../../../../../src/assets/icons/goal.svg';
import { ReactComponent as ProjectIcon } from '../../../../../../src/assets/icons/taskTypes/project.svg';
import { ReactComponent as ActionIcon } from '../../../../../../src/assets/icons/taskTypes/dotted-circle.svg';
import TooltipComponent from "../../../Tooltip/TooltipComponent";
import {COLORS} from "../../../../../constants/colors";

const OverallHealthComponent = ({ data }) => {
    const calculatePercentage = (value, total) => {
        return total === 0 ? 0 : (value / total) * 100;
    };

    const renderBar = (lateCount, onTrackCount, atRiskCount, notTrackedCount) => {
        const total = lateCount + onTrackCount + atRiskCount + notTrackedCount;
        const latePercentage = calculatePercentage(lateCount, total);
        const onTrackPercentage = calculatePercentage(onTrackCount, total);
        const atRiskPercentage = calculatePercentage(atRiskCount, total);
        const notTrackedPercentage = calculatePercentage(notTrackedCount, total);
        return (
            <div className={styles.barContainer}>
                <div className={styles.barWrapper}>
                    <TooltipComponent
                        title={<div>
                            <p>{`Late (${lateCount || 0}) : ${latePercentage ? latePercentage.toFixed(2) : 0}%`}</p>
                            <p>{`On Track (${onTrackCount || 0}) : ${onTrackPercentage ? onTrackPercentage.toFixed(2) : 0}%`}</p>
                            <p>{`At Risk (${atRiskCount || 0}) : ${atRiskPercentage ? atRiskPercentage.toFixed(2) : 0}%`}</p>
                            <p>{`Not Tracked (${notTrackedCount || 0}) : ${notTrackedPercentage ? notTrackedPercentage.toFixed(2) : 0}%`}</p>
                        </div>}
                        children={
                        <div className={styles.bar}>
                            <div className={styles.barInner}>
                            <div
                                className={styles.late}
                                style={{ width: `${latePercentage}%` }}
                            >
                            </div>
                            <div
                                className={styles.onTrack}
                                style={{ width: `${onTrackPercentage}%` }}
                            >
                            </div>
                            <div
                                className={styles.atRisk}
                                style={{ width: `${atRiskPercentage}%` }}
                            >
                            </div>
                            <div
                                className={styles.notTracked}
                                style={{ width: `${notTrackedPercentage}%` }}
                            >
                            </div>
                            </div>
                        </div>
                    }/>
                </div>
            </div>
        );

    };

    return (
        <div className={styles.container}>
            <div className={styles.iconAndBarWrapper}>
                <GoalIcon color={COLORS.iconGray} width={16} height={16}/>
                {renderBar(data.goalLateCount, data.goalOnTrackCount, data.goalAtRiskCount, data.goalNotTrackedCount)}
            </div>
            <div className={styles.iconAndBarWrapper}>
                <KpiIcon />
                {renderBar(data.kpiLateCount, data.kpiOnTrackCount, data.kpiAtRiskCount, data.kpiNotTrackedCount)}
            </div>
            <div className={styles.iconAndBarWrapper}>
                <ActionIcon />
                {renderBar(data.actionLateCount, data.actionOnTrackCount, data.actionAtRiskCount, data.actionNotTrackedCount)}
            </div>
            <div className={styles.iconAndBarWrapper}>
                <ProjectIcon />
                {renderBar(data.projectLateCount, data.projectOnTrackCount, 0, data.projectNotTrackedCount)}
            </div>
        </div>
    );
};

export default memo(OverallHealthComponent);
