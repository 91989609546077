import React from 'react';
import styles from './ItemUpdatesHeader.module.scss';
import PostUpdateComponent from "./PostUpdateComponent";

const ItemUpdatesHeader = ({data, handleUpdate}) => {
  return (
    <div className={styles.container}>
      <PostUpdateComponent data={data} handleUpdate={handleUpdate} />
    </div>
  );
};

export default ItemUpdatesHeader;
