import React, {PureComponent} from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {COLORS} from "../../../constants/colors";
import styles from './indexStyle.module.scss';
import CustomDot from "../../Shared/Dot/CustomDot";
import {TASK_TYPES} from "../../../constants/strings";
import {itemHealthTransformer} from "../../../utils/dashboard";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={20} y={-3} dy={16} fontSize={10} textAnchor="end" fill={COLORS.legendBlack} transform="rotate(-20)">
                    {payload.value.length > 15 ? payload.value.slice(0,15) + '...' : payload.value}
                </text>
            </g>
        );
    }
}

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltipWrapper}>
        <p className={styles.title}>{label}</p>
        {payload.map((item, index) => {
          return (
            <div className={styles.tooltipItemWrapper} key={index}>
              <CustomDot backgroundColor={item.color} large />
              <h4 className={styles.tooltipItemName}>{itemHealthTransformer(item.name)}:</h4>
              <h4 className={styles.tooltipItemName}>{item.value}</h4>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

function HealthOfYourPlan({data, onChartClick}) {
  const terminology = useSelector(terminologySelector());

    return (
        <div style={{width: '100%', height: '100%'}}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 10,
                        bottom: 25,
                    }}
                    onClick={(e) => {
                        if (e && Object.keys(e).length > 0) {
                            onChartClick(TASK_TYPES.PERSPECTIVE, [e.activePayload[0]?.payload?.id]);
                        }
                    }}
                >
                    <Legend
                        wrapperStyle={{ bottom: '0px' }}
                        iconType={'circle'}
                        verticalAlign={'bottom'}
                        formatter={itemHealthTransformer}
                    />
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={<CustomizedAxisTick />} interval={0}/>
                    <YAxis label={{ value: `(# of ${terminology.goal}s)`, angle: -90, style: {fontSize: 12}, dx: -25 }}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend wrapperStyle={{bottom: '0px'}} iconType={'circle'} verticalAlign={'bottom'}/>
                    <Bar dataKey="late" stackId="a" fill={COLORS.red} />
                    <Bar dataKey="atRisk" stackId="a" fill={COLORS.brightOrange} />
                    <Bar dataKey="onTrack" stackId="a" fill={COLORS.brightGreen} />
                    <Bar dataKey="notStarted" stackId="a" fill={COLORS.darkerGray} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


export default HealthOfYourPlan;
