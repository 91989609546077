import produce from 'immer';
import {
  RESET_STATE, SET_PERSPECTIVE_ACTION,
} from './actionTypes';

export const initialState = {
  perspectiveAction: null,
};

/* eslint-disable default-case */
const perspectiveActionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_PERSPECTIVE_ACTION:
        draft.perspectiveAction = action.payload;
        break;
      case RESET_STATE:
        return initialState;
    }
  });

export default perspectiveActionsReducer;
