import React, {useCallback, useEffect, useState} from 'react';
import styles from './Metrics.module.scss'
import DefineYourTarget from "../DefineYourTargets";
import OutlinedSelectInput from "../../Inputs/OutlinedSelectInput";
import metricsService from "../../../../services/MetricsService";

export function MetricsComponent({
                                   metric,
                                   item,
                                   setFormValues,
                                   errors,
                                   clearProp,
                                   setFieldValue,
                                 }) {
  const [metrics, setMetrics] = useState([])
  const [selectedMetrics, setSelectedMetrics] = useState([])
  const onChangeMetric = (val) => {
    setSelectedMetrics([val.target.value])
    setFormValues({metricIds: [val.target.value].map(item => item.id)})
  }


  useEffect(() => {
    if(metric?.id) {
      setSelectedMetrics([metric])
    }
  }, [metric])


  const getMetrics = useCallback(async () => {
    try {
      const res = await metricsService.getAllMetrics();
      setMetrics(res)
    } catch (e) {
      console.log('err', {e});
    }
  }, []);

  useEffect(() => {
    getMetrics()
  }, [getMetrics])

  const getSelectedArr = (arr) => {

    return metrics.filter(metric => {
      let found = arr.find(item => item.id === metric.id)
      if (found) return found
    })
  }

  useEffect(() => {
    if(item && item.metrics && metrics.length > 0 && !metric) {
      setFormValues({metricIds: item.metrics.map(item => item.id)})
      setSelectedMetrics(getSelectedArr(item.metrics))
    }
  }, [item, metrics, metric])

  return (
    <div className={styles.manuallyContainer}>
      {!metric && <div className={styles.selectTargetWrapper}>
        <p>Metric<span
          className={styles.errorMsg}>{errors?.metricIds && ` ${errors?.metricIds}`}</span></p>
        <OutlinedSelectInput
          placeholder={'Select Metrics'}
          options={metrics}
          value={selectedMetrics[0] || ''}
          handleChange={onChangeMetric}
          fullItemValue
        />
      </div>}
      { selectedMetrics && <DefineYourTarget metrics={selectedMetrics} padding={0} errors={errors} item={item} setFieldValue={setFieldValue}
                         clearProp={clearProp} title={'Define your Targets'}
                         setFormValues={setFormValues}/>}
    </div>
  )

}

export default MetricsComponent;
