import {ACTIONS} from "../../../constants/strings";
import {COLORS} from "../../../constants/colors";

export const HEADER_MENU_ITEMS = ({terminology}) => {
  return [
    {
      id: 1,
      title: `Connect metric to ${terminology.kpi}`,
      action: ACTIONS.CONNECT_METRICS_TO_KPI,
      disabled: process.env.REACT_APP_METRIC_DISABLED === 'true'
    },
    {
      id: 2,
      title: 'Delete',
      action: ACTIONS.DELETE,
      titleColor: COLORS.red,
    },
  ];
};
