import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './RisksTab.module.scss';
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import {ReactComponent as RiskIcon} from '../../../assets/icons/warning-risk.svg';
import riskService from "../../../services/RiskService";
import {Divider} from "@mui/material";
import ToggleExpandButton from "../../Shared/Buttons/ToggleExpandButton";
import {ACTIONS} from "../../../constants/strings";
import RiskCard from "./RiskCard";
import {refreshPageSaga, setModal} from "../../../store/helper/actions";
import DeleteContent from "../../Shared/Modals/DeleteContent";
import {useDispatch, useSelector} from "react-redux";
import {modalSelector} from "../../../store/helper/selectors";
import CustomModal from "../../Shared/Modals/CustomModal";

const RisksContent = ({toggleForm, item, rightModal}) => {
    const dispatch = useDispatch();
    const [risks, setRisks] = useState(null);
    const [collapsedSections, setCollapsedSections] = useState({open: true, closed: true});
    const modal = useSelector(modalSelector());
    const [individualCardCollapse, setIndividualCardCollapse] = useState({});
    const [mitigations, setMitigations] = useState({});
    const getRisks = async () => {
        try {
            const res = await riskService.getRisks({parentType: item.type.toLowerCase(), parentId: item.id});
            setRisks(res);
            const initialCollapseState = {};
            res.forEach((risk) => {
                initialCollapseState[risk.id] = true;
            });
            // setIndividualCardCollapse(initialCollapseState);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getRisks();
    }, [])

    const initialModalState = useMemo(() => ({
        isVisible: false,
        title: '',
        component: null,
    }), []);

    const closeModal = useCallback(() => {
        dispatch(setModal(initialModalState));
    }, [dispatch, initialModalState]);

    const deleteItem = async (itemId) => {
        try {
            await riskService.deleteRisk(itemId);
            await getRisks();
            dispatch(refreshPageSaga({
                page: rightModal?.page,
                scorecardId: rightModal?.scorecardId,
                goalId: item.goalId,
                kpiId: item.kpiId,
                projectId: item.projectId,
                actionId: item.actionId,
            }));
            closeModal();
        } catch (err) {
            console.log(err);
        }
    }

    const closeRisk = async (itemId) => {
        try {
            await riskService.closeRisk(itemId, {status: 'closed'});
            await getRisks();
            closeModal();
        } catch (err) {
            console.log(err);
        }
    }

    const onCardMenuClick = async (item, action) => {
        switch (action) {
            case ACTIONS.DELETE:
                dispatch(setModal({
                    isVisible: true,
                    title: 'Delete Risk',
                    component: (
                        <DeleteContent
                            text={`Are you sure you want to delete Risk: ${item?.name}?`}
                            onPressClose={closeModal}
                            onPressConfirm={() => deleteItem(item.id)}
                        />
                    ),
                }));
            break;
            case ACTIONS.EDIT:
                toggleForm(item, ACTIONS.EDIT);
                break;
            case ACTIONS.RE_EVALUATE:
                toggleForm(item, ACTIONS.RE_EVALUATE);
                break;
            case ACTIONS.CLOSE_RISK:
                dispatch(setModal({
                    isVisible: true,
                    title: 'Close Risk',
                    component: (
                        <DeleteContent
                            text={`Are you sure you want to close Risk: ${item?.name}?`}
                            onPressClose={closeModal}
                            onPressConfirm={() => closeRisk(item.id)}
                        />
                    ),
                }));
                break;
            default:
                console.log('No action found');
        }
    }

    const calculateRiskCount = (minScore, maxScore) => {
        return risks?.filter(risk => risk.score >= minScore && risk.score <= maxScore).length;
    }

    const lowRiskCount = calculateRiskCount(0, 33);
    const moderateRiskCount = calculateRiskCount(34, 66);
    const highRiskCount = calculateRiskCount(67, 100);

    const submitMitigation = async (risk) => {
        try {
            const data = {
                name: mitigations[risk.id],
                riskId: risk.id,
            }
            await riskService.createMitigation(data)
            setMitigations({});
            await getRisks();
        } catch (err) {
            console.error(err);
        }
    }

    const checkMitigation = async (mitigation) => {
        try {
            await riskService.updateMitigation(mitigation.id, {completed: !mitigation.completed, name: mitigation.name});
            await getRisks();
        } catch (err) {
            console.log(err);
        }
    }

    const renderRiskCard = (risk) => {
        return (
            <RiskCard
                key={risk.id}
                risk={risk}
                onCardMenuClick={onCardMenuClick}
                getRisks={getRisks}
                individualCardCollapse={individualCardCollapse}
                onToggleCollapse={(id) => setIndividualCardCollapse(prevState => ({
                    ...prevState,
                    [id]: !prevState[id]
                }))}
                mitigations={mitigations}
                setMitigations={(value) => setMitigations(value)}
                submitMitigation={submitMitigation}
                checkMitigation={checkMitigation}
            />
        );
    };

    return (
        <div>
            {
                risks?.length > 0 &&
                <div>
                    <div className={styles.risksDataWrapper}>
                        <div className={styles.addNewRiskButtonWrapper}>
                            <DefaultButton title={'+ Add new risk'} onClick={() => toggleForm()}/>
                        </div>
                        <div className={styles.risksCounterWrapper}>
                            <div className={styles.sideCounters}>
                                <p className={styles.counterDataNumber}>{lowRiskCount}</p>
                                <p className={styles.counterDataTitle}>Low Risk</p>
                            </div>
                            <div className={styles.centerCounter}>
                                <p className={styles.counterDataNumber}>{moderateRiskCount}</p>
                                <p className={styles.counterDataTitle}>Moderate Risk</p>
                            </div>
                            <div className={styles.sideCounters}>
                                <p className={styles.counterDataNumber}>{highRiskCount}</p>
                                <p className={styles.counterDataTitle}>High Risk</p>
                            </div>
                        </div>
                    </div>
                    <Divider/>
                    <div className={styles.openRisksWrapper}>
                        <div onClick={() => setCollapsedSections((prevState) => ({...prevState, open: !prevState.open}))}
                             className={styles.sectionCollapse}>
                            <p className={styles.sectionTitle}>Open</p>
                            <ToggleExpandButton expanded={collapsedSections.open}/>
                        </div>
                        {collapsedSections.open && risks.filter(risk => risk.status === 'open').map(renderRiskCard)}
                    </div>
                    <Divider/>
                    <div>
                        <div
                            onClick={() => setCollapsedSections((prevState) => ({...prevState, closed: !prevState.closed}))}
                            className={styles.sectionCollapse}>
                            <p className={styles.sectionTitle}>Closed</p>
                            <ToggleExpandButton expanded={collapsedSections.closed}/>
                        </div>
                        {
                            collapsedSections.closed && risks.filter(risk => risk.status === 'closed').map(renderRiskCard)
                        }
                    </div>
                </div>
            }
            { risks?.length === 0 &&
                <div>
                    <div className={styles.noContentComponentImageWrapper}>
                        <RiskIcon width={160} height={160}/>
                    </div>
                    <p className={styles.title}>Start taking Action</p>
                    <p className={styles.subtitle}>Identify the potential risks that could jeopardize your strategy
                        execution. Prioritize risks based on the impact and probability of occurring.</p>
                    <div className={styles.buttonWrapper}>
                        <DefaultButton onClick={() => toggleForm()} title={'Create a Risk'} width={'30%'}/>
                    </div>
                </div>
            }
            <CustomModal
                isVisible={modal.isVisible}
                title={modal.title}
                closeModal={closeModal}
            >
                {modal.component}
            </CustomModal>
        </div>
    );
};

export default RisksContent;
