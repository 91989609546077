import React from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {defaultFormatDate} from "../../utils/format";
import {getNestedObjValue} from "../../utils/table";
import styles from "./Tables.module.scss";

const SimpleTableComponent = ({config, data}) => {
  const tableCell = (item, row) => {
    switch (item.type) {
      case 'string':
        return row.value;
      case 'date':
        return defaultFormatDate(getNestedObjValue(row, item.value));
      case 'component':
        return item.component;
      default:
        console.log('no matching type selected');
        return (
          <div />
        );
    }
  };

  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <Table>
          <TableHead>
            <TableRow>
              {config().map((row, index) => {
                return (
                  <TableCell key={index}>
                    {row.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow key={index}>
                  {config(row).map((item, index) => {
                    return (
                      <TableCell key={index}>
                        {tableCell(item, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <h4 className={styles.emptyTableTitle}>
            No results found
          </h4>
        )}
      </Box>
    </Box>
  );
};

export default SimpleTableComponent;
