import * as Yup from 'yup';
import {TRACKING_TYPE} from "../constants/strings";
import parse from "date-fns/parse";

export const createGroupSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  ownerId: Yup.number().required('This field is required'),
});

export const editGroupSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  ownerId: Yup.number().required('This field is required'),
});


export const createScorecardSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  templateId: Yup.number().required('This field is required'),
});

export const editScorecardSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
});

export const createGoalSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  startDate: Yup.date().required().typeError('Please enter a valid date'),
  endDate: Yup.date().required().typeError('Please enter a valid date'),
  planId: Yup.string().required('This field is required'),
  parentId: Yup.string().required('This field is required'),
  day: Yup.string().required('This field is required'),
});

export const createProjectSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  startDate: Yup.date().required().typeError('Please enter a valid date'),
  endDate: Yup.date().required().typeError('Please enter a valid date'),
  day: Yup.string().required('This field is required'),
});

export const createActionSchema = Yup.object().shape({
    jiraConnectionId: Yup.string()
        .when('tracking', {
            is: (val) => val !== null && val !== undefined && val === 'jira',
            then: (schema) => schema.required('*Field is required'),
            otherwise: (schema) => schema.nullable(),
        }),
    jiraLink: Yup.string()
        .matches(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            'Enter correct url!'
        )
        .when('jiraSearch', {
            is: (val) => val === false && val !== null && val !== undefined,
            then: (schema) => schema.required('*Field is required'),
            otherwise: (schema) => schema.nullable(),
        }),
    jiraProjectId: Yup.string()
        .when('jiraSearch', {
            is: (val) => val === true,
            then: (schema) => schema.required('*Field is required'),
            otherwise: (schema) => schema.nullable(),
        }),
    jiraIssueId: Yup.string()
        .when('jiraSearch', {
            is: (val) => val === true,
            then: (schema) => schema.required('*Field is required'),
            otherwise: (schema) => schema.nullable(),
        }),
  name: Yup.string().required('This field is required'),
  startDate: Yup.date().required('This field is required').typeError('Please enter a valid date'),
  endDate: Yup.date().required('This field is required').typeError('Please enter a valid date'),
  milestones: Yup.array().of(
    Yup.object().shape({
      percentage: Yup.number()
        .required("percentage is required")
        .positive("percentage can only be a positive number")
        .min(0, "percentage can not be a negative number")
        .test({name: 'percentage value', message:'Each percentage must be higher than the previous one',
        test: (val, testContext) => {
          let arr = testContext.options.context.milestones;
          let milestoneIndex = testContext.options?.index;

          if(arr[milestoneIndex - 1]?.percentage > val) {
            return false
          }
          return true
        }}),
      name: Yup.string()
        .required("Name is required"),
      date: Yup.date()
        .required("Date is required")
        .test({
          name: 'date order',
          message: 'Each date must be later than the previous one',
          test: (val, testContext) => {
            let arr = testContext.options.context.milestones;
            let milestoneIndex = testContext.options.index;

            if (milestoneIndex > 0 && new Date(arr[milestoneIndex - 1]?.date) >= new Date(val)) {
              return false;
            }
            return true;
          }
        }),
    })
  ).min(2, '*Must have at least two milestones'),
  checklists: Yup.array().of(
      Yup.object().shape({
          name: Yup.string().required('Name is required'),
          date: Yup.date().required('Date is required'),
      })
  ).min(2, '*Must have at least two checklists'),
});

export const createKpiSchema = ({trackingTypeRequired = false}) => {
    return Yup.object().shape({
      googleSheetConnectionId: Yup.string()
        .when('tracking', {
          is: (val) => val !== null && val !== undefined && val === 'googleSheet',
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      microsoftConnectionId: Yup.string()
        .when('tracking', {
          is: (val) => val !== null && val !== undefined && val === 'microsoftExcel',
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      resourceId: Yup.string()
        .when('tracking', {
          is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      resourceName: Yup.string()
        .when('tracking', {
          is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      dateIdentifier: Yup.string()
        .when('tracking', {
            is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      progressIdentifier: Yup.string()
        .when('tracking', {
            is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      targetIdentifier: Yup.string()
        .when('tracking', {
            is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      cumulative: Yup.string()
        .when('tracking', {
            is: (val) => val !== null && val !== undefined && (val === 'googleSheet' || val === 'microsoftExcel'),
          then: (schema) => schema.required('*Field is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        name: Yup.string().required('This field is required'),
        startDate: Yup.date().required().typeError('Please enter a valid date'),
        endDate: Yup.date().required().typeError('Please enter a valid date'),
        tracking: Yup.string(),
        trackingType: trackingTypeRequired ? Yup.string().required('*Field is required') : Yup.string().when('tracking', {
        is: (val) => val !== null && val !== undefined && val === 'metric',
        then: (schema) => schema.required('*Field is required'),
        otherwise: (schema) => schema.nullable(),
      }),
        metricIds: Yup.array()
          .when('tracking', {
            is: (val) => val !== null && val !== undefined && val === 'metric',
            then: (schema) => schema.min(1, '*At least one metric must be selected'),
            otherwise: (schema) => schema.nullable(),
          }),
        unit: Yup.string()
            .when('trackingType', {
                is: (val) => val !== null && val !== undefined,
                then: (schema) => schema.required('*Field is required'),
                otherwise: (schema) => schema.nullable(),
            }),
        min: Yup.number()
            .when(TRACKING_TYPE.TYPE, {
                is: (val) => val !== null && val !== undefined,
                then: (schema) => schema.required('*Field is required'),
                otherwise: (schema) => schema.nullable(),
            })
            .when(['upperTarget', 'trackingType'], {
                is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null && (trackingType === TRACKING_TYPE.BELOW || trackingType === TRACKING_TYPE.BETWEEN),
                then: (schema) => schema.max(Yup.ref('upperTarget'), "Value must be lower than Upper Target")
            })
            .when(['lowerTarget', 'trackingType'], {
                is: (lowerTarget, trackingType) => lowerTarget !== undefined && lowerTarget !== null && (trackingType === TRACKING_TYPE.EXCEED || trackingType === TRACKING_TYPE.BETWEEN),
                then: (schema) => schema.max(Yup.ref('lowerTarget'), "Value must be lower than Lower Target")
            }),
        max: Yup.number()
            .when(TRACKING_TYPE.TYPE, {
                is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.EXCEED || val === TRACKING_TYPE.BETWEEN || val === TRACKING_TYPE.BELOW),
                then: (schema) => schema.required('*Field is required'),
                otherwise: (schema) => schema.nullable(),
            })
            .when(['upperTarget', 'trackingType'], {
                is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null && (trackingType === TRACKING_TYPE.BETWEEN || trackingType === TRACKING_TYPE.BELOW),
                then: (schema) => schema.min(Yup.ref('upperTarget'), "Value must be higher than Upper Target")
            }),
        lowerTarget: Yup.number()
            .when(TRACKING_TYPE.TYPE, {
                is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.EXCEED || val === TRACKING_TYPE.ACHIEVED || val === TRACKING_TYPE.BETWEEN),
                then: (schema) => schema.required('*Field is required'),
                otherwise: (schema) => schema.nullable(),
            })
            .when(['max', 'trackingType'], {
                is: (max, trackingType) => max !== undefined && max !== null && (trackingType === TRACKING_TYPE.BELOW || trackingType === TRACKING_TYPE.BETWEEN || trackingType === TRACKING_TYPE.EXCEED),
                then: (schema) => schema.max(Yup.ref('max'), "Value must be lower than Highest Value")
            })
            .when(['upperTarget', 'trackingType'], {
                is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null && trackingType === TRACKING_TYPE.BETWEEN,
                then: (schema) => schema.max(Yup.ref('upperTarget'), "Value must be lower than Upper Target")
            }),
        upperTarget: Yup.number()
            .when(TRACKING_TYPE.TYPE, {
                is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.BELOW || val === TRACKING_TYPE.BETWEEN),
                then: (schema) => schema.required('*Field is required'),
                otherwise: (schema) => schema.nullable(),
            })
    });
};

export const addTrackingToKpiSchema = Yup.object({
  googleSheetConnectionId: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  resourceId: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  resourceName: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  dateIdentifier: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  progressIdentifier: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  targetIdentifier: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  cumulative: Yup.string()
    .when('tracking', {
      is: (val) => val !== null && val !== undefined && val === 'googleSheet',
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  trackingType: Yup.string().required('*Field is required'),
  tracking: Yup.string(),
  metricIds: Yup.array()
    .when('tracking', {
      is: (val) => (val !== null) && (val !== undefined) && (val === 'metric'),
      then: (schema) => schema.min(1, '*At least one metric must be selected'),
      otherwise: (schema) => schema.nullable(),
    }),
  unit: Yup.string()
    .when(TRACKING_TYPE.TYPE, {
      is: (val) => val !== null && val !== undefined,
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  min: Yup.number()
    .when(TRACKING_TYPE.TYPE, {
      is: (val) => val !== null && val !== undefined,
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    })

    .when(['upperTarget', 'trackingType'],{
      is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null && (trackingType === TRACKING_TYPE.BELOW || trackingType === TRACKING_TYPE.BETWEEN)  ,
      then: (schema ) => schema.max(Yup.ref('upperTarget'), "Value must be lower than Upper Target")
    })
    .when(['lowerTarget', 'trackingType'],{
      is: (lowerTarget, trackingType) => lowerTarget !== undefined && lowerTarget !== null && (trackingType === TRACKING_TYPE.EXCEED || trackingType === TRACKING_TYPE.BETWEEN)  ,
      then: (schema ) => schema.max(Yup.ref('lowerTarget'), "Value must be lower than Lower Target")
    }),
  max: Yup.number()
    .when(TRACKING_TYPE.TYPE, {
      is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.EXCEED || val === TRACKING_TYPE.BETWEEN || val === TRACKING_TYPE.BELOW),
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    })
    .when(['upperTarget', 'trackingType'],{
      is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null &&  (trackingType === TRACKING_TYPE.BETWEEN || trackingType === TRACKING_TYPE.BELOW),
      then: (schema ) => schema.min(Yup.ref('upperTarget'), "Value must be higher than Upper Target")
    }),
  lowerTarget: Yup.number()
    .when(TRACKING_TYPE.TYPE, {
      is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.EXCEED || val === TRACKING_TYPE.ACHIEVED || val === TRACKING_TYPE.BETWEEN),
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    })
    .when(['max', 'trackingType'],{
      is: (max, trackingType) => max !== undefined && max !== null && (trackingType === TRACKING_TYPE.BELOW || trackingType === TRACKING_TYPE.BETWEEN || trackingType === TRACKING_TYPE.EXCEED),
      then: (schema ) => schema.max(Yup.ref('max'), "Value must be lower than Highest Value")
    })
    .when(['upperTarget', 'trackingType'],{
      is: (upperTarget, trackingType) => upperTarget !== undefined && upperTarget !== null &&  trackingType === TRACKING_TYPE.BETWEEN,
      then: (schema ) => schema.max(Yup.ref('upperTarget'), "Value must be lower than Upper Target")
    }),
  upperTarget: Yup.number()
    .when(TRACKING_TYPE.TYPE, {
      is: (val) => val !== null && val !== undefined && (val === TRACKING_TYPE.BELOW || val === TRACKING_TYPE.BETWEEN),
      then: (schema) => schema.required('*Field is required'),
      otherwise: (schema) => schema.nullable(),
    })
});
