import React from 'react';
import ItemUpdateComponentListItem from "./ItemUpdatesComponentListItem";

const ItemUpdatesComponentList = ({data, type}) => {
  return (
    <div>
      {data.map((item) => {
        return (
          <ItemUpdateComponentListItem item={item} key={item.id} type={type} />
        );
      })}
    </div>
  );
};

export default ItemUpdatesComponentList;
