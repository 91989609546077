import React, {useState} from 'react';
import styles from './ContributesComponent.module.scss';
import {ReactComponent as AlignIcon} from '../../../assets/icons/alignment.svg';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrowDown.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/editIcon.svg';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus-circle.svg';
import {ReactComponent as GoalIcon} from '../../../assets/icons/goal.svg';
import {ReactComponent as TrashIcon} from '../../../assets/icons/trash-can.svg';
import ContributesForm from "./ContributesForm";
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import {COLORS} from "../../../constants/colors";
import EditParent from "./EditParent";
import {useDispatch, useSelector} from "react-redux";
import {
    optionsForEditParentSelector, showEditParentSelector,
    strategyPlanSelector
} from "../../../store/strategyPlan/selectors";
import {getEditParentDataSaga,
    moveItemFromEditParentSaga, setShowEditParent,
} from "../../../store/strategyPlan/actions";
import MovePerspectiveItem from "../../MovePerspectiveItem/MovePerspectiveItem";
import {changeGoalParentSaga} from "../../../store/perspectives/actions";
import {rightModalSelector} from "../../../store/helper/selectors";
import {getItemParent} from "../../../utils/perspective";
import {terminologySelector} from "../../../store/auth/selectors";

function ContributesToComponent({item, setFormikField, showForms = true, goals, parent, canEditParent = true, scorecard}) {
    const [form, setForm] = useState([]);
    const [show, setShow] = useState(false);
    const [newGoalIds, setNewGoalIds] = useState([]);
    const [contributionGoals, setContributionGoals] = useState(item?.contributionGoals || []);
    const [errorForm, setErrorForm] = useState(false);
    const extractGoalIds = (contributionGoals) => {
        return contributionGoals.map((goal) => goal.id);
    };
    const showEditForm = useSelector(showEditParentSelector());
    const optionsForEditForm = useSelector(optionsForEditParentSelector());
    const strategyPlan = useSelector(strategyPlanSelector());
    const rightModal = useSelector(rightModalSelector());
    const terminology = useSelector(terminologySelector());

    const dispatch = useDispatch();
    const onAddForm = () => {
        if (form.length) {
            const checkingLastForm = form[form.length - 1];
            if (checkLastFormValidation(checkingLastForm)) {
                const formObject = {
                    selectedOption: '',
                    secondField: '',
                    thirdField: '',
                    fourthField: '',
                    id: form.length

                }
                setForm([...form, formObject]);
            } else {
                setErrorForm(true);
            }
        } else {
            const formObject = {
                selectedOption: '',
                secondField: '',
                thirdField: '',
                fourthField: '',
                id: form.length

            }
            setForm([...form, formObject]);
        }
    }

    const checkLastFormValidation = (lastForm) => {
        if (Number(lastForm.selectedOption) === 2) {
            return !!lastForm.secondField;
        } else {
            return !!lastForm.fourthField;
        }
    }

    const onDeleteForm = (val) => {
        let newForm = form.filter((element, index) => {
            return index !== val;
        });
        formatDataForFormik(newForm);
        setForm(newForm);
    }

    const formatDataForFormik = (data) => {
        let goalIds = [];
        // eslint-disable-next-line array-callback-return
        data.map((item, index) => {
            if (Number(item.selectedOption) === 2) {
                if (item.secondField) {
                    goalIds.push(item.secondField);
                }
            } else {
                if (item.fourthField) {
                    goalIds.push(item.fourthField)
                }
            }
        });
        setNewGoalIds(goalIds);
        const combinedIds = [...goalIds, ...extractGoalIds(contributionGoals)]
        setFormikField(combinedIds);
    }

    const handleOnChangeSelectField = (value, index, type) => {
        setErrorForm(false);
        let changedForm = [...form];

            changedForm[index][type] = value;
            if (type === 'selectedOption') {
                changedForm[index].selectedOption = value;
                changedForm[index].secondField = '';
                changedForm[index].thirdField = '';
                changedForm[index].fourthField = '';
            } else {
                changedForm[index][type] = value;
            }
            setForm(changedForm);
        formatDataForFormik(changedForm);
    }

    const onBoxShow = () => {
        setShow(!show);
    }


    const handleRemoveGoal = async (goalId) => {
        const updatedGoals = await contributionGoals?.filter((el) => el.id !== goalId);
        await setContributionGoals(updatedGoals);
        const combinedIds = [...newGoalIds, ...extractGoalIds(updatedGoals)]
        await setFormikField(combinedIds);
    }

    const onEditClick = () => {
        dispatch(getEditParentDataSaga(item));
    }
    const onSaveEditParent = (val) => {
        dispatch(moveItemFromEditParentSaga({item, parent: {...val, strategyPlanName: parent.scorecard}, strategyPlan, page: rightModal.page}))

    }

    const closeEditParent = () => {
        dispatch(setShowEditParent(false));
    };

    const changeParent = (item, parentItemId, parentItemType, retainContribution, isParentGoal, removeLinks) => {
        dispatch(changeGoalParentSaga(
            item,
            parentItemId,
            parentItemType,
            retainContribution,
            isParentGoal,
            scorecard.id,
            removeLinks,
        ));
    };

    const getEditParentComponent = () => {
        switch (item?.type) {
            case ROW_ITEM_TYPES.ACTION:
            case ROW_ITEM_TYPES.KPI:
            case ROW_ITEM_TYPES.PROJECT:
                return (
                    <EditParent
                      item={item}
                      parent={getItemParent(item)}
                      options={optionsForEditForm}
                      onCancel={() => dispatch(setShowEditParent(false))}
                      onSave={onSaveEditParent}
                    />
                );
            case ROW_ITEM_TYPES.GOAL:
                return (
                    <MovePerspectiveItem
                        data={item}
                        isParentGoal={false}
                        closeModal={closeEditParent}
                        moveItem={changeParent}
                        scorecard={scorecard}
                    />
                );
            default:
                console.log('no matching type selected');
                return <div />;
        }
    };

    return (
        <div className={styles.wrapper} style={{height: show ? 'auto' : '70px'}}>
            <div className={styles.titleWrapper}>
                <div className={styles.leftTitleWrapper}>
                    <AlignIcon/>
                    <p className={styles.titleText}>Contributes To</p>
                </div>
                <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: show ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                    <ArrowDown/>
                </button>
            </div>
            {!showEditForm ?
              <div>
                <div className={styles.parentTitleDiv}>
                    <p>Parent</p>
                </div>
                <div className={styles.scorecardNameWrapper}>
                    <div className={styles.leftMiddle}>
                        {parent?.icon}
                        <div className={styles.subTitleWrapper}>
                            <p className={styles.scorecardTitle}>{parent?.name}</p>
                            <p className={styles.scorecardSubTitle}>{parent?.scorecard}</p>
                        </div>
                    </div>
                    {canEditParent && (
                        <button type={'button'} className={styles.arrowDownBtn} onClick={onEditClick}>
                            <EditIcon/>
                        </button>
                    )}
                </div>
              </div>
            :
              <div>
                  {getEditParentComponent()}
              </div>
            }
            { item?.contributionGoals?.length > 0 && !showForms &&
                <div className={styles.parentTitleDiv}>
                    <p>Other contributions</p>
                </div>
            }
            { item?.contributionGoals?.map((el) => {
                return !showForms && (
                <div key={el.id}>
                    <div className={styles.scorecardNameWrapper}>
                        <div className={styles.leftMiddle}>
                            <GoalIcon width={30} height={30}/>
                            <div className={styles.subTitleWrapper}>
                                <p className={styles.scorecardTitle}>{el?.name}</p>
                                <p className={styles.scorecardSubTitle}>{el?.perspective?.scorecard?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
                })
            }
            {
                showForms && (
                    <div>
                        <div className={styles.otherContributionWrapper}>
                            <p>Other Contribution</p>
                            <button type={'button'} onClick={onAddForm} className={styles.arrowDownBtn}>
                                <PlusIcon color={'black'}/>
                            </button>
                        </div>
                        { contributionGoals?.map((el) => {
                            return (
                                <div key={el.id}>
                                    <div className={styles.scorecardNameWrapper}>
                                        <div className={styles.leftMiddle}>
                                            <GoalIcon width={30} height={30}/>
                                            <div className={styles.subTitleWrapper}>
                                                <p className={styles.scorecardTitle}>{el?.name}</p>
                                                <p className={styles.scorecardSubTitle}>{el?.perspective?.scorecard?.name}</p>
                                            </div>
                                        </div>
                                        <div className={styles.deleteBtn} onClick={()=>handleRemoveGoal(el.id)}>
                                            <TrashIcon color={COLORS.red} width={16} height={16}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                        {
                            form.length ?
                                <div>
                                    {
                                        form.map((val, index) => {
                                            return(
                                                <ContributesForm
                                                    currentGoal={item}
                                                    onUpdateForm={handleOnChangeSelectField}
                                                    onDeleteForm={onDeleteForm}
                                                    index={index}
                                                    key={index}/>
                                            )
                                        })
                                    }
                                </div> :
                                <div className={styles.noFormWrapper}>
                                    <p>This section will update based on the parent {terminology.plan} or {terminology.goal} you select</p>
                                </div>
                        }
                        {
                            errorForm && (
                                <div className={styles.errorFormWrapper}>
                                    <p>You need to complete your last form</p>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}


export default ContributesToComponent;
