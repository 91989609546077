export const INPUT_FORMATS_TYPES = {
  CUMULATIVE: 'cumulative',
  NON_CUMULATIVE: 'nonCumulative',
};

export const TOTAL_TYPES = {
  MOST_RECENT_VALUE: 'mostRecentValue',
  SUM_OF_VALUES: 'sumOfValues',
  AVG_OF_VALUES: 'avgOfValues',
};

export const TIME_INTERVAL_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  SEMI_ANNUALLY: 'semiAnnually',
  ANNUALLY: 'annually',
};

export const FORMAT_TIME_INTERVAL = (timeInterval) => {
  switch (timeInterval) {
    case TIME_INTERVAL_TYPES.DAILY:
      return 'day';
    case TIME_INTERVAL_TYPES.WEEKLY:
      return 'week';
    case TIME_INTERVAL_TYPES.MONTHLY:
      return 'month';
    case TIME_INTERVAL_TYPES.QUARTERLY:
      return 'quarter';
    case TIME_INTERVAL_TYPES.SEMI_ANNUALLY:
      return 'half a year';
    case TIME_INTERVAL_TYPES.ANNUALLY:
      return 'year';
    default:
      return '';
  }
};

export const TREND_TYPES = {
  NO_TREND: 'noTrend',
  LOWER_IS_BEST: 'lowerIsBest',
  HIGHER_IS_BEST: 'higherIsBest',
};

export const METRIC_TYPES = {
  MANUALLY_TRACKED: 'manuallyTracked',
  SOURCE_CONNECTED: 'sourceConnected',
};

export const FORMAT_METRIC_DETAILS = (string) => {
  switch (string) {
    case INPUT_FORMATS_TYPES.CUMULATIVE:
      return 'Cumulative';
    case INPUT_FORMATS_TYPES.NON_CUMULATIVE:
      return 'Non-Cumulative';
    case TOTAL_TYPES.MOST_RECENT_VALUE:
      return 'Most recent value';
    case TOTAL_TYPES.SUM_OF_VALUES:
      return 'Sum of values';
    case TOTAL_TYPES.AVG_OF_VALUES:
      return 'Average of values';
    case TIME_INTERVAL_TYPES.DAILY:
      return 'Daily';
    case TIME_INTERVAL_TYPES.WEEKLY:
      return 'Weekly';
    case TIME_INTERVAL_TYPES.MONTHLY:
      return 'Monthly';
    case TIME_INTERVAL_TYPES.QUARTERLY:
      return 'Quarterly';
    case TIME_INTERVAL_TYPES.SEMI_ANNUALLY:
      return 'Semi-Annually';
    case TIME_INTERVAL_TYPES.ANNUALLY:
      return 'Annually';
    case TREND_TYPES.NO_TREND:
      return 'No Trend';
    case TREND_TYPES.LOWER_IS_BEST:
      return 'Lower is best';
    case TREND_TYPES.HIGHER_IS_BEST:
      return 'Higher is best';
    case METRIC_TYPES.MANUALLY_TRACKED:
      return 'Manually tracked';
    case METRIC_TYPES.SOURCE_CONNECTED:
      return 'Source connected';
    default:
      return string;
  }
};
