import React from 'react';
import {Menu, MenuItem, styled} from "@mui/material";
import styles from './CustomMenu.module.scss';

const StyledMenu = styled(Menu)`
    & .MuiPaper-root {
      border-radius: 8px;
    }

    & .MuiList-root {
      margin: 0;
      padding: 0;
    }
  `;

const CustomMenu = ({button, items, onClick, transitionDuration = 'auto'}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onItemClick = (e, item) => {
    e.stopPropagation();
    onClick(item);
    handleClose(e);
  };

  return (
    <div className={styles.button}>
      <div onClick={handleClick} className={styles.button}>
        {button}
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        transitionDuration={transitionDuration}
      >
        {items.map((item) => {
          return (
            <MenuItem onClick={(e) => onItemClick(e, item)} key={item.id} disabled={item.disabled}>
              <div>
                <div className={styles.itemContainer}>
                  {item.icon && <div className={styles.iconContainer}>{item.icon}</div>}
                  <h4 className={styles.itemTitle} style={{color: item.titleColor}}>{item.title}</h4>
                </div>
                <p className={styles.itemSubtitle} style={{color: item.titleColor}}>{item.subtitle}</p>
              </div>
            </MenuItem>
          )
        })}
      </StyledMenu>
    </div>
  );
};

export default CustomMenu;
