import React, {PureComponent} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import styles from './compareMultiple.module.scss';
import {COLORS} from "../../../constants/colors";
import CustomDot from "../../Shared/Dot/CustomDot";
import {formatUnitAndValue} from "../../../utils/perspective";
import {TASK_TYPES} from "../../../constants/strings";
import {getRandomColors} from "../../../utils/dashboard";

function CompareMultipleKpisByOwner({data, onChartClick, fromReport}) {
    let maxGoals = 0;
    data.forEach((dataEntry) => {
        maxGoals = Math.max(maxGoals, dataEntry.kpis.length);
    });
    let goalMap = [];
    for (let i = 0; i < maxGoals; i++) {
        goalMap.push(i);
    }

    const colorsArr = getRandomColors(maxGoals);

    const CustomTooltip = ({active, payload, label}) => {
      if (active && payload && payload.length) {
        return (
          <div className={styles.customTooltipWrapper}>
            <p className={styles.title}>{payload[0].payload.name}</p>
            {payload[0]?.payload?.kpis.map((item, index) => {
              return (
                <div className={styles.tooltipItemWrapper} key={index}>
                  <CustomDot backgroundColor={colorsArr[index]} />
                  <h4 className={styles.tooltipItemName}>{item.name}:</h4>
                  <h4 className={styles.tooltipItemName}>{item.unit ? formatUnitAndValue(item.unit, item.actualValue) : '%'}</h4>
                </div>
              );
            })}
          </div>
        );
      }

      return null;
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    {payload.value.split(' ').map((word, index) => (
                        <text
                            key={index}
                            x={0}
                            y={-12 + index * 16}
                            dy={16}
                            fontSize={13}
                            textAnchor="end"
                            fill={COLORS.legendBlack}
                            transform="rotate(0)"
                        >
                            <tspan>{word}</tspan>
                        </text>
                    ))}
                </g>
            );
        }
    }

    const CustomLegend = () => {
        return (
            <div className={styles.legendWrapper}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className={styles.legendItemWrapper}>
                                <div className={styles.kpiWrapper}>
                                    {
                                        item.kpis.map((val, ind) => {
                                            return (
                                                <div key={ind} className={styles.innerItem}>
                                                    <div className={styles.circleDiv} style={{background: colorsArr[ind]}} />
                                                    <p>{val.name}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        );
    };



    return (
        <div className={styles.mainContainer}>
            <div className={styles.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        onClick={(e) => {
                            if (e.activePayload?.length > 0) {
                                onChartClick(TASK_TYPES.KPI, e.activePayload[0].payload.kpis.map((kpi) => kpi.id));
                            }
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            tickFormatter={(value) => `${formatUnitAndValue(data[0].kpis[0].unit, value)}`}
                            type="number" />
                        <YAxis tick={<CustomizedAxisTick />} dataKey="name" type="category" />
                        <Tooltip content={<CustomTooltip/>} wrapperStyle={{zIndex: 1000}}/>
                        {
                            goalMap.map((entry, index) => {
                                return (
                                    <Bar
                                        key={entry}
                                        dataKey={`kpis.${entry}.actualValue`}
                                        fill={colorsArr[index]}
                                    />
                                )})
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className={fromReport ? styles.legendFromReportContainer : styles.legendContainer}>
                    <Legend wrapperStyle={{position:'relative'}} content={<CustomLegend />} />
            </div>
        </div>
    );
}

export default CompareMultipleKpisByOwner;
