import {
  GET_GOALS_ALIGNMENT_SAGA,
  GET_GOALS_ALIGNMENT_WITH_FILTERS_SAGA,
  SET_ALIGNED_GOALS_ALIGNMENT_NODES,
  SET_ALL_GOALS_ALIGNMENT_NODES,
  SET_GOALS_ALIGNMENT_EDGES,
  SET_GOALS_ALIGNMENT_FILTERS,
  SET_GOALS_ALIGNMENT_MODAL,
  SET_UNALIGNED_GOALS_ALIGNMENT_NODES,
} from './actionTypes';

export function getGoalsAlignmentSaga() {
  return {
    type: GET_GOALS_ALIGNMENT_SAGA,
  };
}

export function setAllGoalsAlignmentNodes(payload) {
  return {
    type: SET_ALL_GOALS_ALIGNMENT_NODES,
    payload,
  };
}

export function setAlignedGoalsAlignmentNodes(payload) {
  return {
    type: SET_ALIGNED_GOALS_ALIGNMENT_NODES,
    payload,
  };
}

export function setUnalignedGoalsAlignmentNodes(payload) {
  return {
    type: SET_UNALIGNED_GOALS_ALIGNMENT_NODES,
    payload,
  };
}

export function setGoalsAlignmentEdges(payload) {
  return {
    type: SET_GOALS_ALIGNMENT_EDGES,
    payload,
  };
}

export function setGoalsAlignmentFilters(payload) {
  return {
    type: SET_GOALS_ALIGNMENT_FILTERS,
    payload,
  };
}

export function getGoalsAlignmentWithFilterSaga(payload) {
  return {
    type: GET_GOALS_ALIGNMENT_WITH_FILTERS_SAGA,
    payload,
  };
}

export function setGoalsAlignmentModal(payload) {
  return {
    type: SET_GOALS_ALIGNMENT_MODAL,
    payload,
  };
}
