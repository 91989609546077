import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  CREATE_EDIT_REPORT_ELEMENT_SAGA,
  CREATE_EDIT_REPORT_SAGA,
  DELETE_REPORT_ELEMENT_SAGA,
  DELETE_REPORT_SAGA,
  DUPLICATE_REPORT_SAGA,
  GET_REPORT_SAGA,
  GET_REPORTS_GRID_SAGA,
  GET_SNAPSHOT_BY_NAME_SAGA,
  GET_SNAPSHOT_SAGA,
  TAKE_REPORT_SNAPSHOT_SAGA,
  UPDATE_REPORT_ELEMENTS_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import reportsService from "../../services/ReportsService";
import {getReportSaga, getReportsGridSaga, setCanEditReport, setReport, setReportsGrid} from "./actions";
import {setSnackbar} from "../snackbar/actions";
import {rightModalOpen, setModal} from "../helper/actions";
import {REPORT_ELEMENT_TYPES} from "../../constants/strings";
import {goBack, push} from "connected-react-router";
import {PAGES} from "../../constants/pages";
import dashboardsService from "../../services/DashboardsService";
import {REPORT_SNAPSHOT} from "../../routesConstants";
import {formatReport} from "../../utils/report";

export function* getReportsGridSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    const reportsGrid = yield call(reportsService.getReportsGrid, payload);
    yield put(setReportsGrid(reportsGrid));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteReportSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(reportsService.deleteReport, payload.report.id);
    if (payload.from === PAGES.SINGLE_REPORT) {
      yield put(goBack());
    } else {
      yield put(getReportsGridSaga(payload.pagination));
    }
    yield put(setModal({
      isVisible: false,
      component: null,
      title: '',
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${payload?.report?.title} was successfully deleted! `,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* createEditReportSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    if (payload.item) {
      yield call(reportsService.editReport, payload.data, payload.reportId);
    } else {
      yield call(reportsService.createReport, payload.data);
    }

    if (payload.from === PAGES.SINGLE_REPORT) {
      yield put(getReportSaga({reportId: payload.reportId}));
    } else {
      yield put(getReportsGridSaga({page: 1, perPage: 10}));
    }

    yield put(setModal({
      isVisible: false,
      component: null,
      title: '',
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${payload.item ? payload.item.title : 'Report'} was successfully ${payload.item ? 'edited' : 'created'}!`,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getReportSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    const report = yield call(reportsService.getReport, payload.reportId);

    if (formatReport(report).elements.length > 0 && !payload.canEditReport) {
      yield put(setCanEditReport(false));
    } else {
      yield put(setCanEditReport(true));
    }

    yield put(setReport(formatReport(report)));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* duplicateReportSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(reportsService.duplicateReport, payload.reportId, payload.data);
    if (payload.from === PAGES.SINGLE_REPORT) {

    } else {
      yield put(getReportsGridSaga(payload.pagination));
    }

    yield put(setModal({
      isVisible: false,
      component: null,
    }));

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: 'Report has been duplicated!',
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* takeReportSnapshotSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(reportsService.createSnapshot, payload);
    yield put(setModal({
      isVisible: false,
      component: null,
      title: '',
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteReportElementSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    if (payload.item?.chartType) {
      yield call(dashboardsService.deleteWidget, payload.item?.id);
    } else {
      yield call(reportsService.deleteReportElement, payload.item?.id);
    }
    yield put(getReportSaga({reportId: payload.reportId, canEditReport: payload.canEditReport}));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* createEditReportElementSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    if (payload.item) {
      switch (payload.type) {
        case REPORT_ELEMENT_TYPES.TEXT:
        case REPORT_ELEMENT_TYPES.IMAGE:
          yield call(reportsService.editReportElement, payload.data, payload.reportId, payload.item?.id);
          break;
        default:
          console.log('no matching type selected');
      }
    } else {
      switch (payload.type) {
        case REPORT_ELEMENT_TYPES.TEXT:
        case REPORT_ELEMENT_TYPES.IMAGE:
          yield call(reportsService.createReportElement, payload.data, payload.reportId);
          break;
        default:
          console.log('no matching type selected');
      }
    }


    yield put(getReportSaga({reportId: payload.reportId, canEditReport: true}));
    yield put(setModal({
      isVisible: false,
      component: null,
      title: '',
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* updateReportElementsSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(reportsService.updateReportElements, payload.data, payload.reportId);
    yield put(getReportSaga({reportId: payload.reportId, canEditReport: payload.canEditReport}));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getSnapshotSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setCanEditReport(false));

    yield put(push(REPORT_SNAPSHOT
      .replace(':reportId', payload.reportId)
      .replace(':folderName', payload.folder.folderName)
      .replace(':snapshotName', payload.snapshot.name)
    ));

    yield put(rightModalOpen({
      isVisible: false,
      component: null,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getSnapshotByNameSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setCanEditReport(false));
    const snapshot = yield call(reportsService.getSnapshot, {
      folderName: payload.folderName,
      snapshotName: payload.snapshotName,
    });

    yield put(setReport(formatReport(snapshot.report)));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* reportsSaga() {
  yield takeLatest(GET_REPORTS_GRID_SAGA, getReportsGridSagaFn);
  yield takeLatest(DELETE_REPORT_SAGA, deleteReportSagaFn);
  yield takeLatest(CREATE_EDIT_REPORT_SAGA, createEditReportSagaFn);
  yield takeLatest(GET_REPORT_SAGA, getReportSagaFn);
  yield takeLatest(DUPLICATE_REPORT_SAGA, duplicateReportSagaFn);
  yield takeLatest(TAKE_REPORT_SNAPSHOT_SAGA, takeReportSnapshotSagaFn);
  yield takeLatest(DELETE_REPORT_ELEMENT_SAGA, deleteReportElementSagaFn);
  yield takeLatest(CREATE_EDIT_REPORT_ELEMENT_SAGA, createEditReportElementSagaFn);
  yield takeLatest(UPDATE_REPORT_ELEMENTS_SAGA, updateReportElementsSagaFn);
  yield takeLatest(GET_SNAPSHOT_SAGA, getSnapshotSagaFn);
  yield takeLatest(GET_SNAPSHOT_BY_NAME_SAGA, getSnapshotByNameSagaFn);
}
