import InputField from "../../components/Shared/Inputs/InputField";
import SelectField from "../../components/Shared/Inputs/SelectField";
import AutocompleteField from "../../components/Shared/Inputs/AutocompleteField";

export const groupFormConfig = ({user, group, onCancel, options}) => {
  return {
    fields: [
      {
        id: 1,
        component: InputField,
        name: 'name',
        placeholder: 'Group Name',
        label: 'Group Name',
        value: group?.name || null,
      },
      {
        id: 2,
        component: SelectField,
        name: 'ownerId',
        placeholder: {
          value: 'Group Owner',
          icon: require('../../assets/icons/profile-owner.png'),
        },
        label: 'Group Owner',
        options: {
          options: options,
          titleKey: 'name',
          subtitleKey: 'email',
          imageKey: 'photo',
          defaultImage: require('../../assets/icons/profile-owner.png'),
        },
        value: group?.ownerId ?? user?.id ?? null,
      },
      {
        id: 3,
        component: AutocompleteField,
        name: 'memberIds',
        placeholder: {
          value: 'Add members to the group',
          icon: require('../../assets/icons/profile-owner.png'),
        },
        label: 'Group Members',
        options: {
          options: options.filter((option) => option.id !== user.id),
          titleKey: 'name',
          subtitleKey: 'email',
          imageKey: 'photo',
          defaultImage: require('../../assets/icons/profile-owner.png'),
        },
        value: group?.users?.map((user) => {
          return {
            id: user.id,
            value: user.id,
            title: user.name,
            subtitle: user.email,
            image: user.photo
          };
        }),
      },
    ],
    footer: [
      {id: 1, title: 'Cancel', secondary: true, onClick: onCancel, type: 'button'},
      {id: 2, title: 'Save', type: 'submit'},
    ],
  };
};
