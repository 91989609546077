import produce from 'immer';
import {
  SET_EXPANDED_GROUPS,
  RESET_STATE,
  SET_GROUP,
  SET_GROUPS,
  SET_GROUPS_WITH_SCORECARDS,
  SET_RIGHT_SIDEBAR_SCORECARD, SET_ALL_SCORECARDS,
  SET_DELETE_PLAN_ITEM, SET_ALL_GROUP_IDS, IS_GROUP_IDS_SET, SET_SCORECARD_TITLE,
} from './actionTypes';

export const initialState = {
  groups: null,
  groupsWithScorecards: null,
  allScorecards: [],
  group: null,
  strategyPlan: null,
  expandedGroups: [],
  isGroupIdsSet: false,
  rightSidebarScorecard: null,
  deletePlanItemFromWidget: null,
  scorecardTitle: 'Strategy',
};

/* eslint-disable default-case */
const scorecardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_GROUPS:
        draft.groups = action.groups;
        break;
      case SET_SCORECARD_TITLE:
        draft.scorecardTitle = action.payload;
        break;
      case IS_GROUP_IDS_SET:
        draft.isGroupIdsSet = action.payload;
        break;
      case SET_ALL_GROUP_IDS:
        draft.expandedGroups = action.ids;
        break;
      case SET_GROUPS_WITH_SCORECARDS:
        draft.groupsWithScorecards = action.groupsWithScorecards;
        break;
      case SET_GROUP:
        draft.group = action.group;
        break;
      case SET_ALL_SCORECARDS:
        draft.allScorecards = action.payload;
        break;
      case SET_EXPANDED_GROUPS:
        const { group } = action;
        const index = draft.expandedGroups.indexOf(group);
        if (index !== -1) {
          draft.expandedGroups.splice(index, 1);
        } else {
          draft.expandedGroups.push(group);
        }
        break;
      case SET_RIGHT_SIDEBAR_SCORECARD:
        draft.rightSidebarScorecard = action.scorecard;
        break;
      case SET_DELETE_PLAN_ITEM:
        draft.deletePlanItemFromWidget = action.scorecard;
        break;
      case RESET_STATE:
        return initialState;
    }
  });

export default scorecardReducer;
