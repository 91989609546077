import * as Yup from "yup";

export const validationSchemaStep1 = Yup.object({
    riskName: Yup.string().required('Required'),
    riskDescription: Yup.string().required('Required'),
    likelihood: Yup.number().required('Required'),
    weight: Yup.number().required('Required'),
});

export const validationSchemaStep2 = Yup.object({
    ownerId: Yup.string().required('Required'),
    type: Yup.array().required('Required'),
    timeExpected: Yup.date().required('Required').typeError('Please enter a valid date'),
});
