import React from 'react';
import Rating from '@mui/material/Rating';
import styles from './RatingComponentFormik.module.scss';
import { Field } from 'formik';
import { COLORS } from '../../../constants/colors';

const RatingComponentFormik = ({ name, showScaleNames, score, maxValue }) => {
    const getColor = (score) => {
        switch (true) {
            case 0 <= score && score <= 33:
                return COLORS.lightBlueish;
            case 33 < score && score <= 66:
                return COLORS.brightOrange;
            case 66 < score && score <= 100:
                return COLORS.progressBarRed;
            default:
                return COLORS.blue;
        }
    };

    const RatingIcon = () => {
        return (
            <div className={styles.square}>
                <div className={styles.innerSquare} style={{ background: getColor(score) }} />
            </div>
        );
    };

    const EmptyRatingIcon = () => {
        return <div className={styles.square} />;
    };

    return (
        <div className={styles.container}>
            <Field name={name}>
                {({ field, form }) => (
                    <Rating
                        max={maxValue}
                        name={name}
                        value={field.value}
                        onChange={(event, newValue) => {
                            form.setFieldValue(name, newValue);
                        }}
                        icon={<RatingIcon />}
                        emptyIcon={<EmptyRatingIcon />}
                    />
                )}
            </Field>

            {showScaleNames && (
                <div className={styles.ratingScaleNamesWrapper}>
                    <p className={styles.ratingScaleLow}>Low</p>
                    <p className={styles.ratingScaleMedium}>Medium</p>
                    <p className={styles.ratingScaleHigh}>High</p>
                </div>
            )}
        </div>
    );
};

export default RatingComponentFormik;
