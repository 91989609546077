import React, {useEffect, useState} from 'react';
import styles from './TrackingComponent.module.scss'
import {measurementData, targetTypeData} from "./progressScheduleData";
import ExpectedProgress from "./ExpectedProgress";
import OutlinedSelectInput from "../Inputs/OutlinedSelectInput";
import {TRACKING_TYPE} from "../../../constants/strings";
import TargetAndCriticalLimits from "./TargetAndCriticalLimits";
import AchieveTarget from "./AchieveTarget";


export function DefineYourTarget({errors, setFormValues, title, item, padding, metrics}) {

  const [selectedTargetType, setSelectedTargetType] = useState('');
  const [selectedMeasurement, setSelectedMeasurement] = useState('');
  const [clearProp, setClearProp] = useState(false);

  const getMeasurementId = (value) => {
    return measurementData.find((item) => item?.value === value)?.id;
  }


  useEffect(() => {
    if (item) {
      setSelectedTargetType(getTrackingTypeID(item?.trackingType));
      setSelectedMeasurement(getMeasurementId(metrics?.length > 0 ? metrics[0]?.unit : item?.unit));
      if (metrics?.length > 0) {
        setFormValues({unit: metrics[0]?.unit});
      }
    } else if (metrics) {
      setSelectedMeasurement(getMeasurementId(metrics[0]?.unit));
      setFormValues({unit: metrics[0]?.unit});
    }
  }, [item, metrics]);

  const onSetTargetType = (val) => {
    let isClear = false
    setSelectedTargetType(prevState => {
      if (Number(prevState) === 1 || val.target.value === 1) isClear = true
      return val.target.value
    })
    setClearProp(isClear)
    setFormValues({
      trackingType: getTrackingType(val.target.value),
      unit: selectedMeasurement && getMeasurement(selectedMeasurement, 'value')
    })
  }
  const onSetMeasurement = (val) => {
    setSelectedMeasurement(val.target.value)
    setFormValues({trackingType: getTrackingType(selectedTargetType), unit: getMeasurement(val.target.value, 'value')})
  }

  const setIntervalFormValues = (values) => {
    setFormValues(values)
  }

  const getMeasurement = (id, prop) => {
    return measurementData.find((item) => item.id === id)[prop];
  }

  const getTrackingType = (id) => {
    switch (id) {
      case 1:
        return TRACKING_TYPE.ACHIEVED;
      case 2:
        return TRACKING_TYPE.EXCEED;
      case 3:
        return TRACKING_TYPE.BELOW;
      case 4:
        return TRACKING_TYPE.BETWEEN;
      default:
        return;

    }
  }

  const getTrackingTypeID = (type) => {
    switch (type) {
      case TRACKING_TYPE.ACHIEVED:
        return 1;
      case TRACKING_TYPE.EXCEED:
        return 2;
      case TRACKING_TYPE.BELOW:
        return 3;
      case TRACKING_TYPE.BETWEEN:
        return 4;
      default:
        return;

    }
  }

  const onHandleTargetAndLimit = (values) => {
    setFormValues(values)
  }

  const renderTargetContent = () => {
    switch (selectedTargetType) {
      case 1:
        return <AchieveTarget errors={errors} item={item} clearProp={clearProp} type={selectedTargetType}
                              setFormValues={onHandleTargetAndLimit}/>
      case 2:
      case 3:
      case 4:
        return <TargetAndCriticalLimits errors={errors} item={item}
                                        measurement={selectedMeasurement && getMeasurement(selectedMeasurement, 'name')}
                                        clearProp={clearProp} type={selectedTargetType}
                                        setFormValues={onHandleTargetAndLimit}/>
      default:
        return;
    }
  }

  return (
    <div className={styles.manuallyContainer} style={{padding: padding}}>
      <p className={styles.manuallyTitle}>{title}</p>
      <div className={styles.periodView}>
        <div className={styles.selectTargetWrapper} style={{width: '48%'}}>
          <p>Target type<span
            className={styles.errorMsg}>{errors?.trackingType && ` ${errors?.trackingType}`}</span></p>
          <OutlinedSelectInput height={40} options={targetTypeData} value={selectedTargetType}
                               handleChange={onSetTargetType}/>
        </div>
        <div className={styles.selectTargetWrapper} style={{width: '48%'}}>
          <p>Measurement<span className={styles.errorMsg}>{errors?.unit && ` ${errors?.unit}`}</span></p>
          <OutlinedSelectInput disabled={Boolean(metrics && metrics[0]?.id)} height={40} options={measurementData} value={selectedMeasurement}
                               handleChange={onSetMeasurement}/>
        </div>
      </div>
      {renderTargetContent()}

      <ExpectedProgress item={item} padding={'0'} title={'Expected progress'}
                        setFormValues={setIntervalFormValues}/>

    </div>
  )

}

export default DefineYourTarget;
