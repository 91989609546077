import React, {memo, useEffect, useState} from 'react';
import styles from './ItemWrapper.module.scss';
import {ROW_ITEM_TYPES} from "../../constants/strings";
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrowLeft.svg';
import goalService from "../../services/GoalService";
import SidebarGoalComponent from "./Goal/SidebarGoalComponent";
import scorecardService from "../../services/ScorecardService";
import {setItemAndParentForAction} from "../../store/helper/actions";
import {getItemParent} from "../../utils/perspective";
import {useDispatch} from "react-redux";
import SidebarKpiComponent from "./Kpi/SidebarKpiComponent";
import SidebarProjectComponent from "./Project/SidebarProjectComponent";
import SidebarActionComponent from "./Action/SidebarActionComponent";


const ShowSuccessCriteriaItemWrapper = ({item, goBack}) => {
  const dispatch = useDispatch()
  const [itemShowed, setItemShowed] = useState(null);
  const getItemById = async () => {
    try {
      let itemSelected = null;
      switch (item.type) {
        case ROW_ITEM_TYPES.GOAL:
          itemSelected = await goalService.getGoalWithItems(item.id);
          break;
        case ROW_ITEM_TYPES.KPI:
          itemSelected = await scorecardService.getKpiById(item.id);
          break;
        case ROW_ITEM_TYPES.ACTION:
          itemSelected = await scorecardService.getActionById(item.id);
          break;
        case ROW_ITEM_TYPES.PROJECT:
          itemSelected = await scorecardService.getProjectById(item.id);
          break;
        default:
          break
      }
      dispatch(setItemAndParentForAction({parent: getItemParent(itemSelected), item: itemSelected}))
      setItemShowed(itemSelected.id)
    } catch (e) {
      console.log({e});
    }
  }

  useEffect(() => {
    getItemById()
  },[item])

  const renderContent = () => {
    switch (item.type) {
      case ROW_ITEM_TYPES.GOAL:
        return (
          <SidebarGoalComponent />
        );
      case ROW_ITEM_TYPES.KPI:
        return (
          <SidebarKpiComponent />
        );
      case ROW_ITEM_TYPES.PROJECT:
        return (
          <SidebarProjectComponent />
        );
      case ROW_ITEM_TYPES.ACTION:
        return (
          <SidebarActionComponent />
        );
      default:
        return console.log('no matching type selected');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div onClick={goBack} className={styles.button}>
          <ArrowLeft />
          <h4 className={styles.backButtonText}>Back</h4>
        </div>
      </div>
      <div className={styles.listContainer}>
        {(itemShowed === item.id) && renderContent()}
      </div>
    </div>
  );
};

export default memo(ShowSuccessCriteriaItemWrapper);
