export const TABS = ({terminology}) => {
  return [
    {
      id: 1,
      title: 'Details',
    },
    {
      id: 2,
      title: `${terminology.kpi}s`,
    },
    {
      id: 3,
      title: 'Table View',
    },
  ];
};
