import React from 'react';
import styles from './indexStyle.module.scss';
import {goalStatus} from "../../../utils/perspective";
import CustomTooltip from "../../Shared/Tooltip/CustomTooltip";
import AvatarGroupButton from "../../Shared/AvatarGroup/AvatarGroupButton";
import TooltipMembersContent from "../../Shared/Tooltip/TooltipMemebersContent";
import {COLORS} from "../../../constants/colors";
import {ReactComponent as DefaultScorecardIcon} from '../../../assets/icons/default-scorecard.svg';
import {getItemIcon} from "./GoalNode";

function GoalNodeItemComponent({item, onItemClick, index, isPlan}) {
  const onHandleClick = (e) => {
    e.stopPropagation()
    onItemClick(item);
  }

  const getStatusValue = () => {
    if (!isPlan) return item.actualProgress && `${item.actualProgress}%`
    return <div className={styles.planDot} style={{background: goalStatus(item.health).color}}/>
  }

  const getSharedParent = () => {
    switch (true) {
      case item.parent:
        return <div className={styles.parentWrapper} style={{ border: COLORS.solidBorder}}><p className={styles.letter}>P</p></div>
      case item.shared:
        return <div className={styles.parentWrapper} style={{ border: COLORS.dottedBorder}}><p className={styles.letter}>S</p></div>

    }
  }

  return (
    <div className={styles.nodeItemWrapper}>
      <div className={styles.nodeItemContainer} style={{borderTop: index !== 0 ? `1px solid ${COLORS.lightGray02}` : ''}} onClick={onHandleClick}>
        <div className={styles.iconDiv}>
          {isPlan ?  <DefaultScorecardIcon width={12} height={12} /> : getItemIcon(item.type)}
        </div>
        <div className={styles.leftWrapper}>
          <div className={styles.childNodeName}>
            <p style={{fontSize: item?.name?.length > 40 ? '8px' : '10px'}}>{item.name}</p>
          </div>
          <div className={styles.rightWrapper}>
            { isPlan && <div className={styles.nodeItemOwner}>{getSharedParent()}</div>}
            <div className={styles.nodeItemOwner}>
              <CustomTooltip title={<AvatarGroupButton badgeHeight={10} badgeWidth={8} small={true} avatars={[item?.owner]} ownerId={item?.ownerId}/>}
                             children={<TooltipMembersContent title={'Owner'} onItemClick={() => console.log('owner')}
                                                              items={[item?.owner]}/>}/>
            </div>
            <div className={styles.nodeItemProgress} style={{color: goalStatus(item.health).color}}>{getStatusValue()}</div>
          </div>

        </div>
      </div>
      {
        item?.items?.length > 0 && <div className={styles.nestedWrapper}>
          {
            item?.items?.map((item, index) => {
              return <GoalNodeItemComponent key={index} index={index} item={item} onItemClick={(value) => onItemClick(value)}/>;
            })
          }
        </div>
      }
    </div>

  );
}


export default GoalNodeItemComponent;
