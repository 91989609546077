import {
    GET_FOUNDATION_SAGA,
    GET_USER_ACTIVITY_SAGA,
    GET_LOG_TYPE_ACTIVITY_SAGA,
    GET_ORGANIZATION_ACTIVITY_SAGA,
    SET_FOUNDATION,
    SET_USER_ACTIVITY,
    SET_LOG_TYPE_ACTIVITY,
    SET_ORGANIZATION_ACTIVITY,
    SET_MODAL,
    GET_SETTINGS_THEMES_SAGA,
    SET_SETTINGS_THEMES,
    SET_ACTION_MODAL_FROM_NAVBAR,
    RIGHT_MODAL_OPEN,
    SET_ITEM_AND_PARENT_FOR_ACTION,
    SET_WARNING_MODAL,
    SET_COMMENTS,
    SET_REFRESH,
    REFRESH_PAGE_SAGA,
    SET_IS_DELETE_MODAL_VISIBLE,
    GET_UPDATES_SAGA,
    SET_UPDATES,
    GET_COMMENTS_SAGA,
    SET_DELETE_MODAL,
    UPDATE_GROUP_MODAL_SHOW,
    ADD_GROUP_UPDATE_SAGA,
    GET_GROUP_UPDATES,
    SET_GROUP_UPDATES,
    POST_UPDATE_SAGA,
    UPDATE_DATE_RANGE_SAGA,
    SET_ADDITIONAL_MODAL, POST_UPDATE_FROM_HOME_PROJECT_SAGA,
} from './actionTypes';

export function getFoundationSaga() {
    return {
        type: GET_FOUNDATION_SAGA,
    };
}

export function setFoundation(payload) {
    return {
        type: SET_FOUNDATION,
        payload
    };
}

export function getOrganizationActivitySaga(payload) {
    return {
        type: GET_ORGANIZATION_ACTIVITY_SAGA,
        payload,
    };
}

export function setOrganizationActivity(payload) {
    return {
        type: SET_ORGANIZATION_ACTIVITY,
        payload,
    };
}

export function getLogTypeActivitySaga(payload) {
    return {
        type: GET_LOG_TYPE_ACTIVITY_SAGA,
        payload,
    };
}

export function setLogTypeActivity(payload) {
    return {
        type: SET_LOG_TYPE_ACTIVITY,
        payload,
    };
}

export function getUserActivity(payload) {
    return {
        type: GET_USER_ACTIVITY_SAGA,
        payload,
    };
}

export function setUserActivity(payload) {
    return {
        type: SET_USER_ACTIVITY,
        payload,
    };
}

export function setModal(payload) {
  return {
    type: SET_MODAL,
    payload,
  };
}

export function updateGroupModalShow(payload) {
    return {
        type: UPDATE_GROUP_MODAL_SHOW,
        payload,
    };
}

export function getGroupUpdates(payload) {
    return {
        type: GET_GROUP_UPDATES,
        payload,
    };
}

export function setGroupUpdates(payload) {
    return {
        type: SET_GROUP_UPDATES,
        payload,
    };
}

export function addGroupUpdateSaga(payload) {
    return {
        type: ADD_GROUP_UPDATE_SAGA,
        payload,
    };
}

export function getSettingsThemesSaga() {
    return {
        type: GET_SETTINGS_THEMES_SAGA,
    };
}

export function setSettingsThemes(payload) {
    return {
        type: SET_SETTINGS_THEMES,
        payload
    };
}

export function setActionModalFromNavbar(payload) {
    return {
        type: SET_ACTION_MODAL_FROM_NAVBAR,
        payload,
    };
}

export function rightModalOpen(payload) {
    return {
        type: RIGHT_MODAL_OPEN,
        payload,
    };
}
export function setItemAndParentForAction(payload) {
    return {
        type: SET_ITEM_AND_PARENT_FOR_ACTION,
        payload,
    };
}

export function setWarningModal(payload) {
    return {
        type: SET_WARNING_MODAL,
        payload,
    };
}

export function setComments(payload) {
    return {
        type: SET_COMMENTS,
        payload
    };
}

export function setRefresh(payload) {
    return {
        type: SET_REFRESH,
        payload
    };
}

export function refreshPageSaga(payload) {
    return {
        type: REFRESH_PAGE_SAGA,
        payload
    };
}

export function setIsDeleteModalVisible(payload) {
    return {
        type: SET_IS_DELETE_MODAL_VISIBLE,
        payload,
    };
}

export function getUpdatesSaga(payload) {
    return {
        type: GET_UPDATES_SAGA,
        payload,
    };
}

export function setUpdates(payload) {
    return {
        type: SET_UPDATES,
        payload,
    };
}

export function getCommentsSaga(payload) {
    return {
        type: GET_COMMENTS_SAGA,
        payload,
    };
}

export function setDeleteModal(payload) {
    return {
        type: SET_DELETE_MODAL,
        payload,
    };
}

export function postUpdateSaga(payload) {
    return {
        type: POST_UPDATE_SAGA,
        payload,
    };
}

export function updateDateRangeSaga(payload) {
    return {
        type: UPDATE_DATE_RANGE_SAGA,
        payload,
    };
}

export function setAdditionalModal(payload) {
    return {
        type: SET_ADDITIONAL_MODAL,
        payload,
    };
}

export function postUpdateFromHomeProjectSaga(payload) {
    return {
        type: POST_UPDATE_FROM_HOME_PROJECT_SAGA,
        payload,
    };
}
