import React, {useEffect, useState} from 'react';
import {updateProgressSchema} from "../../../../validation/perspective";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../components/Shared/Inputs/InputField";
import styles from './UpdateProgressForm.module.scss';
import TextareaField from "../../../../components/Shared/Inputs/TextareaField";
import {formatUnitAndValue, goalStatus} from "../../../../utils/perspective";
import CustomDot from "../../../../components/Shared/Dot/CustomDot";
import DefaultButton from "../../../../components/Shared/Buttons/DefaultButton";
import {ROW_ITEM_TYPES, TRACKING} from "../../../../constants/strings";
import SelectInput from "../../../../components/Shared/Inputs/SelectInput";
import {COLORS} from "../../../../constants/colors";

const UpdateProgressForm = ({
  handleOnSubmit,
  min = 0,
  max = 100,
  progress,
  status,
  expected,
  expectedUnit = '%',
  cancelUpdate,
  onProgressChange,
  item,
}) => {
  useEffect(() => {
    formikRef.current.setValues({actualValue: progress});
  }, [progress]);

  const formikRef = React.useRef(null);

  const [selectOptions, setSelectOptions] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const setProgressFieldValue = (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    onProgressChange(item?.inverted ? min + max - value : value);
  };

  useEffect(() => {
    if(item?.type === ROW_ITEM_TYPES.ACTION &&  item?.tracking === TRACKING.MILESTONE) {
      let options = item.milestones.map((milestone) => {
        return {id: milestone.id, name: `${milestone.percentage}% - ${milestone.name}`}
      })
      const selected = item.milestones.find((milestone) => milestone.percentage === progress);
      setSelectValue(selected);
      formikRef.current.setValues({completedMilestoneId: selected?.id});
      setSelectOptions([...options])
    }
  }, [item, progress])

  const onChangeMilestone = (val, setFieldValue) => {
    setFieldValue('completedMilestoneId', val.target.value.id)
    setSelectValue(val.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.statusContainer}>
        <h4 className={styles.statusTitle}>Progress Update</h4>
        <div className={styles.statusRightContainer}>
            {
                goalStatus(status).color && <CustomDot backgroundColor={goalStatus(status).color} />
            }
          <h4
            className={styles.status}
            style={{color: goalStatus(status).color}}
          >
            {goalStatus(status).title}
          </h4>
        </div>
      </div>

      {item?.tracking !== TRACKING.CHECKLIST && <div className={styles.itemsContainer}>
        <h4>Current Value</h4>
        {!(expected === null || expected === undefined) && (
          <h4 className={styles.expected}>Expected {formatUnitAndValue(expectedUnit, expected)}</h4>
        )}
      </div>}
      <Formik
        initialValues={{actualValue: '', msg: '', completedMilestoneId: ''}}
        onSubmit={handleOnSubmit}
        validationSchema={updateProgressSchema({tracking: item.tracking})}
        enableReinitialize={true}
        validateOnChange={false}
        innerRef={formikRef}
      >
        {({setFieldValue, errors, values}) => {
          return (
            <Form>
              {!(item?.tracking === TRACKING.MILESTONE || item?.tracking === TRACKING.CHECKLIST) && <Field
                  component={InputField}
                  step={0.01}
                  name={'actualValue'}
                  setFieldValue={(fieldName, value) => setProgressFieldValue(fieldName, value, setFieldValue)}
                  errors={errors}
                  placeholder={'Progress'}
                  type={'Number'}
                  min={min}
                />}
              {item && item.tracking === TRACKING.MILESTONE && selectOptions.length > 0 &&
              <SelectInput fullItemValue={true} border={`1px solid ${COLORS.primaryLightGray}`} background={COLORS.white} width={'100%'} value={selectValue} options={selectOptions} handleChange={(val) => onChangeMilestone(val, setFieldValue)}/>}
              <Field
                component={TextareaField}
                name={'msg'}
                setFieldValue={setFieldValue}
                errors={errors}
                placeholder={'Leave a short Update'}
                type={'number'}
              />
              <div className={styles.buttonsContainer}>
                <DefaultButton onClick={cancelUpdate} title={'Cancel'} secondary type={'button'} />
                <DefaultButton title={'Save'} type={'submit'} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateProgressForm;
