import React, { useCallback, useEffect, useState } from 'react';
import styles from './AssignModalComponent.module.scss';
import SelectInput from '../../../../components/CustomInputs/SelectInput';
import DefaultButton from '../../../../components/Shared/Buttons/DefaultButton';
import scorecardService from '../../../../services/ScorecardService';
import {assignPlanToGroupSaga} from "../../../../store/strategyPlan/actions";
import {useDispatch} from "react-redux";

function AssignModalComponent({closeModal, planId, fromRightSidebar = false}) {
    const dispatch = useDispatch();
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const getGroups = useCallback(async () => {
        try {
            const fetchedGroups = await scorecardService.getGroups();
            const transformedOptions = fetchedGroups.map(option => ({
                id: option.id,
                label: option.name,
                value: option.name,
            }));
            setGroups(transformedOptions);
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    }, []);

    useEffect(() => {
        getGroups();
    }, [getGroups]);

    const handleSelectionChange = event => {
        const selectedValue = event.target.value;
        const foundGroup = groups.find(group => group.value === selectedValue);
        if (foundGroup) {
            setSelectedGroup(foundGroup);
        }
    };

    const handleSubmit = () => {
        const data = {
            groupId: selectedGroup.id,
        };
        dispatch(assignPlanToGroupSaga(planId, data, fromRightSidebar));
        closeModal();
    };

    return (
        <div className={styles.container}>
            <p>Select group:</p>
            <SelectInput value={selectedGroup ? selectedGroup.value : ''} handleChange={handleSelectionChange} arr={groups} />
            <DefaultButton title={'Submit'} disabled={!selectedGroup} onClick={handleSubmit} buttonStyles={{ marginTop: 12 }} />
        </div>
    );
}

export default AssignModalComponent;
