import React, { memo} from 'react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import { styled} from "@mui/material";
import {COLORS} from "../../../constants/colors";

const CustomTooltip = ({title, children, background = COLORS.white}) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background,
      color: COLORS.darkestGray,
      fontSize: '12px',
      boxShadow: COLORS.boxShadow,
      borderRadius: '8px'
    },
  }));

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          {children}
        </React.Fragment>
      }
    >
      <div>
        {title}
      </div>
    </HtmlTooltip>
  );
};

export default memo(CustomTooltip);
