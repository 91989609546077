import React, {useCallback, useEffect, useState} from 'react';
import styles from './Details.module.scss';
import ContributesToComponent from "../../../Shared/ContributesToComponent";
import {setParentDataForContributesToComponent} from "../../../../utils/setDataForContributesToComponent";
import {DETAILS_TYPE, ItemStatus, TRACKING} from "../../../../constants/strings";
import DetailsComponent from "../../DetailsComponent";
import OutlinedSelectInput from "../../../Shared/Inputs/OutlinedSelectInput";
import {useDispatch, useSelector} from "react-redux";
import {editItemStatusSaga} from "../../../../store/perspectives/actions";
import ChartWithProgressComponent from "../../../Shared/ChartWithProgressComponent/ChartWithProgressComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import UpdateProgressForm from "../../../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {FormControlLabel, Menu, styled} from "@mui/material";
import {strategyPlanSelector} from "../../../../store/strategyPlan/selectors";
import {COLORS} from "../../../../constants/colors";
import CommentSection from "../../../CommentSection";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/arrowDown.svg";
import {ReactComponent as TrackingIcon} from '../../../../assets/icons/toolsIcon.svg';
import {capitalizeFirstLetter} from "../../../../utils/format";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import {updateActionSaga} from "../../../../store/action/actions";
import {updateChecklistSaga} from "../../../../store/checklist/actions";
import {ReactComponent as Profile} from '../../../../assets/icons/profile.svg';
import {ReactComponent as Performance} from '../../../../assets/icons/performanceIcon.svg';
import {ReactComponent as Hub} from '../../../../assets/icons/hub.svg';
import {ReactComponent as JiraIcon} from "../../../../assets/icons/external-sources/jira-logo.svg";
import TooltipComponent from "../../../Shared/Tooltip/TooltipComponent";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;
const Details = ({data, rightModal, page}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [sliderValue, setSliderValue] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(data?.item?.status);
    const strategyPlan = useSelector(strategyPlanSelector());
    const [showTracking, setShowTracking] = useState(false);

    const onBoxShow = () => {
        setShowTracking(!showTracking);
    };

    const detailList = [
        {
            id: 1,
            type: DETAILS_TYPE.OWNER,
            title: 'Owner',
            name: data?.item?.owner.name || data?.item?.owner.email,
            photo: data?.item?.owner.photo || require('../../../../assets/icons/profile-owner.png')
        },
        {id: 2, type: DETAILS_TYPE.OWNERS, title: 'Sharing With', users: data?.item?.owners},
        {
            id: 3,
            type: DETAILS_TYPE.TIME_FRAME,
            title: 'Time Frame',
            startDate: data?.item?.startDate,
            endDate: data?.item?.endDate,
            alignment: 'start'
        },
        {
            id: 4,
            type: DETAILS_TYPE.DESCRIPTION,
            title: 'Description',
            description: data?.item?.description,
            alignment: 'start'
        },
        {id: 5, type: DETAILS_TYPE.UPDATE_SCHEDULE, title: 'Update Schedule', date: data?.item?.endDate},
    ];

    const statusOptions = [
        {
            id: 1,
            value: ItemStatus.TODO,
            name: 'To do',
            hasImage: true,
            image: require('../../../../assets/icons/todo-status.png')
        },
        {
            id: 2,
            value: ItemStatus.ON_HOLD,
            name: 'On hold',
            hasImage: true,
            image: require('../../../../assets/icons/pause-circle.png')
        },
        {
            id: 3,
            value: ItemStatus.COMPLETE,
            name: 'Complete',
            hasImage: true,
            image: require('../../../../assets/icons/completed-status.png')
        },
        {
            id: 4,
            value: ItemStatus.IN_PROGRESS,
            name: 'In progress',
            hasImage: true,
            image: require('../../../../assets/icons/in-progress-status.png')
        },
        {
            id: 5,
            value: ItemStatus.NOT_DOING,
            name: 'Not Doing',
            hasImage: true,
            image: require('../../../../assets/icons/not-doing-status-icon.png')
        },
    ];

    const handleStatusChange = async (e) => {
        try {
            setSelectedStatus(e.target.value);
            const payload = {
                type: data?.item?.type,
                status: e.target.value,
                itemId: data?.item?.id,
                parent: data?.parent,
            }
            dispatch(editItemStatusSaga(payload));
        } catch (err) {

        }
    };

    const handleClick = (e) => {
        if (!anchorEl) {
            setAnchorEl(anchorEl ? null : e.currentTarget);
        }
    };

    const handleUpdateProgress = (values) => {
        dispatch(updateActionSaga({
            data: values,
            actionId: data.item.id,
            scorecardId: strategyPlan?.id,
            goalId: rightModal.goalId,
            page,
            fromDetailsTab: true,
            entity: rightModal.entity,
            entityId: rightModal.entityId,
        }));
    };

    const onUpdateProgress = (values) => {
        if (values.actualValue) {
            setSliderValue(Number(values.actualValue));
        }
        setAnchorEl(null);
        handleUpdateProgress(values);
    };

    const cancelUpdate = () => {
        setAnchorEl(null);
        setSliderValue(Number(data?.item.actualProgress));
    };

    const onProgressChange = (value) => {
        setSliderValue(Number(value));
    };

    const setSliderValueFn = useCallback(() => {
        setSliderValue(Number(data.item.actualProgress));
    }, [data.item.actualProgress]);

    useEffect(() => {
        setSliderValueFn();
    }, [setSliderValueFn]);

    const checkItem = async (checklist) => {
        try {
            const updateData = {
                completed: !checklist.completed,
            };
            dispatch(updateChecklistSaga({
                checklistId: checklist.id,
                data: updateData,
                actionId: data?.item?.id,
                scorecardId: strategyPlan?.id,
                goalId: rightModal.goalId,
                page,
            }));
        } catch (err) {
            console.log(err);
        }
    }
    const ChecklistView = () => {
        return (
            <div>
                {
                    [...data.item.checklists].sort((a, b) => new Date(a.date) - new Date(b.date)).map((item) => {
                        return (
                            <div className={styles.checklistWrapper} key={item.id}>
                                <div className={styles.flexAndCenter}>
                                    <CalendarMonth className={styles.calendarIcon}/>
                                    <p className={styles.date}>{dayjs(item.date).format("MMM DD, YYYY")}</p>
                                </div>
                                <div>
                                    <FormControlLabel
                                        style={{textDecoration: item.completed && 'line-through'}}
                                        onChange={() => checkItem(item)}
                                        control={<Checkbox checked={item?.completed}/>}
                                        label={item.name}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const MilestoneView = () => {
        return (
            <div>
                {
                    data.item.milestones.map((item) => {
                        return (
                            <div className={styles.checklistWrapper} key={item.id}>
                                <div className={styles.flexAndCenter}>
                                    <CalendarMonth className={styles.calendarIcon}/>
                                    <p className={styles.date}>{dayjs(item.date).format("MMM DD, YYYY")}</p>
                                </div>
                                <div className={styles.milestonePerName}>
                                    <div className={styles.milPercentage}>
                                        <p>{`${item.percentage}%`}</p>
                                    </div>
                                    <div>
                                        <p>{`${item.name}`}</p>

                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const JiraView = () => {
        return (
            <div className={styles.jiraViewWrapper}>
                <div className={styles.flex}>
                    <p className={styles.jiraViewTitles}>Connection: </p>
                    <p>{data?.item?.actionJiraConnection?.jiraConnection?.name || '/'} </p>
                </div>
                <div className={styles.flex}>
                    <p className={styles.jiraViewTitles}>Workspace: </p>
                    <p>{data?.item?.actionJiraConnection?.jiraConnection?.resourceName || '/'} </p>
                </div>
                <div className={styles.flex}>
                    <p className={styles.jiraViewTitles}>Project: </p>
                    <p>{data?.item?.actionJiraConnection?.jiraProjectName  || '/'} </p>
                </div>
                <div className={styles.flex}>
                    <p className={styles.jiraViewTitles}>Issue: </p>
                    <p>{data?.item?.actionJiraConnection?.jiraIssueName || '/'}</p>
                </div>
            </div>
        )
    }

    const getTrackingContent = () => {
        switch (data.item.tracking) {
            case TRACKING.CHECKLIST:
                return <ChecklistView/>
            case TRACKING.MILESTONE:
                return <MilestoneView/>
            case TRACKING.JIRA:
                return <JiraView/>
            default:
                return;
        }
    }
    const getIcon = () => {
        let component;
        let title;
        switch (data.item.tracking) {
            case TRACKING.CHECKLIST:
                component = (
                    <Hub className={styles.userIcon}/>
                );
                title = 'Checklist tracking';
                break;

            case TRACKING.MILESTONE:
                component = (
                    <Performance className={styles.userIcon}/>
                );
                title = 'Milestones tracking';
                break;

            case TRACKING.JIRA:
                component = (
                    <JiraIcon className={styles.userIcon}/>
                );
                title = 'Jira tracking';
                break;

            default:
                component = (
                    <Profile className={styles.userIcon}/>
                );
                title = 'Manually tracking';
        }

        return (
            <TooltipComponent title={title} placement={'bottom'}>
                {component}
            </TooltipComponent>
        );
    }

    const trackingComponent = () => {
        return (
            <div className={styles.trackingWrapper} style={{height: showTracking ? 'auto' : '70px'}}>
                <div className={styles.titleWrapper}>
                    <div className={styles.leftTitleWrapper}>
                        <TrackingIcon/>
                        <p className={styles.titleText}>Tracking</p>
                        <div className={styles.trackingTitleWrapper}>
                            <p className={styles.trackingTitle}>{capitalizeFirstLetter(data?.item.tracking)}</p>
                        </div>
                    </div>
                    {
                        data.item.tracking !== TRACKING.MANUALLY &&
                        <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn}
                                style={{transform: showTracking ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                            <ArrowDown/>
                        </button>
                    }
                </div>
                {showTracking && getTrackingContent()}
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.detailsWithoutComments}>
                <div className={styles.statusAndProgressInputsWrapper}>
                    <div className={styles.statusSelectWrapper}>
                        <OutlinedSelectInput options={statusOptions}
                                             valueKey={'value'}
                                             value={selectedStatus}
                                             handleChange={handleStatusChange}
                                             height={42}
                        />
                    </div>
                    <div className={styles.updateProgressButtonWrapper}>
                        {getIcon()}
                        <DefaultButton title={'Update progress'} inverted border={`1px solid ${COLORS.blue}`}
                                       onClick={(e) => handleClick(e)}
                                       disabled={data?.item?.tracking === TRACKING.CHECKLIST || data?.item?.tracking === TRACKING.JIRA}/>
                        <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                            <UpdateProgressForm
                                item={data?.item}
                                min={data?.item?.min}
                                max={data?.item?.max}
                                progress={sliderValue}
                                status={data?.item?.status}
                                expected={data?.item?.expectedProgress}
                                expectedUnit={'percentage'}
                                handleOnSubmit={onUpdateProgress}
                                cancelUpdate={cancelUpdate}
                                onProgressChange={onProgressChange}
                            />
                        </StyledMenu>
                    </div>
                </div>
                <ChartWithProgressComponent
                    data={data?.item}
                    showSlider={true} page={page}/>


                {data.item.tracking === TRACKING.CHECKLIST &&
                    trackingComponent()
                }
                <div>
                    <DetailsComponent data={data.item} updateDate={data.item.nextUpdateDate}
                                      isBtnVisible={data.item.needsUpdate} items={detailList}/>
                    <ContributesToComponent
                        item={data?.item}
                        parent={setParentDataForContributesToComponent(data?.parent, data?.parent?.strategyPlanName)}
                        showForms={false}
                        canEditParent={false}
                    />
                    {data.item.tracking !== TRACKING.CHECKLIST &&
                        trackingComponent()
                    }
                </div>
            </div>
            <div className={styles.commentSectionWrapper}>
                <CommentSection item={data?.item}/>
            </div>
        </div>
    );
};

export default Details;
