import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_DASHBOARD: 'dashboards/:dashboardId',
  GET_DASHBOARDS_GRID: 'dashboards/grid',
  GET_RECENTLY_VIEWED: '/dashboards/recently-viewed',
  CREATE_DASHBOARD: 'dashboards',
  DUPLICATE_DASHBOARD: '/dashboards/duplicate/:id',
  UPDATE_DASHBOARD: '/dashboards/:id',
  DELETE_DASHBOARD: '/dashboards/:id',
  SHARE_DASHBOARD: 'dashboards/share/:dashboardId',
  CREATE_WIDGET: '/widgets',
  GET_WIDGET_BY_ID: '/widgets/:widgetId',
  UPDATE_WIDGET: '/widgets/:widgetId',
  GET_CHART_DATA: 'widgets/chart-data',
  GET_DASHBOARD_VIEW: 'dashboards/view/:dashboardId',
  GET_CHART_DETAILS_TABLE_DATA: 'widgets/chart-details-table',
  DELETE_WIDGET: 'widgets/:widgetId',
  UPDATE_WIDGET_ZOOM: 'widgets/update-zoom/:widgetId',
};

class DashboardsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  duplicateDashboard = (id, data) => {
    return this.httpService.request({
      url: ROUTES.DUPLICATE_DASHBOARD.replace(':id', id),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getChartData = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_CHART_DATA,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updateDashboard = (id, data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_DASHBOARD.replace(':id', id),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getDashboard = (dashboardId) => {
    return this.httpService.request({
      url: ROUTES.GET_DASHBOARD.replace(':dashboardId', dashboardId),
      method: HTTP_METHODS.GET,
    });
  };
  deleteDashboard = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_DASHBOARD.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  getDashboardsGrid = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_DASHBOARDS_GRID,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getRecentlyViewedDashboards = () => {
    return this.httpService.request({
      url: ROUTES.GET_RECENTLY_VIEWED,
      method: HTTP_METHODS.GET,
    });
  };

  createDashboard = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_DASHBOARD,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  shareDashboard = (data, dashboardId) => {
    return this.httpService.request({
      url: ROUTES.SHARE_DASHBOARD.replace(':dashboardId', dashboardId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  createWidget = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_WIDGET,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updateWidget = (data, widgetId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_WIDGET.replace(':widgetId', widgetId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getWidget = (widgetId) => {
    return this.httpService.request({
      url: ROUTES.GET_WIDGET_BY_ID.replace(':widgetId', widgetId),
      method: HTTP_METHODS.GET,
    });
  };

  getDashboardView = (dashboardId, data) => {
    return this.httpService.request({
      url: ROUTES.GET_DASHBOARD_VIEW.replace(':dashboardId', dashboardId),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getChartDetailsTableData = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_CHART_DETAILS_TABLE_DATA,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  deleteWidget = (widgetId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_WIDGET.replace(':widgetId', widgetId),
      method: HTTP_METHODS.DELETE,
    });
  };

  updateWidgetZoom = (widgetId, data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_WIDGET_ZOOM.replace(':widgetId', widgetId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };
}

const dashboardsService = new DashboardsService(httpService);

export default dashboardsService;
