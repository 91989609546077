import React, {useCallback, useEffect, useRef, useState} from "react";
import {Handle, Position, useReactFlow} from "reactflow";
import styles from './indexStyle.module.scss';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrowDown.svg';
import {COLORS} from "../../../constants/colors";
import { ReactComponent as GoalIcon } from '../../../assets/icons/goal-parent.svg';
import {ACTIONS, ItemHealth, ROW_ITEM_TYPES} from "../../../constants/strings";
import { ReactComponent as ActionIcon } from '../../../assets/icons/dotted-circle.svg';
import { ReactComponent as PlanIcon } from '../../../assets/icons/scorecard.svg';
import { ReactComponent as SpeedometerIcon } from '../../../assets/icons/speedometer.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/project.svg';
import AvatarGroupButton from "../../Shared/AvatarGroup/AvatarGroupButton";
import TooltipMembersContent from "../../Shared/Tooltip/TooltipMemebersContent";
import CustomTooltip from "../../Shared/Tooltip/CustomTooltip";
import GoalNodeItemComponent from "./GoalNodeItemComponent";
import AllOutIcon from '@mui/icons-material/AllOut';
import PopupComponent from "../../Shared/Popup/PopupComponent";
import {goalMenuConfig} from "./goalMenuConfig";
import {useDispatch, useSelector} from "react-redux";
import {setAlignedGoalsAlignmentNodes, setGoalsAlignmentModal} from "../../../store/alignment/actions";
import DeleteContent from "../../Shared/Modals/DeleteContent";
import {deleteGoalSaga, rightSidebarSubmitFormSaga} from "../../../store/strategyPlan/actions";
import {PAGES} from "../../../constants/pages";
import {rightModalOpen, setItemAndParentForAction} from "../../../store/helper/actions";
import {getItemParent} from "../../../utils/perspective";
import MovePerspectiveItem from "../../MovePerspectiveItem/MovePerspectiveItem";
import {moveItemSaga} from "../../../store/perspectives/actions";
import scorecardService from "../../../services/ScorecardService";
import AddGoalModalContentComponent
  from "../../../containers/StrategyPlanPage/PerspectiveComponent/AddGoalModalContentComponent";
import {setGoalsLibraryModal} from "../../../store/goals/actions";
import {getItemScorecard} from "../../../utils/dashboard";
import KpiModalContentComponent
  from "../../../containers/StrategyPlanPage/ItemRowComponent/Components/KpiModalContentComponent/KpiModalContentComponent";
import AddNewProjectModalContentComponent
  from "../../../containers/StrategyPlanPage/RightSidebar/AddNewProjectModalContentComponent";
import ActionModalContentComponent
  from "../../../containers/StrategyPlanPage/ItemRowComponent/Components/ActionModalContentComponent/ActionModalContentComponent";
import {alignedGoalsAlignmentNodesSelector} from "../../../store/alignment/selectors";
import {ALIGNMENT, STRATEGY_PLAN} from "../../../routesConstants";
import {SIDEBAR_ITEM_TYPE} from "../../../utils/helper";
import {useHistory, useLocation} from "react-router-dom";
import {createRouteWithParams, getDecodedURIComponent, getQueryParam} from "../../../utils/url";
import {terminologySelector} from "../../../store/auth/selectors";

export const getItemIcon = (type, iconSize) => {
  switch (type) {
    case ROW_ITEM_TYPES.PROJECT:
      return <ProjectIcon width={iconSize || 11} height={iconSize || 11}/>
    case ROW_ITEM_TYPES.PERSPECTIVE:
      return <AllOutIcon width={iconSize || 11} height={iconSize || 11}/>
    case ROW_ITEM_TYPES.KPI:
      return <SpeedometerIcon width={iconSize || 10} height={iconSize || 10}/>
    case ROW_ITEM_TYPES.ACTION:
      return <ActionIcon width={iconSize || 11} height={iconSize || 11}/>;
    case ROW_ITEM_TYPES.PLAN:
      return <PlanIcon color={COLORS.iconGray} width={iconSize || 11} height={iconSize || 11}/>;
    default:
      return <GoalIcon width={iconSize || 12} height={iconSize || 12} />;
  }
};

function GoalNode({
                    data,
                    isConnectable,
                    showHandle = true,
                    fullWidth = false,
                    inList = false,
                    lastItemInList,
                    onClickGoalFromList,
}) {
  const {search} = useLocation();

  const activeTab = getDecodedURIComponent(getQueryParam(search, 'activeTab'));
  const activeSecondaryTab = getDecodedURIComponent(getQueryParam(search, 'activeSecondaryTab'));
  const itemType = getDecodedURIComponent(getQueryParam(search, 'itemType'));
  const itemId = getDecodedURIComponent(getQueryParam(search, 'itemId'));

  const [expanded, setExpanded] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const divRef = useRef(null);
  const history = useHistory();
  const alignedGoalsAlignmentNodes = useSelector(alignedGoalsAlignmentNodesSelector());
  const terminology = useSelector(terminologySelector());

  const {  fitView, getNode } = useReactFlow();

const handleFocusNode = useCallback(() => {
  const node = getNode(data.id)
  fitView({nodes: [node], duration: 800, maxZoom: 2})
}, [fitView]);

  const dispatch = useDispatch();
  const toggleDrillDown = (e) => {
    e.stopPropagation()
    setExpanded(!expanded);
  };

  const updateDivHeight = (height) => {
    const newNodes = alignedGoalsAlignmentNodes.map((node) => {
      return {...node, nodeHeight: node.id === data.id ? height : node.nodeHeight}
    })
    dispatch(setAlignedGoalsAlignmentNodes(newNodes))
  }
  useEffect(() => {
    updateDivHeight(divRef?.current?.offsetHeight)
  },[divRef?.current?.offsetHeight])

  const getNumOfItemsByTypeArr = (arr) => {
    let typeObj = arr.reduce((acc, item) => {
      acc[item.type] = (acc[item.type] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(typeObj).map(([type, count]) => ({
      type,
      count,
    }));
  }


  const goalStatus = (status) => {
    switch (status) {
      case ItemHealth.AT_RISK:
        return {color: COLORS.lightOrange, title: ItemHealth.AT_RISK, fontColor: COLORS.orange}
      case ItemHealth.LATE:
        return {color: COLORS.lightRed, title: ItemHealth.LATE, fontColor: COLORS.red}
      case ItemHealth.ON_TRACK:
        return {color: COLORS.lightgreen, title: ItemHealth.ON_TRACK, fontColor: COLORS.green}
      case ItemHealth.ACHIEVED:
        return {color: COLORS.lightestBlue, title: ItemHealth.ACHIEVED, fontColor: COLORS.darkestBlue}
      case ItemHealth.EXCEEDED:
        return {color: COLORS.palePurple, title: ItemHealth.EXCEEDED, fontColor: COLORS.darkPurple}
      default:
        return {color: COLORS.lighterGray, title: '', fontColor: COLORS.lightGray}
    }
  };

  const onAddSuccessCriteria = () => {
    setIsSuccess(true)
  }
  const onCloseSuccessCriteria = (value) => {
    if(!value) setIsSuccess(false)
  }
  const onEditGoal = async () => {
    setIsSuccess(false)
    const goal = await getGoalById();
    const scorecard = getItemScorecard(goal);
    dispatch(setItemAndParentForAction({parent: getItemParent(goal), item: goal}));
    let component = (
      <AddGoalModalContentComponent
        onFormSubmit={(values) => onEditSubmit(values, goal)}
        planId={scorecard?.id}
        parentType={getItemParent(goal).type}
        onCancel={closeSidebar}
        scorecard={scorecard}
      />
    );

    dispatch(rightModalOpen({
      isVisible: true,
      component: component,
      page: PAGES.GOAL_ALIGNMENT_MAP,
    }));
  }

  const closeSidebar = () => {
    dispatch(rightModalOpen({
      isVisible: false,
      component: null,
      page: null,
    }));
  }

  const onEditSubmit = (values, goal) => {
    const scorecard = getItemScorecard(goal);
    dispatch(rightSidebarSubmitFormSaga(
      values,
      ACTIONS.EDIT,
      goal.type,
      goal,
      scorecard?.id,
      true,
    ));
  }

  const getGoalById = async () =>{
    try {
        return await scorecardService.getGoalById(data.id);
    } catch (e) {
      console.log('err', {e});
    }
  }
  const onMoveGoal = async () => {
    setIsSuccess(false)
    const goal = await getGoalById();
    const scorecard = getItemScorecard(goal);

    dispatch(setGoalsAlignmentModal({
      isVisible: true,
      title: 'Move a goal?',
      component: (
        <MovePerspectiveItem
          data={goal}
          isParentGoal={!goal.goalId}
          closeModal={onCloseModal}
          moveItem={moveItem}
          scorecard={scorecard}
        />
      ),
    }));

  }

  const moveItem = (item, parentItemId, parentItemType, retainContribution, isParentGoal, removeLinks) => {
    const scorecard = getItemScorecard(item);

    dispatch(moveItemSaga(
      item,
      parentItemId,
      parentItemType,
      retainContribution,
      isParentGoal,
      scorecard?.id,
      removeLinks,
      PAGES.GOAL_ALIGNMENT_MAP
    ));
  };

  const initialModalState = {
    isVisible: false,
    title: '',
    maxWidth: 'md',
    component: null,
  };

  const onCloseModal = () => {
    dispatch(setGoalsAlignmentModal(initialModalState))
  }
  const onDelete = () => {
    const deleteContent = {
      title: `Delete ${data?.name}`,
      isVisible: true,
      component: <DeleteContent
        text={`Are you sure you want to delete ${data?.name}?`}
        onPressClose={onCloseModal}
        onPressConfirm={onDeleteConfirm}/>
    }
    setIsSuccess(false)
    dispatch(setGoalsAlignmentModal(deleteContent))
  }

  const onDeleteConfirm = () => {
    dispatch(deleteGoalSaga(data, data.perspective.scorecard.id, PAGES.GOAL_ALIGNMENT_MAP));
  }
  const addNewItem = async (type) => {
    setIsSuccess(false)
    const goal = await getGoalById();
    dispatch(setItemAndParentForAction({parent: goal, item: null}));
    const scorecard = getItemScorecard(goal);

    let component;
    switch (type) {
      case ROW_ITEM_TYPES.GOAL:
        component = (
          <AddGoalModalContentComponent
            onFormSubmit={(values) => onFormSubmit(values, goal, type)}
            planId={scorecard?.id}
            parentType={'Goal'}
            onCancel={closeSidebar}
            scorecard={scorecard}
            canContributesToEditParent={false}
          />
        );
        break;
      case ROW_ITEM_TYPES.KPI:
        component = (
          <KpiModalContentComponent
            parentType={'Goal'}
            planId={scorecard?.id}
            onFormSubmit={(values) => onFormSubmit(values, goal, type)}
            onCancel={closeSidebar}
            canContributesToEditParent={false}
          />
        );
        break;
      case ROW_ITEM_TYPES.PROJECT:
        component = (
          <AddNewProjectModalContentComponent
            parentType={'Goal'}
            planId={scorecard?.id}
            onFormSubmit={(values) => onFormSubmit(values, goal, type)}
            onCancel={closeSidebar}
            canContributesToEditParent={false}
          />
        );
        break;
      case ROW_ITEM_TYPES.ACTION:
        component = (
          <ActionModalContentComponent
            parentType={'Goal'}
            planId={scorecard?.id}
            onFormSubmit={(values) => onFormSubmit(values, goal, type)}
            onCancel={closeSidebar}
            canContributesToEditParent={false}
          />
        );
        break;
      default:
        console.log('no matching type selected');
    }

    dispatch(rightModalOpen({
      isVisible: true,
      component: component,
      page: PAGES.GOAL_ALIGNMENT_MAP,
    }));

  }

  const onFormSubmit = useCallback((values, item, type) => {
    const scorecard = getItemScorecard(item);
    dispatch(rightSidebarSubmitFormSaga(
      values,
      ACTIONS.ADD,
      type,
      null,
      scorecard?.id,
      true,
    ));
  }, [dispatch]);

  const alignGoal = async () => {
    setIsSuccess(false)
    const goal = await getGoalById();
    const scorecard = getItemScorecard(goal);

    dispatch(setGoalsLibraryModal({
      isVisible: true,
      goalId: goal.id,
      scorecardId: scorecard?.id,
      page: PAGES.GOAL_ALIGNMENT_MAP,
    }));
  };

  const changeRoute = ({itemType, itemId}) => {
    let url;
    switch (data.page) {
      case PAGES.STRATEGY_PLAN:
        url = STRATEGY_PLAN.replace(':id', data.planId);
        break;
      case PAGES.GOAL_ALIGNMENT_MAP:
        url = ALIGNMENT;
        break;
      default:
        url = '';
    }

    history.replace(createRouteWithParams(url, {
      ...(activeTab && {activeTab}),
      ...(activeSecondaryTab && {activeSecondaryTab}),
      itemType,
      itemId,
    }));
  };

  const handleClick = (e) => {
    if (inList && !data.unaligned) {
      onClickGoalFromList(data);
      handleFocusNode();
    } else {
      if (inList) {
        onClickGoalFromList(data);
      }
      e.stopPropagation();

      changeRoute({
        itemType: SIDEBAR_ITEM_TYPE.GOAL,
        itemId: data.id,
      });
    }
  };

  const onItemClick = (item) => {
    changeRoute({
      itemType: item.type,
      itemId: item.id,
    });
  };

  const onScorecardClick = (item) => {
    changeRoute({
      itemType: SIDEBAR_ITEM_TYPE.SIDEBAR_PLAN,
      itemId: item.id,
    });
  };

  const getNumGoals = () => {
    return `${data.childrenCount} ${data.childrenCount > 1 ? 'Goals' : 'Goal'}`
  }


  return (
    <div ref={divRef} className={styles.planNodeWrapper}>
      <div
        onClick={handleClick}
        className={`
          ${styles.goalWrapper}
          ${expanded ? styles.expanded : ''}
          ${fullWidth && styles.fullWidthContainer}
          ${inList && !lastItemInList && styles.inListContainer}
        `}
        style={{boxShadow: data.isHighlighted ? COLORS.highlightedBoxShadow : 'none'}}
      >
        {showHandle && (
          <Handle
            type="target"
            position={Position.Left}
            style={{ background: "#555", zIndex: 10 }}
            onConnect={(params) => console.log("handle onConnect", params)}
            isConnectable={isConnectable}
          />
        )}
      <div className={`${styles.items} ${expanded ? styles.itemsExp : ''} ${fullWidth && styles.fullWidthItems}`} style={{borderBottom: expanded ? `1px solid ${COLORS.lightGray02}` : ''}}>
        {expanded &&
          data.plans.map((plan, index) => {
            return <GoalNodeItemComponent key={index} index={index} item={plan} onItemClick={(value) => onScorecardClick(value)} isPlan={true}/>;
          })}
      </div>

      <div className={styles.closedGoalWrapper}>
        <div className={styles.iconsWrapper}>
          <div className={styles.scorecardGoalName}>{data.perspective.scorecard?.name}</div>
          <div className={styles.dropdownIcon} style={{padding: '4px 0 4px 0',alignItems: expanded ? 'flex-end' : 'flex-start', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', marginRight: '6px'}}
               onClick={toggleDrillDown}>
            <ArrowDown width={12} height={12}/>
          </div>
          <PopupComponent
            toggle={onCloseSuccessCriteria}
            config={goalMenuConfig({
              onAddSuccessCriteria,
              onEditGoal,
              onMoveGoal,
              onDelete,
              isSuccess,
              addNewItem,
              alignGoal,
              terminology,
            })}/>
        </div>


        <div className={styles.titleWrapper}>
          <div className={styles.goalNameWrapper}>
            <div>
              <GoalIcon width={20} height={20}/>
            </div>
            <div className={styles.goalTitle}>
              <p style={{fontSize: data?.name?.length > 40 ? '8px' : '10px'}} className={styles.goalName}>{data.name}</p>
            </div>
          </div>

          {data.health && <div className={styles.status}>
            <div className={styles.statusContainer} style={{backgroundColor: goalStatus(data.health).color}}>
              <p className={styles.statusText}
                 style={{color: goalStatus(data.health).fontColor}}>{goalStatus(data.health).title}</p>
            </div>
          </div>}
        </div>

        <div className={styles.titleWrapper}>
          <div className={styles.typeWrapper}>
            {getNumOfItemsByTypeArr(data.items).map((item, index) => {
              return(
                <div key={index} className={styles.countWrapper}>{getItemIcon(item.type)}<span className={styles.countNum}>{item.count}</span></div>
              )
            })}
          </div>

          {data?.owner && <div className={styles.ownerWrapper}>
            <CustomTooltip title={<AvatarGroupButton background={COLORS.lighterGray} badgeHeight={10} badgeWidth={8} small={true} avatars={[data.owner]} ownerId={data.ownerId}/>}
                           children={<TooltipMembersContent title={'Owner'} onItemClick={() => console.log('owner')}
                                                            items={[data.owner]}/>}/>
          </div>}

        </div>

      </div>

      <div className={`${styles.items} ${expanded ? styles.itemsExp : ''} ${fullWidth && styles.fullWidthItems}`} style={{borderTop: expanded ? `1px solid ${COLORS.lightGray02}` : ''}}>
        {expanded &&
          data?.items?.map((item, index) => {
            return <GoalNodeItemComponent key={index} index={index} item={item} onItemClick={(value) => onItemClick(value)}/>;
          })}
      </div>
      {showHandle && (
        <Handle
          type="source"
          position={Position.Right}
          id="b"
          style={{ background: "#555" }}
          isConnectable={isConnectable}
        />
      )}
    </div>
      {showHandle && data.hasChildren &&
        <div className={styles.hasChildrenWrapper}>
          <div onClick={(e) => e.stopPropagation()} className={styles.transparentDiv}></div>

          <div className={styles.childrenMiddleDiv}>
            <div className={styles.lineDiv}></div>
            <div className={`${styles.expandCircle} ${data.expanded ? styles.expandCircleOpen : styles.expandCircleClose}`}>{getNumGoals()}</div>
          </div>

          <div onClick={(e) => e.stopPropagation()} className={styles.transparentDiv}></div>

        </div>
      }
    </div>
  );
}

export default GoalNode;
