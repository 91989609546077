import React, {memo} from 'react';
import styles from './FormFooter.module.scss';
import DefaultButton from '../Buttons/DefaultButton';

const FormFooter = ({config}) => {
  return (
    <div className={styles.container}>
      {config.map((button) => {
        return (
          <DefaultButton title={button.title} secondary={button.secondary} type={button.type} key={button.id} buttonStyles={{marginLeft: 8, width: button.width}} onClick={button.onClick} />
        );
      })}
    </div>
  );
};

export default memo(FormFooter);
