import React from 'react';
import styles from './indexStyle.module.scss'
import {Field, Form, Formik} from "formik";
import InputField from "../../components/Shared/Inputs/InputField";
import DefaultButton from "../../components/Shared/Buttons/DefaultButton";
import {editPerspectiveSchema} from "../../validation/perspective";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

export function EditPerspectiveModalComponent({onClose, handleOnSubmit, perspective}) {
    const terminology = useSelector(terminologySelector());

    const handleSubmit = async (values) => {
        handleOnSubmit(values)
    };

    return (
        <div className={styles.editPerspectiveWrapper}>
            <Formik
                initialValues={
                    {
                        name: perspective?.name || '',
                        description: perspective?.description || '',
                    }
                }
                onSubmit={handleSubmit}
                validationSchema={editPerspectiveSchema}
                enableReinitialize={true}
                validateOnChange={true}
                validateOnMount={true}
            >
                {({setFieldValue, errors, values, isValid, dirty}) => {
                    return (
                        <Form>
                            <div className={styles.formContainer}>
                                <Field
                                    component={InputField}
                                    name={'name'}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    label={`${terminology.perspective} name`}
                                    required
                                />
                                <Field
                                    component={InputField}
                                    name={'description'}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    label={'Description'}
                                />
                                <div className={styles.btnWrapperEditModal}>
                                    <DefaultButton onClick={() => onClose()} buttonStyles={{marginRight: '20px'}} type={'button'} title={'Cancel'} outlined={true}/>
                                    <DefaultButton type={'submit'} disabled={!isValid} title={'Save'}/>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default EditPerspectiveModalComponent;
