import React, {useEffect, useState} from 'react';
import styles from './ContributesComponent.module.scss';
import DefaultButton from "../Buttons/DefaultButton";
import OutlinedSelectInput from "../Inputs/OutlinedSelectInput";
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import projectService from "../../../services/ProjectService";
import goalService from "../../../services/GoalService";
import {useDispatch} from "react-redux";
import {setOptionsForEditParent} from "../../../store/strategyPlan/actions";
import {getScorecardName} from "../../../utils/setDataForContributesToComponent";

const parentTypeOptions = [
  {
    id:1,
    name: 'Goal'
  },
  {
    id:2,
    name: 'Project'
  }
]
function EditParent({item, options, onCancel, onSave, parent}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedParentTypeOption, setSelectedParentOption] = useState('');
  const [editOptions, setEditOptions] = useState(options);
  const dispatch = useDispatch()

  useEffect(() => {
    setEditOptions(options)
    let selected = options.find(item => item.id === parent.id);
    if(selected) {
      setSelectedOption(selected)
      setSelectedParentOption(parentTypeOptions.find(item => item.name === selected.type))
    }
  }, [parent, options])

  const onChange = (val) => {
    setSelectedOption(val.target.value)
  }
  const onChangeParentType = async (val) => {
    try {
      setSelectedParentOption(val.target.value)
      let items = [];
      if(val.target.value.name === ROW_ITEM_TYPES.PROJECT) {
        items = await projectService.getProjectsByOrganization();
      } else {
        items = await goalService.getGoalsByOrganization()
      }
      let options = items.map((item) => {
        return {...item, subtitle: getScorecardName(item)}
      })
      setSelectedOption('');
      dispatch(setOptionsForEditParent(options))
    } catch (e) {
      console.log('err', {e});
    }

  }
  const onSaveOption = () => {
    onSave(selectedOption)
  }

  return (
    <div className={styles.formWrapper}>
      { editOptions && <div>
        { item.type === ROW_ITEM_TYPES.ACTION && <div className={styles.additionalSelect}><OutlinedSelectInput value={selectedParentTypeOption} options={parentTypeOptions} handleChange={onChangeParentType}
                                                                           fullItemValue={true}/></div>}
        <OutlinedSelectInput value={selectedOption} options={editOptions} handleChange={onChange} fullItemValue={true}/>
        <div className={styles.editContributesButton}>
          <DefaultButton title={'Cancel'} onClick={onCancel} disabled={false} padding={'4px 16px'} inverted={true}/>
          <DefaultButton type={'button'} title={'Save'} onClick={onSaveOption} disabled={!selectedOption} padding={'4px 16px'}/>
        </div>
      </div>}

    </div>
  );
}


export default EditParent;
