import React, {memo} from 'react';
import styles from './NotificationsItem.module.scss';
import CheckboxInput from "../../../components/Shared/Inputs/Checkbox";
import {NOTIFICATIONS_SEND_TYPE} from "./notifications";
import {Grid} from "@mui/material";

const NotificationsItem = ({item, onChange, values}) => {
  return (
    <Grid item md={6} sm={12}>
      <div className={styles.container}>
        <h4 className={styles.title}>{item.label}</h4>
        <div className={styles.rowContainer}>
          <CheckboxInput value={values?.inApp} handleChangeCheck={(value) => onChange(item, NOTIFICATIONS_SEND_TYPE.IN_APP, value)} />
          <h4>In-App</h4>
        </div>
        <div className={styles.rowContainer}>
          <CheckboxInput value={values?.email} handleChangeCheck={(value) => onChange(item, NOTIFICATIONS_SEND_TYPE.EMAIL, value)} />
          <h4>Email</h4>
        </div>
      </div>
    </Grid>
  );
};

export default memo(NotificationsItem);
