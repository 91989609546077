export const TABS = [
  {
    id: 1,
    title: 'Details',
  },
  // {
  //   id: 2,
  //   title: 'Risks',
  //   disabled: true,
  // },
  {
    id: 3,
    title: 'Updates',
  },
  {
    id: 4,
    title: 'Relationships',
    disabled: true,
  },
];
