import React, {useCallback, useEffect, useMemo, useState} from 'react';
import HorizontalTabs from "../../HorizontalTabs";
import {TABS} from "./tabs";
import SidebarItemHeader from "../SidebarItemHeader";
import styles from './SidebarProjectComponent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {rightModalOpen, setModal} from "../../../store/helper/actions";
import {
  itemAndParentForActionSelector,
  modalSelector,
  rightModalSelector,
  updatesSelector
} from "../../../store/helper/selectors";
import {
  closeSidebarSaga,
  getRightSidebarItemSaga,
  rightSidebarBack,
  rightSidebarDeleteItemSaga,
  rightSidebarOnActionClickSaga,
  rightSidebarSubmitFormSaga
} from "../../../store/strategyPlan/actions";
import Details from "./Details/Details";
import {COLORS} from "../../../constants/colors";
import {ACTIONS, HUB_ENTITY_ROUTE_TYPE} from "../../../constants/strings";
import ItemWrapper from "../ItemWrapper";
import {setGoalsLibraryModal} from "../../../store/goals/actions";
import {getItemTypeTerminology, getMoveItemTitle} from "../../../utils/perspective";
import MovePerspectiveItem from "../../MovePerspectiveItem/MovePerspectiveItem";
import DeleteContent from "../../Shared/Modals/DeleteContent";
import {moveItemSaga} from "../../../store/perspectives/actions";
import CustomModal from "../../Shared/Modals/CustomModal";
import {strategyPlanSelector} from "../../../store/strategyPlan/selectors";
import RisksTab from "../RisksTab/RisksTab";
import ItemUpdatesComponent from "../../ItemUpdates/ItemUpdatesComponent";
import {useHistory, useLocation} from "react-router-dom";
import {HUB} from "../../../routesConstants";
import {getDecodedURIComponent, getQueryParam} from "../../../utils/url";
import {OPENED_TAB_TYPES} from "../../../utils/helper";
import {terminologySelector} from "../../../store/auth/selectors";

const SidebarProjectComponent = ({page}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();

  const openTab = getDecodedURIComponent(getQueryParam(search, 'openTab'));

  const [selectedTab, setSelectedTab] = useState('1');

  const data = useSelector(itemAndParentForActionSelector());
  const strategyPlan = useSelector(strategyPlanSelector());
  const modal = useSelector(modalSelector());
  const updates = useSelector(updatesSelector());
  const rightModal = useSelector(rightModalSelector());
  const terminology = useSelector(terminologySelector());

  const initialModalState = useMemo(() => ({
    isVisible: false,
    title: '',
    component: null,
  }), []);

  const onTabChange = (val) => {
    setSelectedTab(val);
    dispatch(getRightSidebarItemSaga(data.item, data.parent));
  };

  const closeSidebar = () => {
    dispatch(closeSidebarSaga());
  };

  const moveItem = (item, parentItemId, parentItemType, retainContribution, isParentGoal) => {
    dispatch(moveItemSaga(
      item,
      parentItemId,
      parentItemType,
      retainContribution,
      isParentGoal,
      strategyPlan.id,
    ));
  };

  const deleteItem = () => {
    dispatch(rightSidebarDeleteItemSaga(data.item, strategyPlan.id));
  };

  const closeModal = useCallback(() => {
    dispatch(setModal(initialModalState));
  }, [dispatch, initialModalState]);


  const onActionClick = (item, action, type) => {
    switch (action) {
      case ACTIONS.ADD:
      case ACTIONS.EDIT:
        dispatch(rightSidebarOnActionClickSaga(
          item,
          data.parent,
          action,
          <ItemWrapper
            goBack={() => {
              dispatch(rightSidebarBack());
            }}
            action={action}
            item={item}
            type={action === ACTIONS.ADD ? type : item.type}
            parentType={data.item.type}
            onFormSubmit={(values, action, type, item) => {
              dispatch(rightSidebarSubmitFormSaga(
                values,
                action,
                type,
                item,
                strategyPlan?.id
              ));
            }}
            scorecardId={strategyPlan?.id}
          />
        ));
        break;
      case ACTIONS.SHARE:
        dispatch(setGoalsLibraryModal({
          isVisible: true,
          goalId: item.id,
          scorecardId: strategyPlan?.id,
          page,
        }));
        break;
      case ACTIONS.MOVE:
        dispatch(setModal({
          isVisible: true,
          title: getMoveItemTitle({item, modal: true, terminology}),
          component: (
            <MovePerspectiveItem
              data={item}
              isParentGoal={!data.item.goalId}
              closeModal={closeModal}
              moveItem={moveItem}
              scorecard={strategyPlan}
            />
          ),
        }));
        break;
      case ACTIONS.DELETE:
        dispatch(setModal({
          isVisible: true,
          title: `Delete ${getItemTypeTerminology({
            type: data.item.type,
            terminology,
          })}`,
          component: (
            <DeleteContent
              text={`Are you sure you want to delete ${data?.item?.name} ${getItemTypeTerminology({
                type: data.item.type,
                terminology,
              })}?`}
              onPressClose={closeModal}
              onPressConfirm={deleteItem}
            />
          ),
        }));
        break;
      case ACTIONS.OPEN_HUB:
        dispatch(rightModalOpen({
          isVisible: false,
          component: null,
          page: null,
        }));
        history.push(HUB.replace(':entity', HUB_ENTITY_ROUTE_TYPE.PROJECT).replace(':id', item.id));
        break;
      default:
        console.log('no matching action selected');
    }
  };

  const setOpenTab = useCallback(() => {
    if (openTab) {
      let selectedTab;
      switch (openTab) {
        case OPENED_TAB_TYPES.DETAILS:
          selectedTab = '1';
          break;
        case OPENED_TAB_TYPES.UPDATES:
          selectedTab = '2';
          break;
        default:
          selectedTab = '1';
      }

      setSelectedTab(selectedTab);
    }
  }, [openTab]);

  useEffect(() => {
    setOpenTab();
  }, [setOpenTab]);

  const renderContent = () => {
    switch (Number(selectedTab)) {
      case 1:
        return (
          <Details data={data}/>
        );
      case 2:
        return (
          <RisksTab item={data?.item} rightModal={rightModal} />
        );
      case 3:
        return (
          <ItemUpdatesComponent data={data.item} updates={updates} />
        );
      case 4:
        return (
          <div />
        );
      case 5:
        return (
          <div />
        );
      default:
        return (
          <div />
        );
    }
  };

  return (
    <div className={styles.container}>
      <SidebarItemHeader
        item={data.item}
        closeSidebar={closeSidebar}
        onMenuClick={onActionClick}
      />
      <HorizontalTabs selectedTab={selectedTab} onTabChange={onTabChange} tabs={TABS} backgroundColor={COLORS.white} />
      {renderContent()}
      <CustomModal
        isVisible={modal.isVisible}
        title={modal.title}
        closeModal={closeModal}
      >
        {modal.component}
      </CustomModal>
    </div>
  );
};

export default SidebarProjectComponent;
