import {MoreVert} from "@mui/icons-material";
import {COLORS} from "../../../constants/colors";
import React from "react";

export const riskCardOptionsConfig = ({onClick, item, isOpenStatus}) => {
    return {
        button: {
            icon: (
                <MoreVert style={{color: COLORS.darkerGray}}/>
            ),
        },
        content: {
            menu: {
                items: [
                    ...isOpenStatus ? [
                        {
                            id: 1,
                            title: 'Edit',
                            onClick: () => onClick(item, 'edit'),
                        },
                        {
                            id: 2,
                            title: 'Close Risk',
                            onClick: () => onClick(item, 'close-risk'),
                        },
                        {
                            id: 3,
                            title: 'Re-evaluate',
                            onClick: () => onClick(item, 're-evaluate'),
                        }
                    ] : [],
                    {
                        id: 4,
                        title: 'Delete',
                        onClick: () => onClick(item, 'delete'),
                        titleColor: COLORS.red,
                    },
                ],
            },
        },
    };
};
