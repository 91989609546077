import React, {useState} from 'react';
import styles from './ProfileSettingsPage.module.scss'
import EditUserComponent from "../../components/Settings/Users/EditUserComponent";
import {useSelector} from "react-redux";
import {makeSelectUser} from "../../store/auth/selectors";
import {PROFILE_TABS} from "../../constants/strings";
import HorizontalTabs from "../../components/HorizontalTabs";
import {Grid} from "@mui/material";
import NotificationsTabComponent from "./NotificationsTabComponent/NotificationsTabComponent";
import ChangePasswordComponent from "./ChangePasswordTabComponent/ChangePasswordComponent";

export function ProfileSettingsPage() {
  const me = useSelector(makeSelectUser());
    const [selectedTab, setSelectedTab] = useState('1');
    const onTabChange = (val) => {
        setSelectedTab(val);
    }

    const chooseSelectedTab = (val) => {
        switch (Number(val)) {
            case 1:
                return (
                    <Grid container spacing={0} className={styles.contentContainer}>
                        <Grid item xs={12} md={12} lg={7}>
                            <EditUserComponent user={me} isAuthMe/>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                  <NotificationsTabComponent user={me} />
                );
            case 3:
                return (
                    <ChangePasswordComponent/>
                );
            default:
                return (<div></div>)
        }
    }

  return (
      <div className={styles.container}>
                <HorizontalTabs selectedTab={selectedTab} onTabChange={onTabChange} tabs={PROFILE_TABS}/>
              {chooseSelectedTab(selectedTab)}
      </div>
  );
}

export default ProfileSettingsPage;
