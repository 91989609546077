export const DASHBOARDS = '[Perspective]';
export const TEST_DASHBOARDS_SAGA = `${DASHBOARDS} TEST_DASHBOARDS_SAGA`;
export const SET_VISIBILITY_DASHBOARD_ACTION_MODAL = `${DASHBOARDS} SET_VISIBILITY_DASHBOARD_ACTION_MODAL`;
export const DASHBOARD_SETTINGS_SAGA = `${DASHBOARDS} DASHBOARD_SETTINGS_SAGA`;
export const DASHBOARD_DUPLICATE_SAGA = `${DASHBOARDS} DASHBOARD_DUPLICATE_SAGA`;
export const GET_DASHBOARDS_GRID_SAGA = `${DASHBOARDS} GET_DASHBOARDS_GRID_SAGA`;
export const SET_DASHBOARDS_GRID = `${DASHBOARDS} SET_DASHBOARDS_GRID`;
export const DELETE_DASHBOARD_SAGA = `${DASHBOARDS} DELETE_DASHBOARD_SAGA`;
export const SET_SHARE_DASHBOARD_MODAL = `${DASHBOARDS} SET_SHARE_DASHBOARD_MODAL`;
export const GET_DASHBOARD_SAGA = `${DASHBOARDS} GET_DASHBOARD_SAGA`;
export const SET_DASHBOARD = `${DASHBOARDS} SET_DASHBOARD`;
export const GET_CHART_DETAILS_SAGA = `${DASHBOARDS} GET_CHART_DETAILS_SAGA`;
export const SET_CHART_DETAILS = `${DASHBOARDS} SET_CHART_DETAILS`;
export const OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA = `${DASHBOARDS} OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA`;
export const DELETE_WIDGET_SAGA = `${DASHBOARDS} DELETE_WIDGET_SAGA`;
export const CREATE_UPDATE_WIDGET_SAGA = `${DASHBOARDS} CREATE_UPDATE_WIDGET_SAGA`;
