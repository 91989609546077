import React, {PureComponent, useEffect, useState} from "react";
import styles from './compareMultiple.module.scss'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {CHART_COLORS_ARR, COLORS} from "../../../constants/colors";
import dayjs from "dayjs";
import {defaultFormatDate} from "../../../utils/format";
import {formatUnit, formatUnitAndValue} from "../../../utils/perspective";
import {formatGraphNumber} from "../../../utils/dashboard";
import CustomDot from "../../Shared/Dot/CustomDot";
import {ROW_ITEM_TYPES, TASK_TYPES} from "../../../constants/strings";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";
function CompareMultipleKpis({data, label, onChartClick, type = ROW_ITEM_TYPES.KPI}) {
    const [graphData, setGraphData] = useState([]);
    const [graphDataKeys, setGraphDataKeys] = useState([]);

    const terminology = useSelector(terminologySelector());

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltipWrapper}>
                    <p className={styles.title}>{defaultFormatDate(label)}</p>
                    {payload.map((item, index) => {
                        return (
                            <div className={styles.tooltipItemWrapper} key={index}>
                                <CustomDot backgroundColor={item.color} large />
                                <h4 className={styles.tooltipItemName}>{item.name}:</h4>
                                <h4 className={styles.tooltipItemName}>{formatUnitAndValue(data[index]?.unit, item.value)}</h4>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        let formattedData = [];
        let dataKeys = [];

        data.forEach((item) => {
            const dataKeyFound = dataKeys.find((el) => el === item.name);
            if (!dataKeyFound) dataKeys.push(item.name);
            setGraphDataKeys(dataKeys)

            item.data.forEach((update) => {
                if (!formattedData[update.timestamp]) {
                    formattedData[update.timestamp] = {};
                    formattedData[update.timestamp]["name"] = update.timestamp;
                }
                formattedData[update.timestamp][item.name] = update.value;
            });
        });

        let dataForGraph = [];
        for (const [key, value] of Object.entries(formattedData)) {
            dataForGraph.push(value);
        }

        dataForGraph = dataForGraph.sort((a, b) => a.name - b.name);
        setGraphData(dataForGraph);
    }, [data]);

    const dateFormatter = date => {
        return dayjs(new Date(date)).format('MMM-DD/YY');
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={15} y={-5} dy={16} fontSize={11} textAnchor="end" fill={COLORS.legendBlack} transform="rotate(-15)">
                        {defaultFormatDate(payload.value)}
                    </text>
                </g>
            );
        }
    }

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload, anchor, color } = props;

        return (
            <text x={x} y={y} textAnchor={anchor} fill={color}>
                {formatGraphNumber(payload.value)}
            </text>
        );
    };

    return (
        <div className={styles.wrapper}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={graphData} onClick={() => onChartClick(type === ROW_ITEM_TYPES.KPI ? TASK_TYPES.KPI : TASK_TYPES.GOAL, data.map((item) => item.id))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        tick={<CustomizedAxisTick/>}
                        dataKey="name"
                        scale="time"
                        domain={['auto', 'auto']}
                        type={'number'}
                        tickFormatter={dateFormatter}
                    />
                    <YAxis
                        label={{value: label ? label : `Avg Value of ${terminology.kpi} (${formatUnit(data[0]?.unit, true)})`, angle: -90, style: {fill: COLORS.legendBlack, fontSize: 12}, dx: -25}}
                        tick={<CustomizedYAxisTick anchor={'end'} color={COLORS.legendBlack} />}
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend iconType={'circle'} />
                    {data.map((s, index) => (
                        <Line
                            isAnimationActive={false}
                            connectNulls
                            stroke={CHART_COLORS_ARR[index]}
                            dot={{ stroke: CHART_COLORS_ARR[index], strokeWidth: 2 }}
                            dataKey={graphDataKeys[index]}
                            key={s.name}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}


export default CompareMultipleKpis;
