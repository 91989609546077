export const TABS = (item) => {
  return [
    {
      id: 1,
      title: 'Details',
    },
    {
      id: 2,
      title: 'Risks',
    },
    {
      id: 3,
      title: 'Updates',
      disabled: !item.trackingType,
    },
    {
      id: 4,
      title: 'Relationships',
      disabled: true,
    },
  ];
};
