import React from 'react'
import {ListItemIcon, Menu, MenuItem} from "@mui/material";

const Popover = ({currentNode, open, handleClose, options, onItemPress}) => {

    const handleClick = (event) => {
        const value = event.currentTarget.getAttribute('data-value');
        onItemPress(value)
    };

    return (
        <div>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id="basic-menu"
                anchorEl={currentNode}
                open={open}
                onClose={handleClose}
                style={{
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    marginTop: '20px',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {options.map((item, index) => (
                    <MenuItem
                        onClick={(event) => {
                            handleClick(event)
                            handleClose();
                        }}
                        sx={{
                            color: '#212529',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '150%'
                        }}
                        data-value={item.label}
                        key={index}
                        disabled={item.label === "Tasks"}
                    >
                        <ListItemIcon>
                            {item.image}
                        </ListItemIcon>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default Popover
