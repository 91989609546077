import React, {useCallback, useEffect, useState} from 'react';
import styles from './MetricsSidebarComponent.module.scss';
import {COLORS} from "../../../constants/colors";
import HorizontalTabs from "../../../components/HorizontalTabs";
import {useDispatch, useSelector} from "react-redux";
import MetricsSidebarHeader from "./MetricsSidebarHeader";
import {rightModalOpen} from "../../../store/helper/actions";
import {ACTIONS} from "../../../constants/strings";
import {TABS} from "./tabs";
import {
    deleteMetricSaga,
    getMetricSaga,
    setIsDeleteModalVisible,
    setShowEditModalFromSidebar
} from "../../../store/metrics/actions";
import {isDeleteModalVisibleSelector, metricSelector} from "../../../store/metrics/selectors";
import DeleteContent from "../../../components/Shared/Modals/DeleteContent";
import CustomModal from "../../../components/Shared/Modals/CustomModal";
import KpisTabComponent from "./KpisTabComponent";
import DetailsTabComponent from "./DetailsTabComponent/DetailsTabComponent";
import TableViewTabComponent from "./TableViewTabComponent/TableViewTabComponent";
import {loaderSelector} from "../../../store/loader/selectors";
import {CONNECT_METRIC_WITH_KPI} from "../../../routesConstants";
import {useHistory} from "react-router-dom";
import metricsService from "../../../services/MetricsService";
import {terminologySelector} from "../../../store/auth/selectors";

const MetricsSidebarComponent = ({item}) => {
    const dispatch = useDispatch();

    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState('1');
    const [graphData, setGraphData] = useState(null);
    const [dataPoints, setDataPoints] = useState(null);

    const metric = useSelector(metricSelector());
    const isDeleteModalVisible = useSelector(isDeleteModalVisibleSelector());
    const loader = useSelector(loaderSelector());
    const terminology = useSelector(terminologySelector());

    const closeSidebar = () => {
        dispatch(rightModalOpen({
            isVisible: false,
            page: '',
            component: null,
        }));
    };

    const getGraphData = useCallback(async () => {
        try {
            const res = await metricsService.getMetricWithChartData(item.id);
            setGraphData(res.graphData);
        } catch (error) {
            console.error({error});
        }
    }, [item.id]);

    const getDataPoints = useCallback(async () => {
        try {
            const res = await metricsService.showDataPoints(item.id);
            setDataPoints(res);
        } catch (error) {
            console.error({error});
        }
    }, [item.id]);

    const closeDeleteModal = useCallback(() => {
        dispatch(setIsDeleteModalVisible(false));
    }, [dispatch]);

    const onMenuItemClick = useCallback((item, action) => {
        switch (action) {
            case ACTIONS.EDIT:
                dispatch(setShowEditModalFromSidebar({id: item.id, addValue: false}));
                closeSidebar();
                break;
            case ACTIONS.DELETE:
                dispatch(setIsDeleteModalVisible(true));
                break;
            case ACTIONS.CONNECT_METRICS_TO_KPI:
                closeSidebar();
                history.push(CONNECT_METRIC_WITH_KPI.replace(':metricId', item.id))
                break;
            default:
                console.log('no matching action selected');
        }
    }, [dispatch]);

    const handleDeleteMetric = () => {
        dispatch(deleteMetricSaga(metric));
    };

    const getMetric = useCallback(() => {
        dispatch(getMetricSaga(item));
    }, [dispatch, item]);

    const onTabChange = useCallback((val) => {
        setSelectedTab(val);
    }, []);

    const handleUpdate = async () => {
        try {
            await metricsService.refreshMetrics(item.id);
            getMetric();
            getGraphData();
        } catch (error) {
            console.error({error});
        }
    };

    const handleAddValues = useCallback(() => {
        dispatch(setShowEditModalFromSidebar({id: item.id, addValue: true}));
        closeSidebar();
    }, []);

    const getData = useCallback(() => {
        switch (selectedTab) {
            case '1':
                getMetric();
                getGraphData();
                break;
            case '2':
                getMetric();
                break;
            case '3':
                getDataPoints();
                break;
            default:
                console.log('no matching selected tab');
        }
    }, [getDataPoints, getGraphData, getMetric, selectedTab]);

    useEffect(() => {
        getData();
    }, [getData]);

    const renderContent = () => {
        switch (Number(selectedTab)) {
            case 1:
                return (
                    <DetailsTabComponent
                        graphData={graphData}
                        item={metric}
                        update={handleUpdate}
                        loader={loader}
                    />
                );
            case 2:
                return (
                    <KpisTabComponent metric={metric}/>
                );
            case 3:
                return (
                    <TableViewTabComponent metric={item} item={dataPoints?.processedDataPoints} onAddValues={handleAddValues}/>
                );
            default:
                return (
                    <div/>
                );
        }
    };

    return (
        <div className={styles.container}>
            <MetricsSidebarHeader
                item={metric}
                onMenuItemClick={onMenuItemClick}
                closeSidebar={closeSidebar}
            />
            <HorizontalTabs
                selectedTab={selectedTab}
                onTabChange={onTabChange}
                tabs={TABS({terminology})}
                backgroundColor={COLORS.white}
            />
            {renderContent()}
            <CustomModal
                title={'Delete metric'}
                isVisible={isDeleteModalVisible}
                maxWidth={'sm'}
                closeModal={closeDeleteModal}
                children={(
                    <DeleteContent
                        text={`Are you sure you want to delete ${metric?.name} metric?`}
                        onPressClose={closeDeleteModal}
                        onPressConfirm={handleDeleteMetric}
                    />
                )}
            />
        </div>
    );
};

export default MetricsSidebarComponent;
