import React, {useCallback} from "react";
import TableComponent from "../../Tables";
import {TABLE_CONFIG} from "./TABLE_CONFIG";
import scorecardService from "../../../services/ScorecardService";
import SidebarKpiComponent from "../../RightModal/Kpi/SidebarKpiComponent";
import {openRightModalFromChartDetails} from "../../../store/dashboards/actions";
import {useDispatch} from "react-redux";
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import goalService from "../../../services/GoalService";
import SidebarGoalComponent from "../../RightModal/Goal/SidebarGoalComponent";
import projectService from "../../../services/ProjectService";
import SidebarProjectComponent from "../../RightModal/Project/SidebarProjectComponent";
import SidebarActionComponent from "../../RightModal/Action/SidebarActionComponent";
import styles from './indexStyle.module.scss'


function InitiativeTable({data, onChartClick, fromReport}) {
    const dispatch = useDispatch();
    const editRow = useCallback(async (row) => {
        if (!fromReport) {
            let component;
            let item;
            switch (row.type) {
                case ROW_ITEM_TYPES.GOAL:
                    item = await goalService.getGoalWithItems(row.id);
                    component = (
                      <SidebarGoalComponent />
                    );
                    break;
                case ROW_ITEM_TYPES.KPI:
                    item = await scorecardService.getKpiById(row.id);
                    component = (
                      <SidebarKpiComponent />
                    );
                    break;
                case ROW_ITEM_TYPES.PROJECT:
                    item = await projectService.getProjectById(row.id);
                    component = (
                      <SidebarProjectComponent />
                    );
                    break;
                case ROW_ITEM_TYPES.ACTION:
                    item = await scorecardService.getActionById(row.id);
                    component = (
                      <SidebarActionComponent />
                    );
                    break;
                default:
                    console.log('no matching type selected');
            }

            dispatch(openRightModalFromChartDetails({component, item}));
        }
    }, [dispatch, fromReport]);

    return (
        <div className={styles.wrapperTable}>
            {data && <TableComponent
              configTable={TABLE_CONFIG}
              data={data}
              paginated={false}
              rowClickable
              onRowClick={editRow}
            />}
        </div>
    );
}


export default InitiativeTable;
