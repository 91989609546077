import React from "react";
import styles from './indexStyle.module.scss';
import {getRowItemIcon, goalStatus} from "../../../utils/perspective";
import CustomDot from "../../../components/Shared/Dot/CustomDot";
import {DASHBOARD_PERSPECTIVE_HEALTH_CONVERTED} from "../../../constants/strings";

export const TABLE_CONFIG = (row) => {
  return [
    {
      id: 1,
      type: 'component',
      label: 'Name',
      value: 'title',
      sortField: 'name',
      component: (
        <div className={styles.flex}>
          <div className={styles.iconContainer}>
            {getRowItemIcon(row, !row?.goalId)}
          </div>
          <div className={styles.subtitleContainer}>
            <h4 className={styles.subtitle}>{row?.name}</h4>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      id: 2,
      type: 'date',
      label: 'Updated Date',
      value: 'updatedAt',
      component: (
        <div className={styles.date}>
          {`${row?.updatedAt ? row?.updatedAt : '-'}`}
        </div>
      ),
      sortable: false,
    },
    {
      id: 3,
      type: 'component',
      label: 'Progress',
      value: 'progress',
      sortable: false,
      component: (
        <div >
          {`${row?.progress ? row?.progress +'%' : '-'}`}
        </div>
      ),
    },
    {
      id: 4,
      type: 'component',
      label: 'Health',
      value: '',
      sortable: false,
      component: (
        <div className={styles.flex}>
          <CustomDot backgroundColor={goalStatus(row?.health).color} medium />
          <div className={styles.subtitleContainer}>
            <h4 className={styles.subtitle}>{row?.health ? row?.health : DASHBOARD_PERSPECTIVE_HEALTH_CONVERTED.NOT_TRACKED}</h4>
          </div>
        </div>
      ),
    },
  ];
};
