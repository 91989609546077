const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  spinnerTimeout: process.env.REACT_APP_SPINNER_TIMEOUT,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  linkedInClientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  tinyMceApiKey: process.env.REACT_APP_TINY_MCE_API_KEY,
};

export default config;
