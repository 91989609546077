import React from 'react';
import DefaultButton from "../Buttons/DefaultButton";
import styles from './DeleteContent.module.scss';
import {COLORS} from "../../../constants/colors";
import CustomModal from "./CustomModal";
import {useDispatch, useSelector} from "react-redux";
import {warningModalSelector} from "../../../store/helper/selectors";
import WarningModalContent from "./WarningModalContent";
import {setWarningModal} from "../../../store/helper/actions";

const DeleteContent = ({
  text,
  onPressClose,
  onPressConfirm,
  confirmButtonColor = COLORS.lighterRed,
  warningModalTitle,
  warningModalContentTitle,
  warningModalDescription,
  confirmWarningModalAction,
  showWarningModal = false,
}) => {
  const dispatch = useDispatch();
  const warningModal = useSelector(warningModalSelector());

  const closeWarningModal = () => {
    dispatch(setWarningModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));
  };



  return (
    <div className={styles.container}>
      <p className={styles.title}>{text}</p>
      <div className={styles.footer}>
        <DefaultButton onClick={onPressClose} title={'Close'} backgroundColor={'rgba(0, 33, 66, 0.06)'} fontColor={'#495057'} padding={'8px 16px'} borderRadius={'8px'}/>
        <DefaultButton onClick={onPressConfirm} title={'Confirm'} backgroundColor={confirmButtonColor} padding={'8px 16px'} borderRadius={'8px'} buttonStyles={{marginLeft: '8px'}}/>
      </div>
      <CustomModal
        isVisible={warningModal.isVisible}
        title={warningModalTitle}
        maxWidth={'sm'}
        closeModal={closeWarningModal}
      >
        <WarningModalContent
          closeModal={closeWarningModal}
          title={warningModalContentTitle}
          description={warningModalDescription}
          confirmAction={confirmWarningModalAction}
          warningModal={warningModal}
        />
      </CustomModal>
    </div>
  );
};

export default DeleteContent;
