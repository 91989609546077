import React, {useState} from 'react';
import styles from './Details.module.scss';
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import {ReactComponent as AddIcon} from '../../../../assets/icons/plusBlue.svg';
import {COLORS} from "../../../../constants/colors";
import ProgressBarComponent from "../../../Shared/ProgressBar/ProgressBarComponent";
import {useDispatch} from "react-redux";
import {addActionFromSidebarSaga} from "../../../../store/strategyPlan/actions";
import DetailsComponent from "../../DetailsComponent";
import {DETAILS_TYPE} from "../../../../constants/strings";
import ContributesToComponent from "../../../Shared/ContributesToComponent";
import {setParentDataForContributesToComponent} from "../../../../utils/setDataForContributesToComponent";
import CommentSection from "../../../CommentSection";
import {CalendarMonth} from "@mui/icons-material";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/arrowDown.svg";
import ExpectedProgress from "../../../Shared/TrackingComponent/ExpectedProgress";
import ChartWithProgressComponent from "../../../Shared/ChartWithProgressComponent/ChartWithProgressComponent";

const Details = ({data}) => {
    const dispatch = useDispatch();
    const [showScheduleFrequency, setShowScheduleFrequency] = useState(false);
    const detailList = [
        {id: 1, type: DETAILS_TYPE.OWNER, title: 'Owner', name: data.item.owner.name || data.item.owner.email, photo: data.item.owner.photo},
        {id: 2, type: DETAILS_TYPE.OWNERS, title: 'Sharing With', users: data.item.owners},
        {id: 3, type: DETAILS_TYPE.TIME_FRAME, title: 'Time Frame', startDate: data.item.startDate, endDate: data.item.endDate, alignment: 'start'},
        {id: 4, type: DETAILS_TYPE.DESCRIPTION, title: 'Description', description: data.item.description, alignment: 'start'},
        {id: 5, type: DETAILS_TYPE.UPDATE_SCHEDULE, title: 'Update Schedule', date: data.item.endDate},
    ];
    const onAddAction = () => {
        dispatch(addActionFromSidebarSaga(data.item))
    }

    const onBoxShow = () => {
        setShowScheduleFrequency(!showScheduleFrequency);
    }
    return (
        <div className={styles.container}>
          <div className={styles.detailsWithoutComments}>
              <div className={styles.addActionWrapper}>
                  <p>{data.item.actions.length > 0 ? ' ' : 'You need to add action'}</p>
                  <DefaultButton
                      onClick={onAddAction}
                      title={'Add Action'}
                      outlined={true}
                      leftIcon={<AddIcon color={COLORS.blue}/>}
                      padding={'0 16px 0 40px'}
                      height={38}
                      type={'button'}
                  />
              </div>
              <div style={{width: '100%'}}>
                  <ChartWithProgressComponent hideProgress={true} data={data.item}/>
              </div>
              {
                  data?.item?.actualProgress !== null && (
                      <div className={styles.progressBarWrapper}>
                          <ProgressBarComponent
                              hideValue={true}
                              expectedProgress={data?.item?.expectedProgress}
                              header
                              showHeaderBadge
                              headerValue={data?.item?.actualProgress > 100 ? 100 : data?.item?.actualProgress}
                              item={data.item}
                          />
                      </div>
                  )
              }
              <div>
                  <DetailsComponent data={data.item} updateDate={data.item.nextUpdateDate} isBtnVisible={data.item.needsUpdate} items={detailList}/>
                  <ContributesToComponent item={data.item}
                                          parent={setParentDataForContributesToComponent(data?.parent, data?.parent?.strategyPlanName)}
                                          showForms={false}
                                          canEditParent={false} />
                  <div className={styles.scheduleFrequencyWrapper} style={{height: showScheduleFrequency ? 'auto' : '70px'}}>
                      <div className={styles.titleWrapper}>
                          <div className={styles.leftTitleWrapper}>
                              <CalendarMonth/>
                              <p className={styles.titleText}>Update schedule frequency</p>
                          </div>
                          <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: showScheduleFrequency ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                              <ArrowDown/>
                          </button>
                      </div>
                      <ExpectedProgress showCheckbox={false} disableInputs={true} item={data.item} padding={'0'}
                                        setFormValues={()=>{}}/>
                  </div>
              </div>
          </div>
          <div className={styles.commentSectionWrapper}>
              <CommentSection item={data.item}/>
          </div>
        </div>
    );
};

export default Details;
