import {COLORS} from "../constants/colors";

export const getScoreColorsAndText = (score) => {
    switch (true) {
        case 0 <= score && score <= 33:
            return {
                backgroundColor: COLORS.lightBlueish,
                borderColor: '#0D4B64',
                text: 'Low',
            };
        case 33 < score && score <= 66:
            return {
                backgroundColor: COLORS.brightOrange,
                borderColor: '#A07904',
                text: 'Moderate',
            };
        case 66 < score && score <= 100:
            return {
                backgroundColor: COLORS.progressBarRed,
                borderColor: '#80151E',
                text: 'High',
            };
        default:
            return {
                backgroundColor: 'blue',
                borderColor: 'blue',
                text: 'Unknown',
            };
    }
};

export const riskTypes = [
    {
        id: 1,
        title: 'Budget',
        value: 'budget',
    },
    {
        id: 2,
        title: 'Economic',
        value: 'economic',
    },
    {
        id: 3,
        title: 'Environmental',
        value: 'environmental',
    },
    {
        id: 4,
        title: 'Competitive',
        value: 'competitive',
    },
    {
        id: 5,
        title: 'Compliance',
        value: 'compliance',
    },
    {
        id: 6,
        title: 'Cultural',
        value: 'cultural',
    },
    {
        id: 7,
        title: 'Logistic',
        value: 'logistic',
    },
    {
        id: 8,
        title: 'Legal',
        value: 'legal',
    },
    {
        id: 9,
        title: 'Financial',
        value: 'financial',
    },
    {
        id: 10,
        title: 'External',
        value: 'external',
    },
    {
        id: 11,
        title: 'Ethical',
        value: 'ethical',
    },
    {
        id: 12,
        title: 'Innovation',
        value: 'innovation',
    },
    {
        id: 13,
        title: 'Political',
        value: 'political',
    },
    {
        id: 14,
        title: 'Operational',
        value: 'operational',
    },
    {
        id: 15,
        title: 'Quality',
        value: 'quality',
    },
    {
        id: 16,
        title: 'Seasonal',
        value: 'seasonal',
    },
    {
        id: 17,
        title: 'Resource',
        value: 'resource',
    },
    {
        id: 18,
        title: 'Taxation',
        value: 'taxation',
    },
    {
        id: 19,
        title: 'Social',
        value: 'social',
    },
];

export const parseTypesString = (typesString) => {
    if (!typesString || typeof typesString !== 'string') {
        return [];
    }
    return typesString.split(',').map((type) => type.trim());
};
