import React, {useEffect, useState} from 'react';
import styles from './Checklist.module.scss'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {COLORS} from "../../../../constants/colors";
import DefaultButton from "../../Buttons/DefaultButton";
import CustomTextInput from "../../../CustomInputs/TextInput";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import {getMinMaxDatesFromItemAndParent} from "../../../../utils/objects";

export function ChecklistListItemComponent({item, index, onRemoveInput, items, onSetItem, errors}) {
    const [checklist, setChecklist] = useState(item)
    const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
    const [dateError, setDateError] = useState(null)


    useEffect(() => {
        setChecklist(item)
    }, [item])

    useEffect(() => {
        onSetItem({checklist, index})
    }, [checklist])
    const handleDateInputChange = (index, e) => {
        const newItem = {...checklist}
        newItem.date = dayjs(e)
        setChecklist(newItem)
    }

    const handleRemoveInput = (index) => {
        onRemoveInput(index)
    }

    const handleNameChange = (e) => {
        const newItem = {...checklist}
        newItem.name = e.target.value
        setChecklist(newItem)
    }

    return (
        <div className={styles.checklistListItem}>
            <div className={styles.wrapperItem}>
                <div className={styles.inputWrapper}>
                    <div className={styles.nameInput}>
                        <CustomTextInput border={errors?.name && `1px solid ${COLORS.lighterRed}`}  height={40} value={checklist?.name} handleInputChange={handleNameChange} placeholder={'Checklist Name'}/>
                    </div>
                </div>
                <div className={styles.dateInputWrapper}>
                    <div className={styles.dateDiv}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker   name={'date'} value={dayjs(checklist?.date)} slotProps={{ textField: { size: 'small', error: false } }}
                                          onChange={e => handleDateInputChange(index, e)}
                                          maxDate={getMinMaxDatesFromItemAndParent(itemAndParentForAction)?.endDate}
                                          minDate={getMinMaxDatesFromItemAndParent(itemAndParentForAction)?.startDate}
                                          onError={(e) => setDateError(e)}
                                          sx={{
                                              '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                      borderColor: dateError && 'red',
                                                  },
                                                  '&:hover fieldset': {
                                                      borderColor: dateError && 'red',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                      borderColor: dateError && 'red',
                                                  },
                                              },
                                          }}
                            />
                        </LocalizationProvider>
                    </div>
                    <DefaultButton padding={'8px 2px 8px 8px'} type={'button'} title={'X'} onClick={() => handleRemoveInput(index)} inverted
                                                                                     buttonStyles={{height: 40, color: COLORS.darkestGray}}/>
                </div>
            </div>
            <div>
                {dateError && <p className={styles.errMessage}>Invalid date</p>}
            </div>
        </div>
    )

}

export default ChecklistListItemComponent;
