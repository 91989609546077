import { takeLatest, call, put } from 'redux-saga/effects';
import {
  ADD_TRACKING_SAGA,
  CHANGE_PROGRESS_FROM_RIGHT_BAR_SAGA, REMOVE_KPI_CONNECTION_SAGA,
  UPDATE_KPI_PROGRESS_SAGA,
  UPDATE_KPI_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import scorecardService from "../../services/ScorecardService";
import {
  getStrategyPlanSaga,
  setAllItemsOnHomePage,
  setGoalsOnHomePage,
  setProjectsActionsKpisOnHomePage
} from "../strategyPlan/actions";
import kpiService from "../../services/KpiService";
import {
  getUpdatesSaga,
  refreshPageSaga,
  rightModalOpen,
  setActionModalFromNavbar,
  setItemAndParentForAction, setModal,
} from "../helper/actions";
import {setSnackbar} from "../snackbar/actions";
import goalService from "../../services/GoalService";
import {getItemParent} from "../../utils/perspective";
import {setMetric} from "../metrics/actions";
import metricsService from "../../services/MetricsService";
import homeService from "../../services/HomeService";
import {v4 as uuidv4} from "uuid";
import {addFrontIdToEveryItem} from "../../utils/helper";
import {ADD_ACTION_TYPE} from "../../constants/strings";
import actionService from "../../services/ActionService";

export function* updateKpiProgressSagaFn({payload, kpiId, scorecardId, goalId, page}) {
  try {
    yield put(setLoader(true));
    yield call(kpiService.updateKpiProgress, payload, kpiId);
    if (goalId) {
      const goal = yield call(goalService.getGoalWithItems, goalId);
      yield put(setItemAndParentForAction({item: goal, parent: getItemParent(goal)}));
    }
    yield put(refreshPageSaga({page, scorecardId}));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* changeProgressFromRightBarSagaFn({payload, kpiId, scorecardId, page}) {
  try {
    yield put(setLoader(true));
    yield call(kpiService.updateKpiProgress, payload, kpiId);
    yield put(refreshPageSaga({page, scorecardId}));
    const kpi = yield call(scorecardService.getKpiById, kpiId);
    yield put(setItemAndParentForAction({parent: kpi.goal, item: kpi}));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* addTrackingSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(scorecardService.addTrackingForKpi,payload.id, payload.data);
    switch (payload.addActionType) {
      case ADD_ACTION_TYPE.STRATEGY_PLAN:
        yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_GOAL:
        const resGoals = yield call(homeService.getHomeData);
        const goalsObj = {
          items: resGoals.upcomingGoals
        }
        addFrontIdToEveryItem(goalsObj);
        yield put(setGoalsOnHomePage(goalsObj))
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_PROJECTS_ACTIONS_KPIS:
        const resProjects = yield call(homeService.getHomeData);
        const projectsActionsKpisObj = {
          items: resProjects.allOtherItems
        }
        addFrontIdToEveryItem(projectsActionsKpisObj);
        yield put(setProjectsActionsKpisOnHomePage(projectsActionsKpisObj))
        break;
      case ADD_ACTION_TYPE.UPCOMING_GOALS:
        const allGoalsRes = yield call(homeService.getAllUpcomingGoals);
        const allGoals = {
          items: allGoalsRes
        }
        addFrontIdToEveryItem(allGoals);
        yield put(setAllItemsOnHomePage(allGoals))
        break;
      case ADD_ACTION_TYPE.ACTIONS_PROJECTS:
        const allActionsProjectsRes = yield call(homeService.getAllUpcomingProjectsKpisActions);
        const allActionsProjects = {
          items: allActionsProjectsRes
        }
        addFrontIdToEveryItem(allActionsProjects);
        yield put(setAllItemsOnHomePage(allActionsProjects))
        break;
      default: break;
    }
    yield put(setActionModalFromNavbar({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));
    yield put(setItemAndParentForAction({item: null, parent: null}))
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: 'Tracking added successfully',
    }));
    yield put(rightModalOpen({
      isVisible: false,
      component: null,
      page: null,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* updateKpiSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(kpiService.updateKpiProgress, payload.data, payload.kpiId);

    if (payload.metrics?.length > 0) {
      for (const metric of payload.metrics) {
        yield call(metricsService.refreshMetrics, metric.id);
      }
    }

    if (payload.fromCriteriaTab) {
      const goal = yield call(goalService.getGoalWithItems, payload.parentId);

      yield put(setItemAndParentForAction({
        item: goal,
        parent: getItemParent(goal),
      }));
    }

    if (payload.fromDetailsTab) {
      const kpi = yield call(scorecardService.getKpiById, payload.kpiId);
      yield put(setItemAndParentForAction({parent: kpi.goal, item: kpi}));
    }

    if (payload.fromKpisTab) {
      const metric = yield call(metricsService.getMetric, payload.metricId);
      yield put(setMetric(metric));
    }

    if (payload.fromHub) {
      yield put(getUpdatesSaga({entity: payload.hub.type, id: payload.hub.id}));
    }

    if (payload.metricId) {
      yield call(metricsService.refreshMetrics, payload.metricId);
    }

    yield put(refreshPageSaga({
      page: payload.page,
      kpiId: payload.kpiId,
      goalId: payload.goalId,
      scorecardId: payload.scorecardId,
      entity: payload.entity,
      entityId: payload.entityId,
      projectId: payload.projectId,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* removeConnectionSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    if (payload.kpiId) {
      yield call(kpiService.removeKpiConnection, payload.kpiId);
    }
    else if (payload.kpiIdFromExcel) {
      yield call(kpiService.removeKpiMicrosoftConnection, payload.kpiIdFromExcel);
    }
    else {
      yield call(actionService.removeActionConnection, payload.actionId);
    }
    yield put(refreshPageSaga({page: payload.page}));
    yield put(setModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* kpisSaga() {
  yield takeLatest(UPDATE_KPI_PROGRESS_SAGA, updateKpiProgressSagaFn);
  yield takeLatest(CHANGE_PROGRESS_FROM_RIGHT_BAR_SAGA, changeProgressFromRightBarSagaFn);
  yield takeLatest(ADD_TRACKING_SAGA, addTrackingSagaFn);
  yield takeLatest(UPDATE_KPI_SAGA, updateKpiSagaFn);
  yield takeLatest(REMOVE_KPI_CONNECTION_SAGA, removeConnectionSagaFn);
}
