import './index.css';
import './App.css';
import Routes from './Routes';
import {SnackbarProvider} from 'notistack';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchAuthenticatedUser} from "./store/auth/actions";
import {makeSelectToken, makeSelectUser} from "./store/auth/selectors";
import SnackbarComponent from "./components/Snackbar/SnackbarComponent";
import {GoogleOAuthProvider} from '@react-oauth/google';
import config from './config';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useHistory, useLocation} from "react-router-dom";
import {HOME, LOGIN} from "./routesConstants";
import {COLORS} from "./constants/colors";
import authService from "./services/AuthService";

function App() {
    const dispatch = useDispatch();

    const token = useSelector(makeSelectToken());
    const user = useSelector(makeSelectUser());
    const history = useHistory();

    const location = useLocation();
    const { pathname } = location;
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const renderLoadingIndicator = <div>Loading...</div>;

    const destroySession = async () => {
        await authService.destroySession()
    }

    useEffect(() => {
        if (token) {
            if (pathname === LOGIN && email) {
                destroySession();
                window.location.reload()
            } else {
                dispatch(fetchAuthenticatedUser());
            }
        }
    }, [token, dispatch]);

    window.onerror = function (message, source, lineno, colno, error) {
        console.error('Uncaught error: ', message, source, lineno, colno, error);
        if (message.includes('ResizeObserver loop')) {
            console.error(message);
            return;
        }
        showErrorScreen('An unexpected error occurred. Please try again later.', 'goToHome()');
    };


    function showErrorScreen(errorMessage, goToHomeFunction) {
        document.body.innerHTML = '';
        const htmlCode =
            `<div style="display: flex; flex-direction: row; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: ${COLORS.white}; z-index: 999;">
        <div style="height:100%;display: flex; align-items: center; width: 50%; flex-direction: column; justify-content: center">
            <p style="font-weight: 600; font-size: 50px">Ooops!</p>
            <p style="font-weight: 600; font-size: 18px; margin-bottom: 16px">${errorMessage}</p>
            <button onclick="${goToHomeFunction}"  style="border: none; background: ${COLORS.blue}; color: ${COLORS.white}; padding: 10px 20px; font-size: 16px; border-radius: 10px; cursor: pointer">Go Home</button>
        </div>
        <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 50%">
            <img style="width: 40%; height: auto" src="/Logo.svg" alt="Logo">
        </div>
    </div>`;
        document.body.insertAdjacentHTML('beforeend', htmlCode);
    }

    window.goToHome = function () {
        history.push(HOME)
        window.location.reload();
    }

    return (
        <GoogleOAuthProvider clientId={config.googleClientId}>
            <HelmetProvider>
                <SnackbarProvider>
                    <Helmet>
                        <title>StratifyPro</title>
                    </Helmet>
                    <div className='App'>
                        {token && !user ? (
                            renderLoadingIndicator
                        ) : (
                            <Routes user={user}/>
                        )}
                    </div>
                    <SnackbarComponent/>
                </SnackbarProvider>
            </HelmetProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
