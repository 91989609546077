import React, {useEffect, useState} from 'react';
import styles from './TrackingComponent.module.scss'
import CustomTextInput from "../../CustomInputs/TextInput";


export function TargetAndCriticalLimits({errors, item, setFormValues, type, clearProp, padding = '0', measurement}) {
  const dataObj = {
    lowerTarget: '',
    upperTarget: '',
    min: '',
    max: ''
  }
  const [limits, setLimits] = useState(dataObj);
  useEffect(() => {
    setLimits(dataObj)
    setFormValues({...dataObj})
  }, [type])


  useEffect(() => {
    if (item) {
      let itemValues = {
        lowerTarget: item?.lowerTarget ? item?.lowerTarget : '',
        upperTarget: item.upperTarget ? item.upperTarget : '',
        min: item.min ?? '',
        max: item.max ?? ''
      }
      setLimits({...itemValues})
      setFormValues({...itemValues})
    }
  }, [item])

  useEffect(() => {
    if(clearProp){
      setLimits(dataObj)
      setFormValues({...dataObj})
    }
  }, [clearProp])


  const onHandleLimits = (value, type) => {
    Object.defineProperty(limits, type, {value: !isNaN(Number(value)) ? Number(value) : 0, writable: true})
    setLimits({...limits})
    setFormValues({...limits})
  }

  const getFlagPosition = (value) => {
    if (limits.min !== undefined && limits.min !== null && limits.min !== '' && limits.max !== undefined && limits.max !== null && limits.max !== '') {
      let targetDiff = limits?.max - limits?.min;
      let percent = targetDiff / 100;
      if (percent > 0) {
        let realValue = (value - limits?.min) / percent;
        return realValue < 101 && `calc(${realValue}% - 8px)`;
      }
    }

  };

  const onBlur = (value, type) => {
    if (((type === 'lowerTarget' || type === 'upperTarget') && (value < limits.min || value > limits.max)) || (type === 'max' && value < limits.min)) {
      Object.defineProperty(limits, type, {value: limits.min, writable: true})
    }
    if (type === 'min' && value > limits.max && limits.max && limits.max !== 0) {
      Object.defineProperty(limits, type, {value: limits.max, writable: true})
    }
    setLimits({...limits})
    setFormValues({...limits})
  }

  const isDisabled = () => {
    return ((limits.min === null || limits.min === undefined || limits.min === '') && (limits.max === null || limits.max === undefined || limits.max === ''))
  }

  const onFocus = (type) => {
    let clearLimits = {...limits}
    Object.defineProperty(clearLimits, type, {value: clearLimits[type] === 0 ? '' : clearLimits[type], writable: true})
    setLimits(clearLimits)
  }

  return (
    <div className={styles.manuallyContainer} style={{padding: padding}}>


      <p className={styles.manuallyTitle}>Critical Limits</p>
      <div className={styles.targetLimits}>
        <div className={styles.width48}><span>Lowest Value(Min)<span
          className={styles.errorMsg}>{errors?.min && ` ${errors?.min}`}</span></span><CustomTextInput
          onBlur={(val) => onBlur(val.target.value, 'min')} height={40} value={limits.min}
          // onFocus={() => onFocus('min')}
          handleInputChange={(val) => onHandleLimits(val.target.value, 'min')} placeholder={'Lowest Value (Min)'}/>
        </div>
        <div className={styles.width48}><span>Highest Value(Max)<span
          className={styles.errorMsg}>{errors?.max && ` ${errors?.max}`}</span></span><CustomTextInput
          onBlur={(val) => onBlur(val.target.value, 'max')} height={40} value={limits.max}
          // onFocus={() => onFocus('max')}
          handleInputChange={(val) => onHandleLimits(val.target.value, 'max')} placeholder={'Highest Value (Max)'}/>
        </div>

      </div>

      <p className={styles.manuallyTitle}>Target Limits</p>
      <div className={styles.targetLimits}>
        {
          (type === 2 || type === 4) && <div className={styles.width48}><span>{ type === 4 ? 'Lower Target' : 'Target'}<span
            className={styles.errorMsg}>{errors?.lowerTarget && ` ${errors?.lowerTarget}`}</span></span><CustomTextInput
            onBlur={(val) => onBlur(val.target.value, 'lowerTarget')} value={limits.lowerTarget} disabled={isDisabled()}
            // onFocus={() => onFocus('lowerTarget')}
            height={40} handleInputChange={(val) => onHandleLimits(val.target.value, 'lowerTarget')}
            placeholder={ type === 4 ? 'Lower Target' : 'Target'}/></div>
        }
        {
          (type === 3 || type === 4) && <div className={styles.width48}><span>{ type === 4 ? 'Upper Target' : 'Target'}<span
            className={styles.errorMsg}>{errors?.upperTarget && ` ${errors?.upperTarget}`}</span></span><CustomTextInput
            onBlur={(val) => onBlur(val.target.value, 'upperTarget')} value={limits.upperTarget} disabled={isDisabled()}
            // onFocus={() => onFocus('upperTarget')}
            height={40} handleInputChange={(val) => onHandleLimits(val.target.value, 'upperTarget')}
            placeholder={ type === 4 ? 'Upper Target' : 'Target'}/></div>
        }
      </div>

      <div className={styles.mainGraphWrapper}>
        <div className={styles.graphWrapper}>
          <p className={styles.graphTitle}>{limits.min}</p>
          <p className={styles.graphTitle} style={{right: 0}}>{limits.max}</p>

          <div style={{width: '100%'}}>
            {limits.lowerTarget >= limits.min && limits.lowerTarget <= limits.max && limits.lowerTarget !== null && limits.lowerTarget !== undefined && limits.lowerTarget !== '' &&
              <div className={styles.flagView} style={{left: getFlagPosition(limits.lowerTarget)}}>
                <p className={styles.flagTitle}>{limits.lowerTarget}</p>
                <p><img alt="flag-lower" style={{width: 20}} src={require('../../../assets/icons/flag.png')}/></p>
              </div>}

            {limits.upperTarget >= limits.min && limits.upperTarget <= limits.max  && limits.upperTarget !== null && limits.upperTarget !== undefined && limits.upperTarget !== '' &&
              <div className={styles.flagView} style={{left: getFlagPosition(limits.upperTarget)}}>
                <p className={styles.flagTitle}>{limits.upperTarget}</p>
                <p><img alt="flag-upper" style={{width: 20}} src={require('../../../assets/icons/flag.png')}/></p>
              </div>}
          </div>
          <div className={styles.progressView}/>
        </div>

        <div className={styles.titleMeasurement}>
          {
            type === 4 ?
                <p>{limits.lowerTarget}-{limits.upperTarget} {measurement && measurement}</p> :
                <p>{limits.lowerTarget || limits.upperTarget} {measurement && measurement}</p>
          }
        </div>

      </div>

    </div>
  )

}

export default TargetAndCriticalLimits;
