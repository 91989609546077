import React from 'react';
import CustomModalHeader from "../Shared/Modals/CustomModalHeader";
import {Dialog, styled} from "@mui/material";
import GoalsLibraryComponent from "./GoalsLibraryComponent";

const GoalsLibraryModal = ({isVisible, closeModal, title, scorecardId, goalId, perspectiveId, page}) => {
  const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
      border-radius: 16px;
    }
  `;

  return (
    <StyledDialog open={isVisible} fullWidth maxWidth={'md'}>
      <CustomModalHeader title={title} onClick={closeModal} />
      <GoalsLibraryComponent perspectiveId={perspectiveId} scorecardId={scorecardId} goalId={goalId} page={page} />
    </StyledDialog>
  );
};

export default GoalsLibraryModal;
