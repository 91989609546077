import React, {useCallback, useEffect, useState} from 'react';
import styles from './NotificationsTabComponent.module.scss';
import {NOTIFICATIONS} from "./notifications";
import NotificationsItem from "./NotificationsItem";
import DefaultButton from "../../../components/Shared/Buttons/DefaultButton";
import {Grid} from "@mui/material";
import notificationsService from "../../../services/NotificationsService";
import {useDispatch} from "react-redux";
import {setSnackbar} from "../../../store/snackbar/actions";
import authService from "../../../services/AuthService";
import {fetchAuthenticatedUserSuccess, setTerminology} from "../../../store/auth/actions";

const NotificationsTabComponent = ({user}) => {
  const dispatch = useDispatch();
  const [notificationsSettings, setNotificationsSettings] = useState(null);

  const data = {
    notifyItemAssignedInApp: notificationsSettings?.assigned.inApp,
    notifyItemAssignedEmail: notificationsSettings?.assigned.email,
    notifyItemUpdatedInApp: notificationsSettings?.updated.inApp,
    notifyItemUpdatedEmail: notificationsSettings?.updated.email,
    notifyInboxInApp: notificationsSettings?.inbox.inApp,
    notifyInboxEmail: notificationsSettings?.inbox.email,
    notifyReportSharedInApp: notificationsSettings?.sharedReport.inApp,
    notifyReportSharedEmail: notificationsSettings?.sharedReport.email,
    notifyMentionedInApp: notificationsSettings?.mentions.inApp,
    notifyMentionedEmail: notificationsSettings?.mentions.email,
    notifyRiskAssignedInApp: notificationsSettings?.assignedRisk.inApp,
    notifyRiskAssignedEmail: notificationsSettings?.assignedRisk.email,
  };

  const setInitialNotificationsSettings = useCallback(async () => {
    setNotificationsSettings(
        {
          assigned: {
            inApp: user?.notifyItemAssignedInApp,
            email: user?.notifyItemAssignedEmail
          },
          updated: {
            inApp: user?.notifyItemUpdatedInApp,
            email: user?.notifyItemUpdatedEmail
          },
          inbox: {
            inApp: user?.notifyInboxInApp,
            email: user?.notifyInboxEmail
          },
          sharedReport: {
            inApp: user?.notifyReportSharedInApp,
            email: user?.notifyReportSharedEmail
          },
          mentions: {
            inApp: user?.notifyMentionedInApp,
            email: user?.notifyMentionedEmail
          },
          assignedRisk: {
            inApp: user?.notifyRiskAssignedInApp,
            email: user?.notifyRiskAssignedEmail
          }
        }
    )
  }, []);

  const handleNotificationsChange = (item, sendType, value) => {
    setNotificationsSettings(prevState => ({
      ...prevState,
      [item.key]: {
        ...prevState[item.key],
        [sendType]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      await notificationsService.updateNotificationsSettings(data);
      const updatedUser = await authService.fetchAuthenticatedUser();
      dispatch(fetchAuthenticatedUserSuccess(updatedUser));
      dispatch(setTerminology(updatedUser.framework));
      dispatch(setSnackbar({
        open: true,
        severity: 'success',
        message: 'Notification settings have been successfully updated.',
      }));
    } catch (error) {
      console.error({error});
    }
  };

  useEffect(() => {
    setInitialNotificationsSettings();
  }, [setInitialNotificationsSettings]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Enable Notifications</h2>
        <p className={styles.subtitle}>Choose the delivery method to receive notifications from StratifyPro</p>
      </div>
      {notificationsSettings && (
        <div className={styles.listContainer}>
          <div className={styles.innerListContainer}>
            <Grid container>
              {NOTIFICATIONS.map((notification) => {
                return (
                  <NotificationsItem
                    item={notification}
                    onChange={handleNotificationsChange}
                    values={notificationsSettings[notification.key]}
                    key={notification.id}
                  />
                );
              })}
            </Grid>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        <DefaultButton title={'Save'} onClick={handleSave} />
      </div>
    </div>
  );
};

export default NotificationsTabComponent;
