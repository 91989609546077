import React, {useEffect, useState} from 'react';
import styles from './Milestone.module.scss'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {COLORS} from "../../../../constants/colors";
import DefaultButton from "../../Buttons/DefaultButton";
import CustomTextInput from "../../../CustomInputs/TextInput";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import {getMinMaxDatesFromItemAndParent} from "../../../../utils/objects";

export function MilestoneListItemComponent({item, formValues, index, onRemoveInput, items, onAddNew, onSetItem, errors}) {
  const [milestone, setMilestone] = useState(item)
  const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
  const [dateError, setDateError] = useState(null);

  useEffect(() => {
    setMilestone(item)
  }, [item])

  useEffect(() => {
    onSetItem({milestone, index})
  }, [milestone])
  const handleDateInputChange = (index, e) => {
    const newItem = {...milestone}
    newItem.date = dayjs(e)
    setMilestone(newItem)
  }

  const handleRemoveInput = (index) => {
    onRemoveInput(index)
  }

  const handleInputChange = (e) => {
    let value = e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;
    const newItem = {...milestone}
    newItem.percentage = Number(value)
    setMilestone(newItem)
  }

  const handleMilestoneNameChange = (e) => {
    const newItem = {...milestone}
    newItem.name = e.target.value
    setMilestone(newItem)
  }

  const handleAddNew = () => {
    onAddNew(index)
  }

  return (
    <div className={styles.milestoneListItem}>
      <div className={styles.wrapperItem}>
        <div className={styles.inputWrapper}>
          <div className={styles.percentageInput}>
            <CustomTextInput border={errors?.percentage && `1px solid ${COLORS.lighterRed}`} type={'Number'} height={40} value={milestone?.percentage} handleInputChange={handleInputChange} />
          </div>
          <div className={styles.nameInput}>
            <CustomTextInput disabled={index === 0} border={errors?.name && `1px solid ${COLORS.lighterRed}`}  height={40} value={milestone?.name} handleInputChange={handleMilestoneNameChange} placeholder={'Milestone Name'}/>
          </div>
        </div>

        <div className={styles.dateInputWrapper}>
          <div className={styles.dateDiv}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker name={'date'} value={dayjs(milestone?.date)} slotProps={{ textField: { size: 'small', error: false } }}
                          onChange={e => handleDateInputChange(index, e)}
                          maxDate={formValues.endDate}
                          minDate={formValues.startDate}
                          onError={(e) => setDateError(e)}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: dateError && 'red',
                              },
                              '&:hover fieldset': {
                                borderColor: dateError && 'red',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: dateError && 'red',
                              },
                            },
                          }}
              />
            </LocalizationProvider>
          </div>

          {!(index === 0 || index === items?.length - 1) &&  <DefaultButton padding={'8px 2px 8px 8px'} type={'button'} title={'X'} onClick={() => handleRemoveInput(index)} inverted
                          buttonStyles={{height: 40, color: COLORS.darkestGray}}/>}
        </div>

      </div>
      {dateError && <p className={styles.errMessage}>Invalid date</p>}
      <div className={styles.bottomWrapper}>
        {index !== items?.length - 1 && <div onClick={handleAddNew} className={styles.addNew}>
          <div className={styles.addSquare}>+</div>
        </div>}
      </div>

    </div>
  )

}

export default MilestoneListItemComponent;
