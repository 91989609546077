import React from 'react';
import styles from './AvatarGroupButton.module.scss';
import {getInitials} from '../../../utils/format';
import { ReactComponent as PowerUserBadge} from '../../../assets/icons/badge-yellow.svg';
import {ReactComponent as AdminUserBadge} from '../../../assets/icons/admin-badge.svg';
import profileImage from '../../../assets/icons/profile-owner.png';
import {getItemOwner} from "../../../utils/users";
import {COLORS} from "../../../constants/colors";
import {Role} from "../../../constants/strings";

const AvatarGroupButton = ({badgeWidth= 12, badgeHeight = 15, avatars, maximumAvatars = 3, imageKey = 'photo', ownerId, large, small, background}) => {
  const avatarContent = (avatar) => {
    if (avatar[imageKey]) {
      return (
        <img src={avatar[imageKey]} className={styles.image} alt={'avatar'} />
      );
    }
    if (avatar.name) {
      return (
        <h4 className={`${styles.initials} ${small && styles.smallInitials}`}>{getInitials(avatar.name)}</h4>
      );
    }

    return (
      <img src={profileImage} className={styles.image} alt={'avatar'} />
    );
  };

  const owner = getItemOwner(avatars, ownerId)
  return (
    <div className={styles.container}>
      {avatars && avatars
        .slice(0, maximumAvatars === avatars.length ? maximumAvatars : maximumAvatars - 1)
        .map((avatar, index) => {
          return (
            <div style={{background: background}} className={`${styles.imageContainer} ${large && styles.largeImageContainer} ${small && styles.smallImageContainer}`} key={avatar.id}>
              {avatarContent(avatar)}
              {avatar.id === owner?.id && (
                <div className={`${styles.badge} ${small && styles.smallBadge}`}>
                  <PowerUserBadge width={badgeWidth} height={badgeHeight}/>
                </div>
              )}
              {avatar.role === Role.ADMIN && index === 0 && (
                <div className={`${styles.badge} ${small && styles.smallBadge}`}>
                  <AdminUserBadge width={16} height={16} color={COLORS.darkestGray}/>
                </div>
              )}
            </div>
          );
        })}
      {avatars?.length > maximumAvatars && (
        <div style={{background:background}} className={`${styles.imageContainer} ${large && styles.largeImageContainer} ${small && styles.smallImageContainer}`}>
          {`+${avatars.length - (maximumAvatars - 1)}`}
        </div>
      )}
    </div>
  );
};

export default AvatarGroupButton;
