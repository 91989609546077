import {position} from "../components/AlignmentComponent/GoalTabView/GoalDummydata";

export const getHighlightedGoals = (goals, filters) => {
  const { perspectives, plans, health, id } = filters;

  const isHighlightedFn = (data) => {
    return ((perspectives.length > 0 || plans.length > 0 || health.length > 0) &&
      (plans.length > 0 ? plans.some((plan) => plan.id === data.perspective.scorecard.id) : true) &&
      (perspectives.length > 0 ? perspectives.some((perspective) => perspective.ids.includes(data.perspective.id)) : true) &&
      (health.length > 0 ? health.some((item) => item.value === data.health) : true)) ||
      (id !== '' && data.id === id)
  };

  const updatedGoalsAlignmentNodes = goals?.map((item) => {
    return {...item, nodeHeight: item.nodeHeight ? item.nodeHeight : 80, data: {...item.data, isHighlighted: isHighlightedFn(item.data)}};
  });

  return updatedGoalsAlignmentNodes;
};

export const GOAL_TYPE_VALUES = {
  ALL: 'all',
  ALIGNED: 'aligned',
  UNALIGNED: 'unaligned',
};

export const GOAL_TYPES = ({
  alignedGoalsAlignmentNodesCount,
  allGoalsAlignmentNodesCount,
  unalignedGoalsAlignmentNodesCount,
}) => {
  return [
    {id: 1, value: GOAL_TYPE_VALUES.ALL, label: `All (${allGoalsAlignmentNodesCount})`},
    {id: 2, value: GOAL_TYPE_VALUES.ALIGNED, label: `Aligned (${alignedGoalsAlignmentNodesCount})`},
    {id: 3, value: GOAL_TYPE_VALUES.UNALIGNED, label: `Unaligned (${unalignedGoalsAlignmentNodesCount})`},
  ];
}

export const createGoalsAlignmentNodes = (goalAlignment, type) => {
  return goalAlignment[type].map((goal) => {
    let hasChildren = false;
    let count = 0;
    for (const edge of goalAlignment.goalEdges) {
      if (String(goal.id) === edge.source) {
        hasChildren = true;
        count++;
        // break;
      }
    }
    return {id: goal.id, data: {...goal, expanded:true, isHighlighted: false, hasChildren, childrenCount: count}, type: 'goalNode', position: {...position}};
  });
};
