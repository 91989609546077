import React, {memo} from 'react';
import styles from './indexStyle.module.scss';
import {ReactComponent as KpiIcon} from '../../../../../../src/assets/icons/taskTypes/kpi.svg';
import {ReactComponent as GoalIcon} from '../../../../../../src/assets/icons/goal.svg';
import {ReactComponent as ProjectIcon} from '../../../../../../src/assets/icons/taskTypes/project.svg';
import {ReactComponent as ActionIcon} from '../../../../../../src/assets/icons/taskTypes/dotted-circle.svg';
import {COLORS} from "../../../../../constants/colors";

const InitiativesComponent = ({data}) => {
    return (
        <div className={styles.container}>
            <GoalIcon color={COLORS.iconGray} width={16} height={16}/>
            <p className={styles.counter}>{data?.goalCount}</p>
            <KpiIcon/>
            <p className={styles.counter}>{data?.kpiCount}</p>
            <ActionIcon/>
            <p className={styles.counter}>{data?.actionCount}</p>
            <ProjectIcon/>
            <p className={styles.counter}>{data?.projectCount}</p>
        </div>
    );
};

export default memo(InitiativesComponent);
