import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from './PostUpdateFromItemRow.module.scss';
import {ROW_ITEM_TYPES} from "../../constants/strings";
import UpdateProgressForm from "../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {Menu, styled} from "@mui/material";
import DefaultButton from "../Shared/Buttons/DefaultButton";
import {Field, Form, Formik} from "formik";
import TextareaField from "../Shared/Inputs/TextareaField";
import InputField from "../Shared/Inputs/InputField";
import {itemUpdateSchema} from "../../validation/update";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
    width: 320px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const PostUpdateFromItemRowComponent = ({data, handleUpdate, titleBtn}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [sliderValue, setSliderValue] = useState(null);

    const onUpdateProgress = (values) => {
        setAnchorEl(null);
        handleUpdate(values);
    };

    const onUpdateGoalAndProjectProgress = (values) => {
        setAnchorEl(null);
        handleUpdate(values);
    };

    const cancelUpdate = () => {
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        switch (data?.type) {
            case ROW_ITEM_TYPES.GOAL:
            case ROW_ITEM_TYPES.PROJECT:
            case ROW_ITEM_TYPES.KPI:
            case ROW_ITEM_TYPES.ACTION:
                if (!anchorEl) {
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                }
                break;
            default:
                console.log('no matching type selected');
        }
    };

    const setSliderValueFn = useCallback(() => {
        setSliderValue(data?.inverted ? data?.min + data?.max - data?.actualValue : data?.actualValue);
    }, [data.actualValue]);

    useEffect(() => {
        setSliderValueFn();
    }, [setSliderValueFn]);

    const renderContent = () => {
        switch (data?.type) {
            case ROW_ITEM_TYPES.GOAL:
            case ROW_ITEM_TYPES.PROJECT:
                return (
                    <div>
                        <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                            <div className={styles.textareaContainer}>
                                <h4 className={styles.statusTitle}>Post an Update</h4>
                                <Formik
                                    initialValues={{
                                        title: '',
                                        message: '',
                                    }}
                                    validationSchema={itemUpdateSchema}
                                    onSubmit={onUpdateGoalAndProjectProgress}
                                    validateOnMount={false}
                                    validateOnChange={false}
                                >
                                    {({setFieldValue, errors}) => (
                                        <Form>
                                            <Field
                                                component={InputField}
                                                name={'title'}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                placeholder={'Title'}
                                                height={40}
                                            />
                                            <Field
                                                component={TextareaField}
                                                name={'message'}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                placeholder={'Update'}
                                                options={{
                                                    minRows: 5
                                                }}
                                            />
                                            <div className={styles.buttonsContainer}>
                                                <DefaultButton onClick={cancelUpdate} title={'Cancel'} secondary type={'button'}/>
                                                <DefaultButton title={'Save'} type={'submit'} />
                                            </div>
                                      </Form>
                                    )}
                                </Formik>
                            </div>
                        </StyledMenu>
                    </div>
                );
            case ROW_ITEM_TYPES.KPI:
            case ROW_ITEM_TYPES.ACTION:
                return (
                    <div>
                        <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                            <UpdateProgressForm
                                min={data.min}
                                max={data.max}
                                progress={data?.inverted ? data?.min + data?.max - sliderValue : sliderValue}
                                status={data.status}
                                expected={data.type === ROW_ITEM_TYPES.KPI ? data.expectedValue : data.expectedProgress}
                                expectedUnit={data.type === ROW_ITEM_TYPES.KPI ? data.unit : 'percentage'}
                                handleOnSubmit={onUpdateProgress}
                                cancelUpdate={cancelUpdate}
                                onProgressChange={() => {}}
                                item={data}
                            />
                        </StyledMenu>
                    </div>
                );
            default:
                console.log('no matching type selected');
        }
    };

    return (
        <div
            className={`
        ${styles.container}
        ${(data?.type === ROW_ITEM_TYPES.KPI || data?.type === ROW_ITEM_TYPES.ACTION) && styles.altContainer}
      `}
        >
            {renderContent()}
            <DefaultButton title={titleBtn} height={30} onClick={handleClick}/>
        </div>
    );
};

export default memo(PostUpdateFromItemRowComponent);
