import React from 'react';
import styles from './CustomModalHeader.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';

const CustomModalHeader = ({title, onClick, leftIcon, titleAddition}) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftItemsWrapper}>
          {
              leftIcon && <div className={styles.leftIcon}>
                  {leftIcon}
              </div>
          }
          <h2 className={styles.title}>
              {title}
          </h2>
          {
              titleAddition &&
              <div className={styles.titleAdditionWrapper}>
                  <p className={styles.titleAddition}>{titleAddition}</p>
              </div>
          }
      </div>
      <button className={styles.button} onClick={onClick}>
        <CloseIcon  />
      </button>
    </div>
  );
};

export default CustomModalHeader;
