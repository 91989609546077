import {call, put, takeLatest} from 'redux-saga/effects';
import {
  DELETE_HUB_SAGA,
  GET_HUB_DATA_SAGA, GET_HUB_UPDATES_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import {setHubData, setHubUpdates} from "./actions";
import scorecardService from "../../services/ScorecardService";
import {setIsDeleteModalVisible} from "../helper/actions";
import {setSnackbar} from "../snackbar/actions";
import {goBack} from "connected-react-router";
import goalService from "../../services/GoalService";
import {HUB_ENTITY_ROUTE_TYPE, ROW_ITEM_TYPES} from "../../constants/strings";
import kpiService from "../../services/KpiService";
import projectService from "../../services/ProjectService";
import actionService from "../../services/ActionService";

export function* getHubDataSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    let hubData;
    switch (payload.entity.toLowerCase()) {
      case HUB_ENTITY_ROUTE_TYPE.GOAL:
        hubData = yield call(goalService.getGoalWithItems, payload.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.KPI:
        hubData = yield call(scorecardService.getKpiById, payload.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.PROJECT:
        hubData = yield call(scorecardService.getProjectById, payload.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.ACTION:
        hubData = yield call(scorecardService.getActionById, payload.id);
        break;
      default:
        console.log('no matching type selected');
    }
    yield put(setHubData(hubData));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* deleteHubSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    switch (payload.entity) {
      case HUB_ENTITY_ROUTE_TYPE.GOAL:
        yield call(scorecardService.deleteGoal, payload.hubData.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.KPI:
        yield call(scorecardService.deleteKpi, payload.hubData.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.PROJECT:
        yield call(scorecardService.deleteProject, payload.hubData.id);
        break;
      case HUB_ENTITY_ROUTE_TYPE.ACTION:
        yield call(scorecardService.deleteAction, payload.hubData.id);
        break;
      default:
        console.log('no matching type selected');
    }
    yield put(goBack());

    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${payload.hubData[payload.entity]?.name} successfully deleted`,
    }));

    yield put(setIsDeleteModalVisible(false));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getHubUpdatesSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    let updates;
    switch (payload.entity) {
      case ROW_ITEM_TYPES.GOAL:
        updates = yield call(goalService.getGoalUpdates, payload?.id);
        break;
      case ROW_ITEM_TYPES.KPI:
        updates = yield call(kpiService.getKpiUpdates, payload?.id);
        break;
      case ROW_ITEM_TYPES.PROJECT:
        updates = yield call(projectService.getProjectUpdates, payload?.id);
        break;
      case ROW_ITEM_TYPES.ACTION:
        updates = yield call(actionService.getActionUpdates, payload?.id);
        break;
      default:
        console.log('no matching type selected');
    }
    yield put(setHubUpdates(updates));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* hubSaga() {
  yield takeLatest(GET_HUB_DATA_SAGA, getHubDataSagaFn);
  yield takeLatest(DELETE_HUB_SAGA, deleteHubSagaFn);
  yield takeLatest(GET_HUB_UPDATES_SAGA, getHubUpdatesSagaFn);
}
