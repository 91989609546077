import React from 'react';
import styles from './UserStatusComponent.module.scss';
import {capitalizeFirstLetter} from "../../../utils/format";
import {COLORS} from "../../../constants/colors";

const UserStatusComponent = ({status}) => {
    const getStatusColor = (status) => {
        switch (status) {
            case 'invited':
                return COLORS.orange;
            case 'enabled':
                return COLORS.blue;
            case 'disabled':
                return COLORS.lighterRed;
            default: return COLORS.blue;
        }
    }
    return (
        <div className={styles.container}>
            <p style={{color: getStatusColor(status), fontSize: '14px'}}>{capitalizeFirstLetter(status)}</p>
            &nbsp;&nbsp;
            <div className={styles.dot} style={{background: getStatusColor(status)}}/>
        </div>
    );
};

export default UserStatusComponent;
