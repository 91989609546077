import React, {memo} from 'react';
import SelectedPerspectiveItem from "./SelectedPerspectiveItem";
import styles from './SelectedPerspectiveList.module.scss';

const SelectedPerspectiveList = ({data, removeItem}) => {
  return (
    <div className={styles.container}>
      {data.map((item)  => {
        return (
          <SelectedPerspectiveItem item={item} removeItem={removeItem} key={item.id} />
        );
      })}
    </div>
  );
};

export default memo(SelectedPerspectiveList);
