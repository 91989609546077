import styles from "./indexStyle.module.scss";
import React from "react";
import {ReactComponent as AlignmentIcon} from '../../../assets/icons/alignmentIcon.svg';
import {COLORS} from "../../../constants/colors";
import {ReactComponent as Delete} from '../../../assets/icons/deleteGray.svg';
import {MoreVert} from "@mui/icons-material";
import {ReactComponent as Gear} from '../../../assets/icons/gear.svg';
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';
import { ReactComponent as SpeedometerIcon } from '../../../assets/icons/speedometer.svg';
import { ReactComponent as ActionIcon } from '../../../assets/icons/dotted-circle.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/project.svg';
import { ReactComponent as GoalIcon } from '../../../assets/icons/goal-parent.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/move.svg';

export const goalMenuConfig = ({
                                   onAddSuccessCriteria,
                                   onEditGoal,
                                   onMoveGoal,
                                   onDelete,
                                   isSuccess = false,
                                   addNewItem,
                                   alignGoal,
                                   terminology,
                               }) => {
    return {
        container: {
            styles: {
                display: 'inline'
            }
        },
        button:(
            <div className={styles.treeDotsBtn}>
                <MoreVert style={{color: COLORS.legendBlack, height: '15px', width:'15px'}}/>
            </div>
        ),
        content: {
            menu: {
                items: [
                    ...(isSuccess ? [
                        {
                            id: 1,
                            title: `New ${terminology.goal}`,
                            subtitle: `Breakdown your ${terminology.goal}s into lower-level ${terminology.goal}s`,
                            onClick: () => addNewItem(ROW_ITEM_TYPES.GOAL),
                            icon: <GoalIcon color={COLORS.darkestGray} width={20} height={20} />,
                        },
                        {
                            id: 2,
                            title: `New ${terminology.kpi}`,
                            subtitle: 'Quantify what success looks like',
                            onClick: () => addNewItem(ROW_ITEM_TYPES.KPI),
                            icon: <SpeedometerIcon color={COLORS.darkestGray} />,
                        },
                        {
                            id: 3,
                            title: `New ${terminology.project}`,
                            subtitle: 'Breakdown a large piece of work into smaller jobs',
                            onClick: () => addNewItem(ROW_ITEM_TYPES.PROJECT),
                            icon: <ProjectIcon color={COLORS.darkestGray} />,
                        },
                        {id: 4,
                            title: `New ${terminology.action}`,
                            subtitle: `Outline how you will achieve this ${terminology.goal}`,
                            onClick: () => addNewItem(ROW_ITEM_TYPES.ACTION),
                            icon: <ActionIcon color={COLORS.darkestGray} />,

                        },
                        {
                            id: 5,
                            title: `Existing ${terminology.goal}`,
                            subtitle: 'Align success of another outcome with this one',
                            onClick: alignGoal,
                            icon: <GearIcon width={16} height={16} color={COLORS.darkestGray} />,
                        }
                    ] : [
                        {
                            id: 1,
                            title: 'Add Success Criteria',
                            onClick: onAddSuccessCriteria,
                            icon: <AlignmentIcon width={16} height={16} color={COLORS.darkerGray} />,
                            keepOpen: true
                        },
                        {
                            id: 2,
                            title: 'Edit Details',
                            onClick: onEditGoal,
                            icon: <Gear width={16} height={16} color={COLORS.darkerGray} />
                        },
                        {
                            id: 3,
                            title: 'Move',
                            onClick: onMoveGoal,
                            icon: <MoveIcon width={16} height={16} color={COLORS.darkerGray} />
                        },
                        {
                            id: 4,
                            title: 'Delete',
                            titleColor: COLORS.red,
                            onClick: onDelete,
                            icon: <Delete width={16} height={16} color={COLORS.red} />
                        },
                    ]),
                ],
            },
        },
    };
};
