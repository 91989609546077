import React, {cloneElement} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {useDispatch, useSelector} from "react-redux";
import {rightModalSelector} from "../../store/helper/selectors";
import {rightModalOpen, setItemAndParentForAction} from "../../store/helper/actions";
import {setShowEditParent} from "../../store/strategyPlan/actions";
import {useHistory} from "react-router-dom";
import {QUERY_PARAMS_TO_REMOVE, removeQueryParams} from "../../utils/url";

const style = {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: 'auto',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    overflowY: 'auto',
};

export function RightModal() {
    const history = useHistory();
    const dispatch = useDispatch();
    const rightModalContent = useSelector(rightModalSelector());
    const handleClose = () => {
        const currentUrl = window.location.href;
        dispatch(rightModalOpen({
            isVisible: false,
            component: null,
            page: null,
        }));
        dispatch(setItemAndParentForAction({parent: null, item: null}));
        dispatch(setShowEditParent(false));
        history.replace(removeQueryParams(currentUrl, QUERY_PARAMS_TO_REMOVE));
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={rightModalContent.isVisible}
            onClose={() => handleClose()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={rightModalContent.isVisible}>
                <Box sx={style}>
                    {rightModalContent.component && cloneElement(rightModalContent.component, {page: rightModalContent.page})}
                </Box>
            </Fade>
        </Modal>
    );


}

export default RightModal;
