import produce from 'immer';
import {
    ITEM_SORT_ACTIVE,
    SET_ALL_ITEMS_ON_HOME_PAGE,
    SET_GOALS_ON_HOME_PAGE,
    SET_IS_OPEN_EDIT_GOAL_MODAL,
    SET_ITEM_ROW,
    SET_OPTIONS_FOR_EDIT_PARENT,
    SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE,
    SET_SHOW_EDIT_PARENT,
    SET_STRATEGY_PLAN,
    SET_STRATEGY_PLAN_WITH_PERSPECTIVES,
} from './actionTypes';
import {RESET_STATE} from "../scorecard/actionTypes";

export const initialState = {
    strategyPlan: null,
    itemRow: null,
    strategyPlanWithPerspectives: null,
    editGoalModal: {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null
    },
    showEditParent: false,
    optionsForEditParent: null,
    homePageGoals: [],
    homePageProjectsActionsKpis: [],
    homePageAllItems: [],
    itemSortActive: false
};

/* eslint-disable default-case */
const strategyPlanReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_STRATEGY_PLAN:
                draft.strategyPlan = action.strategyPlan;
                break;
            case SET_GOALS_ON_HOME_PAGE:
                draft.homePageGoals = action.payload;
                break;
            case SET_PROJECTS_ACTIONS_KPIS_ON_HOME_PAGE:
                draft.homePageProjectsActionsKpis = action.payload;
                break;
            case SET_ALL_ITEMS_ON_HOME_PAGE:
                draft.homePageAllItems = action.payload;
                break;
            case SET_ITEM_ROW:
                draft.itemRow = action.itemRow;
                break;
            case SET_STRATEGY_PLAN_WITH_PERSPECTIVES:
                draft.strategyPlanWithPerspectives = action.strategyPlanWithPerspectives;
                break;
            case SET_IS_OPEN_EDIT_GOAL_MODAL:
                draft.editGoalModal = action.data;
                break;
            case SET_SHOW_EDIT_PARENT:
                draft.showEditParent = action.payload;
                break;
            case SET_OPTIONS_FOR_EDIT_PARENT:
                draft.optionsForEditParent = action.payload;
                break;
            case ITEM_SORT_ACTIVE:
                console.log('action.payload', action.payload);
                draft.itemSortActive = action.payload;
                break;
            case RESET_STATE:
                return initialState;
        }
    });

export default strategyPlanReducer;
