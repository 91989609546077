import React from "react";
import styles from './goalsTable.module.scss'
import WidgetTableComponent from "../../Shared/WidgetTableComponent";
import {GOALS_TABLE_CONFIG} from "./goalsTableConfig";
import goalService from "../../../services/GoalService";
import SidebarGoalComponent from "../../RightModal/Goal/SidebarGoalComponent";
import {openRightModalFromChartDetails} from "../../../store/dashboards/actions";
import {useDispatch, useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

function GoalsTable({plans, fromReport}) {
    const terminology = useSelector(terminologySelector());

    const newPlans = JSON.parse(JSON.stringify(plans));
    const dispatch = useDispatch();
    for (const plan of newPlans) {
        plan.tableHeader = `${terminology.plan}: ` + plan.name;
        plan.tableRows = plan.goals;
    }

    const onRowClick = async (row) => {
        if (!fromReport) {
            try {
                const item = await goalService.getGoalWithItems(row.id);
                const component = <SidebarGoalComponent />
                dispatch(openRightModalFromChartDetails({component, item}));
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <WidgetTableComponent onRowClick={onRowClick} showName={false} data={newPlans} config={GOALS_TABLE_CONFIG}/>
        </div>
    )
}

export default GoalsTable;
