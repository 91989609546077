import React, {memo} from 'react';
import {Box, OutlinedInput, styled, InputLabel, Typography} from '@mui/material';
import styles from './InputField.module.scss';
import {COLORS} from "../../../constants/colors";

const StyledInput = styled(OutlinedInput)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px ${COLORS.lightBlue} solid;
    box-shadow: 0 0 0 4px ${COLORS.transparentDarkBlue};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin-bottom: 8px;
    font-size: 16px;
    color: ${COLORS.darkestGray};
    font-weight: 400;
    text-align: start;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    padding-top: 4px;
    font-size: 12px;
    color: ${COLORS.lighterRed};
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {
    height: 32px;
  }
`;

const InputField = ({setFieldValue, field, errors, ...props}) => {
  return (
    <Box>
      <StyledInputLabel>
        {props.label}
        {props.required && (
          <span className={styles.requiredStar}>*</span>
        )}
      </StyledInputLabel>
      <StyledInput
        type={props.type || 'text'}
        value={field.value}
        onChange={(e) => setFieldValue(field.name, e.currentTarget.value.replace(/^\s+/, ''))}
        fullWidth
        placeholder={props.placeholder}
        disabled={props.disabled}
        inputProps={{ min: props.min, max: props.max, step: props.step, onClick: (e) => e.currentTarget.select() }}
      />
      {props.additionalMessage && <p className={styles.additionalMessage}>{props.additionalMessage}</p>}
      <StyledBox>
        {errors[field?.name] && (
          <StyledTypography>
            {errors[field?.name]}
          </StyledTypography>
        )}
      </StyledBox>
    </Box>
  );
};

export default memo(InputField);
