import styles from "./goalsTable.module.scss";
import {ReactComponent as GoalIcon} from "../../../assets/icons/goal.svg";
import {ReactComponent as ArrowIcon} from "../../../assets/icons/arrowLeft.svg";
import React from "react";
import dayjs from "dayjs";
import {COLORS} from "../../../constants/colors";
import CustomDot from "../../Shared/Dot/CustomDot";
import {goalStatus} from "../../../utils/perspective";

export const GOALS_TABLE_CONFIG = (row, history, dispatch, terminology) => {
    return [
        {
            id: 1,
            type: 'component',
            label: terminology.goal,
            value: 'name',
            component: (
                <div className={styles.flexAndCenter}>
                    <div className={styles.flexAndCenter}>
                        <GoalIcon width={16} height={16}/>
                    </div>
                    <h4 className={styles.rowTitle}>{row?.name}</h4>
                </div>
            ),
        },
        {
            id: 2,
            type: 'component',
            label: 'Start/End Date',
            value: 'jobTitle',
            component: (
                <div>
                    <p className={styles.rowGrayText}>{dayjs(row?.startDate).format('MMM DD YYYY')}</p>
                    <p className={styles.rowBlackText}>{dayjs(row?.endDate).format('MMM DD YYYY')}</p>
                </div>
            ),
        },
        {
            id: 3,
            label: 'Progress Change (MoM)',
            value: 'name',
            type: 'component',
            component: (
                <div>
                    <div className={styles.flexAndCenter}>
                        {
                            !row?.progressChange?.noChange &&
                            <div className={styles.rowProgressIcon}
                                 style={{
                                     background: row?.progressChange?.increased ? COLORS.green : COLORS.red,
                                     transform: row?.progressChange?.increased ? 'rotate(90deg)' : 'rotate(-90deg)'
                                 }}>
                                <ArrowIcon color={COLORS.white}/>
                            </div>
                        }
                        <p className={styles.rowBlackText}>{row?.progressChange?.percentageChange?.toFixed(2) || '0.00'}%</p>
                    </div>
                    <p className={styles.rowGrayText}>{`${row?.progressChange?.initialProgress?.toFixed(2) || '0.00'}%`} - {`${row?.progressChange?.progress?.toFixed(2) || '0.00'}%`}</p>
                </div>
            )
        },
        {
            id: 4,
            label: 'Health',
            value: 'health',
            type: 'component',
            component: (
                <div className={styles.flex}>
                    <CustomDot backgroundColor={goalStatus(row?.health).color} medium />
                    <div className={styles.subtitleContainer}>
                        <h4 className={styles.subtitle}>{row?.health}</h4>
                    </div>
                </div>
            ),
        },
    ];
};
