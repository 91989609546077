import {
  CONNECT_GOOGLE_SHEETS_SAGA, CONNECT_JIRA_SAGA, CONNECT_MICROSOFT_EXCEL_SAGA,
  CREATE_KPI_AND_ADD_TO_METRIC_SAGA,
  CREATE_METRIC_SAGA,
  DELETE_METRIC_SAGA, DELETE_SINGLE_METRIC_FROM_LIST_SAGA,
  GET_METRIC_SAGA, SET_ALL_METRICS, SET_IS_CREATE_MODAL_VISIBLE, SET_IS_DELETE_MODAL_VISIBLE,
  SET_METRIC, SET_SELECTED_METRIC_FOR_EDIT, SET_SHOW_EDIT_MODAL_FROM_SIDEBAR, UPDATE_METRIC_AND_ADD_TO_KPI_SAGA,
} from './actionTypes';

export function getMetricSaga(payload) {
  return {
    type: GET_METRIC_SAGA,
    payload,
  };
}

export function setMetric(payload) {
  return {
    type: SET_METRIC,
    payload,
  };
}

export function setSelectedMetricForEdit(payload) {
  return {
    type: SET_SELECTED_METRIC_FOR_EDIT,
    payload,
  };
}

export function deleteMetricSaga(payload) {
  return {
    type: DELETE_METRIC_SAGA,
    payload,
  };
}

export function setIsDeleteModalVisible(payload) {
  return {
    type: SET_IS_DELETE_MODAL_VISIBLE,
    payload,
  };
}

export function setIsCreateModalVisible(payload) {
  return {
    type: SET_IS_CREATE_MODAL_VISIBLE,
    payload,
  };
}

export function updateKpiAndAddToMetricSaga(payload) {
  return {
    type: UPDATE_METRIC_AND_ADD_TO_KPI_SAGA,
    payload,
  };
}

export function setShowEditModalFromSidebar(payload) {
  return {
    type: SET_SHOW_EDIT_MODAL_FROM_SIDEBAR,
    payload,
  };
}

export function createMetricSaga(payload) {
  return {
    type: CREATE_METRIC_SAGA,
    payload,
  };
}

export function createKpiAndAddToMetric(values) {
  return {
    type: CREATE_KPI_AND_ADD_TO_METRIC_SAGA,
    values,
  };
}

export function deleteSingleMetricFromListSaga(payload) {
  return {
    type: DELETE_SINGLE_METRIC_FROM_LIST_SAGA,
    payload,
  };
}

export function setAllMetrics(payload) {
  return {
    type: SET_ALL_METRICS,
    payload,
  };
}

export function connectGoogleSheetsSaga(payload) {
  return {
    type: CONNECT_GOOGLE_SHEETS_SAGA,
  };
}

export function connectJiraSaga() {
  return {
    type: CONNECT_JIRA_SAGA,
  };
}

export function connectMicrosoftExcelSaga() {
  return {
    type: CONNECT_MICROSOFT_EXCEL_SAGA,
  };
}
