import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_TIME_HORIZONS: 'time-horizons',
  GET_TIME_HORIZONS_GRID: 'time-horizons/grid',
  CREATE_TIME_HORIZON: 'time-horizons',
  UPDATE_TIME_HORIZON: 'time-horizons/:id',
  DELETE_TIME_HORIZON: 'time-horizons/:id',
};

class TimeHorizonsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getTimeHorizons = () => {
    return this.httpService.request({
      url: ROUTES.GET_TIME_HORIZONS,
      method: HTTP_METHODS.GET,
    });
  };

  getTimeHorizonsGrid = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_TIME_HORIZONS_GRID,
      method: HTTP_METHODS.POST,
      data
    });
  };

  createTimeHorizon = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_TIME_HORIZON,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updateTimeHorizon = (data, id) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_TIME_HORIZON.replace(':id', id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  deleteTimeHorizon = (horizonId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_TIME_HORIZON.replace(':id', horizonId),
      method: HTTP_METHODS.DELETE
    });
  };
}

const timeHorizonsService = new TimeHorizonsService(httpService);

export default timeHorizonsService;
