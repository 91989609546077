import React, {useState} from 'react';
import styles from './MetricDetails.module.scss';
import {COLORS} from "../../../../constants/colors";
import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrowDown.svg';
import {Divider} from "@mui/material";
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import {METRIC_DETAILS_LIST} from "./metricDetailsList";

const MetricDetails = ({item}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.flexAlignCenter}>
          <div className={styles.titleIcon}>
            <StackedBarChartIcon style={{color: COLORS.darkestGray}} />
          </div>
          <h2 className={styles.title}>Metric Details</h2>
        </div>
        <div onClick={() => setIsExpanded(!isExpanded)} className={styles.expandedIcon} style={{transform: `rotate(${isExpanded ? 180 : 0}deg)`}}>
          <ArrowDown color={COLORS.darkestGray} />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.expandedContainer}>
          <div className={styles.leftContainer}>
            {METRIC_DETAILS_LIST(item).slice(0, 4).map((item) => {
              return (
                <h4 key={item.id}><span className={styles.subtitle}>{`${item.title}: `}</span>{item.value}</h4>
              );
            })}
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <div className={styles.rightContainer}>
          {METRIC_DETAILS_LIST(item).slice(4).map((item) => {
            return (
              <h4 key={item.id}><span className={styles.subtitle}>{`${item.title}: `}</span>{item.value}</h4>
            );
          })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MetricDetails;
