import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_PROJECTS_BY_ORGANIZATION: 'projects/by-organization',
  MOVE_PROJECT: 'projects/move/:projectId',
  GET_SINGLE_PROJECT: 'projects/:id',
  UPDATE_PROJECT_WEIGHT: 'projects/:projectId/weight',
  UPDATE_PROJECT_WEIGHED: 'projects/:projectId/weighed',
  GET_PROJECT_UPDATES: 'projects/:projectId/updates',
  POST_PROJECT_UPDATE: 'projects/post-update',
  UPDATE_PROJECT_DATE_RANGE: 'projects/:projectId/dates',
};

class ProjectService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getProjectsByOrganization = () => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECTS_BY_ORGANIZATION,
      method: HTTP_METHODS.GET,
    });
  };

  getProjectById = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_SINGLE_PROJECT.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };

  moveProject = (data, projectId) => {
    return this.httpService.request({
      url: ROUTES.MOVE_PROJECT.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  updateProjectWeight = (data, projectId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROJECT_WEIGHT.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateProjectWeighed = (data, projectId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROJECT_WEIGHED.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getProjectUpdates = (projectId) => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECT_UPDATES.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
    });
  };

  postProjectUpdate = (data) => {
    return this.httpService.request({
      url: ROUTES.POST_PROJECT_UPDATE,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  updateProjectDateRange = (data, projectId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROJECT_DATE_RANGE.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data
    });
  };
}

const projectService = new ProjectService(httpService);

export default projectService;
