import {COLORS} from "../../constants/colors";
import { ReactComponent as GoalArrowIcon } from '../../assets/icons/goal-arrow.svg';
import { ReactComponent as GearIcon } from '../../assets/icons/gear.svg';
import { ReactComponent as SpeedometerIcon } from '../../assets/icons/speedometer.svg';
import { ReactComponent as ActionIcon } from '../../assets/icons/dotted-circle.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/project.svg';
import { ReactComponent as HubIcon } from '../../assets/icons/hub.svg';
import { ReactComponent as AlignmentIcon } from '../../assets/icons/alignment-secondary.svg';
import { ReactComponent as MoveIcon } from '../../assets/icons/move.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-can.svg';
import React from "react";
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import styles from './SidebarItemHeader.module.scss';
import {ACTIONS, ROW_ITEM_TYPES} from "../../constants/strings";
import {MoreVert} from "@mui/icons-material";
import {getItemTypeTerminology} from "../../utils/perspective";

export const menuConfig = ({
                             item,
                             onClick,
                             button,
                             terminology,
                           }) => {
  const buttonTitle = () => {
    switch (item?.type) {
      case ROW_ITEM_TYPES.GOAL:
        return 'Add a Success Criteria';
      case ROW_ITEM_TYPES.KPI:
        return 'Add Tracking';
      case ROW_ITEM_TYPES.PROJECT:
        return `Add an ${terminology.action}`;
      case ROW_ITEM_TYPES.ACTION:
        return 'Add a Success Criteria';
      default:
        return;
    }
  };

  return {
    button: button ? (
      <div className={styles.itemRowProgressButton}>
        <AddIcon />
        {buttonTitle()}
      </div>
    ) : (
      <MoreVert style={{color: COLORS.lightGray}} />
    ),
    content: {
      menu: {
        items: [
          ...item?.type === ROW_ITEM_TYPES.GOAL ? [{
            id: 1,
            title: `New ${terminology.goal}`,
            subtitle: `Breakdown your ${terminology.goal}s into lower-level ${terminology.goal}s`,
            onClick: () => onClick(item, ACTIONS.ADD, ROW_ITEM_TYPES.GOAL),
            icon: <GoalArrowIcon color={COLORS.darkestGray} />,
          },
          {
            id: 2,
            title: `New ${terminology.kpi}`,
            subtitle: 'Quantify what success looks like',
            onClick: () => onClick(item, ACTIONS.ADD, ROW_ITEM_TYPES.KPI),
            icon: <SpeedometerIcon color={COLORS.darkestGray} />,
          },
          {
            id: 3,
            title: `New ${terminology.project}`,
            subtitle: 'Breakdown a large piece of work into smaller jobs',
            onClick: () => onClick(item, ACTIONS.ADD, ROW_ITEM_TYPES.PROJECT),
            icon: <ProjectIcon color={COLORS.darkestGray} />,
          },
          {
            id: 4,
            title: `Existing ${terminology.goal}`,
            subtitle: 'Align success of another outcome with this one',
            onClick: () => onClick(item, ACTIONS.SHARE),
            icon: <GearIcon width={16} height={16} color={COLORS.darkestGray} />,
          }] : [],
          ...(item?.type === ROW_ITEM_TYPES.GOAL || item?.type === ROW_ITEM_TYPES.PROJECT ? [{
            id: 5,
            title: `New ${terminology.action}`,
            subtitle: `Outline how you will achieve this ${terminology.goal}`,
            onClick: () => onClick(item, ACTIONS.ADD, ROW_ITEM_TYPES.ACTION),
            icon: <ActionIcon color={COLORS.darkestGray} />,
          }] : []),
          ...(!button ? [
            {
              id: 6,
              title: `Open ${getItemTypeTerminology({
                type: item.type,
                terminology
              })} Hub`,
              onClick: () => onClick(item, ACTIONS.OPEN_HUB),
              icon: <HubIcon color={COLORS.darkestGray} />,
            },
            {
              id: 7,
              title: 'View Alignment Map',
              onClick: () => onClick(item, 'view-alignment-map'),
              icon: <AlignmentIcon color={COLORS.darkestGray} />,
            },
            {
              id: 8,
              title: 'Move',
              onClick: () => onClick(item, ACTIONS.MOVE),
              icon: <MoveIcon color={COLORS.darkestGray} />,
            },
            {
              id: 9,
              title: 'Delete',
              onClick: () => onClick(item, ACTIONS.DELETE),
              icon: <TrashIcon color={COLORS.red} />,
              titleColor: COLORS.red,
            },
          ] : []),
        ],
      },
    },
  };
};
