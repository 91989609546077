import {
  GET_SUBSCRIPTION_SAGA, ON_BACK_FROM_STRIPE, SET_SUBSCRIPTION, SUBSCRIPTION_LOADED,
} from './actionTypes';

export function getSubscriptionSaga() {
  return {
    type: GET_SUBSCRIPTION_SAGA,
  };
}

export function onBackFromStripeSaga() {
  return {
    type: ON_BACK_FROM_STRIPE,
  };
}

export function setSubscription(payload) {
  return {
    type: SET_SUBSCRIPTION,
    payload
  };
}

export function isSubscriptionLoaded(payload) {
  return {
    type: SUBSCRIPTION_LOADED,
    payload
  };
}
