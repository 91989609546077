import React, {memo} from 'react';
import {MenuItem, Select, styled} from "@mui/material";
import {COLORS} from "../../../constants/colors";
import styles from './OutlinedSelectInput.module.scss';
import TooltipComponent from "../Tooltip/TooltipComponent";

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    display: flex;
    align-items: center;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px ${COLORS.lightBlue} solid;
    box-shadow: 0 0 0 4px ${COLORS.transparentDarkBlue};
  }
`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};
const OutlinedSelectInput = ({renderTitle, options, handleChange, value, disabled, placeholder, height, valueKey = 'id', fullItemValue= false, disabledItemIds, showProp}) => {
  return (
    <StyledSelect
        value={
            (value === undefined || value === null || options.length === 0)
                ? ''
                : value
        }
      onChange={handleChange}
      disabled={disabled}
      fullWidth
      displayEmpty
      renderValue={!fullItemValue ? null : (p) => renderTitle ? p[renderTitle] : p.name ? p.name : p[showProp]}
      sx={{
        height: height,
        color: value ? COLORS.darkerGray : COLORS.lightGray ,
        border: disabled && `1px solid ${COLORS.lightGray}`,
        borderRadius: disabled && '8px',
      }}
        MenuProps={MenuProps}
    >
      {placeholder && <MenuItem style={{color: COLORS.lightGray}} value={''}>{placeholder}</MenuItem>}
      {options.map((item) => {
        return (
          <MenuItem key={item.id} value={fullItemValue ? item : item[valueKey]} disabled={disabledItemIds?.includes(item.id)}>
            <TooltipComponent title={item.name} placement={'right'}>
              <div className={styles.itemContainer}>
                {item.hasImage && (
                  <img src={item.image || item.defaultImage} className={styles.image} alt={item.name} />
                )}
                {item.icon && item.icon}
                <div className={styles.titleContainer}>
                  <h4 className={styles.title}>{item.name} {showProp && !item.name && item[showProp]}<span className={styles.titleAddition}>{item?.addition && ` ${item?.addition}`}</span></h4>
                  {item.subtitle && (
                    <h4 className={styles.subtitle}>{item.subtitle}</h4>
                  )}
                </div>
              </div>
            </TooltipComponent>
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default memo(OutlinedSelectInput);
