import React from 'react';
import {Box, InputLabel, styled, Typography} from '@mui/material';
import {COLORS} from '../../../constants/colors';
import styles from "./TextareaField.module.scss";

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    margin-bottom: 8px;
    font-size: 16px;
    color: ${COLORS.darkestGray};
    font-weight: 400;
    line-height: 24px;
    text-align: start;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    padding-top: 4px;
    font-size: 12px;
    color: ${COLORS.lighterRed};
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {

  }
`;

const TextareaField = ({label, placeholder, onChange, value, error, hideLabel = false, minRows = 1}) => {
  return (
    <Box>
      {!hideLabel && (
        <StyledInputLabel>
          {label}
        </StyledInputLabel>
      )}
      <textarea
        value={value}
        onChange={(e) => onChange(e.currentTarget.value.replace(/^\s+/, ''))}
        placeholder={placeholder}
        className={styles.container}
        rows={minRows}
        onKeyDown={(e) => { e.stopPropagation()}}
      ></textarea>
      <StyledBox>
        {error && (
          <StyledTypography>
            {error}
          </StyledTypography>
        )}
      </StyledBox>
    </Box>
  );
};

export default TextareaField;
