import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  MOVE_ACTION: 'actions/move/:actionId',
  UPDATE_ACTION_WEIGHT: 'actions/:actionId/weight',
  UPDATE_ACTION_WEIGHED: 'actions/:actionId/weighed',
  UPDATE_ACTION_PROGRESS: 'actions/:actionId/update-value',
  UPDATE_ACTION_MILESTONE: 'actions/:actionId/update-milestone',
  GET_ACTIONS: 'actions/get-all',
  GET_ACTION_UPDATES: 'actions/:actionId/updates',
  UPDATE_CHECKLIST: 'checklists/:id',
  UPDATE_ACTION_DATE_RANGE: 'actions/:actionId/dates',
  REMOVE_ACTION_CONNECTION: 'action-jira-connections/:id',

};

class ActionService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  moveAction = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.MOVE_ACTION.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  updateActionProgress = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ACTION_PROGRESS.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };
  updateActionMilestone = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ACTION_MILESTONE.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  updateActionWeight = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ACTION_WEIGHT.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateActionWeighed = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ACTION_WEIGHED.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateActionChecklist = (checklistId, data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_CHECKLIST.replace(':id', checklistId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getActions = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_ACTIONS,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getActionUpdates = (actionId) => {
    return this.httpService.request({
      url: ROUTES.GET_ACTION_UPDATES.replace(':actionId', actionId),
      method: HTTP_METHODS.GET,
    });
  };

  updateActionDateRange = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ACTION_DATE_RANGE.replace(':actionId', actionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  removeActionConnection = (id) => {
    return this.httpService.request({
      url: ROUTES.REMOVE_ACTION_CONNECTION.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };
}

const actionService = new ActionService(httpService);

export default actionService;
