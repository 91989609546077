import {
  OPEN_ACTION_RIGHT_SIDEBAR,
  UPDATE_ACTION_SAGA,
} from './actionTypes';

export function updateActionSaga(payload) {
  return {
    type: UPDATE_ACTION_SAGA,
    payload,
  };
}

export function openActionRightSidebarSaga(payload) {
  return {
    type: OPEN_ACTION_RIGHT_SIDEBAR,
    payload,
  };
}
