import produce from 'immer';
import {
  RESET_STATE, SET_SHOW_EDIT_PERSPECTIVE_MODAL, SET_SHOW_PERSPECTIVE_LIBRARY_MODAL,
} from './actionTypes';

export const initialState = {
  showEditModalPerspective: false,
  showPerspectiveLibraryModal: false,
};

/* eslint-disable default-case */
const perspectiveReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case SET_SHOW_EDIT_PERSPECTIVE_MODAL:
        draft.showEditModalPerspective = action.payload;
        break;
      case SET_SHOW_PERSPECTIVE_LIBRARY_MODAL:
        draft.showPerspectiveLibraryModal = action.isVisible;
        break;
    }
  });

export default perspectiveReducer;
