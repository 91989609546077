import React from 'react';
import styles from './SidebarActionComponent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {strategyPlanSelector} from "../../../../store/strategyPlan/selectors";
import {ROW_ITEM_TYPES} from "../../../../constants/strings";
import {ReactComponent as ArrowLeft} from '../../../../assets/icons/arrowLeft.svg';
import ActionModalContentComponent
    from "../../../../containers/StrategyPlanPage/ItemRowComponent/Components/ActionModalContentComponent/ActionModalContentComponent";
import {addActionSaga, rightSidebarBack} from "../../../../store/strategyPlan/actions";
const SidebarCreateActionComponent = () => {
    const dispatch = useDispatch();

    const strategyPlan = useSelector(strategyPlanSelector());
    const onBackPress = () => {
        dispatch(rightSidebarBack());
    }

  return (
    <div className={styles.container}>
        <div className={styles.headerWrapper}>
            <button onClick={onBackPress} className={styles.backBtn}>
                <ArrowLeft />
            </button>
            <p>Back</p>
        </div>
        <ActionModalContentComponent onCancel={onBackPress} removePadding={true} onFormSubmit={(val) => dispatch(addActionSaga({
            ...val,
            type: ROW_ITEM_TYPES.ACTION,
            scorecardId: strategyPlan.id
        }))}/>
    </div>
  );
};

export default SidebarCreateActionComponent;
