import {GOAL} from "../goals/actionTypes";

export const HELPER = '[Helper]';

export const SET_MODAL = `${HELPER} SET_MODAL`;
export const GET_FOUNDATION_SAGA = `${HELPER} GET_FOUNDATION_SAGA`;
export const SET_FOUNDATION = `${HELPER} SET_FOUNDATION`;
export const GET_ORGANIZATION_ACTIVITY_SAGA = `${HELPER} GET_ORGANIZATION_ACTIVITY_SAGA`;
export const SET_ORGANIZATION_ACTIVITY = `${HELPER} SET_ORGANIZATION_ACTIVITY`;
export const GET_LOG_TYPE_ACTIVITY_SAGA = `${HELPER} GET_LOG_TYPE_ACTIVITY_SAGA`;
export const SET_LOG_TYPE_ACTIVITY = `${HELPER} SET_LOG_TYPE_ACTIVITY`;
export const GET_USER_ACTIVITY_SAGA = `${HELPER} GET_USER_ACTIVITY_SAGA`;
export const SET_USER_ACTIVITY = `${HELPER} SET_USER_ACTIVITY`;
export const GET_SETTINGS_THEMES_SAGA = `${HELPER} GET_SETTINGS_THEMES_SAGA`;
export const SET_SETTINGS_THEMES = `${HELPER} SET_SETTING_THEMES`;
export const SET_CREATE_GOAL_MODAL_FROM_NAVBAR = `${HELPER} SET_CREATE_GOAL_MODAL_FROM_NAVBAR`;
export const SET_ACTION_MODAL_FROM_NAVBAR = `${HELPER} SET_ACTION_MODAL_FROM_NAVBAR`;
export const RIGHT_MODAL_OPEN = `${HELPER} RIGHT_MODAL_OPEN`;
export const SET_ITEM_AND_PARENT_FOR_ACTION = `${HELPER} SET_ITEM_AND_PARENT_FOR_ACTION`;
export const SET_WARNING_MODAL = `${HELPER} SET_WARNING_MODAL`;
export const SET_COMMENTS = `${HELPER} SET_COMMENTS`;
export const SET_REFRESH = `${HELPER} SET_REFRESH`;
export const REFRESH_PAGE_SAGA = `${HELPER} REFRESH_PAGE_SAGA`;
export const SET_IS_DELETE_MODAL_VISIBLE = `${HELPER} SET_IS_DELETE_MODAL_VISIBLE`;
export const GET_UPDATES_SAGA = `${HELPER} GET_UPDATES_SAGA`;
export const SET_UPDATES = `${HELPER} SET_UPDATES`;
export const GET_COMMENTS_SAGA = `${HELPER} GET_COMMENTS_SAGA`;
export const SET_DELETE_MODAL = `${HELPER} SET_DELETE_MODAL`;
export const UPDATE_GROUP_MODAL_SHOW = `${HELPER} UPDATE_GROUP_MODAL_SHOW`;
export const ADD_GROUP_UPDATE_SAGA = `${HELPER} ADD_GROUP_UPDATE_SAGA`;
export const GET_GROUP_UPDATES = `${HELPER} GET_GROUP_UPDATES`;
export const SET_GROUP_UPDATES = `${HELPER} SET_GROUP_UPDATES`;
export const POST_UPDATE_SAGA = `${HELPER} POST_UPDATE_SAGA`;
export const UPDATE_DATE_RANGE_SAGA = `${HELPER} UPDATE_DATE_RANGE_SAGA`;
export const SET_ADDITIONAL_MODAL = `${HELPER} SET_ADDITIONAL_MODAL`;
export const POST_UPDATE_FROM_HOME_PROJECT_SAGA = `${HELPER} POST_UPDATE_FROM_HOME_PROJECT_SAGA`;
