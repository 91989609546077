export const AUTH = '[Auth]';

export const LOGIN_REQUEST = `${AUTH} LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${AUTH} LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${AUTH} LOGIN_ERROR`;
export const FETCH_AUTHENTICATED_USER_REQUEST = `${AUTH} FETCH_AUTHENTICATED_USER_REQUEST`;
export const FETCH_AUTHENTICATED_USER_SUCCESS = `${AUTH} FETCH_AUTHENTICATED_USER_SUCCESS`;
export const LOGOUT_REQUEST = `${AUTH} LOGOUT_REQUEST`;
export const SET_ORGANIZATION = `${AUTH} SET_ORGANIZATION`;
export const LOGOUT_SUCCESS = `${AUTH} LOGOUT_SUCCESS`;
export const ON_GOOGLE_SIGN_UP = `${AUTH} ON_GOOGLE_SIGN_UP`;
export const ON_LINKEDIN_SIGN_UP = `${AUTH} ON_LINKEDIN_SIGN_UP`;
export const SET_TOKEN = `${AUTH} SET_TOKEN`;
export const SET_REFRESH_TOKEN = `${AUTH} SET_REFRESH_TOKEN`;
export const SESSION_EXPIRED = `${AUTH} SESSION_EXPIRED`;
export const FORGOT_PASSWORD_REQUEST = `${AUTH} FORGOT_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SAGA = `${AUTH} RESET_PASSWORD_SAGA`;
export const FORGOT_PASSWORD_SUCCESS = `${AUTH} FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_ERROR = `${AUTH} FORGOT_PASSWORD_ERROR`;
export const REGISTER_REQUEST = `${AUTH} REGISTER_REQUEST`;
export const REGISTER_SUCCESS = `${AUTH} REGISTER_SUCCESS`;
export const REGISTER_ERROR = `${AUTH} REGISTER_ERROR`;
export const RESET_PASSWORD_REQUEST = `${AUTH} RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${AUTH} RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${AUTH} RESET_PASSWORD_ERROR`;
export const SOCIAL_AUTH_REQUEST = `${AUTH} SOCIAL_AUTH_REQUEST`;
export const SOCIAL_AUTH_SUCCESS = `${AUTH} SOCIAL_AUTH_SUCCESS`;
export const SOCIAL_AUTH_ERROR = `${AUTH} SOCIAL_AUTH_ERROR`;
export const SET_LOGIN_ERROR = `${AUTH} SET_LOGIN_ERROR`;
export const SET_USER_JWT_DATA = `${AUTH} SET_USER_JWT_DATA`;
export const SET_SIDEBAR_STATE = `${AUTH} SET_SIDEBAR_STATE`;
export const SELECT_ORGANIZATION = `${AUTH} SELECT_ORGANIZATION`;
export const CREATE_ORGANIZATION = `${AUTH} CREATE_ORGANIZATION`;

export const SEND_TWO_FA_REQUEST_SAGA = `${AUTH} SEND_TWO_FA_REQUEST_SAGA`;
export const SET_TWO_FA_ERROR = `${AUTH} SET_TWO_FA_ERROR`;
export const SIGN_IN_MICROSOFT_SAGA = `${AUTH} SIGN_IN_MICROSOFT_SAGA`;
export const SIGN_IN_FROM_INVITATION = `${AUTH} SIGN_IN_FROM_INVITATION`;
export const SET_TERMINOLOGY = `${AUTH} SET_TERMINOLOGY`;
