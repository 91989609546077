export const PAGES = {
  HOME: 'Home',
  PRICING: 'Pricing',
  UPCOMING_GOALS: 'Upcoming Goals',
  METRICS: 'Metrics',
  CONNECT_METRIC_WITH_KPI: 'Connect with KPI',
  AIGen: 'Generate Plan with A.I.',
  UPCOMING_PROJECTS_AND_ACTIONS: 'Upcoming Projects, KPIs and Actions',
  RECENT_UPDATES: 'Recent Updates',
  SETTINGS: 'Settings',
  SCORECARDS: 'Groups & Plans',
  GROUP_SETTINGS: 'Group Settings',
  PROFILE_SETTINGS: 'My Settings',
  STRATEGY_PLAN: 'Strategic Plan',
  DASHBOARDS: 'Dashboards',
  PERSPECTIVE_DASHBOARDS: 'Viewpoint Dashboard',
  CREATE_WIDGET: 'Create Widget',
  WIDGET_LIST: 'Charts & Graphs Widget Library',
  SINGLE_DASHBOARD: 'Single Dashboard',
  STRATEGY_PLAN_AND_ALIGNMENT: 'Strategy plan and alignment',
  GOAL_ALIGNMENT_MAP: 'Goal Alignment Map',
  HUB: 'Hub',
  GOAL_HUB: 'Goal Hub',
  KPI_HUB: 'KPI Hub',
  PROJECT_HUB: 'Project Hub',
  ACTION_HUB: 'Action Hub',
  ALIGNMENT_MAP: 'Alignment Map',
  PERSPECTIVE_DASHBOARD: 'Viewpoint Dashboard',
  STRATEGY_PLAN_CRITERIA_TAB: 'Strategy Plan Criteria Tab',
  GOAL_HUB_CRITERIA_TAB: 'Goal Hub Criteria Tab',
  HOME_CRITERIA_TAB: 'Home Criteria Tab',
  VNC_ACADEMY: 'Training Center',
  WEBINAR: 'Webinar',
  WORKSHOPS: 'Workshops',
  REPORTS: 'Reports',
  INTEGRATIONS: 'Integrations',
  MANAGE_CONNECTIONS: 'Manage your connections',
  SINGLE_REPORT: 'Report',
  HUB_ACTIVITY_TAB: 'Hub Activity Tab',
  REPORT_SNAPSHOT: 'Report',
  RENDER_REPORT_SNAPSHOT: 'Report/Snapshot',
  NOTIFICATIONS: 'Notifications',
  KANBAN_BOARD: 'KanBan Board',
  RELEASE_NOTES: 'Release Notes'
};

export const DYNAMIC_TITLE = ({terminology}) => {
  return {
    UPCOMING_GOALS: `Upcoming ${terminology?.goal}s`,
    UPCOMING_PROJECTS_AND_ACTIONS: `Upcoming ${terminology?.project}s, ${terminology?.kpi}s and ${terminology?.action}s`,
    SCORECARDS: `Groups & ${terminology.plan}s`,
    PERSPECTIVE_DASHBOARD: `${terminology.perspective} Dashboard`,
    STRATEGY_PLAN: `${terminology.plan}`,
    AIGen: `Generate ${terminology.plan} with A.I.`,
    CONNECT_METRIC_WITH_KPI: `Connect with ${terminology.kpi}`,
  };
};
