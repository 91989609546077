import produce from 'immer';
import {
  RESET_STATE, SET_KPI,
} from './actionTypes';

export const initialState = {
  kpi: null,
};

/* eslint-disable default-case */
const kpisReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_KPI:
        draft.kpi = action.payload;
        break;
      case RESET_STATE:
        return initialState;
    }
  });

export default kpisReducer;
