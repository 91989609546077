import React from "react";
import styles from './indexStyle.module.scss';
import {getRowItemIcon, goalStatus} from "../../../utils/perspective";
import { ReactComponent as SpeedometerIcon } from '../../../assets/icons/speedometer.svg';
import CustomDot from "../../../components/Shared/Dot/CustomDot";
import dayjs from "dayjs";
import {getKpiTarget} from "../../../utils/helper";
import {formatNumberValueWithUnit} from "../../../utils/dashboard";

export const TABLE_CONFIG = (row) => {
  return [
    {
      id: 1,
      type: 'component',
      label: 'Name',
      value: 'title',
      sortField: 'name',
      component: (
        <div className={styles.flex}>
          <div className={styles.iconContainer}>
            <SpeedometerIcon />
          </div>
          <div className={styles.subtitleContainer}>
            <h4 className={styles.subtitle}>{row?.name}</h4>
          </div>
        </div>
      ),
      sortable: false,
    },
    {
      id: 2,
      type: 'component',
      label: 'Start/End Date',
      value: 'startDate',
      component: (
        <div>
          <p className={styles.rowGrayText}>{dayjs(row?.startDate).format('MMM DD YYYY')}</p>
          <p className={styles.rowBlackText}>{dayjs(row?.endDate).format('MMM DD YYYY')}</p>
        </div>
      ),
      sortable: false,
    },
    {
      id: 3,
      type: 'component',
      label: 'Progress',
      value: 'progress',
      sortable: false,
      component: (
        <div >
          {row && row?.progress ? `${row?.progress}%` : '-'}
        </div>
      ),
    },
    {
      id: 4,
      type: 'component',
      label: 'Target',
      value: '',
      sortable: false,
      component: (
        <div className={styles.flex}>
          {getKpiTarget(row)}
        </div>
      ),
    },
    {
      id: 4,
      type: 'component',
      label: 'Health',
      value: '',
      sortable: false,
      component: (
        <div className={styles.flex}>
          <CustomDot backgroundColor={goalStatus(row?.health).color} medium />
          <div className={styles.subtitleContainer}>
            <h4 className={styles.subtitle}>{row?.health ? row?.health : 'No Health' }</h4>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      type: 'component',
      label: 'Actual Value',
      value: 'actualValue',
      sortable: false,
      component: (
        <div className={styles.flex}>
          <div className={styles.subtitleContainer}>
            <h4 className={styles.subtitle}>{row?.actualValue ? formatNumberValueWithUnit(row?.actualValue, row?.unit) : '-'}</h4>
          </div>
        </div>
      ),
    },
  ];
};
