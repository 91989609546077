import React from 'react';
import styles from './ProgressBar.module.scss';
import Badge from "../Badge/Badge";
import {ReactComponent as VerticalDashedLine} from '../../../assets/icons/vertical-dashed-line.svg';
import {ReactComponent as FlagSvg} from '../../../assets/icons/flagSvg.svg';
import {ROW_ITEM_TYPES} from "../../../constants/strings";

const ProgressBar = ({
                         width,
                         progress = 0,
                         colors,
                         hideValue,
                         large,
                         showProgressBadge,
                         progressProperties,
                         badgeTitle,
                         expectedProgress,
                         flags,
                         type,
                         kpiMarks,
                     }) => {
    return (
        <div className={styles.container}>
            <div
                className={`${styles.progressBar} ${large && styles.largeProgressBar}`}
                style={{width: width}}
            >
                <div className={styles.centerLine}/>
                <div
                    className={`${styles.progress} ${progressProperties()?.barStyle}`}
                    style={{
                        width: `${Math.min(100, progress)}%`,
                        ...(colors?.length > 1 && {
                            backgroundColor: colors[0],
                            backgroundImage: `linear-gradient(45deg, ${colors[1]} 25%, transparent 25%, transparent 50%, ${colors[1]} 50%, ${colors[1]} 75%, transparent 75%, transparent)`
                        }),
                    }}
                />
                {expectedProgress && (
                    <div className={styles.expectedProgressContainer} style={{left: `${expectedProgress}%`}}>
                        {`Expected progress: ${expectedProgress}%`}
                        <div className={styles.expectedProgressLine}>
                            <VerticalDashedLine/>
                        </div>
                    </div>
                )}
                {
                    flags && flags.length && flags.map((item, index) => {
                        return (
                            <div key={index} className={styles.flagMarkContainer} style={{left: `${item.expectedProgress}%`}}>
                                <FlagSvg />
                                <p>{item.expectedProgress + '%'}</p>
                            </div>
                        )
                    })
                }
            </div>
            {!hideValue && (
                <h4 className={styles.value}>
                    {`${progress}%`}
                </h4>
            )}
            {showProgressBadge && (
                <div className={styles.badgeContainer}>
                    <Badge title={badgeTitle} containerStyles={progressProperties().badgeStyle}/>
                </div>
            )}
          {type === ROW_ITEM_TYPES.KPI && (
            <div className={styles.kpiMarks}>
              {kpiMarks?.length > 0 && kpiMarks.map((mark) => {
                return (
                  <div className={styles.kpiMark} style={{left: `${mark.value}%`}} key={mark.id} />
                );
              })}
            </div>
          )}
        </div>
    );
};

export default ProgressBar;
