import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_GOALS_FOR_LIBRARY: '/goals/library',
  GET_FIRST_LEVEL_GOALS: '/goals/first-level',
  SHARE_GOALS: '/goals/share',
  SHARE_CONTRIBUTE_GOALS: '/goals/contribute',
  DELETE_GOAL_LINK: 'goals/link/:parentId/:goalId',
  GET_GOALS_BY_ORGANIZATION: 'goals/by-organization',
  MOVE_GOAL: 'goals/move/:goalId',
  GET_GOAL_WITH_ITEMS: 'goals/:goalId/with-items',
  UPDATE_GOAL_WEIGHT: 'goals/:goalId/weight',
  UPDATE_GOAL_WEIGHT_TYPE: 'goals/:goalId/weight-type',
  UPDATE_GOAL_WEIGHED: 'goals/:goalId/weighed',
  GET_GOAL_UPDATES: 'goals/:goalId/updates',
  POST_GOAL_UPDATE: 'goals/post-update',
  UPDATE_GOAL_DATE_RANGE: 'goals/:goalId/dates',
};

class GoalService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getGoalsForLibrary = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_GOALS_FOR_LIBRARY,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getFirstLevelGoals = () => {
    return this.httpService.request({
      url: ROUTES.GET_FIRST_LEVEL_GOALS,
      method: HTTP_METHODS.GET,
    });
  };

  shareGoals = (data) => {
    return this.httpService.request({
      url: ROUTES.SHARE_GOALS,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getGoalsByOrganization = () => {
    return this.httpService.request({
      url: ROUTES.GET_GOALS_BY_ORGANIZATION,
      method: HTTP_METHODS.GET,
    });
  };

  moveGoal = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.MOVE_GOAL.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  shareContributeGoals = (data) => {
    return this.httpService.request({
      url: ROUTES.SHARE_CONTRIBUTE_GOALS,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getGoalWithItems = (goalId) => {
    return this.httpService.request({
      url: ROUTES.GET_GOAL_WITH_ITEMS.replace(':goalId', goalId),
      method: HTTP_METHODS.GET,
    });
  };

  updateGoalWeight = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOAL_WEIGHT.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateGoalWeightType = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOAL_WEIGHT_TYPE.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  updateGoalWeighed = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOAL_WEIGHED.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  getGoalUpdates = (goalId) => {
    return this.httpService.request({
      url: ROUTES.GET_GOAL_UPDATES.replace(':goalId', goalId),
      method: HTTP_METHODS.GET,
    });
  };

  postGoalUpdate = (data) => {
    return this.httpService.request({
      url: ROUTES.POST_GOAL_UPDATE,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  updateGoalDateRange = (data, goalId) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_GOAL_DATE_RANGE.replace(':goalId', goalId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };
}

const goalService = new GoalService(httpService);

export default goalService;
