import React from 'react';
import styles from './ProgressBarHeader.module.scss';
import Badge from "../Badge/Badge";
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import {formatProgressString} from "../../../utils/perspective";

const ProgressBarHeader = ({
  value,
  icon,
  showHeaderBadge,
  badgeTitle,
  progressProperties,
  showHeaderProgress,
  showHeaderUnitProgress,
  item,
  type,
}) => {
  const getExpectedProgress = () => {
    switch (type) {
      case ROW_ITEM_TYPES.KPI:
        return formatProgressString(item, value);
      case ROW_ITEM_TYPES.ACTION:
        return {
          currentValue: `${value}%`,
          expectedProgress: 'of 100%',
        };
      default:
        console.log('no matching type selected');
    }
  };

  return (
    <div className={styles.container}>
      {showHeaderProgress && (
        <div className={styles.leftContainer}>
          <div className={styles.expectedProgressStatus}>
            <p>{`${value}%`}</p>
            <p className={styles.expectedProgressGoalString}>{`of ${item.expectedProgress || 100}%`}</p>
          </div>
        </div>
      )}
      {showHeaderUnitProgress && (
        <div className={styles.expectedProgressStatus}>
          <p>{getExpectedProgress().currentValue}</p>
          <p className={styles.expectedProgressGoalString}>{getExpectedProgress().expectedProgress}</p>
        </div>
      )}
      {icon && (
        <button
          onClick={icon.onClick}
          className={styles.button}
          type={'button'}
        >
          {icon?.url ? (
            <img src={icon.url} className={styles.image} alt={'icon'} />
          ) : (
            <div className={styles.image}>
              {icon.component}
            </div>
          )}
        </button>
      )}
      {showHeaderBadge && (
        <Badge title={badgeTitle} containerStyles={progressProperties().badgeStyle} />
      )}
    </div>
  );
};

export default ProgressBarHeader;
