import {
  CREATE_EDIT_REPORT_ELEMENT_SAGA,
  CREATE_EDIT_REPORT_SAGA,
  DELETE_REPORT_ELEMENT_SAGA,
  DELETE_REPORT_SAGA,
  DUPLICATE_REPORT_SAGA,
  GET_REPORT_SAGA,
  GET_REPORTS_GRID_SAGA,
  GET_SNAPSHOT_BY_NAME_SAGA,
  GET_SNAPSHOT_SAGA,
  SET_CAN_EDIT_REPORT,
  SET_REPORT,
  SET_REPORTS_GRID,
  SET_SHARE_REPORT_MODAL,
  TAKE_REPORT_SNAPSHOT_SAGA,
  UPDATE_REPORT_ELEMENTS_SAGA,
} from './actionTypes';

export function getReportsGridSaga(payload) {
  return {
    type: GET_REPORTS_GRID_SAGA,
    payload,
  };
}

export function setReportsGrid(payload) {
  return {
    type: SET_REPORTS_GRID,
    payload,
  };
}

export function deleteReportSaga(payload) {
  return {
    type: DELETE_REPORT_SAGA,
    payload,
  };
}

export function createEditReportSaga(payload) {
  return {
    type: CREATE_EDIT_REPORT_SAGA,
    payload,
  };
}

export function getReportSaga(payload) {
  return {
    type: GET_REPORT_SAGA,
    payload,
  };
}

export function setReport(payload) {
  return {
    type: SET_REPORT,
    payload,
  };
}

export function duplicateReportSaga(payload) {
  return {
    type: DUPLICATE_REPORT_SAGA,
    payload,
  };
}

export function takeReportSnapshotSaga(payload) {
  return {
    type: TAKE_REPORT_SNAPSHOT_SAGA,
    payload,
  };
}

export function setShareReportModal(payload) {
  return {
    type: SET_SHARE_REPORT_MODAL,
    payload,
  };
}

export function deleteReportElementSaga(payload) {
  return {
    type: DELETE_REPORT_ELEMENT_SAGA,
    payload,
  };
}

export function createEditReportElementSaga(payload) {
  return {
    type: CREATE_EDIT_REPORT_ELEMENT_SAGA,
    payload,
  };
}

export function updateReportElementsSaga(payload) {
  return {
    type: UPDATE_REPORT_ELEMENTS_SAGA,
    payload,
  };
}

export function getSnapshotSaga(payload) {
  return {
    type: GET_SNAPSHOT_SAGA,
    payload,
  };
}

export function getSnapshotByNameSaga(payload) {
  return {
    type: GET_SNAPSHOT_BY_NAME_SAGA,
    payload,
  };
}

export function setCanEditReport(payload) {
  return {
    type: SET_CAN_EDIT_REPORT,
    payload,
  };
}
