import React from 'react';
import styles from './Badge.module.scss';

const Badge = ({
  title,
  backgroundColor,
  fontColor,
  percent,
  width,
  height,
  icon,
  containerStyles,
}) => {
  return (
    <div
      className={`${styles.container} ${containerStyles}`}
      style={{
        width: width || 'auto',
        height: height || 'auto',
        backgroundColor: backgroundColor,
        color: fontColor,
      }}
    >
      {icon && (
        <div className={styles.icon}>
          {icon}
        </div>
      )}
      <h4 className={styles.title}>{`${title} ${percent ? '%' : ''}`}</h4>
    </div>
  );
};

export default Badge;
