import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA,
  ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA,
  ASSIGN_TO_GROUP_SAGA,
  GET_STRATEGY_PLAN_SAGA,
  DELETE_PERSPECTIVE,
  DELETE_GOAL,
  UPDATE_GOAL,
  DELETE_KPI,
  DELETE_ACTION,
  DELETE_PROJECT,
  RIGHT_SIDEBAR_SAGA,
  GET_RIGHT_SIDEBAR_ITEM_SAGA,
  RIGHT_SIDEBAR_SUBMIT_FORM_SAGA,
  RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA,
  ADD_ACTION_FROM_SIDEBAR_SAGA,
  RIGHT_SIDEBAR_BACK,
  CLOSE_SIDEBAR_SAGA,
  ADD_ACTION_SAGA,
  ADD_TRACKING_FROM_SIDEBAR_SAGA,
  BACK_FROM_ADD_TRACKING_SAGA,
  RIGHT_SIDEBAR_DELETE_ITEM_SAGA,
  ADD_TRACKING_AND_BACK_SAGA,
  MOVE_ITEM_FROM_EDIT_PARENT_SAGA,
  GET_EDIT_PARENT_DATA_SAGA,
  REORDER_PERSPECTIVE_SAGA,
  EXPAND_PERSPECTIVE_SAGA,
  DELETE_PERSPECTIVE_FROM_WIDGET,
  REORDER_PERSPECTIVE_ITEM,
  ON_REORDER_SAVE_PERSPECTIVE_SAGA, REORDER_ROW_ITEM, SAVE_REORDER_ROW_ITEM,
} from './actionTypes';
import {
  getStrategyPlanSaga,
  rightSidebarBack,
  setAllItemsOnHomePage,
  setEditGoalModal,
  setGoalsOnHomePage,
  setItemRow, setItemSortActive,
  setOptionsForEditParent,
  setProjectsActionsKpisOnHomePage,
  setShowEditParent,
  setStrategyPlan,
  setStrategyPlanWithPerspectives
} from './actions';
import scorecardService from '../../services/ScorecardService';
import {
  goalsOnHomePageSelector,
  homePageAllItemsSelector,
  homePageProjectsActionsKpisSelector,
  itemRowSelector,
  strategyPlanWithPerspectivesSelector
} from "./selectors";
import {setSnackbar} from "../snackbar/actions";
import {ACTIONS, ADD_ACTION_TYPE, ITEM_ROW_LINKED_TYPES, ROW_ITEM_TYPES} from "../../constants/strings";
import {setLoader} from "../loader/actions";
import SidebarGoalComponent from "../../components/RightModal/Goal/SidebarGoalComponent";
import {
  refreshPageSaga,
  rightModalOpen,
  setActionModalFromNavbar,
  setItemAndParentForAction,
  setModal,
  setRefresh
} from "../helper/actions";
import goalService from "../../services/GoalService";
import projectService from "../../services/ProjectService";
import SidebarProjectComponent from "../../components/RightModal/Project/SidebarProjectComponent";
import React from "react";
import SidebarActionComponent from "../../components/RightModal/Action/SidebarActionComponent";
import SidebarCreateActionComponent from "../../components/RightModal/Project/SidebarActionComponent/SidebarActionComponent";
import perspectiveService from "../../services/PerspectiveService";
import SidebarKpiComponent from "../../components/RightModal/Kpi/SidebarKpiComponent";
import SidebarAddTrackingComponent from "../../components/RightModal/Kpi/SidebarAddTrackingComponent/SidebarAddTrackingComponent";
import {getItemParent} from "../../utils/perspective";
import kpiService from "../../services/KpiService";
import {itemAndParentForActionSelector, rightModalSelector} from "../helper/selectors";
import actionService from "../../services/ActionService";
import { v4 as uuidv4 } from 'uuid';
import {setGoalsAlignmentModal} from "../alignment/actions";
import {setRightSidebarScorecard} from "../scorecard/actions";
import {getScorecardName} from "../../utils/setDataForContributesToComponent";
import {addFrontIdToEveryItem} from "../../utils/helper";
import {terminologySelector} from "../auth/selectors";

export function* getStrategyPlanSagaFn({payload}) {
  try {
    const strategyPlanWithPerspectives = yield call(scorecardService.getStrategyPlan, payload);
    yield addFrontIdToEveryItem(strategyPlanWithPerspectives);
    const updatePositions = (items) => {
      items.forEach((item, index) => {
        if (item.linkedType === 'contributor') {
          item.position = 0;
        } else if (item.position === null || item.position === undefined) {
          item.position = index + 1;
        }
        item.isExpanded = true;

        if (item.items && item.items.length > 0) {
          item.items.forEach((val, ind) => {
            if (val.linkedType === 'contributor') {
              val.position = 0;
            } else if (val.position === null || val.position === undefined) {
              val.position = ind + 1;
            }
          });
          updatePositions(item.items);
          item.items.sort((a, b) => a.position - b.position);
        }
      });
    };
    updatePositions(strategyPlanWithPerspectives.items);
    strategyPlanWithPerspectives.items.sort((a, b) => {
      return a.position - b.position;
    });
    yield put(setStrategyPlanWithPerspectives(strategyPlanWithPerspectives));
    yield put(setStrategyPlan(strategyPlanWithPerspectives));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* reorderPerspectiveSagaFn({payload}) {
  try {
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector());
    const newObj = {...strategyPlanWithPerspectives};
    newObj.items = payload;
    yield put(setStrategyPlanWithPerspectives(newObj));
    yield put(setStrategyPlan(newObj));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* onReorderPerspectiveSaveSagaFn({payload}) {
  try {
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector());
    let newArr = [];
    strategyPlanWithPerspectives.items.forEach((item) => {
      const data = {};
      data.id = item.id;
      data.position = item.position;
      data.items = [];
      if (item.items.length > 0) {
        item.items.forEach((val) => {
          data.items.push({id: val.id, position: val.position});
        })
      }
      newArr.push(data);
    })
    const payloadReq = {
      perspectives: newArr
    }
    yield call(scorecardService.reorderPerspectives, payloadReq);
    yield put(getStrategyPlanSaga(payload));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* reorderPerspectiveItemSagaFn({payload}) {
  try {
    console.log('saga payload', payload);
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector());
    const newObj = {...strategyPlanWithPerspectives};
    let modifiedItems = [];
    console.log('newObj', newObj);
    newObj.items.forEach((item) => {
      if (payload.id === item.id) {
        modifiedItems.push(payload)
      } else {
        modifiedItems.push(item)
      }
    })
    newObj.items = modifiedItems;
    yield put(setStrategyPlanWithPerspectives(newObj));
    yield put(setStrategyPlan(newObj));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* reorderRowItemSagaFn({payload}) {
  try {
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector());
    // const newObj = {...strategyPlanWithPerspectives};
    let newObj = JSON.parse(JSON.stringify(strategyPlanWithPerspectives));

    const findItemAndAddItems = (items) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].frontId === payload.frontId) {
          items[i].items = payload.items;
          return;
        } else {
          items[i].items && findItemAndAddItems(items[i].items);
        }
      }
    };

    yield findItemAndAddItems(newObj.items);
    yield put(setStrategyPlanWithPerspectives(newObj));
    yield put(setStrategyPlan(newObj));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* expandPerspectiveSagaFn({payload}) {
  try {
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector());
    const newObj = {...strategyPlanWithPerspectives};
    let newItemsArr = [...newObj.items];
    let newItemsModifiedArr = [];
    newItemsArr.forEach((item, index) => {
      if (payload.perspectiveId === item.id) {
        newItemsModifiedArr.push({...item, isExpanded: payload.isExpanded});
      } else {
        newItemsModifiedArr.push(item)
      }
    });
    newObj.items = newItemsModifiedArr;
    yield put(setStrategyPlanWithPerspectives(newObj));
    yield put(setStrategyPlan(newObj));
  } catch (error) {
    console.log({error});
  } finally {
  }
}

export function* addEmptyPerspectiveSaga({action}) {
  try {
    const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector())
    const newObj = JSON.parse(JSON.stringify(strategyPlanWithPerspectives))

    if(action === 'add'){
      newObj.items.unshift({
        name: '',
        status: '',
        type: 'PERSPECTIVE',
        items: []
      })
    } else {
      newObj.items.shift()
    }

    yield put(setStrategyPlanWithPerspectives(newObj))

  } catch (error) {
    console.log({error});
  } finally {
  }
}
export function* assignToGroup({strategyPlanId, data, fromRightSidebar}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.moveScorecardToGroup, data, strategyPlanId);
    yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.plan} added to group successfully`,
    }))
    if (fromRightSidebar) {
      const scorecard = yield call(scorecardService.getScorecard, strategyPlanId);
      yield put(setRightSidebarScorecard(scorecard));
    }
  } catch (error) {
    console.log({error})
  } finally {
  }
}

export function* addEmptyItemPerspectiveSaga({payload}) {
  try {
    let newObj;
    switch (payload.addActionType) {
      case ADD_ACTION_TYPE.STRATEGY_PLAN:
        const strategyPlanWithPerspectives = yield select(strategyPlanWithPerspectivesSelector())
        newObj = yield JSON.parse(JSON.stringify(strategyPlanWithPerspectives));
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_GOAL:
        const homePageGoals = yield select(goalsOnHomePageSelector());
        newObj = yield JSON.parse(JSON.stringify(homePageGoals));
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_PROJECTS_ACTIONS_KPIS:
        const homePageProjectsActionsKpis = yield select(homePageProjectsActionsKpisSelector());
        newObj = yield JSON.parse(JSON.stringify(homePageProjectsActionsKpis));
        break;
      case ADD_ACTION_TYPE.UPCOMING_GOALS:
      case ADD_ACTION_TYPE.ACTIONS_PROJECTS:
        const homePageSeeAll = yield select(homePageAllItemsSelector());
        newObj = yield JSON.parse(JSON.stringify(homePageSeeAll));
        break;
      default:
        break;
    }
    let emptyItem = {};
    switch (payload.type) {
      case ROW_ITEM_TYPES.GOAL:
        emptyItem = {name: '', type: payload.type, parentType: payload.itemRow.type, parentId: payload.itemRow.id, items: []}
        break
      case ROW_ITEM_TYPES.KPI:
        emptyItem = {name: '', type: payload.type, goalId: payload.itemRow.id}
        break
      case ROW_ITEM_TYPES.ACTION:
        emptyItem = {name: '', type: payload.type, parentType: payload.itemRow.type, parentId: payload.itemRow.id}
        break
      case ROW_ITEM_TYPES.PROJECT:
        emptyItem = {name: '', type: payload.type, goalId: payload.itemRow.id}
        break
      default:
        emptyItem = {name: '', type: payload.type}
        break
    }

    emptyItem.frontId = uuidv4();

    const findParentAndAddEmpty = (items) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].frontId === payload.itemRow.frontId) {
          payload.action === 'add' ?
            items[i].items
              ? items[i].items.unshift({...emptyItem})
              : Object.assign(items[i], {items:[{...emptyItem}]})
            :
            items.shift()
          return;
        } else {
          items[i].items && findParentAndAddEmpty(items[i].items);
        }
      }
    };

    yield findParentAndAddEmpty(newObj.items);
    switch (payload.addActionType) {
      case ADD_ACTION_TYPE.STRATEGY_PLAN:
        yield put(setStrategyPlanWithPerspectives(newObj))
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_GOAL:
        yield put(setGoalsOnHomePage(newObj))
        break;
      case ADD_ACTION_TYPE.HOME_PAGE_PROJECTS_ACTIONS_KPIS:
        yield put(setProjectsActionsKpisOnHomePage(newObj))
        break;
      case ADD_ACTION_TYPE.UPCOMING_GOALS:
      case ADD_ACTION_TYPE.ACTIONS_PROJECTS:
        yield put(setAllItemsOnHomePage(newObj))
        break;
      default:
        break;
    }
  } catch (error) {
    console.log({error});
  } finally {
  }
}
export function* deletePerspectiveSaga({perspectiveId, strategyPlanId}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.deletePerspective, perspectiveId);
    yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.perspective} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* deletePerspectiveFromWidgetSaga({perspectiveId, strategyPlanId}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.deletePerspective, perspectiveId);
    yield put(setRefresh(true));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.perspective} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* deleteGoalSaga({goal, strategyPlanId, page}) {
  try {
    const terminology = yield select(terminologySelector());
    switch (goal.linkedType) {
      case ITEM_ROW_LINKED_TYPES.CONTRIBUTOR:
        yield call(scorecardService.deleteLinkedGoal, goal.parent.id, goal.id);
        break
      case ITEM_ROW_LINKED_TYPES.SHARE:
        yield call(scorecardService.deleteSharedGoal, goal.parent.id, goal.id);
        break;
      default:
        yield call(scorecardService.deleteGoal, goal.id);
    }
    yield put(refreshPageSaga({page, scorecardId: strategyPlanId}));
    yield put(setGoalsAlignmentModal({
      isVisible: false,
      title: '',
      maxWidth: 'md',
      component: null,
    }))
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.goal} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function* deleteKpiSagaFn({kpiId, strategyPlanId}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.deleteKpi, kpiId);
    yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.kpi} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* deleteActionSagaFn({actionId, strategyPlanId}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.deleteAction, actionId);
    if (strategyPlanId) {
      yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    } else {
      yield put(setRefresh(true));
    }
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.action} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* updateGoalSaga({goalId, strategyPlanId, data}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.updateGoal, data, goalId);
    yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    yield put(setEditGoalModal({isVisible: false, title: ''}))
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${data.name} ${terminology.goal} successfully updated! `,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* deleteProjectSagaFn({projectId, strategyPlanId}) {
  try {
    const terminology = yield select(terminologySelector());
    yield call(scorecardService.deleteProject, projectId);
    yield put(getStrategyPlanSaga({scorecardId: strategyPlanId}));
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology.project} successfully deleted`,
    }));
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* rightSidebarSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setItemRow(payload.item));
    let sidebarComponent;
    let selectedItem;
    switch (payload.item.type) {
      case ROW_ITEM_TYPES.GOAL:
        selectedItem = yield call(goalService.getGoalWithItems, payload.item.id);
        sidebarComponent = (
          <SidebarGoalComponent />
        );
        break;
      case ROW_ITEM_TYPES.PROJECT:
        selectedItem = yield call(projectService.getProjectById, payload.item.id);
        sidebarComponent = (
          <SidebarProjectComponent />
        );
        break;
      case ROW_ITEM_TYPES.KPI:
        selectedItem = yield call(scorecardService.getKpiById, payload.item.id);
        sidebarComponent = (
          <SidebarKpiComponent />
        );
        break;
      case ROW_ITEM_TYPES.ACTION:
        selectedItem = yield call(scorecardService.getActionById, payload.item.id);
        yield put(setItemRow(payload.item));
        sidebarComponent = (
          <SidebarActionComponent />
        );
        break;
      default:
        return <div />;
    }
    yield put(setItemAndParentForAction({parent: getItemParent(selectedItem), item: selectedItem}));
    yield put(rightModalOpen({
      isVisible: true,
      component: sidebarComponent,
      page: payload.page,
      goalId: payload.goalId,
      scorecardId: payload.scorecardId,
      entity: payload.entity,
      entityId: payload.entityId,
      projectId: payload.projectId,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* addActionFromSidebarSagaFn({payload}) {
  try {
    let sidebarComponent;
    const project = yield call(projectService.getProjectById, payload.id);
    yield put(setItemAndParentForAction({parent: project, item: null}));
    sidebarComponent = (
        <SidebarCreateActionComponent />
    );
    const rightModal = yield select(rightModalSelector());
    yield put(rightModalOpen({
      isVisible: true,
      component: sidebarComponent,
      page: rightModal.page,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* addTrackingFromSidebarSagaFn({payload}) {
  try {
    let sidebarComponent;
    yield put(setItemAndParentForAction({parent: null, item: payload}));
    sidebarComponent = (
        <SidebarAddTrackingComponent />
    );
    const rightModal = yield select(rightModalSelector());
    yield put(rightModalOpen({
      isVisible: true,
      component: sidebarComponent,
      page: rightModal.page,
      scorecardId: rightModal.scorecardId,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* getRightSidebarItemSagaFn({item}) {
  try {
    yield put(setLoader(true));
    let perspectiveItem;
    switch (item.type) {
      case ROW_ITEM_TYPES.GOAL:
        perspectiveItem = yield call(goalService.getGoalWithItems, item.id);
        break;
      case ROW_ITEM_TYPES.KPI:
        perspectiveItem = yield call(scorecardService.getKpiById, item.id);
        break;
      case ROW_ITEM_TYPES.PROJECT:
        perspectiveItem = yield call(scorecardService.getProjectById, item.id);
        break;
      case ROW_ITEM_TYPES.ACTION:
        perspectiveItem = yield call(scorecardService.getActionById, item.id);
        break;
      default:
        return;
    }
    yield put(setItemAndParentForAction({parent: getItemParent(perspectiveItem), item: perspectiveItem}));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* rightSidebarSubmitFormSagaFn({values, action, itemType, item, scorecardId, closeSidebar}) {
  try {
    yield put(setLoader(true));
    const data = {
      ...values,
      owners: values.owners.length > 0 ? values.owners.map((member) => member.id) : []
    };

    switch (action) {
      case ACTIONS.ADD:
        switch (itemType) {
          case ROW_ITEM_TYPES.GOAL:
            yield call(scorecardService.createGoal, data);
            break;
          case ROW_ITEM_TYPES.KPI:
            yield call(perspectiveService.addNewKPI, values);
            break
          case ROW_ITEM_TYPES.PROJECT:
            yield call(perspectiveService.addNewProject, values);
            break;
          case ROW_ITEM_TYPES.ACTION:
            yield call(perspectiveService.addNewAction, values);
            break;
          default:
            console.log('no matching type selected');
        }

        break;
      case ACTIONS.EDIT:
        switch (itemType) {
          case ROW_ITEM_TYPES.GOAL:
            yield call(scorecardService.updateGoal, data, item.id);
            break;
          case ROW_ITEM_TYPES.KPI:
            yield call(perspectiveService.editKpi, values);
            break
          case ROW_ITEM_TYPES.PROJECT:
            yield call(scorecardService.updateProject, values);
            break;
          case ROW_ITEM_TYPES.ACTION:
            yield call(perspectiveService.editAction, values);
            break;
          default:
            console.log('no matching type selected');
        }
        break;
      default:
        console.log('no matching action selected');
    }

    const rightModal = yield select(rightModalSelector());
    yield put(refreshPageSaga({
      page: rightModal.page,
      scorecardId: rightModal.scorecardId,
      goalId: rightModal.goalId,
      kpiId: rightModal.kpiId,
      projectId: rightModal.projectId,
      actionId: rightModal.actionId,
    }));

    const terminology = yield select(terminologySelector());
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${values.name} ${terminology[itemType.toLowerCase()]} successfully ${action === ACTIONS.ADD ? 'created!' : 'edited!'}`,
    }));
    if (closeSidebar) {
      yield put(rightModalOpen({
        isVisible: false,
        component: null,
        page: null,
      }));
    } else {
      yield put(rightSidebarBack());
    }
  } catch (error) {
    yield put(setSnackbar({
      open: true,
      severity: 'error',
      message: error?.response?.data?.message === "Invalid JIRA issue link" ? error?.response?.data?.message : 'Something went wrong!',
    }));
  } finally {
    yield put(setLoader(false));
  }
}

export function* rightSidebarOnActionClickSagaFn({item, action, component}) {
  try {
    yield put(setLoader(true));
    switch (action) {
      case ACTIONS.ADD:
        yield put(setItemAndParentForAction({item: null, parent: item}));
        break;
      case ACTIONS.EDIT:
        let selectedItem;
        switch (item.type) {
          case ROW_ITEM_TYPES.GOAL:
            selectedItem = yield call(goalService.getGoalWithItems, item.id);
            break;
          case ROW_ITEM_TYPES.PROJECT:
            selectedItem = yield call(projectService.getProjectById, item.id);
            break;
          case ROW_ITEM_TYPES.KPI:
            selectedItem = yield call(scorecardService.getKpiById, item.id);
            break;
          case ROW_ITEM_TYPES.ACTION:
            selectedItem = yield call(scorecardService.getActionById, item.id);
            break;
          default:
            return <div />;
        }
        yield put(setItemAndParentForAction({parent: getItemParent(selectedItem), item: selectedItem}));
        break;
      default:
        console.log('no matching action selected');
    }

    const rightModal = yield select(rightModalSelector());
    yield put(rightModalOpen({
      isVisible: true,
      component,
      page: rightModal.page,
      goalId: rightModal.goalId,
      scorecardId: rightModal.scorecardId,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* rightSidebarBackFn() {
  try {
    let sidebarComponent;
    const currentItemRow = yield select(itemRowSelector());
    let item;
    switch (currentItemRow.type) {
      case ROW_ITEM_TYPES.GOAL:
        item = yield call(goalService.getGoalWithItems, currentItemRow.id);
        sidebarComponent = (
            <SidebarGoalComponent />
        );
        break;
      case ROW_ITEM_TYPES.KPI:
        item = yield call(scorecardService.getKpiById, currentItemRow.id);
        sidebarComponent = (
          <SidebarKpiComponent />
        );
        break;
      case ROW_ITEM_TYPES.PROJECT:
        item = yield call(projectService.getProjectById, currentItemRow.id);
        sidebarComponent = (
          <SidebarProjectComponent />
        );
        break;
      case ROW_ITEM_TYPES.ACTION:
        item = yield call(scorecardService.getActionById, currentItemRow.id);
        sidebarComponent = (
          <SidebarActionComponent />
        );
        break;
      default:
        console.log('no matching type selected');
    }
    yield put(setItemAndParentForAction({parent: getItemParent(item), item: item}));

    const rightModal = yield select(rightModalSelector());
    yield put(rightModalOpen({
      isVisible: true,
      component: sidebarComponent,
      page: rightModal.page,
      scorecardId: rightModal.scorecardId,
      goalId: rightModal.goalId,
      kpiId: rightModal.kpiId,
      projectId: rightModal.projectId,
      actionId: rightModal.actionId,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* backFromAddTrackingFn() {
  try {
    let sidebarComponent;
    const currentItemRow = yield select(itemRowSelector());
    const kpi = yield call(scorecardService.getKpiById, currentItemRow.id);
    yield put(setItemAndParentForAction({parent: kpi.goal, item: kpi}));
    sidebarComponent = (
        <SidebarKpiComponent />
    );
    const rightModal = yield select(rightModalSelector());
    yield put(rightModalOpen({
      isVisible: true,
      component: sidebarComponent,
      page: rightModal.page,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}
export function* addTrackingAndBackSagaFn({payload}) {
  try {
    const strategyPlanSelector = yield select(strategyPlanWithPerspectivesSelector())
    yield put(setLoader(true));
    yield call(scorecardService.addTrackingForKpi,payload.id, payload);
    const rightModal = yield select(rightModalSelector());
    yield put(refreshPageSaga({page: rightModal.page, scorecardId:strategyPlanSelector?.id}));
    yield put(rightSidebarBack())
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* addActionSagaFn({payload}) {
  try {
    yield call(perspectiveService.addNewAction, payload);
    yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
    const terminology = yield select(terminologySelector());
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${terminology[payload.type.toLowerCase()]} successfully created`,
    }));
    yield put(rightSidebarBack())
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setLoader(false));
  }
}

export function* rightSidebarDeleteItemSagaFn({item, scorecardId}) {
  try {
    yield put(setLoader(true));
    switch (item.type) {
      case ROW_ITEM_TYPES.GOAL:
        yield call(scorecardService.deleteGoal, item.id);
        break;
      case ROW_ITEM_TYPES.KPI:
        yield call(scorecardService.deleteKpi, item.id);
        break;
      case ROW_ITEM_TYPES.PROJECT:
        yield call(scorecardService.deleteProject, item.id);
        break;
      case ROW_ITEM_TYPES.ACTION:
        yield call(scorecardService.deleteAction, item.id);
        break;
      default:
        console.log('no matching type selected');
    }
    yield put(setSnackbar({
      open: true,
      severity: 'success',
      message: `${item.name} ${item.type} was successfully deleted!`,
    }))
    yield put(setActionModalFromNavbar({
      isVisible: false,
      title: '',
      component: null,
    }));
    yield put(setModal({
      isVisible: false,
      component: null,
      title: '',
    }));
    const rightModal = yield select(rightModalSelector());
    yield put(refreshPageSaga({page: rightModal.page, scorecardId, goalId: rightModal.goalId}));
    yield put(rightModalOpen({
      isVisible: false,
      component: null,
      page: null,
    }));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* closeSidebarSagaFn() {
  try {
    yield put(setLoader(true));
    yield put(rightModalOpen({
      isVisible: false,
      component: null,
      page: null,
    }));
    yield put(setItemAndParentForAction({parent: null, item: null}));
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}
export function* saveReorderRowItemSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield call(scorecardService.reorderRowItems, {item: payload})
    yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
    yield put(setItemSortActive(false))
    console.log('payload', payload);
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export function* getEditParentDataSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    let res;
    let options;
    switch (payload.type) {
      case ROW_ITEM_TYPES.GOAL:
        break
      case ROW_ITEM_TYPES.PROJECT:
      case ROW_ITEM_TYPES.KPI:
        res = yield call(goalService.getGoalsByOrganization)
        break
      case ROW_ITEM_TYPES.ACTION:
        if (payload.goalId) {
          res = yield call(goalService.getGoalsByOrganization)

        } else {
          res = yield call(projectService.getProjectsByOrganization)
        }
        break
      default:
        break
    }
    options = res?.map((item) => {
      return {...item, subtitle: getScorecardName(item)}
    })
    yield put(setOptionsForEditParent(options))
    yield put(setShowEditParent(true))

  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}
export function* moveItemFromEditParentSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    const itemWithParent = yield select(itemAndParentForActionSelector())
    let data;
    let newItem;
    switch (payload.item.type) {
      case ROW_ITEM_TYPES.GOAL:
        break
      case ROW_ITEM_TYPES.PROJECT:
        data = {
          goalId: payload.parent.id,
        };
        yield call(projectService.moveProject, data, payload.item.id);
        newItem = yield call(projectService.getProjectById, itemWithParent.item.id);
        break
      case ROW_ITEM_TYPES.KPI:
        data = {
          goalId: payload.parent.id,
        };
        yield call(kpiService.moveKpi, data, payload.item.id);
        newItem = yield call(scorecardService.getKpiById, itemWithParent.item.id);
        break
      case ROW_ITEM_TYPES.ACTION:
        data = {
          parentId: payload.parent.id,
          parentType: payload.parent.type,
        };
        yield call(actionService.moveAction, data, payload.item.id);
        newItem = yield call(scorecardService.getActionById, itemWithParent.item.id);

        break
      default:
        break
    }
    yield put(setItemAndParentForAction({item: newItem, parent: payload.parent}));
    yield put(refreshPageSaga({page: payload.page, scorecardId: payload.strategyPlan.id}));
    yield put(setShowEditParent(false))

  } catch (error) {
    console.log(error)
  } finally {
    yield put(setLoader(false));
  }
}

export default function* strategyPlanSaga() {
  yield takeLatest(ADD_ACTION_SAGA, addActionSagaFn);
  yield takeLatest(ON_REORDER_SAVE_PERSPECTIVE_SAGA, onReorderPerspectiveSaveSagaFn);
  yield takeLatest(EXPAND_PERSPECTIVE_SAGA, expandPerspectiveSagaFn);
  yield takeLatest(REORDER_PERSPECTIVE_SAGA, reorderPerspectiveSagaFn);
  yield takeLatest(REORDER_PERSPECTIVE_ITEM, reorderPerspectiveItemSagaFn);
  yield takeLatest(REORDER_ROW_ITEM, reorderRowItemSagaFn);
  yield takeLatest(ADD_TRACKING_AND_BACK_SAGA, addTrackingAndBackSagaFn);
  yield takeLatest(BACK_FROM_ADD_TRACKING_SAGA, backFromAddTrackingFn);
  yield takeLatest(RIGHT_SIDEBAR_BACK, rightSidebarBackFn);
  yield takeLatest(GET_STRATEGY_PLAN_SAGA, getStrategyPlanSagaFn);
  yield takeLatest(ADD_ACTION_FROM_SIDEBAR_SAGA, addActionFromSidebarSagaFn);
  yield takeLatest(ADD_TRACKING_FROM_SIDEBAR_SAGA, addTrackingFromSidebarSagaFn);
  yield takeLatest(ADD_REMOVE_NEW_EMPTY_PERSPECTIVE_SAGA, addEmptyPerspectiveSaga);
  yield takeLatest(ADD_NEW_EMPTY_ITEM_PERSPECTIVE_SAGA, addEmptyItemPerspectiveSaga);
  yield takeLatest(ASSIGN_TO_GROUP_SAGA, assignToGroup);
  yield takeLatest(DELETE_PERSPECTIVE, deletePerspectiveSaga);
  yield takeLatest(DELETE_GOAL, deleteGoalSaga);
  yield takeLatest(DELETE_KPI, deleteKpiSagaFn);
  yield takeLatest(DELETE_ACTION, deleteActionSagaFn);
  yield takeLatest(UPDATE_GOAL, updateGoalSaga);
  yield takeLatest(DELETE_PROJECT, deleteProjectSagaFn);
  yield takeLatest(RIGHT_SIDEBAR_SAGA, rightSidebarSagaFn);
  yield takeLatest(GET_RIGHT_SIDEBAR_ITEM_SAGA, getRightSidebarItemSagaFn);
  yield takeLatest(RIGHT_SIDEBAR_SUBMIT_FORM_SAGA, rightSidebarSubmitFormSagaFn);
  yield takeLatest(RIGHT_SIDEBAR_ON_ACTION_CLICK_SAGA, rightSidebarOnActionClickSagaFn);
  yield takeLatest(RIGHT_SIDEBAR_DELETE_ITEM_SAGA, rightSidebarDeleteItemSagaFn);
  yield takeLatest(CLOSE_SIDEBAR_SAGA, closeSidebarSagaFn);
  yield takeLatest(GET_EDIT_PARENT_DATA_SAGA, getEditParentDataSagaFn);
  yield takeLatest(MOVE_ITEM_FROM_EDIT_PARENT_SAGA, moveItemFromEditParentSagaFn);
  yield takeLatest(DELETE_PERSPECTIVE_FROM_WIDGET, deletePerspectiveFromWidgetSaga);
  yield takeLatest(SAVE_REORDER_ROW_ITEM, saveReorderRowItemSagaFn);
}
