import React from 'react';
import styles from "../../MovePerspectiveItem/RemoveLinkedItemsContent.module.scss";
import CustomModalFooter from "./CustomModalFooter";
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning.svg';
import {COLORS} from "../../../constants/colors";
import DefaultButton from "../Buttons/DefaultButton";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

const WarningModalContent = ({
                               confirmAction,
                               closeModal,
                               title,
                               description,
                               warningModal,
                             }) => {
  const terminology = useSelector(terminologySelector());
  const footerConfig = [
    {id: 1, title: 'Cancel', secondary: true, onClick: closeModal, type: 'button'},
    {id: 2, title: 'Delete', onClick: confirmAction, type: 'button', backgroundColor: COLORS.red},
  ];

  const secondaryFooterConfig = [
    {id: 1, title: 'Ok', secondary: true, onClick: closeModal, type: 'button'},
  ];

  const content = () => {
    switch (true) {
      case warningModal.cannotBeDeleted:
        return (
          <div>
            <div className={styles.descriptionContainer}>
              <h2 className={styles.title}>Cannot Delete {terminology.plan}</h2>
              <p>This {terminology.plan} cannot be deleted until all contribution {terminology.goal}s are removed:</p>
              <ul className={styles.list}>
                <li>It contains {terminology.goal}s that contribute to other {terminology.plan}s.</li>
                <li>There are {terminology.goal}s that contributes to this {terminology.plan}.</li>
              </ul>
              <p>Please delete all {terminology.goal}s before attempting to delete this {terminology.plan}.</p>
            </div>
            <CustomModalFooter config={secondaryFooterConfig} />
          </div>
        );
      case warningModal.scorecardHasLinks:
        return (
          <div>
            <div className={styles.descriptionContainer}>
              <h2 className={styles.title}>Confirmation Required</h2>
              <p>Are you sure you want to delete this {terminology.plan}? This action cannot be undone. Please note:</p>
              <ul className={styles.list}>
                <li>This {terminology.plan} may contain {terminology.goal}s.</li>
                <li>This {terminology.plan} might have a parent {terminology.plan}.</li>
                <li>This {terminology.plan} could be a parent to other {terminology.plan}s.</li>
              </ul>
              <p>Deleting this {terminology.plan} will also remove any linked {terminology.goal}s and may impact related {terminology.plan}s.</p>
            </div>
            <CustomModalFooter config={footerConfig} />
          </div>
        );
      default:
        return (
          <div>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.descriptionContainer}>
              {description && description.map((paragraph, index) => {
                return (
                  <p className={styles.description} key={index}>{paragraph}</p>
                );
              })}
            </div>
            <CustomModalFooter config={footerConfig} />
          </div>
        );
    }
  };

  return (
    <div className={styles.container}>
      <WarningIcon width={48} height={48} />
      {content()}
    </div>
  );
};

export default WarningModalContent;
