import React, {memo, useEffect, useState} from 'react';
import {Autocomplete, Box, styled, TextField, Typography} from '@mui/material';
import styles from './AutocompleteField.module.scss';
import {COLORS} from "../../../constants/colors";

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 4px ${COLORS.transparentDarkBlue} solid;
    outline: none;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    padding-top: 4px;
    font-size: 12px;
    color: ${COLORS.lighterRed};
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {
    height: 32px;
  }
`;

const AutocompleteField = ({setFieldValue, field, errors, ...props}) => {
  const [options, setOptions] = useState([]);
  const getDataForSelect = async () => {
    const methodProperties = props.options.apiServiceMethodProperties || [];
    const res = await props.options.apiService[
      props.options.apiServiceMethod
      ](...(methodProperties || []));
    const filteredOptions = res.map((item) => {
      return {
        id: item.id,
        value: item.id,
        title: item[props.options.titleKey],
        subtitle: item[props.options.subtitleKey],
        image: item[props.options.imageKey],
        disabled: item[props.options.disabledKey]
      };
    });
    setOptions(filteredOptions);
  };

  useEffect(() => {
    if (props.options.apiService) {
      getDataForSelect();
    }
  }, [props.options.apiService]);

  useEffect(() => {
    if (props.options.options) {
      setOptions(props.options.options);
    }
  }, [props.options.options]);

  const handleChange = (event, value) => {
    setFieldValue(field.name, value);
  };

  const filterOptions = (options, { inputValue }) =>
    options.filter(option =>
      option.title?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.subtitle?.toLowerCase().includes(inputValue.toLowerCase())
    );

  const isOptionEqualToValue = (option, value) => option.id === value.id;

  const getOptionLabel = (option) => option.name || '';

  const getOptionDisabled = (option) => option.disabled;

  const renderInput = (params) => {
    return (
      <StyledTextField
        {...params}
        label={props.placeholder.value}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password'
        }}
      />
    );
  };

  const renderOption = (renderProps, option, index) => {
    return (
      <li {...renderProps} key={`item-${option.id}-${index}`}>
        <div className={styles.itemContainer}>
          <div className={styles.itemLeftContainer}>
            {props.options.imageKey && (
              <img src={option.image || props.options.defaultImage} className={styles.tagImage} alt={props.options.imageKey} />
            )}
            <div className={styles.titleContainer}>
              <h4 className={styles.title}>{option.title}</h4>
              {props.options.subtitleKey && (
                <h4 className={styles.subtitle}>{option.subtitle}</h4>
              )}
            </div>
          </div>
          {option.disabled && (
            <h4 className={styles.additionalText}>{props.options.disabledText}</h4>
          )}
        </div>
      </li>
    );
  };

  const renderTags = (value, getTagProps) => {
    return (
      value.map((option, index) => {
        return (
          <button className={styles.tagContainer} onClick={() => getTagProps({index}).onDelete(index)} key={`item-${option.id}-${index}`} type={'button'}>
            {props.options.imageKey && (
              <img src={option.image || props.options.defaultImage} className={styles.tagImage} alt={props.options.imageKey} />
            )}
            <div>
              <h4 className={styles.title}>{option.title}</h4>
              {props.options.subtitleKey && (
                <h4 className={styles.subtitle}>{option.subtitle}</h4>
              )}
            </div>
          </button>
        );
      })
    );
  };

  return (
    <div>
      <div className={styles.labelContainer}>
        {props.label}
      </div>
      <Autocomplete
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        filterOptions={filterOptions}
        fullWidth
        multiple
        limitTags={2}
        options={options}
        value={field?.value?.length ? field.value : []}
        onChange={handleChange}
        renderInput={renderInput}
        renderOption={renderOption}
        renderTags={renderTags}
      />
      <StyledBox>
        {errors[field?.name] && (
          <StyledTypography>
            {errors[field?.name]}
          </StyledTypography>
        )}
      </StyledBox>
    </div>
  );
};

export default memo(AutocompleteField);
