import produce from 'immer';
import {

} from './actionTypes';

export const initialState = {
  checklist: null,
};

/* eslint-disable default-case */
const checklistReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {

    }
  });

export default checklistReducer;
