import {formatUnit} from "../../../../utils/perspective";
import {FORMAT_METRIC_DETAILS} from "../../../../utils/metrics";

export const METRIC_DETAILS_LIST = (item) => {
  return [
    {id: 1, title: 'Measurement', value: formatUnit(item?.unit, true)},
    {id: 2, title: 'Input Format', value: FORMAT_METRIC_DETAILS(item?.inputFormat)},
    {id: 3, title: 'Total Type', value: FORMAT_METRIC_DETAILS(item?.totalType)},
    {id: 4, title: 'Time Interval', value: FORMAT_METRIC_DETAILS(item?.timeInterval)},
    {id: 5, title: 'Owner', value: FORMAT_METRIC_DETAILS(item?.owner?.name)},
    {id: 6, title: 'Trend', value: FORMAT_METRIC_DETAILS(item?.trend)},
    {id: 7, title: 'Metric Type', value: FORMAT_METRIC_DETAILS(item?.type)},
  ];
};
