import produce from 'immer';
import {
  SET_DASHBOARDS_GRID,
  SET_VISIBILITY_DASHBOARD_ACTION_MODAL, SET_SHARE_DASHBOARD_MODAL, SET_DASHBOARD, SET_CHART_DETAILS
} from './actionTypes';

export const initialState = {
  dashboardsGrid: {
    data: null,
    perPage: 10,
    page: 1,
    total: 0,
    maxPageNumbers: 1,
  },
  dashboardActionModal: {
    isVisible: false,
    title: '',
    maxWidth: 'md',
    component: null,
  },
  shareDashboardModal: {
    isVisible: false,
  },
  dashboard: null,
  chartDetails: null,
};

/* eslint-disable default-case */
const dashboardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_DASHBOARDS_GRID:
        draft.dashboardsGrid = {
          perPage: action.payload.perPage,
          page: action.payload.page,
          total: action.payload.total,
          maxPageNumbers: Math.ceil(action.payload.total / action.payload.perPage),
          data: action.payload.data,
        };
        break;
      case SET_VISIBILITY_DASHBOARD_ACTION_MODAL:
        draft.dashboardActionModal = action.payload;
        break;
      case SET_SHARE_DASHBOARD_MODAL:
        draft.shareDashboardModal = action.payload;
        break;
      case SET_DASHBOARD:
        draft.dashboard = action.dashboard;
        break;
      case SET_CHART_DETAILS:
        draft.chartDetails = action.payload;
        break;
    }
  });

export default dashboardsReducer;
