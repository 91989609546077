import {Role} from '../constants/strings';

export const getUsersByRole = (users = [], role = '') => {
  if (role === Role.OWNER) {
    return users?.find((user) => user.role === role);
  }
  return users?.filter((user) => user.role === role);
};

export const getItemOwner = (users, ownerId) => {
  if (ownerId && users){
  return users.find(user => user.id === ownerId);
  }
}

export const sortByNameOrEmail = (a, b) => {
  if (a.name) {
    return a.name?.localeCompare(b.name)
  } else {
    return a.email?.localeCompare(b.email)
  }
}

export const setSharingWithEntityPermissionForSelect = (user) => {
  switch (true) {
    case user.canWrite:
      return 1;
    case user.canRead:
      return 2;
    default:
      return 3;
  }
};


export const setSharingWithEntityPermissionForFormData = (value) => {
  switch (value) {
    case 1:
      return {
        canWrite: true,
        canRead: true,
      };
    case 2:
      return {
        canWrite: false,
        canRead: true,
      };
    default:
      return {
        canWrite: false,
        canRead: false,
      };
  }
};

export const getSharingWithEntityPermissionForSelect = (hasNoAccess) => {
  return [
    {id: 1, name: 'Edit', value: 1},
    {id: 2, name: 'View', value: 2},
    ...hasNoAccess ? [{id: 3, name: 'no access', value: 3}] : [],
  ];
};
