import produce from 'immer';

import {
    SET_FOUNDATION,
    SET_LOG_TYPE_ACTIVITY,
    SET_ORGANIZATION_ACTIVITY,
    SET_USER_ACTIVITY,
    SET_MODAL,
    SET_SETTINGS_THEMES,
    SET_ACTION_MODAL_FROM_NAVBAR,
    RIGHT_MODAL_OPEN,
    SET_ITEM_AND_PARENT_FOR_ACTION,
    SET_WARNING_MODAL,
    SET_COMMENTS,
    SET_REFRESH,
    SET_IS_DELETE_MODAL_VISIBLE,
    SET_UPDATES,
    SET_DELETE_MODAL,
    UPDATE_GROUP_MODAL_SHOW,
    SET_GROUP_UPDATES,
    SET_ADDITIONAL_MODAL,
} from './actionTypes';

export const initialState = {
    foundation: null,
    organizationActivity: {
        data: [],
        perPage: 10,
        page: 1,
        total: 0,
        isLoading: true
    },
    logTypeActivity: {
        data: [],
        perPage: 10,
        page: 1,
        total: 0,
        isLoading: true
    },
    userActivity: {
        data: [],
        perPage: 10,
        page: 1,
        total: 0,
        isLoading: true
    },
    modal: {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null
    },
    modalGroupUpdate: {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null
    },
    warningModal: {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null,
    },
    actionModalFromNavbar: {
        isVisible: false,
        title: '',
        maxWidth: 'md',
        component: null
    },
    themes: [],
    rightModal: {
        isVisible: false,
        component: (<div></div>),
        page: null,
    },
    itemAndParentForAction: {
        parent: null,
        item: null
    },
    comments: [],
    refresh: null,
    isDeleteModalVisible: false,
    updates: null,
    deleteModal: {
        isVisible: false,
    },
    groupUpdates: [],
    additionalModal: {
        isVisible: false,
        title: '',
        maxWidth: 'sm',
        component: null,
    },
};

/* eslint-disable default-case */

const helperReducer = (state = initialState, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case SET_FOUNDATION:
                draft.foundation = payload;
                break;
            case SET_GROUP_UPDATES:
                draft.groupUpdates = payload;
                break;
            case RIGHT_MODAL_OPEN:
                draft.rightModal = payload;
                break;
            case UPDATE_GROUP_MODAL_SHOW:
                draft.modalGroupUpdate = payload;
                break;
            case SET_ORGANIZATION_ACTIVITY:
                draft.organizationActivity = {
                    perPage: payload.perPage,
                    page: payload.page,
                    total: payload.total,
                    data:
                        Number(payload.page) === 1
                            ? payload.data
                            : [...draft.organizationActivity.data, ...payload.data],
                    isLoading: false,
                };
                break;
            case SET_LOG_TYPE_ACTIVITY:
                draft.logTypeActivity = {
                    perPage: payload.perPage,
                    page: payload.page,
                    total: payload.total,
                    data:
                        payload.page === 1
                            ? payload.docs
                            : [...draft.logTypeActivity.data, ...payload.data],
                    isLoading: false,
                };
                break;
            case SET_USER_ACTIVITY:
                draft.userActivity = {
                    perPage: payload.perPage,
                    page: payload.page,
                    total: payload.total,
                    data:
                        payload.page === 1
                            ? payload.docs
                            : [...draft.userActivity.data, ...payload.data],
                    isLoading: false,
                };
                break;
            case SET_MODAL:
                draft.modal = payload;
                break;
            case SET_WARNING_MODAL:
                draft.warningModal = payload;
                break;
            case SET_SETTINGS_THEMES:
                draft.themes = payload;
                break;
            case SET_ACTION_MODAL_FROM_NAVBAR:
                draft.actionModalFromNavbar = payload;
                break;
            case SET_ITEM_AND_PARENT_FOR_ACTION:
                draft.itemAndParentForAction = payload;
                break;
            case SET_COMMENTS:
                draft.comments = payload;
                break;
            case SET_REFRESH:
                draft.refresh = payload;
                break;
            case SET_IS_DELETE_MODAL_VISIBLE:
                draft.isDeleteModalVisible = payload;
                break;
            case SET_UPDATES:
                draft.updates = payload;
                break;
            case SET_DELETE_MODAL:
                draft.deleteModal = payload;
                break;
            case SET_ADDITIONAL_MODAL:
                draft.additionalModal = payload;
                break;
        }
    })

export default helperReducer;
