import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  GET_GOALS_ALIGNMENT_SAGA, GET_GOALS_ALIGNMENT_WITH_FILTERS_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import alignmentService from "../../services/AlignmentService";
import {
  setAlignedGoalsAlignmentNodes, setAllGoalsAlignmentNodes,
  setGoalsAlignmentEdges,
  setGoalsAlignmentFilters, setUnalignedGoalsAlignmentNodes,
} from "./actions";
import {
  alignedGoalsAlignmentNodesSelector, allGoalsAlignmentNodesSelector,
  goalsAlignedAlignmentNodesSelector,
  goalsAlignmentFiltersSelector
} from "./selectors";
import {createGoalsAlignmentNodes, getHighlightedGoals} from "../../utils/alignment";

export function* getGoalsAlignmentSagaFn() {
  try {
    yield put(setLoader(true));
    const goalsAlignmentFilters = yield select(goalsAlignmentFiltersSelector());
    const goalsAlignment = yield call(alignmentService.getGoalsAlignment);

    yield put(setAlignedGoalsAlignmentNodes(getHighlightedGoals(createGoalsAlignmentNodes(goalsAlignment, 'alignedGoals'), goalsAlignmentFilters)));
    yield put(setAllGoalsAlignmentNodes(getHighlightedGoals(createGoalsAlignmentNodes(goalsAlignment, 'goals'), goalsAlignmentFilters)));
    yield put(setUnalignedGoalsAlignmentNodes(getHighlightedGoals(createGoalsAlignmentNodes(goalsAlignment, 'unalignedGoals'), goalsAlignmentFilters)));
    yield put(setGoalsAlignmentEdges(goalsAlignment.goalEdges));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* getGoalsAlignmentWithFiltersSagaFn({payload}) {
  try {
    yield put(setLoader(true));
    yield put(setGoalsAlignmentFilters(payload));
    const alignedGoalsAlignmentNodes = yield select(alignedGoalsAlignmentNodesSelector());
    const allGoalsAlignmentNodes = yield select(allGoalsAlignmentNodesSelector());
    yield put(setAllGoalsAlignmentNodes(getHighlightedGoals(allGoalsAlignmentNodes, payload)));
    yield put(setAlignedGoalsAlignmentNodes(getHighlightedGoals(alignedGoalsAlignmentNodes, payload)));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* alignmentsSaga() {
  yield takeLatest(GET_GOALS_ALIGNMENT_SAGA, getGoalsAlignmentSagaFn);
  yield takeLatest(GET_GOALS_ALIGNMENT_WITH_FILTERS_SAGA, getGoalsAlignmentWithFiltersSagaFn);
}
