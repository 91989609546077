import React from 'react';
import styles from './MetricsSidebarHeader.module.scss';
import {COLORS} from "../../../constants/colors";
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {ReactComponent as MetricsIcon} from '../../../assets/icons/metrics.svg';
import {ACTIONS} from "../../../constants/strings";
import CustomMenu from "../../../components/Shared/Menu/CustomMenu";
import {MoreVert} from "@mui/icons-material";
import {HEADER_MENU_ITEMS} from "./menuItems";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

const MetricsSidebarHeader = ({item, closeSidebar, onMenuItemClick}) => {
  const terminology = useSelector(terminologySelector());

  return (
    <div className={styles.container}>
      <div className={styles.flexAlignCenter}>
        <MetricsIcon color={COLORS.darkestGray} />
        <h2 className={styles.title}>{item?.name}</h2>
      </div>
      <div className={styles.flexAlignCenter}>
        <button className={styles.button} onClick={() => onMenuItemClick(item, ACTIONS.EDIT)}>
          <EditIcon color={COLORS.darkestGray} />
        </button>
        <div className={styles.menuContainer}>
          <CustomMenu
            button={(
              <MoreVert style={{color: COLORS.darkestGray}} />
            )}
            items={HEADER_MENU_ITEMS({terminology})}
            onClick={(menuItem) => onMenuItemClick(item, menuItem.action)}
          />
        </div>
        <button className={styles.button} onClick={closeSidebar}>
          <CloseIcon color={COLORS.darkestGray} />
        </button>
      </div>
    </div>
  );
};

export default MetricsSidebarHeader;
