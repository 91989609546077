import React, {useCallback, useEffect, useState} from 'react';
import styles from './CriteriaTab.module.scss';
import {ReactComponent as ScalesIcon} from '../../../../assets/icons/scales.svg';
import CriteriaTabListItem from "./CriteriaTabListItem";
import OutlinedSelectInput from "../../../Shared/Inputs/OutlinedSelectInput";
import PopupComponent from "../../../Shared/Popup/PopupComponent";
import {menuConfig} from "../../menuConfig";
import {ACTIONS, ROW_ITEM_TYPES, WEIGHT_TYPE} from "../../../../constants/strings";
import goalService from "../../../../services/GoalService";
import {refreshPageSaga, setItemAndParentForAction} from "../../../../store/helper/actions";
import {useDispatch, useSelector} from "react-redux";
import {itemRowSelector, strategyPlanSelector} from "../../../../store/strategyPlan/selectors";
import {getItemParent} from "../../../../utils/perspective";
import {rightModalSelector} from "../../../../store/helper/selectors";
import {terminologySelector} from "../../../../store/auth/selectors";

const CriteriaTab = ({data, onAddSuccessCriteria, page}) => {
  const dispatch = useDispatch();
  const [selectedItemType, setSelectedItemType] = useState('');
  const [selectedWeightType, setSelectedWeightType] = useState(data.weightType);
  const [weighingCount, setWeighingCount] = useState(0);

  const itemRow = useSelector(itemRowSelector());
  const strategyPlan = useSelector(strategyPlanSelector());
  const rightModal = useSelector(rightModalSelector());
  const terminology = useSelector(terminologySelector());

  const typeOptions = [
    {id: 1, value: 1, name: `${terminology.goal}s`, type: ROW_ITEM_TYPES.GOAL},
    {id: 2, value: 2, name: `${terminology.kpi}s`, type: ROW_ITEM_TYPES.KPI},
    {id: 3, value: 3, name: `${terminology.project}s`, type: ROW_ITEM_TYPES.PROJECT},
    {id: 4, value: 4, name: `${terminology.action}s`, type: ROW_ITEM_TYPES.ACTION},
  ];

  const selectedTypeOption = typeOptions.find((option) => option.value === selectedItemType);

  const weighingOptions = [
    {id: 1, value: WEIGHT_TYPE.ALL, name: 'All Criteria Weighing Evenly'},
    {id: 2, value: WEIGHT_TYPE.KPI, name: `By ${terminology.kpi}s`},
    {id: 3, value: WEIGHT_TYPE.ACTION_AND_PROJECT, name: `By ${terminology.action}s & ${terminology.project}s`},
    {id: 4, value: WEIGHT_TYPE.GOALS, name: `By ${terminology.goal}s`},
    {id: 5, value: WEIGHT_TYPE.CUSTOM, name: 'Custom Weighing'},
  ];

  const handleEditItem = (item) => {
    onAddSuccessCriteria(item, ACTIONS.EDIT);
  };

  const handleShowItem = (item) => {
    onAddSuccessCriteria(item, ACTIONS.VIEW_ITEM);
  };

  const handleTypeChange = (e) => {
    setSelectedItemType(e.target.value);
  };

  const handleWeighingChange = useCallback(async (e) => {
    try {
      const weightTypeData = {
        weightType: e.target.value,
      };
      await goalService.updateGoalWeightType(weightTypeData, data.id);
      const goal = await goalService.getGoalWithItems(data?.id);

      dispatch(setItemAndParentForAction({
        item: goal,
        parent: getItemParent(goal),
      }));
      dispatch(refreshPageSaga({
        page,
        scorecardId: strategyPlan?.id,
        goalId: rightModal.goalId,
        parentId: data?.id,
        entity: rightModal.entity,
        entityId: rightModal.entityId,
      }));

      setSelectedWeightType(e.target.value);
    } catch (error) {
      console.error({error});
    }
  }, [data.id, dispatch, page, strategyPlan?.id, rightModal.goalId, rightModal.entity, rightModal.entityId]);

  const showWeighing = (item) => {
    return (
      (selectedWeightType === WEIGHT_TYPE.ACTION_AND_PROJECT && (item.type === ROW_ITEM_TYPES.ACTION || item.type === ROW_ITEM_TYPES.PROJECT)) ||
      (selectedWeightType === WEIGHT_TYPE.GOALS && item.type === ROW_ITEM_TYPES.GOAL) ||
      (selectedWeightType === WEIGHT_TYPE.KPI && item.type === ROW_ITEM_TYPES.KPI) ||
      (selectedWeightType === WEIGHT_TYPE.ALL || selectedWeightType === WEIGHT_TYPE.CUSTOM)
    );
  };

  useEffect(() => {
    if (data.items?.length > 0) {
      setWeighingCount(data.items.filter((item) => item.weighed)?.length);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <div className={styles.fieldContainer}>
          <OutlinedSelectInput
            options={typeOptions}
            value={selectedItemType}
            handleChange={handleTypeChange}
            placeholder={'All'}
            valueKey={'value'}
          />
        </div>
        <PopupComponent
          config={menuConfig({
            onClick: onAddSuccessCriteria,
            item: data,
            button: true,
            terminology,
          })}
        />
      </div>
      <div className={styles.selectContainer}>
        <div className={styles.addSuccessCriteriaButtonContainer}>
          <div className={styles.flexItems}>
            <ScalesIcon width={32} height={32} />
            <div className={styles.weighingLeftContainer}>
              <h4>Weighing</h4>
              <h5>{weighingCount} of {data.items?.length}</h5>
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <OutlinedSelectInput
            options={weighingOptions}
            value={selectedWeightType}
            handleChange={handleWeighingChange}
            valueKey={'value'}
          />
        </div>
      </div>
      <div className={styles.listContainer}>
        {data.items?.length > 0 && data.items.filter((item) => !selectedItemType || item.type === selectedTypeOption?.type).map((item) => {
          return (
            <CriteriaTabListItem
              parent={data}
              item={item}
              itemRow={itemRow}
              onEditItem={handleEditItem}
              onShowItem={handleShowItem}
              key={item.type + item.id}
              weighing={selectedWeightType}
              showWeighing={showWeighing(item)}
              scorecardId={strategyPlan?.id}
              rightModal={rightModal}
              page={page}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CriteriaTab;
