import produce from 'immer';
import { getItem } from '../../utils/localStorage';
import {
  FETCH_AUTHENTICATED_USER_SUCCESS,
  LOGOUT_SUCCESS,
  SET_TOKEN,
  SESSION_EXPIRED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SOCIAL_AUTH_SUCCESS,
  SOCIAL_AUTH_ERROR,
  SET_LOGIN_ERROR,
  SET_USER_JWT_DATA, SET_SIDEBAR_STATE, SET_TWO_FA_ERROR, SET_ORGANIZATION, SET_REFRESH_TOKEN, SET_TERMINOLOGY,
} from './actionTypes';

export const initialState = {
  token: getItem('token') || null,
  refreshToken: getItem('refreshToken') || null,
  isRegistered: false,
  user: null,
  loginError: null,
  userJwtData: null,
  sidebarState: 'show',
  faError: false,
  terminology: {
    plan: 'Strategy',
    perspective: 'Perspective',
    goal: 'Goal',
    project: 'Project',
    action: 'Action',
    kpi: 'KPI',
    nestedGoal: 'Nested Goal',
    sharedGoal: 'Shared Goal',
    contributingGoal: 'contributing Goal',
  }
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.user;
        break;
      case SET_TWO_FA_ERROR:
        draft.faError = action.payload;
        break;
      case LOGOUT_SUCCESS:
        draft.token = null;
        draft.user = null;
        break;
      case FORGOT_PASSWORD_SUCCESS:
        break;
      case FORGOT_PASSWORD_ERROR:
        break;
      case REGISTER_SUCCESS:
        draft.isRegistered = action.payload;
        break;
      case REGISTER_ERROR:
        break;
      case RESET_PASSWORD_SUCCESS:
        break;
      case RESET_PASSWORD_ERROR:
        break;
      case SOCIAL_AUTH_SUCCESS:
        break;
      case SOCIAL_AUTH_ERROR:
        break;
      case SESSION_EXPIRED:
        draft.user = null;
        draft.token = null;
        break;
      case SET_TOKEN:
        draft.token = action.token;
        break;
      case SET_REFRESH_TOKEN:
        draft.refreshToken = action.token;
        break;
      case SET_LOGIN_ERROR:
        draft.loginError = action.error;
        break;
      case SET_USER_JWT_DATA:
        draft.userJwtData = action.data;
        break;
      case SET_SIDEBAR_STATE:
        draft.sidebarState = action.data;
        break;
      case SET_TERMINOLOGY:
        draft.terminology = action.payload;
        break;
    }
  });

export default appReducer;
