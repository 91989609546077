import React from 'react';
import {Route} from 'react-router-dom';
import PrivateRoute from "../PrivateRoute";
import {PublicRoute} from "../PublicRoute";
import {ACCEPT_INVITATION} from "../../routesConstants";

const HybridRoute = ({ user, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            rest.path === ACCEPT_INVITATION ? (
                <Component {...props} />
            ) : user ? (
                <PrivateRoute user={user} component={Component} {...props} />
            ) : (
                <PublicRoute user={user} component={Component} {...props} />
            )
        }
    />
);

export default HybridRoute;
