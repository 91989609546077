import React from 'react';
import styles from './KpisTabComponent.module.scss';
import {Grid} from "@mui/material";
import {ReactComponent as KpiIcon} from '../../../../../src/assets/icons/taskTypes/kpi.svg';
import {ReactComponent as PlusIcon} from '../../../../assets/icons/plus.svg';
import {ReactComponent as ArrowDownIcon} from "./../../../../assets/icons/arrowDown.svg";
import profileImage from "../../../../assets/images/user.png";
import {ItemHealth, ROW_ITEM_TYPES} from "../../../../constants/strings";
import {COLORS} from "../../../../constants/colors";
import {getItemIcon} from "../../../../components/AlignmentComponent/GoalTabView/GoalNode";
import ItemRowProgressComponent from "../../../StrategyPlanPage/ItemRowComponent/Components/ItemRowProgressComponent";
import {updateKpiSaga} from "../../../../store/kpis/actions";
import {useDispatch, useSelector} from "react-redux";
import {CONNECT_METRIC_WITH_KPI, STRATEGY_PLAN} from "../../../../routesConstants";
import {useHistory} from "react-router-dom";
import {rightModalOpen} from "../../../../store/helper/actions";
import {PAGES} from "../../../../constants/pages";
import {rightSidebarSaga} from "../../../../store/strategyPlan/actions";
import {terminologySelector} from "../../../../store/auth/selectors";
import TooltipComponent from "../../../../components/Shared/Tooltip/TooltipComponent";

const KpisTabComponent = ({metric}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const terminology = useSelector(terminologySelector());

    const getStatus = (status) => {
        switch (status) {
            case ItemHealth.AT_RISK:
                return {color: COLORS.lightOrange, title: ItemHealth.AT_RISK, fontColor: COLORS.orange}
            case ItemHealth.LATE:
                return {color: COLORS.lightRed, title: ItemHealth.LATE, fontColor: COLORS.red}
            case ItemHealth.ON_TRACK:
                return {color: COLORS.lightgreen, title: ItemHealth.ON_TRACK, fontColor: COLORS.green}
            default:
                return {color: COLORS.lighterGray, title: 'N/A', fontColor: COLORS.lightGray};
        }
    };

    const handleUpdateProgress = (item, values) => {
        dispatch(updateKpiSaga({
            data: values,
            kpiId: item?.id,
            metricId: metric?.id,
            page: PAGES.METRICS,
            fromKpisTab: true,
        }));
    };

    const onPressScorecard = (item) => {
        dispatch(rightModalOpen({
            isVisible: false,
            component: null,
            page: null,
        }));
        history.push(STRATEGY_PLAN.replace(':id', item?.goal?.perspective?.scorecard?.id));
    }

    const onPressParent = (item) => {
        dispatch(rightSidebarSaga({item, page: PAGES.METRICS}));
    }

    const closeSidebar = () => {
        dispatch(rightModalOpen({
            isVisible: false,
            page: '',
            component: null,
        }));
    };

    const onConnectMetricToKpi = () => {
        if (process.env.REACT_APP_METRIC_DISABLED === 'true') return;
        closeSidebar();
        history.push(CONNECT_METRIC_WITH_KPI.replace(':metricId', metric.id))
    }

    return (
        <Grid container spacing={0} className={styles.container}>
            {
                metric?.kpis.map((item) => {
                    return (
                        <Grid item xs={6} md={6} lg={6} key={item.id}>
                            <div className={styles.card}>
                                <div onClick={() => onPressParent(item)} className={styles.cardHeader}>
                                    <KpiIcon width={24} height={24}/>
                                    <TooltipComponent title={item.name} placement={'right'}>
                                        <p className={styles.kpiName}>{item.name}</p>
                                    </TooltipComponent>
                                </div>
                                <ItemRowProgressComponent
                                    showStatus={false}
                                    fromHome={false}
                                    data={item}
                                    handleUpdateProgress={(val) => handleUpdateProgress(item, val)}
                                />
                                <div className={styles.cardItemWrapper}>
                                    <div className={styles.cardItemLeftWrapper}>
                                        <div className={styles.dot} style={{backgroundColor: getStatus(item.health).fontColor}}/>
                                        <TooltipComponent title={getStatus(item.health).title} placement={'right'}>
                                            <p className={styles.name} style={{color: getStatus(item.health).fontColor}}>{getStatus(item.health).title}</p>
                                        </TooltipComponent>
                                    </div>
                                </div>
                                <div className={styles.cardClickableItemWrapper} onClick={() => onPressScorecard(item)}>
                                    <div className={styles.cardItemLeftWrapper}>
                                        {getItemIcon(ROW_ITEM_TYPES.PLAN, 22)}
                                        <TooltipComponent title={item?.goal?.perspective?.scorecard?.name} placement={'right'}>
                                            <p className={styles.name}>{item?.goal?.perspective?.scorecard?.name}</p>
                                        </TooltipComponent>
                                    </div>
                                    <ArrowDownIcon width={16} height={16} style={{transform: 'rotate(-90deg)'}}/>
                                </div>
                                <div className={styles.cardClickableItemWrapper} onClick={() => onPressParent(item?.goal || item?.project)}>
                                    <div className={styles.cardItemLeftWrapper}>
                                        {getItemIcon(ROW_ITEM_TYPES.GOAL, 24)}
                                        <TooltipComponent title={item?.goal?.name} placement={'right'}>
                                            <p className={styles.name}>{item?.goal?.name}</p>
                                        </TooltipComponent>
                                    </div>
                                    <ArrowDownIcon width={16} height={16} style={{transform: 'rotate(-90deg)'}}/>
                                </div>
                                <div className={styles.cardItemWrapper}>
                                    <div className={styles.cardItemLeftWrapper}>
                                        <img src={item?.owner?.photo ? item.owner.photo : profileImage} className={styles.profileImg}
                                             alt={'user'}/>
                                        <TooltipComponent title={item?.owner?.name} placement={'right'}>
                                            <p className={styles.name}>{item?.owner?.name}</p>
                                        </TooltipComponent>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )
                })
            }
            <Grid item xs={6} md={6} lg={6}>
                <div className={styles.cardPlus}>
                    <p className={styles.connectText}>Connect this metric to a {terminology.kpi}</p>
                    <div className={styles.plusIconWrapper} onClick={onConnectMetricToKpi}>
                    <PlusIcon width={32} height={32}/>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default KpisTabComponent;
