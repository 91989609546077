import {call, put, takeLatest} from 'redux-saga/effects';
import {
    GET_SUBSCRIPTION_SAGA, ON_BACK_FROM_STRIPE,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import {isSubscriptionLoaded, setSubscription} from "./actions";
import billingService from "../../services/BillingService";
import {fetchAuthenticatedUser, fetchAuthenticatedUserSuccess, logoutSuccess} from "../auth/actions";
import organizationService from "../../services/OrganizationService";
import {push} from "connected-react-router";
import {HOME, LOGIN, WORKSPACE} from "../../routesConstants";
import authService from "../../services/AuthService";

export function* getSubscriptionSagaFn({payload}) {
    try {
        yield put(isSubscriptionLoaded(false));
        yield put(setLoader(true));
        try {
            const res = yield call(billingService.getSubscriptions);
            yield put(setSubscription(res));
            yield put(fetchAuthenticatedUser());
        } catch (e) {

        }
        yield put(isSubscriptionLoaded(true));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* onBackFromStripeSagaFn() {
    try {
        const userOrganizations = yield call(organizationService.getUsersOrganizations);
        if (userOrganizations.length > 1) {
            yield put(push(WORKSPACE));
        } else {
            yield put(logoutSuccess());
            yield call(authService.destroySession);
            yield put(push(LOGIN));
        }
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export default function* perspectivesSaga() {
    yield takeLatest(GET_SUBSCRIPTION_SAGA, getSubscriptionSagaFn);
    yield takeLatest(ON_BACK_FROM_STRIPE, onBackFromStripeSagaFn);
}
