import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {ROW_ITEM_TYPES, TRACKING} from "../../../../constants/strings";
import ProgressBarComponent from "../../../Shared/ProgressBar/ProgressBarComponent";
import SliderComponent from "../../../Shared/SliderComponent/SliderComponent";
import styles from './CriteriaTabListItemProgressComponent.module.scss';
import UpdateProgressForm from "../../../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {Menu, styled} from "@mui/material";
import {getMarksForSlider} from "../../../../utils/perspective";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const CriteriaTabListItemProgressComponent = ({item, handleUpdateProgress}) => {
  const [sliderValue, setSliderValue] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const handleSliderValueChange = (e) => {
    setSliderValue(e.target.value);
  };

  const handleClick = (e) => {
    if (!anchorEl) {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };

  const onUpdateProgress = (values) => {
    if(values.actualValue) {
      setSliderValue(Number(item.inverted ? item.min + item.max - values.actualValue : values.actualValue));
    }
    setAnchorEl(null);
    handleUpdateProgress(values);
  };

  const cancelUpdate = () => {
    setAnchorEl(null);
    setSliderValueFn();
  };

  const onProgressChange = (value) => {
    setSliderValue(Number(value));
  };

  const handleChangeCommitted = (e, value) => {
    setSliderValue(value);
    if (!anchorEl) {
      if (anchorRef.current && !anchorRef.current.contains(e.target)) {
        setAnchorEl(anchorRef.current);
      } else {
        setAnchorEl(anchorEl ? null : e.target);
      }
    }
  };

  const setSliderValueFn = useCallback(() => {
    switch (item.type) {
      case ROW_ITEM_TYPES.KPI:
        setSliderValue(item?.inverted ? item?.min + item?.max - item?.actualValue : item?.actualValue);
        break;
      case ROW_ITEM_TYPES.GOAL:
      case ROW_ITEM_TYPES.PROJECT:
      case ROW_ITEM_TYPES.ACTION:
        setSliderValue(item?.actualProgress);
        break;
      default:
        console.log('no matching type selected');
    }
  }, [item.type, item.actualProgress, item.actualValue]);

  useEffect(() => {
    setSliderValueFn();
  }, [setSliderValueFn]);

  const renderContent = () => {
    if (item.type === ROW_ITEM_TYPES.GOAL || item.type === ROW_ITEM_TYPES.PROJECT) {
      return (
        <ProgressBarComponent
          item={item}
          header
          headerValue={sliderValue}
          hideValue
        />
      );
    }
    if ((item.type === ROW_ITEM_TYPES.KPI && item.trackingType) || item.type === ROW_ITEM_TYPES.ACTION) {
      return (
        <div ref={anchorRef} className={styles.container}>
          <SliderComponent
            value={sliderValue}
            onChange={handleSliderValueChange}
            onClick={handleClick}
            status={item.health}
            marks={getMarksForSlider(item)}
            min={item.min}
            max={item.max}
            inverted={item.inverted}
            onChangeCommitted={handleChangeCommitted}
            item={item}
            showHeader
            disabled={item.tracking === TRACKING.CHECKLIST || item.tracking === TRACKING.JIRA}
          />
          <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <UpdateProgressForm
              item={item}
              progress={item.inverted ? item.min + item.max - sliderValue : sliderValue}
              status={item.status}
              expected={item.type === ROW_ITEM_TYPES.KPI ? item.expectedValue : item.expectedProgress}
              expectedUnit={item.type === ROW_ITEM_TYPES.KPI ? item.unit : 'percentage'}
              handleOnSubmit={onUpdateProgress}
              cancelUpdate={cancelUpdate}
              onProgressChange={onProgressChange}
              min={item.min}
              max={item.max}
            />
          </StyledMenu>
        </div>
      );
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default memo(CriteriaTabListItemProgressComponent);
