import React from 'react';
import {MenuItem, Select, styled} from "@mui/material";

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    height: 48px;
  }
  & .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      background: white;

      border: 1px solid black;
      opacity: 0.2;

      padding: 6px 12px;
    }
    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  `;

const SelectInput = React.memo(({value, handleChange, arr, disabled}) => {

    return (
        <StyledSelect
            fullWidth={true}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            {
                arr.map((item) => {
                    return(
                        <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
                        // <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                    )
                })
            }

        </StyledSelect>
    );
});

export default SelectInput;


