import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTimeHorizons = (state) => state.timeHorizons || initialState;

const timeHorizonsSelector = () =>
  createSelector(selectTimeHorizons, (state) => state.timeHorizons);


export {
  timeHorizonsSelector,
};
