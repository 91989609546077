import {
  DELETE_HUB_SAGA,
  GET_HUB_DATA_SAGA,
  GET_HUB_UPDATES_SAGA,
  SET_HUB_DATA,
  SET_HUB_UPDATES,
} from './actionTypes';


export function getHubDataSaga(payload) {
  return {
    type: GET_HUB_DATA_SAGA,
    payload,
  };
}

export function setHubData(payload) {
  return {
    type: SET_HUB_DATA,
    payload,
  };
}

export function deleteHubSaga(payload) {
  return {
    type: DELETE_HUB_SAGA,
    payload,
  };
}

export function getHubUpdatesSaga(payload) {
  return {
    type: GET_HUB_UPDATES_SAGA,
    payload,
  };
}


export function setHubUpdates(payload) {
  return {
    type: SET_HUB_UPDATES,
    payload,
  };
}
