import React from "react";
import {MoreVert} from "@mui/icons-material";
import {ReactComponent as DeleteIcon} from '../../../../assets/icons/trash-can.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/editIcon.svg';
import {COLORS} from "../../../../constants/colors";
import {ACTIONS} from "../../../../constants/strings";

export const WIDGET_ITEM_HEADER_MENU = ({item, onActionPress}) => {
  return {
    button: {
      icon: (
        <MoreVert style={{color: COLORS.lightGray}} />
      ),
    }, content: {
      menu: {
        items: [
          {
            id: 1,
            title: 'Edit',
            onClick: () => {
              onActionPress(ACTIONS.EDIT, item);
            },
            icon: <EditIcon color={COLORS.darkestGray} />
          },
          {
            id: 2,
            title: 'Delete',
            onClick: () => {
              onActionPress(ACTIONS.DELETE, item);
            },
            titleColor: COLORS.red,
            icon: <DeleteIcon color={COLORS.red} />
          },
        ],
      },
    },
  };
};
