import {
  DASHBOARD_DUPLICATE_SAGA,
  DASHBOARD_SETTINGS_SAGA,
  SET_VISIBILITY_DASHBOARD_ACTION_MODAL,
  GET_DASHBOARDS_GRID_SAGA,
  SET_DASHBOARDS_GRID,
  DELETE_DASHBOARD_SAGA,
  SET_SHARE_DASHBOARD_MODAL,
  GET_DASHBOARD_SAGA,
  SET_DASHBOARD,
  GET_CHART_DETAILS_SAGA,
  SET_CHART_DETAILS,
  OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA,
  DELETE_WIDGET_SAGA,
  CREATE_UPDATE_WIDGET_SAGA,
} from './actionTypes';

export function getDashboardsGridSaga(payload) {
  return {
    type: GET_DASHBOARDS_GRID_SAGA,
    payload,
  };
}

export function setDashboardsGrid(payload) {
  return {
    type: SET_DASHBOARDS_GRID,
    payload,
  };
}

export function setVisibilityDashboardActionModal(payload) {
  return {
    type: SET_VISIBILITY_DASHBOARD_ACTION_MODAL,
    payload
  };
}

export function dashboardSettingSaga(payload) {
  return {
    type: DASHBOARD_SETTINGS_SAGA,
    payload
  };
}

export function dashboardDuplicateSaga(payload) {
  return {
    type: DASHBOARD_DUPLICATE_SAGA,
    payload
  };
}
export function deleteDashboardSaga(payload) {
  return {
    type: DELETE_DASHBOARD_SAGA,
    payload
  }
}

export function setShareDashboardModal(payload) {
  return {
    type: SET_SHARE_DASHBOARD_MODAL,
    payload,
  };
}

export function getDashboardSaga(payload) {
  return {
    type: GET_DASHBOARD_SAGA,
    payload,
  };
}

export function setDashboard(dashboard) {
  return {
    type: SET_DASHBOARD,
    dashboard,
  };
}

export function getChartDetailsSaga(payload) {
  return {
    type: GET_CHART_DETAILS_SAGA,
    payload,
  };
}

export function setChartDetails(payload) {
  return {
    type: SET_CHART_DETAILS,
    payload,
  };
}

export function openRightModalFromChartDetails(payload) {
  return {
    type: OPEN_RIGHT_MODAL_FROM_CHART_DETAILS_SAGA,
    payload,
  };
}

export function deleteWidgetSaga(payload) {
  return {
    type: DELETE_WIDGET_SAGA,
    payload,
  };
}

export function createUpdateWidgetSaga(payload) {
  return {
    type: CREATE_UPDATE_WIDGET_SAGA,
    payload,
  };
}
