import React from "react";
import {MoreVert} from "@mui/icons-material";
import {ACTIONS} from "../../../../constants/strings";
import {COLORS} from "../../../../constants/colors";
import {ReactComponent as SettingsIcon} from '../../../../assets/icons/gear.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/icons/trash-can.svg';
import {ReactComponent as AlignIcon} from '../../../../assets/icons/manage.svg';

export const menuConfigListItemChildren = ({onClick, terminology}) => {
    return {
        button:  (
            <div>
                <MoreVert style={{color: COLORS.darkestGray}} />
            </div>
        ),
        content: {
            menu: {
                items: [
                    {
                        id: 1,
                        title: 'Edit',
                        icon: <AlignIcon/>,
                        onClick: () => onClick(ACTIONS.EDIT),
                    },
                    {
                        id: 2,
                        title: `${terminology.perspective} Dashboard`,
                        icon: <AlignIcon/>,
                        onClick: () => onClick(ACTIONS.PERSPECTIVE_DASHBOARD),
                    },
                    {
                        id: 3,
                        title: `Delete from ${terminology.plan}`,
                        onClick: () => onClick(ACTIONS.DELETE_FROM_PLAN),
                        icon: <DeleteIcon color={COLORS.red}/>
                    },
                ],
            },
        },
    };
};
