import React, {PureComponent, useEffect, useState} from "react";
import styles from './indexStyle.module.scss';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {COLORS} from "../../../constants/colors";
import dayjs from "dayjs";
import CustomDot from "../../Shared/Dot/CustomDot";
import {formatUnit, formatUnitAndValue} from "../../../utils/perspective";
import {defaultFormatDate} from "../../../utils/format";
import {formatGraphNumber} from "../../../utils/dashboard";
import {TASK_TYPES} from "../../../constants/strings";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";

function CompareKpisDifferentUnit({data, onChartClick}) {
    const [graphData, setGraphData] = useState([]);
    const [graphDataKeys, setGraphDataKeys] = useState([]);

    const terminology = useSelector(terminologySelector());

    useEffect(() => {
        let formattedData = [];
        let dataKeys = [];

        data?.forEach((item) => {
            const dataKeyFound = dataKeys.find((el) => el === item.name);
            if (!dataKeyFound) dataKeys.push(item.name);
            setGraphDataKeys(dataKeys)

            item?.data?.forEach((update) => {
                if (!formattedData[update.timestamp]) {
                    formattedData[update.timestamp] = {};
                    formattedData[update.timestamp]["name"] = update.timestamp;
                }
                formattedData[update.timestamp][item.name] = update.value;
            });
        });

        let dataForGraph = [];
        for (const [key, value] of Object.entries(formattedData)) {
            dataForGraph.push(value);
        }

        dataForGraph = dataForGraph.sort((a, b) => a.name - b.name);
        setGraphData(dataForGraph);
    }, [data]);

    const dateFormatter = date => {
        return dayjs(new Date(date)).format('MMM-DD/YY');
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const {x, y, stroke, payload} = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={15} y={-5} dy={16} fontSize={11} textAnchor="end" fill={COLORS.legendBlack}
                          transform="rotate(-15)">
                        {defaultFormatDate(payload.value)}
                    </text>
                </g>
            );
        }
    }

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltipWrapper}>
                    <p className={styles.title}>{defaultFormatDate(label)}</p>
                    {payload.map((item, index) => {
                        return (
                            <div className={styles.tooltipItemWrapper} key={index}>
                                <CustomDot backgroundColor={item.color} large/>
                                <h4 className={styles.tooltipItemName}>{item.name}:</h4>
                                <h4 className={styles.tooltipItemName}>{formatUnitAndValue(data.find((item) => item.name === payload[index].name)?.unit, item.value)}</h4>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    const CustomizedYAxisTick = (props) => {
        const {x, y, payload, anchor, color} = props;

        return (
            <text x={x} y={y} textAnchor={anchor} fill={color}>
                {formatGraphNumber(payload.value)}
            </text>
        );
    };

    return (
        <div className={styles.wrapper} onClick={() => onChartClick(TASK_TYPES.KPI, data?.map((item) => item.id))}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={graphData}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        tick={<CustomizedAxisTick/>}
                        dataKey="name"
                        scale="time"
                        domain={['auto', 'auto']}
                        type={'number'}
                        tickFormatter={dateFormatter}
                    />
                    <YAxis
                        label={{
                            value: `Avg Value of ${terminology.kpi} (${formatUnit(data[0]?.unit, true)})`,
                            angle: -90,
                            style: {fill: COLORS.lighterRed, fontSize: 12},
                            dx: -25
                        }}
                        yAxisId="left"
                        tick={<CustomizedYAxisTick anchor={'end'} color={COLORS.lighterRed}/>}
                    />
                    <YAxis
                        label={{
                            value: `Avg Value of ${terminology.kpi} (${formatUnit(data[1]?.unit, true)})`,
                            angle: -90,
                            style: {fill: COLORS.blue, fontSize: 12},
                            dx: 25
                        }}
                        yAxisId="right"
                        orientation="right"
                        tick={<CustomizedYAxisTick anchor={'start'} color={COLORS.blue}/>}
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend iconType={'circle'}/>
                    <Line
                        isAnimationActive={false}
                        stroke={COLORS.lighterRed}
                        dot={{stroke: COLORS.lighterRed, strokeWidth: 2}}
                        dataKey={graphDataKeys[0]}
                        yAxisId="left"
                        connectNulls
                    />
                    {
                        data.length > 1 &&
                        <Line
                            isAnimationActive={false}
                            yAxisId="right"
                            stroke={COLORS.blue}
                            dot={{stroke: COLORS.blue, strokeWidth: 2}}
                            dataKey={graphDataKeys[1]}
                            connectNulls
                        />
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CompareKpisDifferentUnit;
