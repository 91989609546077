import React from 'react';
import styles from './ProgressBarDateRange.module.scss';
import {daysAgoOrInFuture, defaultFormatDate} from "../../../utils/format";

const ProgressBarDateRange = ({item}) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <h4 className={styles.title}>{defaultFormatDate(item?.startDate)}</h4>
        <h4 className={styles.subtitle}>{daysAgoOrInFuture(new Date(item?.startDate))}</h4>
      </div>
      <div className={styles.rightContainer}>
        <h4 className={styles.title}>{defaultFormatDate(item?.endDate)}</h4>
        <h4 className={styles.subtitle}>{daysAgoOrInFuture(new Date(item?.endDate))}</h4>
      </div>
    </div>
  );
};

export default ProgressBarDateRange;
