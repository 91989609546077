export const SCORECARD = '[Scorecard]';
export const GET_SCORECARD_LIST_DATA_SAGA = `${SCORECARD} GET_SCORECARD_LIST_DATA_SAGA`;
export const SET_GROUPS = `${SCORECARD} SET_GROUPS`;
export const RESET_STATE = `${SCORECARD} RESET_STATE`;
export const CREATE_GROUP_SAGA = `${SCORECARD} CREATE_GROUP_SAGA`;
export const SET_GROUPS_WITH_SCORECARDS = `${SCORECARD} SET_GROUPS_WITH_SCORECARDS`;
export const EDIT_GROUP_SAGA = `${SCORECARD} EDIT_GROUP_SAGA`;
export const MANAGE_SCORECARD_SAGA = `${SCORECARD} MANAGE_SCORECARD_SAGA`;
export const MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA = `${SCORECARD} MANAGE_RIGHT_SIDEBAR_SCORECARD_SAGA`;
export const GET_GROUP_SAGA = `${SCORECARD} GET_GROUP_SAGA`;
export const SET_GROUP = `${SCORECARD} SET_GROUP`;
export const DELETE_GROUP_SAGA = `${SCORECARD} DELETE_GROUP_SAGA`;
export const DELETE_SCORECARD_SAGA = `${SCORECARD} DELETE_SCORECARD_SAGA`;
export const SET_EXPANDED_GROUPS = `${SCORECARD} SET_EXPANDED_GROUPS`;
export const GET_RIGHT_SIDEBAR_SCORECARD_SAGA = `${SCORECARD} GET_RIGHT_SIDEBAR_SCORECARD_SAGA`;
export const SET_RIGHT_SIDEBAR_SCORECARD = `${SCORECARD} SET_RIGHT_SIDEBAR_SCORECARD`;
export const SET_ALL_SCORECARDS = `${SCORECARD} SET_ALL_SCORECARDS`;
export const REORDER_SCORECARD_ITEMS_SAGA = `${SCORECARD} REORDER_SCORECARD_ITEMS_SAGA`;
export const SET_DELETE_PLAN_ITEM = `${SCORECARD} SET_DELETE_PLAN_ITEM`;
export const DELETE_SCORECARD_SAGA_FROM_WIDGET = `${SCORECARD} DELETE_SCORECARD_SAGA_FROM_WIDGET`;
export const SET_ALL_GROUP_IDS = `${SCORECARD} SET_ALL_GROUP_IDS`;
export const IS_GROUP_IDS_SET = `${SCORECARD} IS_GROUP_IDS_SET`;
export const SET_SCORECARD_TITLE = `${SCORECARD} SET_SCORECARD_TITLE`;
