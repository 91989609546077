import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectPerspective = (state) => state.perspective || initialState;

const showEditModalPerspectiveSelector = () =>
  createSelector(selectPerspective, (state) => state.showEditModalPerspective);

const showPerspectiveLibraryModalSelector = () =>
  createSelector(selectPerspective, (state) => state.showPerspectiveLibraryModal);


export {
  showEditModalPerspectiveSelector,
  showPerspectiveLibraryModalSelector,
};
