import React from 'react';
import styles from './graphTableHeader.module.scss'

const GraphTableHeader = () => {

    return (
        <div className={styles.container}>
            <div className={styles.leftWrapper}>
                <div className={styles.leftEmptySpace}/>
                <p>Name</p>
            </div>
            <div className={styles.displayFlex}>
                <div className={styles.ownerWrapper}>
                    <p>Owner</p>
                </div>
                {/*<div className={styles.dateWrapper}>*/}
                {/*    <p>Start/End Date</p>*/}
                {/*</div>*/}
                <div className={styles.healthWrapper}>
                    <p>Health</p>
                </div>
                <div className={styles.rightEmptySpace}/>
            </div>
        </div>
    );
};

export default GraphTableHeader;
