import {MoreVert} from "@mui/icons-material";
import {COLORS} from "../../../constants/colors";
import React from "react";

export const riskMitigationsOptionsConfig = ({onClick, item}) => {
    return {
        button: {
            icon: (
                <MoreVert style={{color: COLORS.darkerGray}} />
            ),
        },
        content: {
            menu: {
                items: [
                    {
                        id: 1,
                        title: 'Edit',
                        onClick: () => onClick(item, 'edit'),
                    },
                    {
                        id: 4,
                        title: 'Delete',
                        onClick: () => onClick(item, 'delete'),
                        titleColor: COLORS.red,
                    },
                ],
            },
        },
    };
};
