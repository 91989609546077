import React from 'react';
import { useSelector } from 'react-redux';
import { loaderSelector } from '../../store/loader/selectors';
import styles from './Loader.module.scss';
import {CircularProgress} from "@material-ui/core";

const Loader = () => {
  const isLoading = useSelector(loaderSelector());

  return (
    <div>
      {isLoading && (
        <div className={styles.container}>
          {/*<img style={{animation: `App-logo-spin 2s linear infinite`}} className={styles.logo} src={require('../../assets/images/logo.png')} alt="img"/>*/}
          <CircularProgress style={{'color': '#56b2fb'}}/>
        </div>
      )}
    </div>
  );
};

export default Loader;
