import React, {memo, useEffect, useState} from 'react';
import {MenuItem, Select, styled, FormControl, Typography, Box} from '@mui/material';
import styles from './SelectField.module.scss';
import {COLORS} from "../../../constants/colors";

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px ${COLORS.lightBlue} solid;
    box-shadow: 0 0 0 4px ${COLORS.transparentDarkBlue};
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    padding-top: 4px;
    font-size: 12px;
    color: ${COLORS.lighterRed};
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  &.MuiBox-root {
    height: 32px;
    display: flex;
  }
`;

const SelectField = ({setFieldValue, field, getFieldVal = () => {}, errors, ...props}) => {
  const [options, setOptions] = useState([]);
  const handleChange = (event) => {
    setFieldValue(field.name, event.target.value);
    getFieldVal(event.target.value);
  };

  useEffect(() => {
    if (props.options.apiService) {
      getDataForSelect();
    }
  }, [props.options.apiService]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.options.options) {
      setOptions(props.options.options);
    }
  }, [props.options.options]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataForSelect = async () => {
    const methodProperties = props.options.apiServiceMethodProperties || [];
    const res = await props.options.apiService[
      props.options.apiServiceMethod
      ](...methodProperties);
    const filteredOptions = res.map((item) => {
      return {
        id: item.id,
        value: item.id,
        title: item[props.options.titleKey],
        subtitle: item[props.options.subtitleKey],
        image: item[props.options.imageKey],
        color: item[props.options.colorKey],
      };
    });
    setOptions(filteredOptions);
  };

  return (
    <FormControl fullWidth>
      {props.label && (
        <div className={styles.labelContainer}>
          {props.label}
        </div>
      )}
      <StyledSelect
        disabled={props.disabled}
        value={
          (field.value === undefined || field.value === null || options.length === 0)
            ? ''
            : field.value
        }
        onChange={handleChange}
        fullWidth
        displayEmpty
      >
        <MenuItem value='' disabled={!props.canSelectEmpty}>
          <div className={styles.placeholderMenuItemContainer}>
            {props.placeholder?.icon && (
              <img
                src={props.placeholder.icon}
                className={styles.placeholderImage}
                alt={props.options.imageKey}
              />
            )}
            <h4 className={styles.placeholder}>{props.placeholder?.value}</h4>
          </div>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            <div className={styles.placeholderMenuItemContainer}>
              {props.options.imageKey && (
                <img
                  src={option.image || props.options.defaultImage}
                  className={`
                    ${styles.image}
                    ${props.options.mediumImage && styles.mediumImage}
                    ${props.options.disableImageBorderRadius && styles.disableImageBorderRadius}
                  `} alt={props.options.imageKey} />
              )}
              {
                  props.options.colorKey &&
                  <div className={styles.colorDot} style={{background: option.color}}></div>
              }
              <div className={styles.titleContainer}>
                {option.addition ?
                    <div className={styles.titleWithAdditionWrapper}>
                      <h4 className={option.addition ? styles.titleWithAddition : styles.title}>{option.title}</h4>
                      <h4 className={styles.titleAddition}>{option.addition}</h4>
                    </div> : <h4 className={styles.title}>{option.title}</h4>
                }
                {props.options.subtitleKey && (
                  <h4 className={styles.subtitle}>{option.subtitle}</h4>
                )}
              </div>
            </div>
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledBox>
        {errors[field?.name] && (
          <StyledTypography>
            {errors[field?.name]}
          </StyledTypography>
        )}
      </StyledBox>
    </FormControl>
  );
};

export default memo(SelectField);
