import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGoals = (state) => state.goals || initialState;

const goalsLibraryModalSelector = () =>
  createSelector(selectGoals, (state) => state.goalsLibraryModal);


export {
  goalsLibraryModalSelector,
};
