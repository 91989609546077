import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from "../utils/history";
import loadingReducer from './loading/reducer';
import notifierReducer from './notifier/reducer';
import authReducer from './auth/reducer';
import snackbarReducer from './snackbar/reducer';
import loaderReducer from './loader/reducer';
import scorecardReducer from './scorecard/reducer';
import helperReducer from './helper/reducer';
import strategyPlanReducer from './strategyPlan/reducer';
import perspectiveReducer from './perspectives/reducer';
import goalsReducer from './goals/reducer';
import kpisReducer from './kpis/reducer';
import dashboardsReducer from './dashboards/reducer';
import perspectiveActionsReducer from './perspectiveActions/reducer';
import alignmentReducer from './alignment/reducer';
import metricsReducer from './metrics/reducer';
import hubReducer from './hub/reducer';
import actionReducer from './action/reducer';
import checklistReducer from './checklist/reducer';
import reportsReducer from './reports/reducer';
import timeHorizonsReducer from './timeHorizons/reducer';
import billingReducer from './billing/reducer';

const rootReducer = (injectedReducers = {}) => {
  const combinedReducers = combineReducers({
    router: connectRouter(history),
    loading: loadingReducer,
    notifier: notifierReducer,
    auth: authReducer,
    snackbar: snackbarReducer,
    loader: loaderReducer,
    scorecard: scorecardReducer,
    helper: helperReducer,
    strategyPlan: strategyPlanReducer,
    perspective: perspectiveReducer,
    goals: goalsReducer,
    kpis: kpisReducer,
    dashboards: dashboardsReducer,
    perspectiveActions: perspectiveActionsReducer,
    alignment: alignmentReducer,
    metrics: metricsReducer,
    hub: hubReducer,
    action: actionReducer,
    checklist: checklistReducer,
    reports: reportsReducer,
    timeHorizons: timeHorizonsReducer,
    billing: billingReducer,
    ...injectedReducers,
  });

  return (state, action) => {
    return combinedReducers(state, action);
  };
};

export default rootReducer;
