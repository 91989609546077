import {
  POST_UPDATE_FROM_HOME_GOAL_SAGA,
  RESET_STATE, SET_GOALS_LIBRARY_MODAL, SHARE_EXISTING_GOALS_SAGA,
} from './actionTypes';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function setGoalsLibraryModal(payload) {
  return {
    type: SET_GOALS_LIBRARY_MODAL,
    payload,
  };
}
export function postUpdateFromHomeGoalSaga(payload) {
  return {
    type: POST_UPDATE_FROM_HOME_GOAL_SAGA,
    payload,
  };
}

export function shareExistingGoalsSaga(goals, goalId, perspectiveId, scorecardId, page) {
  return {
    type: SHARE_EXISTING_GOALS_SAGA,
    goals,
    goalId,
    perspectiveId,
    scorecardId,
    page,
  };
}
