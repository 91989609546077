import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_PERSPECTIVES_FOR_LIBRARY: '/perspectives/library',
  ADD_EXISTING_PERSPECTIVES: '/perspectives/add-existing',
  UPDATE: '/perspectives/:id',
  ADD_NEW_PERSPECTIVE: '/perspectives',
  ADD_NEW_GOAL: '/goals',
  EDIT_GOAL_NAME: '/goals/:id/name',
  ADD_NEW_KPI: '/kpis',
  EDIT_KPI_NAME: '/kpis/:id/name',
  ADD_NEW_ACTION: '/actions',
  GET_PERSPECTIVES_BY_ORGANIZATION: 'perspectives/by-organization',
  EDIT_ACTION_NAME: '/actions/:id/name',
  EDIT_ACTION: '/actions/:id',
  EDIT_PROJECT_NAME: '/projects/:id/name',
  ADD_NEW_PROJECT: '/projects',
  EDIT_KPI: '/kpis/:id',
  EDIT_ACTION_STATUS: '/actions/:id/update-status',
  GET_PERSPECTIVE_DASHBOARD_DATA: '/perspectives/dashboard/:id',
};

class PerspectiveService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getPerspectivesForLibrary = (data) => {
    return this.httpService.request({
      url: ROUTES.GET_PERSPECTIVES_FOR_LIBRARY,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getPerspectivesDashboardData = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_PERSPECTIVE_DASHBOARD_DATA.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };

  addExistingPerspectives = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_EXISTING_PERSPECTIVES,
      method: HTTP_METHODS.POST,
      data
    });
  };

  updatePerspective = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  addNewPerspective = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_NEW_PERSPECTIVE,
      method: HTTP_METHODS.POST,
      data
    });
  };
  addNewGoal = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_NEW_GOAL,
      method: HTTP_METHODS.POST,
      data
    });
  };
  editNameGoal = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_GOAL_NAME.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  addNewKPI = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_NEW_KPI,
      method: HTTP_METHODS.POST,
      data
    });
  };

  editKpiName = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_KPI_NAME.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  editActionName = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_ACTION_NAME.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  editAction = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_ACTION.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  editKpi = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_KPI.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  addNewAction = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_NEW_ACTION,
      method: HTTP_METHODS.POST,
      data
    });
  };

  getPerspectivesByOrganization = () => {
    return this.httpService.request({
      url: ROUTES.GET_PERSPECTIVES_BY_ORGANIZATION,
      method: HTTP_METHODS.GET,
    });
  };

  addNewProject = (data) => {
    return this.httpService.request({
      url: ROUTES.ADD_NEW_PROJECT,
      method: HTTP_METHODS.POST,
      data
    });
  };

  editProjectName = (data) => {
    return this.httpService.request({
      url: ROUTES.EDIT_PROJECT_NAME.replace(':id', data.id),
      method: HTTP_METHODS.PUT,
      data
    });
  };

  editActionStatus = (data, actionId) => {
    return this.httpService.request({
      url: ROUTES.EDIT_ACTION_STATUS.replace(':id', actionId),
      method: HTTP_METHODS.PUT,
      data
    });
  };
}
const perspectiveService = new PerspectiveService(httpService);

export default perspectiveService;
