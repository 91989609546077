import React from 'react';
import {Dialog, styled} from "@mui/material";
import CustomModalHeader from "./CustomModalHeader";
import styles from './CustomModal.module.scss';
import CustomModalFooter from "./CustomModalFooter";

const CustomModal = ({
                       isVisible,
                       title,
                       children,
                       closeModal,
                       maxWidth = 'md',
                       footerConfig,
                       leftIcon,
                       disableEnforceFocus,
                     }) => {
  const StyledDialog = styled(Dialog)`
    & .MuiPaper-root {
      border-radius: 16px;
    }
  `;

  return (
    <StyledDialog open={isVisible} fullWidth maxWidth={maxWidth} {...(disableEnforceFocus && { disableEnforceFocus: true })}>
      <div className={styles.container}>
        <CustomModalHeader title={title} onClick={closeModal} leftIcon={leftIcon} />
        <div className={styles.mainContainer}>
          {children}
        </div>
        {footerConfig && (
          <CustomModalFooter config={footerConfig} />
        )}
      </div>
    </StyledDialog>
  );
};

export default CustomModal;
