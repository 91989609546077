import React from 'react';
import {Field, Form, Formik} from "formik";
import FormFooter from "./FormFooter";
import {Box, styled} from "@mui/material";

const StyledBox = styled(Box)`
  &.MuiBox-root {
    padding: 32px;
  },
`;

const DynamicForm = ({config, validationSchema, handleOnSubmit}) => {
  const getInitialValues = () => {
    return config.fields.reduce((result, item) => {
      result[item.name] = item.value || '';

      return result;
    }, {});
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={false}
    >
      {({setFieldValue, errors, values}) => {
        return (
          <Form>
            <StyledBox>
              {config.fields.map((item) => {
                 return (
                   !item.hidden && <Field
                    component={item.component}
                    name={item.name}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    placeholder={item.placeholder}
                    label={item.label}
                    key={item.id}
                    options={item.options || null}
                    disabled={item.disabled}
                    canSelectEmpty={item.canSelectEmpty}
                  />
                );
              })}
            </StyledBox>
            <FormFooter config={config.footer} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DynamicForm;
