import {format} from "date-fns";
import {measurementData} from "../components/Shared/TrackingComponent/progressScheduleData";

export const getInitials = (name) => {
  if (name) {
    const words = name.split(' ');
    const firstWord = words[0] || '';

    if (words.length === 1) {
      return firstWord.charAt(0);
    } else {
      const lastWord = words[words.length - 1] || '';
      const firstInitial = firstWord.charAt(0);
      const lastInitial = lastWord.charAt(0);
      return firstInitial + lastInitial;
    }
  }
};

export const formatActivityDate = (date) => {
  return format(date, "mm-dd-yyyy");
};

export const capitalizeFirstLetter = (string) => {
  const word = string
  const firstLetter = word.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = word.slice(1);

  return firstLetterCap + remainingLetters;
}

export const extractFirstSegmentFromUrl = (url) => {
  const parts = url.split('/').filter(part => part.trim() !== '');
  if (parts.length > 0) {
    return parts[0];
  }
  return null;
}

export const defaultFormatDate = (date = new Date()) => {
  return format(new Date(date), "MMM dd, yyy");
};

export const defaultFormatTime = (date = new Date()) => {
  return format(new Date(date), "HH:mm:ss a zzz");
};

export const daysAgoOrInFuture = (date) => {
  const targetDateObj = new Date(date);
  const currentDate = new Date();

  targetDateObj.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const timeDifference = currentDate - targetDateObj;

  const daysDifference = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));

  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return timeDifference < 0 ? "Tomorrow" : "Yesterday";
  } else {
    return timeDifference < 0 ? `In ${daysDifference} days` : `${daysDifference} days ago`;
  }
}

export const timeAgo = (date, lessThanMinute) => {
  const now = new Date();
  const millisecondsDifference = now - date;
  const seconds = Math.max(Math.floor(millisecondsDifference / 1000), 0);

  if (seconds < 60) {
    if (lessThanMinute) {
      return 'less than a minute ago';
    }

    return `${seconds} sec ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} min ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  }

  const years = Math.floor(months / 12);
  return `${years} ${years === 1 ? 'year' : 'years'} ago`;
}

export const getRangePercentage = (value, min, max) => {
  return 100 * (value - min) / (max - min);
}

export const timeToGo = (date) => {
  const now = new Date();
  const seconds = Math.floor((date - now) / 1000);

  if (seconds < 60) {
    return `in ${seconds} sec`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `in ${minutes} min`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `in ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `in ${days} ${days === 1 ? 'day' : 'days'}`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `in ${months} ${months === 1 ? 'month' : 'months'}`;
  }

  const years = Math.floor(months / 12);
  return `in ${years} ${years === 1 ? 'year' : 'years'}`;
}

export const getAlphabet12 = () => {
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let items = [];

  for (let i = 1; i <= 12; i++) {
    let item = {
      id: i,
      name: alphabet[i - 1]
    };
    items.push(item);
  }
  return items;
}

export const getNumbersWithLetter = (letter) => {
  let numbers = [];

  for (let i = 1; i <= 30; i++) {
    let item = {
      id: i,
      name: `${letter}${i}`
    };
    numbers.push(item);
  }
  return numbers;
}
export const roundToDecimalFormat = (number) => {
  const decimalPlaces = (number.toString().split('.')[1] || '').length;

  switch (decimalPlaces) {
    case 0:
      return number;
    case 1:
      return Math.round(number * 10) / 10;
    default:
      return Math.round(number * 100) / 100;
  }
};

export const sortByDate = (data, fieldName = 'createdAt', sortOrder = 'DESC') => {
  return data?.slice().sort((a, b) => {
    const valueA = new Date(a[fieldName]);
    const valueB = new Date(b[fieldName]);
    if (sortOrder === 'DESC') {
      return valueB - valueA;
    } else {
      return valueA - valueB;
    }
  });
};

export const formatUpdates = (arr) => {
  let sortedDates = [...arr];

  sortedDates.sort((date1, date2) => date1.id - date2.id);

  const uniqueDatesMap = sortedDates.reduce((map, entry) => {
    const date = entry.createdAt.split('T')[0];

    map.set(date, entry);
    return map;
  }, new Map());


  const uniqueDataArray = Array.from(uniqueDatesMap.values());
  return uniqueDataArray;
}

export const truncateText = (text, length) => {
 return text.length > (length +3) ? (text.slice(0,length) + '...') : text
}

export const getUnitName = (unitValue) => {
  const item = measurementData.find(item => item.value === unitValue);
  return item ? item.name : unitValue;
}

export const formatUpdateText = ({text, terminology}) => {
  if (text) {
    return text
      .replace('%kpi%', terminology.kpi)
      .replace('%action%', terminology.action)
      .replace('%project%', terminology.project)
      .replace('%goal%', terminology.goal)
      .replace('%contributingGoal%', terminology.contributingGoal);
  } else {
    return '';
  }
};
