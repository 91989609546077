export const TABLE_CONFIG = (row) => {
  return [
    {
      id: 1,
      label: 'Date',
      value: 'date',
      type: 'component',
      component: (<p>{row && row.date ? row.date : '-'}</p>)
    },
    {
      id: 2,
      label: 'Value',
      value: 'value',
      type: 'string',
    },
  ];
};
