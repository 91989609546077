import React from 'react';
import styles from './ConnectionErrorModalContent.module.scss';
import DefaultButton from "../../Buttons/DefaultButton";

const ConnectionErrorModalContent = ({closeModal}) => {
  return (
    <div >
      <div className={styles.mainWrapper}>
        <p className={styles.text}>There is a problem with this connection!</p>
        <p className={styles.text}>Please contact the administrator.</p>
      </div>
      <div className={styles.footer}>
        <DefaultButton title={'Close'} onClick={closeModal} secondary />
      </div>
    </div>
  );
};

export default ConnectionErrorModalContent;
