import {ReactComponent as DefaultScorecard} from "../assets/icons/default-scorecard.svg";
import {ReactComponent as GoalIcon} from "../assets/icons/goal.svg";
import {ReactComponent as ProjectIcon} from "../assets/icons/project.svg";
import React from "react";
import {ROW_ITEM_TYPES} from "../constants/strings";

export const getScorecardName = (parent) => {
    switch (parent.type) {
        case ROW_ITEM_TYPES.PERSPECTIVE:
            return parent?.scorecard?.name;
        case ROW_ITEM_TYPES.GOAL:
            return parent?.perspective?.scorecard?.name;
        case ROW_ITEM_TYPES.PROJECT:
        case ROW_ITEM_TYPES.KPI:
            return parent?.goal?.perspective?.scorecard?.name;
        case ROW_ITEM_TYPES.ACTION:
            if (parent.goal) {
                return parent?.goal?.perspective?.scorecard?.name;
            } else {
                return parent?.project?.goal?.perspective?.scorecard?.name;
            }
        default:
            return 'Scorecard not found'
    }
}

export const setParentDataForContributesToComponent = (parent, scorecardName) => {
    const getIconForParent = (parentType) => {
        switch (parentType) {
            case ROW_ITEM_TYPES.PERSPECTIVE:
                return <DefaultScorecard/>
            case ROW_ITEM_TYPES.GOAL:
                return <GoalIcon width={30} height={30}/>
            case ROW_ITEM_TYPES.PROJECT:
                return <ProjectIcon width={30} height={30}/>
            default: return null
        }
    }
    const icon = getIconForParent(parent.type);
    const name = parent.name;
    return {
        icon: icon,
        name: name,
        scorecard: getScorecardName(parent),
    };
};

export const getScorecardId = (item) => {
    switch (item.type) {
        case ROW_ITEM_TYPES.PERSPECTIVE:
            return item.scorecard.id;
        case ROW_ITEM_TYPES.GOAL:
            return item.perspective.scorecard.id;
        case ROW_ITEM_TYPES.PROJECT:
            return item.goal.perspective.scorecard.id;
        default:
            return 'Scorecard not found'
    }
}
