import InputField from "../../components/Shared/Inputs/InputField";
import SelectField from "../../components/Shared/Inputs/SelectField";
import scorecardService from "../../services/ScorecardService";
import TextareaField from "../../components/Shared/Inputs/TextareaField";

export const editScorecardFormConfig = ({
                                          scorecard,
                                          group,
                                          onCancel,
                                          terminology,
                                          options,
                                        }) => {
  return {
    fields: [
      {
        id: 1,
        component: InputField,
        name: 'name',
        placeholder: `${terminology.plan} Name`,
        label: `${terminology.plan} Name`,
        value: scorecard?.name || null,
      },
      {
        id: 2,
        component: TextareaField,
        name: 'description',
        placeholder: `${terminology.plan} Description`,
        label: `${terminology.plan} Description`,
        value: scorecard?.description || null,
      },
      {
        id: 3,
        component: SelectField,
        name: 'ownerId',
        placeholder: {
          value: `Select Owner`,
        },
        label: 'Select Owner',
        options: {
          options,
          titleKey: 'name',
          subtitleKey: 'email',
          imageKey: 'photo',
          defaultImage: require('../../assets/icons/profile-owner.png'),
        },
        value: scorecard?.ownerId || null,
      },
      {
        id: 4,
        component: SelectField,
        name: 'parentId',
        placeholder: {
          value: `Choose Parent ${terminology.plan}`,
        },
        label: `${terminology.plan} Parent (optional)`,
        options: {
          apiService: scorecardService,
          apiServiceMethod: 'getPlanParents',
          apiServiceMethodProperties: [scorecard?.id],
          titleKey: 'name',
        },
        value: scorecard?.scorecardId || null,
        canSelectEmpty: true,
      },
      {
        id: 5,
        component: SelectField,
        name: 'groupId',
        placeholder: {
          value: 'Team',
        },
        label: 'Group (optional)',
        options: {
          apiService: scorecardService,
          apiServiceMethod: 'getGroups',
          titleKey: 'name',
        },
        value: scorecard?.groupId ?? group?.id ?? null,
        disabled: Boolean(group?.id),
        canSelectEmpty: true,
      },
    ],
    footer: [
      {id: 1, title: 'Cancel', secondary: true, onClick: onCancel, type: 'button'},
      {id: 2, title: 'Save', type: 'submit'},
    ],
  };
};
