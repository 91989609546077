import produce from 'immer';
import {
  SET_CAN_EDIT_REPORT,
  SET_REPORT,
  SET_REPORT_GRID_LAYOUT,
  SET_REPORTS_GRID, SET_SHARE_REPORT_MODAL,
} from './actionTypes';

export const initialState = {
  reportsGrid: {
    data: null,
    perPage: 10,
    page: 1,
    total: 0,
    maxPageNumbers: 1,
  },
  reportGridLayout: [],
  report: null,
  shareReportModal: {
    isVisible: false,
    report: null,
  },
  canEditReport: false,
};

/* eslint-disable default-case */
const reportsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_REPORTS_GRID:
        draft.reportsGrid = {
          perPage: action.payload.perPage,
          page: action.payload.page,
          total: action.payload.total,
          maxPageNumbers: Math.ceil(action.payload.total / action.payload.perPage),
          data: action.payload.data,
        };
        break;
      case SET_REPORT_GRID_LAYOUT:
        draft.reportGridLayout = action.payload;
        break;
      case SET_REPORT:
        draft.report = action.payload;
        break;
      case SET_SHARE_REPORT_MODAL:
        draft.shareReportModal = action.payload;
        break;
      case SET_CAN_EDIT_REPORT:
        draft.canEditReport = action.payload;
        break;
    }
  });

export default reportsReducer;
