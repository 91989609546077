import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import { makeSelectIsAuthenticated } from 'store/auth/selectors';

export function PublicRoute({
  component: Component,
  user,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Redirect to="/home" /> : <Component {...props} />
      }
    />
  );
}



const mapStateToProps = createStructuredSelector({
  // isAuthenticated: makeSelectIsAuthenticated(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PublicRoute);
