import React, {memo, useCallback} from 'react';
import {OutlinedInput, styled} from "@mui/material";
import {ReactComponent as Search} from '../../../assets/icons/search.svg';
import {ReactComponent as Remove} from '../../../assets/icons/close.svg';
import styles from './SearchInput.module.scss';
import {COLORS} from "../../../constants/colors";

const StyledInput = styled(OutlinedInput)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px ${COLORS.lightBlue} solid;
    box-shadow: 0 0 0 4px ${COLORS.transparentDarkBlue};
  }
`;

const SearchInput = ({searchText, setSearchText, isRemoveShown, onRemoveClick, placeholder, height}) => {
  const removeButton = () => {
    return (
      <button
        onClick={onRemoveClick}
        className={styles.removeButton}>
        <Remove
          width={'16px'}
          height={'16px'}
          color={COLORS.white}
        />
      </button>
    );
  };

  const onChangeText = useCallback(
    (e) => {
      setSearchText(e.target.value)
    }, [setSearchText]
  );

  return (
    <StyledInput
      value={searchText}
      onChange={(e) => onChangeText(e)}
      fullWidth
      placeholder={placeholder}
      startAdornment={<Search style={{marginRight: '8px'}} />}
      endAdornment={isRemoveShown && removeButton()}
      sx={{
        height: height,
      }}
    />
  );
};

export default memo(SearchInput);
