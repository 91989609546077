import React, {memo} from 'react';
import styles from './SidebarItemHeader.module.scss';
import {getItemTypeTerminology, getRowItemIcon} from "../../utils/perspective";
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import {COLORS} from "../../constants/colors";
import PopupComponent from "../Shared/Popup/PopupComponent";
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {menuConfig} from "./menuConfig";
import {ACTIONS} from "../../constants/strings";
import TooltipComponent from "../Shared/Tooltip/TooltipComponent";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

const SidebarItemHeader = ({item, onMenuClick, closeSidebar}) => {
  const terminology = useSelector(terminologySelector());

  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <TooltipComponent
          title={getItemTypeTerminology({
            type: item.type,
            terminology,
          })}
          placement={'bottom-start'}
        >
          {getRowItemIcon(item, !item.goalId)}
        </TooltipComponent>
        <h2 className={styles.title}>{item?.name}</h2>
      </div>
      <div className={styles.flexContainer}>
        <button className={styles.button} onClick={() => onMenuClick(item, ACTIONS.EDIT, item.type)}>
          <EditIcon color={COLORS.lightGray} />
        </button>
        <div className={styles.menuContainer}>
          <PopupComponent
            config={menuConfig({
              onClick: onMenuClick,
              item,
              terminology,
            })}
          />
        </div>
        <button className={styles.button} onClick={closeSidebar}>
          <CloseIcon color={COLORS.lightGray} />
        </button>
      </div>
    </div>
  );
};

export default memo(SidebarItemHeader);
