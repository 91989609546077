import produce from 'immer';
import {

} from './actionTypes';

export const initialState = {
  action: null,
};

/* eslint-disable default-case */
const actionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {

    }
  });

export default actionReducer;
