import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Error from '../Error';
import styles from '../FormFieldStyles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginErrorSelector } from '../../../store/auth/selectors';
import { setLoginError } from '../../../store/auth/actions';
import {ReactComponent as Eye} from '../../../assets/icons/eyeIcon.svg';

const PasswordField = ({
  name,
  label,
  placeholder,
  required,
  minValue,
  maxValue,
  autoFocus,
  onChange,
  autocomplete = true,
}) => {
  const loginError = useSelector(getLoginErrorSelector());
  const dispatch = useDispatch();
  const [showText, setShowText] = useState('password');

  const handleChange = (e) => {
    onChange(e.target.value);
    dispatch(setLoginError(null));
  };

  const showPassword = () => {
    if (showText === 'password') {
      setShowText('text')
    } else {
      setShowText('password')
    }
  }

  return (
    <div className={styles.field}>
      <button type={'button'} onClick={() => showPassword()} className={styles.eyeIcon}><Eye/></button>
      <Field
        type={showText}
        placeholder={placeholder}
        name={name}
        required={required}
        autoFocus={autoFocus}
        className={styles.input}
        onChange={handleChange}
        autoComplete={autocomplete ? '' : 'new-password'}
      />
      <Error>
        {!loginError ? (
          <ErrorMessage name={name}>
            {(msg) => {
              return <span className={styles.errorMsg}>{msg}</span>;
            }}
          </ErrorMessage>
        ) : (
          loginError
        )}
      </Error>
    </div>
  );
};

export default PasswordField;

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  autoFocus: PropTypes.bool,
};
