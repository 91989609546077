import {REPORT_ELEMENT_TYPES} from "../constants/strings";
import { v4 as uuidv4 } from 'uuid';

export const newItemYAxis = (array) => {
  if (array.length === 0) return 0;
  const sortedArray = array.slice().sort((a, b) => {
    return (b?.y + b?.h) - (a?.y + a?.h);
  });

  return sortedArray[0]?.y + sortedArray[0]?.h;
};

export const formatReport = (report) => {
  return {
    ...report,
    elements: [
      ...report.elements,
      ...report.widgets
    ].map((item, index) => ({
      ...item,
      i: index.toString(),
      x: item.xAxis,
      y: item.yAxis,
      w: item.width,
      h: item.height,
      type: item.type || REPORT_ELEMENT_TYPES.CHART,
      resizeHandles: ["s", "w", "e", "n", "sw", "nw", "se", "ne"],
    }))
  };
};

export const formatPDFElements = (report, pageBlockHeight, title) => {
  const _report = {
    ...report,
    elements: [
      {
        id: uuidv4(),
        reportId: report.id,
        value: `<p style="text-align: center;"><span style="font-size: 24pt;"><b>${title}</b></span></p>`,
        xAxis: 0,
        yAxis: 0,
        width: 24,
        height: 2,
        type: "text",
        deletedAt: null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        report_id: report.id,
        i: uuidv4(),
        x: 0,
        y: 0,
        w: 24,
        h: 2,
        skip: true,
      },
      ...report.elements,
      ...report.widgets
    ].map((item, index) => ({
      ...item,
      i: index.toString(),
      x: item.xAxis,
      y: item.skip ? item.yAxis : item.yAxis + 2,
      w: item.width,
      h: item.height,
      type: item.type || REPORT_ELEMENT_TYPES.CHART,
    }))
  };

  _report.elements.sort((a, b) => a.y - b.y);

  return formatElementsForPDF(_report?.elements, pageBlockHeight)
};

export const formatElementsForPDF = (elements, pageBlockHeight) => {
  let completed = false;
  let page = 1;
  let newElements = elements;
  while (!completed) {
    const elementsCopy = JSON.parse(JSON.stringify(newElements));
    newElements = skipHBlock(elementsCopy, page * pageBlockHeight, (page - 1) * pageBlockHeight);
    let maxY = 0;
    for (const element of newElements) {
      maxY = Math.max(element.y + element.h, maxY);
    }

    page++;
    if (maxY < page * pageBlockHeight) completed = true;
  }

  return newElements;
};

const skipHBlock = (elements, hBlock, uhBlock) => {
  let elementsOfInterest = [];
  let adjustedElements = [];
  let newElements = [];

  let upperHOffset = -1;
  for (const eloi of elements) {
    if (eloi.h > hBlock - uhBlock) eloi.h = hBlock - uhBlock;
    const uhOffset = eloi.y - uhBlock;
    if (uhOffset >= 0 && upperHOffset === -1) upperHOffset = uhOffset;
    if (uhOffset >= 0 && uhOffset < upperHOffset) upperHOffset = uhOffset;
  }

  if (upperHOffset > 0) {
    for (const element of elements) {
      let move = false;

      if (element.y > uhBlock) {
        move = true;
      }

      let elementCopy = JSON.parse(JSON.stringify(element));

      if (move) {
        elementCopy.y -= upperHOffset;
      }

      adjustedElements.push(elementCopy);
    }
  } else {
    adjustedElements = elements;
  }

  for (const element of adjustedElements) {
    if (element.y < hBlock && (element.y + element.h) >= hBlock) {
      elementsOfInterest.push(element);
    }
  }



  let maxHOffset = 0;
  for (const eloi of elementsOfInterest) {
    const hOffset = hBlock - eloi.y;
    if (hOffset > maxHOffset) {
      maxHOffset = hOffset;
    }
  }



  for (const element of adjustedElements) {
    let move = false;
    if (element.y < hBlock && (element.y + element.h) > hBlock) {
      move = true;
    } else if (element.y > hBlock) {
      move = true;
    }

    let elementCopy = JSON.parse(JSON.stringify(element));

    if (move) {
      elementCopy.y += maxHOffset;
    }

    newElements.push(elementCopy);
  }

  return newElements;
};
