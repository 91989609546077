import React from 'react';
import styles from './PerspectiveComponent.module.scss';
import {COLORS} from "../../../constants/colors";
import {ItemHealth} from "../../../constants/strings";

const PerspectiveStatusComponent = ({perspective}) => {
  const getData = () => {
    if (perspective.items?.length === 0) {
      return {color: COLORS.lightGray, title: 'No Goals', fontColor: COLORS.darkestGray};
    } else {
      switch (perspective.health) {
        case ItemHealth.AT_RISK:
          return {color: COLORS.lightOrange, title: 'At Risk', fontColor: COLORS.orange}
        case ItemHealth.LATE:
          return {color: COLORS.lightRed, title: 'Late', fontColor: COLORS.red}
        case ItemHealth.ON_TRACK:
          return {color: COLORS.lightgreen, title: 'On Track', fontColor: COLORS.green}
        default:
          return {color: COLORS.lightGray, title: 'No Goals', fontColor: COLORS.darkestGray}
      }
    }
  }

  return (
    <div className={styles.statusContainer} style={{backgroundColor: getData().color}}>
      <p className={styles.statusText} style={{color: getData().fontColor}}>{getData().title}</p>
    </div>
  );
};

export default PerspectiveStatusComponent;
