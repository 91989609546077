import React from 'react';
import {DETAILS_TYPE, ROW_ITEM_TYPES} from "../../constants/strings";
import styles from './DetailsComponent.module.scss';
import SharingMembers from "./SharingMembers";
import {Grid} from "@mui/material";
import {daysAgoOrInFuture, defaultFormatDate, getInitials} from "../../utils/format";
import profileImage from "../../assets/icons/profile-owner.png";
import dayjs from "dayjs";
import PostUpdateFromItemRow from "../ItemUpdates/PostUpdateFromItemRow";
import {updateKpiSaga} from "../../store/kpis/actions";
import {updateActionSaga} from "../../store/action/actions";
import {postUpdateFromHomeGoalSaga} from "../../store/goals/actions";
import {postUpdateFromHomeProjectSaga} from "../../store/helper/actions";
import {useDispatch, useSelector} from "react-redux";
import {rightModalSelector} from "../../store/helper/selectors";
import {strategyPlanSelector} from "../../store/strategyPlan/selectors";

const DetailsComponent = ({items, updateDate, isBtnVisible, data}) => {

  const dispatch = useDispatch();

  const page = useSelector(rightModalSelector());
  const strategyPlan = useSelector(strategyPlanSelector());
  const imageContent = (user) => {
    if (user.photo) {
      return (
        <img src={user.photo} className={styles.image} alt={'avatar'} />
      );
    }

    if (user.name) {
      return (
        <h4 className={styles.initials}>{getInitials(user.name)}</h4>
      );
    }

    return (
      <img src={profileImage} className={styles.image} alt={'avatar'} />
    );
  };

  const handleUpdateProgress = (values) => {
    switch (data.type) {
      case ROW_ITEM_TYPES.KPI:
        dispatch(updateKpiSaga({
          data: values,
          kpiId: data?.id,
          goalId: data.goalId,
          scorecardId: strategyPlan.id,
          page: page.page,
          fromDetailsTab: true
        }));
        break;
      case ROW_ITEM_TYPES.ACTION:
        dispatch(updateActionSaga({
          data: values,
          actionId: data?.id,
          goalId: data.goalId,
          scorecardId: strategyPlan.id,
          fromDetailsTab: true,
          page: page.page,
        }));
        break;
      case ROW_ITEM_TYPES.GOAL:
        dispatch(postUpdateFromHomeGoalSaga({data: {...values, goalId: data?.id},  page: page.page, scorecardId: strategyPlan.id}))
        break;
      case ROW_ITEM_TYPES.PROJECT:
        dispatch(postUpdateFromHomeProjectSaga({data: {...values, projectId: data?.id},  page}))
        break;

      default:
        console.log('no matching type selected');
    }
  };

  const renderContent = (item) => {
    switch (item.type) {
      case DETAILS_TYPE.OWNER:
        return (
          <div className={styles.flexCenter}>
            <div className={styles.imageContainer}>
              {imageContent(item)}
            </div>
            <h4 className={styles.subtitle}>
              {item.name || item.email}
            </h4>
          </div>
        );
      case DETAILS_TYPE.OWNERS:
        return (
          <SharingMembers users={item.users} />
        );
      case DETAILS_TYPE.TIME_FRAME:
        return (
          <div>
            {item.startDate && item.endDate ? (
              <div>
                <h4 className={styles.subtitle}>
                  {defaultFormatDate(item.startDate)} {daysAgoOrInFuture(item.startDate)}
                </h4>
                <h4 className={styles.subtitle}>
                  {defaultFormatDate(item.endDate)} {daysAgoOrInFuture(item.endDate)}
                </h4>
              </div>
            ) : (
              <h4 className={styles.subtitle}>No time frame set</h4>
            )}
          </div>
        );
      case DETAILS_TYPE.DESCRIPTION:
        return (
          <div>
            {item.description ? (
              <h4 className={styles.subtitle}>{item.description}</h4>
            ) : (
              <h4 className={styles.subtitle}>No description</h4>
            )}
          </div>
        );
      case DETAILS_TYPE.UPDATE_SCHEDULE:
        return (
          <div>
            {updateDate && (
                <div className={styles.updateWrapper}>
                  <h4 className={styles.subtitle}>Next scheduled on {dayjs(updateDate).format('YYYY-MM-DD')}</h4>
                  {
                    isBtnVisible && (
                        <div>
                          <PostUpdateFromItemRow titleBtn={'Update'} data={data} handleUpdate={handleUpdateProgress}/>
                        </div>
                      )
                  }
                </div>
            )}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div>
      {items.map((item) => {
        return (
          <Grid container alignItems={item.alignment || 'center'} marginBottom={'16px'} marginTop={'16px'} key={item.id}>
            <Grid item xs={3}>
              <h4 className={styles.title}>{item.title}</h4>
            </Grid>
            <Grid item xs={9}>
              {renderContent(item)}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default DetailsComponent;
