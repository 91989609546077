import produce from 'immer';
import {
  SET_ALL_METRICS,
  SET_IS_CREATE_MODAL_VISIBLE,
  SET_IS_DELETE_MODAL_VISIBLE,
  SET_METRIC, SET_SELECTED_METRIC_FOR_EDIT, SET_SHOW_EDIT_MODAL_FROM_SIDEBAR,
} from './actionTypes';

export const initialState = {
  allMetrics: [],
  metric: null,
  isDeleteModalVisible: false,
  isCreateModalVisible: false,
  showEditModalFromSidebar: {
    id: null,
    addValue: false
  },
  selectedMetricForEdit: null
};

/* eslint-disable default-case */
const metricReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_METRIC:
        draft.metric = action.payload;
        break;
      case SET_SELECTED_METRIC_FOR_EDIT:
        draft.selectedMetricForEdit = action.payload;
        break;
      case SET_SHOW_EDIT_MODAL_FROM_SIDEBAR:
        draft.showEditModalFromSidebar = action.payload;
        break;
      case SET_IS_DELETE_MODAL_VISIBLE:
        draft.isDeleteModalVisible = action.payload;
        break;
      case SET_ALL_METRICS:
        draft.allMetrics = action.payload;
        break;
      case SET_IS_CREATE_MODAL_VISIBLE:
        draft.isCreateModalVisible = action.payload;
        break;
    }
  });

export default metricReducer;
