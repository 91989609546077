export const REPORTS = '[Reports]';
export const GET_REPORTS_GRID_SAGA = `${REPORTS} GET_REPORTS_GRID_SAGA`;
export const SET_REPORTS_GRID = `${REPORTS} SET_REPORTS_GRID`;
export const DELETE_REPORT_SAGA = `${REPORTS} DELETE_REPORT_SAGA`;
export const CREATE_EDIT_REPORT_SAGA = `${REPORTS} CREATE_EDIT_REPORT_SAGA`;
export const SET_REPORT_GRID_LAYOUT = `${REPORTS} SET_REPORT_GRID_LAYOUT`;
export const GET_REPORT_SAGA = `${REPORTS} GET_REPORT_SAGA`;
export const SET_REPORT = `${REPORTS} SET_REPORT`;
export const DUPLICATE_REPORT_SAGA = `${REPORTS} DUPLICATE_REPORT_SAGA`;
export const TAKE_REPORT_SNAPSHOT_SAGA = `${REPORTS} TAKE_REPORT_SNAPSHOT_SAGA`;
export const SET_SHARE_REPORT_MODAL = `${REPORTS} SET_SHARE_REPORT_MODAL`;
export const DELETE_REPORT_ELEMENT_SAGA = `${REPORTS} DELETE_REPORT_ELEMENT_SAGA`;
export const CREATE_EDIT_REPORT_ELEMENT_SAGA = `${REPORTS} CREATE_EDIT_REPORT_ELEMENT_SAGA`;
export const UPDATE_REPORT_ELEMENTS_SAGA = `${REPORTS} UPDATE_REPORT_ELEMENTS_SAGA`;
export const GET_SNAPSHOT_SAGA = `${REPORTS} GET_SNAPSHOT_SAGA`;
export const GET_SNAPSHOT_BY_NAME_SAGA = `${REPORTS} GET_SNAPSHOT_BY_NAME_SAGA`;
export const SET_CAN_EDIT_REPORT = `${REPORTS} SET_CAN_EDIT_REPORT`;
