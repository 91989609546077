import {ROW_ITEM_TYPES} from "../../constants/strings";

export const footerConfig = ({
  data,
  selectedPerspective,
  selectedItem,
  closeModal,
  moveItem,
  selectedType,
  retainContribution,
  isSubmitButtonEnabled,
  isParentGoal,
}) => {
  return [
    {
      id: 1,
      title: 'Cancel',
      secondary: true,
      onClick: closeModal,
      type: 'button'
    },
    {
      id: 2,
      title: 'Confirm',
      type: 'button',
      onClick: () => moveItem(
        data,
        selectedPerspective || selectedItem,
        selectedPerspective
          ? ROW_ITEM_TYPES.PERSPECTIVE
          : selectedType
            ? selectedType?.type
            : ROW_ITEM_TYPES.GOAL,
        retainContribution,
        isParentGoal,
      ),
      disabled: !isSubmitButtonEnabled()
    },
  ];
};
