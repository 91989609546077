import React from "react";
import {ResponsiveContainer} from "recharts";
import styles from './CurrentStateOfKpi.module.scss';
import {formatNumberValueWithUnit, formatPeriodRanges} from "../../../utils/dashboard";
import {TASK_TYPES, TRACKING_TYPE} from "../../../constants/strings";
import {
  WIDGET_ITEM_HEADER_MENU
} from "../../../containers/DashboardPage/SingleDashboardPage/SingleDashboardWidgetList/widgetItemHeaderMenu";
import PopupComponent from "../../Shared/Popup/PopupComponent";
import {roundToDecimalFormat} from "../../../utils/format";
import {ArrowCircleDown, ArrowCircleUp} from "@mui/icons-material";

function CurrentStateOfKpi({
                               data,
                               onChartClick,
                               showMenu,
                               onActionPress,
                               item,
                               title,
                               hideShadow,
                           }) {
    item = item ?? {zoom: 1};

    const progressStyles = () => {
        const isBelow = data.trackingType === TRACKING_TYPE.BELOW;
        const isIncreased = data.increased;

        return isBelow
            ? (isIncreased ? styles.subValueDecreased : styles.subValueIncreased)
            : (isIncreased ? styles.subValueIncreased : styles.subValueDecreased);
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <div className={`${styles.widgetWrapper} ${hideShadow && styles.widgetWrapperNoShadow}`} onClick={() => onChartClick(TASK_TYPES.KPI, [data.id])}>
                <div className={styles.header}>
                    <p
                        className={`${styles.widgetTitle} ${showMenu && styles.showMenuWidgetTitle}`}
                        style={{fontSize: 24 * item.zoom}}
                    >{title || item?.title}</p>
                    {showMenu && (
                        <PopupComponent config={WIDGET_ITEM_HEADER_MENU({item, onActionPress})} />
                    )}
                </div>
                {data && <p className={`${styles.mainValue} ${showMenu && styles.showMenuMainValue}`} style={{fontSize: 42 * item.zoom}}>{formatNumberValueWithUnit(data.actualValue, data.unit)}</p>}
                <div className={styles.subValuesWrapper}>
                    {data?.noChange || !data ?
                        <p className={styles.subValuePeriod}> - </p> :
                        <div className={styles.flexAndCenter}>
                            <p className={`${progressStyles()} ${showMenu && styles.showMenuSubValuePeriod}`} style={{fontSize: 20 * item.zoom}}>{formatNumberValueWithUnit(data?.actualProgressByPeriod, data?.unit)}({roundToDecimalFormat(data?.progressByPeriod)}%)</p>
                            {data?.increased ? (
                              <ArrowCircleUp style={{width: 20 * item?.zoom, height: 20 * item?.zoom}} className={progressStyles()} />
                            ) : (
                              <ArrowCircleDown style={{width: 20 * item?.zoom, height: 20 * item?.zoom}} className={progressStyles()} />
                            )}
                        </div>
                    }
                    <p className={styles.subValuePeriod} style={{fontSize: 14 * item.zoom}}>{formatPeriodRanges(data?.period)}</p>
                </div>
            </div>
        </ResponsiveContainer>
    );
}

export default CurrentStateOfKpi;
