import React, {memo, useState} from 'react';
import styles from './GoalItem.module.scss';
import {COLORS} from "../../constants/colors";
import {ReactComponent as CheckedIcon} from '../../assets/icons/checked.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/icons/unchecked.svg';
import {getRowItemIcon} from "../../utils/perspective";
import { ReactComponent as ChevronUpIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg';
import TooltipComponent from "../Shared/Tooltip/TooltipComponent";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

const GoalItem = ({
                    item,
                    selectedItems,
                    onItemClick,
                    lastItem,
                    isParent,
                    perspectiveId,
                  }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const terminology = useSelector(terminologySelector());

  const tooltip = () => {
    switch (true) {
      case Boolean(item?.goalExistsInParent):
        return `${terminology.goal} exists in the parent ${terminology.plan}`;
      case Boolean(item?.goalId && perspectiveId):
        return `Nested ${terminology.goal} inherit ${terminology.plan}s and ${terminology.perspective}s from their parent ${terminology.plan}`;
      default:
        return '';
    }
  };

  return (
    <TooltipComponent title={tooltip()} placement={'bottom'}>
      <div className={styles.fullWidth}>
        <div className={`${styles.container} ${(item?.goalExistsInParent || (perspectiveId && item?.goalId)) && styles.disableLibItem}`}>
          <button
            disabled={!selectedItems || item?.goalExistsInParent || (item?.goalId && perspectiveId)}
            className={`${styles.checkIconContainer} ${(item?.goalExistsInParent || (item?.goalId && perspectiveId)) && styles.disablePointerCursor}`}
            onClick={() => onItemClick(item)}
          >
            {selectedItems && (
              <div>
                {selectedItems.find((selectedItem) => selectedItem.id === item.id) ? (
                  <CheckedIcon color={COLORS.darkestGray} />
                ) : (
                  <UncheckedIcon color={COLORS.darkestGray} />
                )}
              </div>
            )}
          </button>
          <button
            disabled={item?.goalExistsInParent}
            className={`${styles.header} ${isParent && styles.parentHeader} ${(item?.items?.length === 0 || item?.goalExistsInParent || !isParent) && styles.disablePointerCursor}`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className={styles.iconContainer}>
              {getRowItemIcon(item, isParent)}
            </div>
            <h4 className={styles.title}>{item.name}</h4>
            <div className={styles.toggleExpandButtonContainer}>
              {item?.items?.length > 0 && (
                <div className={styles.chevronView}>
                  {
                    isExpanded ? (
                      <ChevronUpIcon color={COLORS.darkerGray} />
                    ) : (
                      <ChevronDownIcon color={COLORS.darkerGray} />
                    )
                  }
                </div>
              )}
            </div>
          </button>
        </div>
        {isExpanded && (
          <div className={`${styles.subItemContainer} ${styles.lastItemContainer}`}>
            {item.items?.length > 0 && item.items.map((subItem, index) => {
              return (
                <GoalItem
                  item={subItem}
                  lastItem={index === item.items.length - 1}
                  key={`${subItem.id} ${subItem.type}`}
                  isParent={false}
                />
              );
            })}
          </div>
        )}
      </div>
    </TooltipComponent>
  );
};

export default memo(GoalItem);
