export const getQueryParam = (search, param) => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
};

export const getDecodedURIComponent = (value) => {
  return value ? decodeURIComponent(value) : null;
};

export const createRouteWithParams = (baseUrl, queryParams) => {
  const queryParamsString = new URLSearchParams(queryParams).toString();
  return `${baseUrl}?${queryParamsString}`;
};

export const QUERY_PARAMS_TO_REMOVE = [
  'itemType',
  'itemId',
  'openTab',
];

export const removeQueryParams = (url, queryParamsToRemove) => {
  const baseUrl = window.location.origin;
  const urlObject = new URL(url);
  const queryParams = new URLSearchParams(urlObject.search);

  queryParamsToRemove.forEach((param) => {
    queryParams.delete(param);
  });

  urlObject.search = queryParams.toString();
  return urlObject.toString().substring(baseUrl.length);
};

export const openLink = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
  if (newWindow) newWindow.opener = null;
};
