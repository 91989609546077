import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ReactComponent as Sort } from '../../../src/assets/icons/sort-arrows-icon.svg';
import { ReactComponent as SortAsc } from '../../../src/assets/icons/sort-arrows-icon-asc.svg';
import { ReactComponent as SortDesc } from '../../../src/assets/icons/sort-arrows-icon-desc.svg';
import PopupComponent from "../Shared/Popup/PopupComponent";
import Box from "@mui/material/Box";
import styles from './Tables.module.scss';
import {checkIfStringExist} from "../../utils/checkIfStringExist";
import {defaultFormatDate} from "../../utils/format";
import {getNestedObjValue} from "../../utils/table";
import {COLORS} from "../../constants/colors";

function TableComponent({
    data,
    history,
    dispatch,
    handleChangePage,
    selectedPage,
    maxPageNumbers,
    configTable,
    onSortPress,
    rowMenuConfig,
    headerBackground = COLORS.lightestGray,
    bodyBackground = COLORS.lightestGray,
    paginated = true,
    sortField,
    sortOrder,
    onRowClick = () => {},
    rowClickable = false,
    terminology,
}) {
    const getTableCell = (item, row) => {
        switch (item.type) {
            case 'text':
                return checkIfStringExist(getNestedObjValue(row, item.value));
            case 'date':
                return defaultFormatDate(getNestedObjValue(row, item.value));
            case 'component':
                return item.component;
            default:
                console.log('no matching type selected');
                return (
                  <div />
                );
        }
    };

    const sortIcon = ({item}) => {
        switch (true) {
            case Boolean(sortOrder === 'ASC' && sortField === item?.sortField):
                return (
                    <SortAsc width={15} height={15} className={styles.sortIcon} />
                );
            case Boolean(sortOrder === 'DESC' && sortField === item?.sortField):
                return (
                  <SortDesc width={15} height={15} className={styles.sortIcon} />
                );
            default:
                return (
                  <Sort width={15} height={15} className={styles.sortIcon} />
                );
        }
    };

    return (
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <Table>
                    <TableHead sx={{backgroundColor: headerBackground}}>
                        <TableRow>
                            {configTable(null, history, dispatch, terminology).map((item, index) => {
                                return (
                                    <TableCell key={index} className={styles.tableCell}>
                                        <Box className={styles.rowWrapper}>
                                            {item.label}
                                            {item.sortable && (
                                                <button onClick={() => onSortPress(item.sortField)} className={styles.button}>
                                                    {sortIcon({item})}
                                                </button>
                                            )}
                                        </Box>
                                    </TableCell>
                                );
                            })}
                            {rowMenuConfig && (
                              <TableCell sx={{backgroundColor: headerBackground}} className={styles.emptyRow}></TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{backgroundColor: bodyBackground}}>
                        {data.map((row, index) => (
                            <TableRow key={index} onClick={() => onRowClick(row)} className={rowClickable ? styles.fullRow : ''}>
                                {configTable(row, history, dispatch, terminology).map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            {getTableCell(item, row)}
                                        </TableCell>
                                    );
                                })}
                                {rowMenuConfig && (
                                  <TableCell>
                                      <div className={styles.lastColumn}>
                                          <div className={styles.popupContainer}>
                                              <PopupComponent config={rowMenuConfig({row})} />
                                          </div>
                                      </div>
                                  </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data.length === 0 && (
                  <h4 className={styles.emptyTableTitle}>
                      No results found
                  </h4>
                )}
                {paginated && (
                    <div className={styles.paginationWrapper}>
                        <Stack spacing={2}>
                            <Pagination count={maxPageNumbers} page={selectedPage} onChange={handleChangePage} />
                        </Stack>
                    </div>
                )}
            </Box>
        </Box>
    );
}


export default TableComponent;
