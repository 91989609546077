import React from 'react';
import styles from './SidebarActionComponent.module.scss';
import {useDispatch} from "react-redux";
import {ReactComponent as ArrowLeft} from '../../../../assets/icons/arrowLeft.svg';
import {addTrackingAndBackSaga, rightSidebarBack} from "../../../../store/strategyPlan/actions";
import TrackingModalContentComponent from "../../../Shared/TrackingComponent/TrackingModalContentComponent";
const SidebarAddTrackingComponent = () => {

    const dispatch = useDispatch();
    const onBackPress = () => {
        dispatch(rightSidebarBack());
    }

    const onFormSubmit = (values) => {
        dispatch(addTrackingAndBackSaga(values));
    }

  return (
    <div className={styles.container}>
        <div className={styles.headerWrapper}>
            <button onClick={onBackPress} className={styles.backBtn}>
                <ArrowLeft />
            </button>
            <p>Back</p>
        </div>
        <TrackingModalContentComponent onFormSubmit={onFormSubmit} onCancel={onBackPress}/>
    </div>
  );
};

export default SidebarAddTrackingComponent;
