import React from 'react';
import styles from './Milestone.module.scss';
import MilestoneListItemComponent from "./MilestoneListItemComponent";

export function MilestoneListComponent({ items, formValues, onRemoveInput, onAddNew, onSetItem, errors }) {

  const aggregatedErrors = new Set();
  if (Array.isArray(errors)) {
    errors.forEach(error => {
      if (error?.percentage) aggregatedErrors.add(`*${error.percentage}`);
      if (error?.date) aggregatedErrors.add(`*${error.date}`);
    });
  } else if (errors) {
    aggregatedErrors.add(errors);
  }

  return (
    <div className={styles.milestoneList}>
      {items?.length > 0 && items.map((item, index) => {
        return (
          <MilestoneListItemComponent
              formValues={formValues}
            errors={errors?.[index]}
            onSetItem={onSetItem}
            onAddNew={onAddNew}
            items={items}
            item={item}
            key={index}
            index={index}
            onRemoveInput={onRemoveInput}
          />
        );
      })}
      {
        aggregatedErrors.size > 0 && (
          <div>
            {[...aggregatedErrors].map((error, idx) => (
              <p className={styles.errMessage} key={idx}>{error}</p>
            ))}
          </div>
        )
      }
    </div>
  );
}

export default MilestoneListComponent;
