import React, {PureComponent, useEffect, useState} from "react";
import styles from './compareMultiple.module.scss'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {CHART_COLORS_ARR, COLORS} from "../../../constants/colors";
import dayjs from "dayjs";
import {defaultFormatDate} from "../../../utils/format";
import {formatUnit} from "../../../utils/perspective";
import {formatGraphNumber} from "../../../utils/dashboard";
import {TASK_TYPES} from "../../../constants/strings";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../../store/auth/selectors";


function PerformanceOfKpis({data, onChartClick}) {
    const [graphData, setGraphData] = useState([]);
    const [graphDataKeys, setGraphDataKeys] = useState([]);

    const terminology = useSelector(terminologySelector());

    const TooltipWrapper = () => (
        <div className={styles.tooltipWrapper}>
            {
                data.map((item, index) => {
                    return(
                        <div key={index} className={styles.singleTooltipItem}>
                            <p className={styles.itemTitleText}>{item.name}</p>
                            {
                                item.data.map((val, ind) => {
                                    return(
                                        <div key={ind} className={styles.dateTooltipWrapper}>
                                            <div className={styles.leftItemWrapper}>
                                                <p className={styles.itemText}>{dateFormatter(val.timestamp)}</p>
                                            </div>
                                            <div>
                                                <p className={styles.itemText}>{val.actualProgress + formatUnit(item.unit) + '/' + val.value + formatUnit(item.unit)}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltipWrapper}>
                    <p className={styles.title}>{defaultFormatDate(label)}</p>
                    <TooltipWrapper />
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        let formattedData = [];
        let dataKeys = [];

        data.forEach((item) => {
            const dataKeyFound = dataKeys.find((el) => el === item.name);
            if (!dataKeyFound) dataKeys.push(item.name);
            setGraphDataKeys(dataKeys)

            item.data.forEach((update) => {
                if (!formattedData[update.timestamp]) {
                    formattedData[update.timestamp] = {};
                    formattedData[update.timestamp]["name"] = update.timestamp;
                    formattedData[update.timestamp]["ids"] = [];

                }
                formattedData[update.timestamp][item.name] = update.value;
                formattedData[update.timestamp]["ids"].push(item.id);
            });
        });

        let dataForGraph = [];
        for (const [key, value] of Object.entries(formattedData)) {
            dataForGraph.push(value);
        }
        dataForGraph = dataForGraph.sort((a, b) => a.name - b.name);
        setGraphData(dataForGraph);
    }, [data]);

    const dateFormatter = date => {
        return dayjs(new Date(date)).format('MMM-DD/YY');
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={15} y={-5} dy={16} fontSize={11} textAnchor="end" fill={COLORS.legendBlack} >
                        {defaultFormatDate(payload.value)}
                    </text>
                </g>
            );
        }
    }

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload, anchor, color } = props;

        return (
            <text x={x} y={y} textAnchor={anchor} fill={color}>
                {formatGraphNumber(payload.value)}
            </text>
        );
    };

    const oneDayInMiliseconds = 86400000;

    return (
        <div className={styles.wrapper}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={graphData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={(e) => {
                        if (e.activePayload?.length > 0) {
                            onChartClick(TASK_TYPES.KPI, e.activePayload[0]?.payload?.ids);
                        }
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        tick={<CustomizedAxisTick/>}
                        dataKey="name"
                        scale="time"
                        domain={graphData.length > 1 ? [graphData[0].name - oneDayInMiliseconds, graphData[graphData.length - 1].name + oneDayInMiliseconds] : ['auto', 'auto']}
                        type={'number'}
                        tickFormatter={dateFormatter}
                    />
                    <YAxis
                        label={{value: `${terminology.kpi}s Measure Value (${formatUnit(data[0]?.unit, true)})`, angle: -90, style: {fill: COLORS.legendBlack, fontSize: 12}, dx: -25}}
                        tick={<CustomizedYAxisTick anchor={'end'} />}
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    {data.map((s, index) => (
                        <Bar
                            fill={CHART_COLORS_ARR[index]}
                            dataKey={graphDataKeys[index]}
                            key={s.name} />
                    ))}
                    <Legend />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
export default PerformanceOfKpis;
