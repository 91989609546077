import React, {memo, useCallback, useState} from 'react';
import {getRowItemIcon, goalStatus} from "../../../../utils/perspective";
import styles from './CriteriaTabListItem.module.scss';
import CustomDot from "../../../Shared/Dot/CustomDot";
import {ReactComponent as ScalesIcon} from '../../../../assets/icons/scales.svg';
import SwitchInput from "../../../Shared/Inputs/SwitchInput";
import RatingComponent from "../../../Shared/RatingComponent/RatingComponent";
import {DETAILS_TYPE, ROW_ITEM_TYPES, WEIGHT_TYPE} from "../../../../constants/strings";
import DetailsComponent from "../../DetailsComponent";
import CriteriaTabListItemProgressComponent from "./CriteriaTabListItemProgressComponent";
import goalService from "../../../../services/GoalService";
import kpiService from "../../../../services/KpiService";
import projectService from "../../../../services/ProjectService";
import actionService from "../../../../services/ActionService";
import {refreshPageSaga} from "../../../../store/helper/actions";
import {useDispatch} from "react-redux";
import {updateActionSaga} from "../../../../store/action/actions";
import {updateKpiSaga} from "../../../../store/kpis/actions";

const CriteriaTabListItem = ({
                               parent,
                               item,
                               onEditItem,
                               onShowItem,
                               weighing,
                               showWeighing,
                               scorecardId,
                               rightModal,
                               page,
}) => {
  const dispatch = useDispatch();
  const [isWeighed, setIsWeighed] = useState(item.weighed);
  const [weight, setWeight] = useState(item.weight);

  const onSetWeighed = useCallback(async (value) => {
    try {
      const data = {
        weight: value,
      };

      switch (item.type) {
        case ROW_ITEM_TYPES.GOAL:
          await goalService.updateGoalWeight(data, item.id);
          break;
        case ROW_ITEM_TYPES.KPI:
          await kpiService.updateKpiWeight(data, item.id);
          break;
        case ROW_ITEM_TYPES.PROJECT:
          await projectService.updateProjectWeight(data, item.id);
          break;
        case ROW_ITEM_TYPES.ACTION:
          await actionService.updateActionWeight(data, item.id);
          break;
        default:
          console.log('no matching type selected');
      }

      dispatch(refreshPageSaga({
        page: page,
        scorecardId,
        goalId: rightModal.goalId,
        parentId: parent?.id,
      }));
      setWeight(value);
    } catch (error) {
      console.error({error});
    }

  }, [item.type, item.id, dispatch, page, scorecardId, rightModal.goalId, parent?.id]);

  const handleChangeIsWeighed = useCallback(async () => {
    try {
      const data = {
        weighed: !isWeighed,
      };

      switch (item.type) {
        case ROW_ITEM_TYPES.GOAL:
          await goalService.updateGoalWeighed(data, item.id);
          break;
        case ROW_ITEM_TYPES.KPI:
          await kpiService.updateKpiWeighed(data, item.id);
          break;
        case ROW_ITEM_TYPES.PROJECT:
          await projectService.updateProjectWeighed(data, item.id);
          break;
        case ROW_ITEM_TYPES.ACTION:
          await actionService.updateActionWeighed(data, item.id);
          break;
        default:
          console.log('no matching type selected');
      }

      dispatch(refreshPageSaga({
        page: page,
        scorecardId,
        goalId: rightModal.goalId,
        parentId: parent?.id,
        entity: rightModal.entity,
        entityId: rightModal.entityId,
      }));
      setIsWeighed(!isWeighed);
    } catch (error) {
      console.error({error});
    }
  }, [isWeighed, item.type, item.id, dispatch, page, scorecardId, rightModal.goalId, rightModal.entity, rightModal.entityId, parent?.id]);

  const detailList = [
    {id: 1, type: DETAILS_TYPE.OWNER, title: 'Owner', name: item.owner.name, email: item.owner.email, photo: item.owner.photo},
    {id: 2, type: DETAILS_TYPE.OWNERS, title: 'Sharing With', users: item.owners},
    {id: 3, type: DETAILS_TYPE.TIME_FRAME, title: 'Time Frame', startDate: item.startDate, endDate: item.endDate, alignment: 'start'},
    {id: 4, type: DETAILS_TYPE.DESCRIPTION, title: 'Description', description: item.description, alignment: 'start'},
    {id: 5, type: DETAILS_TYPE.UPDATE_SCHEDULE, title: 'Update Schedule', date: item.endDate},
  ];

  const handleUpdateProgress = useCallback((values) => {
    switch (item.type) {
      case ROW_ITEM_TYPES.KPI:
        dispatch(updateKpiSaga({
          data: values,
          kpiId: item?.id,
          scorecardId,
          goalId: rightModal.goalId,
          parentId: parent?.id,
          page,
          fromCriteriaTab: true,
          entity: rightModal.entity,
          entityId: rightModal.entityId,
        }));
        break;
      case ROW_ITEM_TYPES.ACTION:
        dispatch(updateActionSaga({
          data: values,
          actionId: item?.id,
          scorecardId,
          goalId: rightModal.goalId,
          parentId: parent?.id,
          page,
          fromCriteriaTab: true,
          entity: rightModal.entity,
          entityId: rightModal.entityId,
        }));
        break;
      default:
        console.log('no matching type selected');
    }
  }, [item.type, item?.id, dispatch, scorecardId, rightModal.goalId, rightModal.entity, rightModal.entityId, parent?.id, page]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.flexCenter}>
          {getRowItemIcon(item, false)}
          <div onClick={() => onShowItem(item)} className={`${styles.button}`}>
            <h4 className={styles.title}>{item.name}</h4>
          </div>
        </div>
        <div className={styles.flexCenter}>
          <div className={styles.flexCenter}>
            {
                goalStatus(item.health).color && <CustomDot backgroundColor={goalStatus(item.health).color} />
            }
            <h4 className={styles.title}>{goalStatus(item?.health)?.title}</h4>
          </div>
        </div>
      </div>
      <CriteriaTabListItemProgressComponent
        item={item}
        handleUpdateProgress={handleUpdateProgress}
      />
      <DetailsComponent items={detailList} />
      <div className={styles.footer}>
        <div className={styles.flexCenter}>
          <ScalesIcon width={24} height={24} />
          {isWeighed && weighing === WEIGHT_TYPE.CUSTOM && (
            <div className={styles.ratingComponentContainer}>
              <RatingComponent value={weight} setValue={onSetWeighed} />
            </div>
          )}
        </div>
        {showWeighing && (
          <div className={styles.flexCenter}>
            <h4 className={styles.subtitle}>Weighed</h4>
            <SwitchInput checked={isWeighed} handleChange={handleChangeIsWeighed} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CriteriaTabListItem);
