import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
    ADD_EXISTING_PERSPECTIVES_SAGA,
    ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA,
    ADD_NEW_PERSPECTIVE_SAGA,
    CHANGE_GOAL_PARENT_SAGA,
    EDIT_FULL_ITEM_SAGA,
    EDIT_ITEM_FOR_PERSPECTIVE_SAGA,
    EDIT_ITEM_STATUS_SAGA,
    MOVE_ITEM_SAGA,
    UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA,
    UPDATE_PERSPECTIVE_SAGA,
} from './actionTypes';
import perspectiveService from "../../services/PerspectiveService";
import {setLoader} from "../loader/actions";
import {
    getStrategyPlanSaga,
    setAllItemsOnHomePage,
    setGoalsOnHomePage,
    setProjectsActionsKpisOnHomePage,
    setShowEditParent
} from "../strategyPlan/actions";
import {setShowEditPerspectiveModal, setShowPerspectiveLibraryModal} from "./actions";
import scorecardService from "../../services/ScorecardService";
import {setSnackbar} from "../snackbar/actions";
import {ADD_ACTION_TYPE, ROW_ITEM_TYPES} from "../../constants/strings";
import goalService from "../../services/GoalService";
import kpiService from "../../services/KpiService";
import {
    refreshPageSaga,
    setActionModalFromNavbar,
    setItemAndParentForAction, setModal,
    setRefresh,
    setWarningModal
} from "../helper/actions";
import actionService from "../../services/ActionService";
import projectService from "../../services/ProjectService";
import {getItemParent} from "../../utils/perspective";
import {rightModalSelector} from "../helper/selectors";
import {setGoalsAlignmentModal} from "../alignment/actions";
import homeService from "../../services/HomeService";
import {addFrontIdToEveryItem} from "../../utils/helper";
import {terminologySelector} from "../auth/selectors";

export function* updatePerspectiveSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const terminology = yield select(terminologySelector());
        yield call(perspectiveService.updatePerspective, payload);
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${terminology.perspective} successfully updated`,
        }));
        yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        yield put(setShowEditPerspectiveModal(false));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* updatePerspectiveFromDashboardSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const terminology = yield select(terminologySelector());
        yield call(perspectiveService.updatePerspective, payload);
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${terminology.perspective} successfully updated`,
        }));
        yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        yield put(setRefresh(true));
        yield put(setShowEditPerspectiveModal(false));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* addNewPerspectivesSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const terminology = yield select(terminologySelector());
        yield call(perspectiveService.addNewPerspective, payload);
        yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${terminology.perspective} successfully created`,
        }));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* addNewItemForPerspectivesSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        switch (payload.type) {
            case ROW_ITEM_TYPES.GOAL:
                yield call(perspectiveService.addNewGoal, payload);
                break
            case ROW_ITEM_TYPES.KPI:
                yield call(perspectiveService.addNewKPI, payload);
                break
            case ROW_ITEM_TYPES.ACTION:
                yield call(perspectiveService.addNewAction, payload);
                break
            case ROW_ITEM_TYPES.PROJECT:
                yield call(perspectiveService.addNewProject, payload);
                break
            default:
                console.log('other action', payload);
                break

        }
        switch (payload.addActionType) {
            case ADD_ACTION_TYPE.STRATEGY_PLAN:
                yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
                break;
            case ADD_ACTION_TYPE.HOME_PAGE_GOAL:
                const resGoals = yield call(homeService.getHomeData);
                const goalsObj = {
                    items: resGoals.upcomingGoals
                }
                addFrontIdToEveryItem(goalsObj);
                yield put(setGoalsOnHomePage(goalsObj))
                break;
            case ADD_ACTION_TYPE.HOME_PAGE_PROJECTS_ACTIONS_KPIS:
                const resProjects = yield call(homeService.getHomeData);
                const projectsActionsKpisObj = {
                    items: resProjects.allOtherItems
                }
                addFrontIdToEveryItem(projectsActionsKpisObj);
                yield put(setProjectsActionsKpisOnHomePage(projectsActionsKpisObj))
                break;
            case ADD_ACTION_TYPE.UPCOMING_GOALS:
                const allGoalsRes = yield call(homeService.getAllUpcomingGoals);
                const allGoals = {
                    items: allGoalsRes
                }
                addFrontIdToEveryItem(allGoals);
                yield put(setAllItemsOnHomePage(allGoals))
                break;
            case ADD_ACTION_TYPE.ACTIONS_PROJECTS:
                const allActionsProjectsRes = yield call(homeService.getAllUpcomingProjectsKpisActions);
                const allActionsProjects = {
                    items: allActionsProjectsRes
                }
                addFrontIdToEveryItem(allActionsProjects);
                yield put(setAllItemsOnHomePage(allActionsProjects))
                break;
          default: break;
        }

        yield put(setActionModalFromNavbar({
            isVisible: false,
            title: '',
            component: null
        }))
        const terminology = yield select(terminologySelector());
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${terminology[payload.type.toLowerCase()]} successfully created`,
        }));
    } catch (error) {
        yield put(setLoader(false));
        console.log({error});
    } finally {
        yield put(setLoader(false));
        yield put(setItemAndParentForAction({parent: null, item: null}));
    }
}

export function* editItemForPerspectivesSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        switch (payload.type) {
            case ROW_ITEM_TYPES.GOAL:
                yield call(perspectiveService.editNameGoal, payload);
                break
            case ROW_ITEM_TYPES.KPI:
                yield call(perspectiveService.editKpiName, payload);
                break
            case ROW_ITEM_TYPES.ACTION:
                yield call(perspectiveService.editActionName, payload);
                break
            case ROW_ITEM_TYPES.PROJECT:
                yield call(perspectiveService.editProjectName, payload);
                break
            default:
                console.log('other action', payload);
                break

        }
        if (payload.scorecardId) {
            yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        }
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${payload.type} name successfully edited`,
        }));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* editStatusForItemsSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        const data = {
            status: payload.status,
        }
        yield call(perspectiveService.editActionStatus, data, payload.itemId);
        const action = yield call(scorecardService.getActionById, payload.itemId)
        yield put(setItemAndParentForAction({parent: getItemParent(action), item: action}))
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* editFullItemSagaFn({payload}) {
    try {
        yield put(setLoader(true));
        switch (payload.type) {
            case ROW_ITEM_TYPES.GOAL:
                break
            case ROW_ITEM_TYPES.KPI:
                yield call(perspectiveService.editKpi, payload);
                break
            case ROW_ITEM_TYPES.ACTION:
                yield call(perspectiveService.editAction, payload);
                break
            case ROW_ITEM_TYPES.PROJECT:
                yield call(scorecardService.updateProject, payload)
                break
            default:
                console.log('other action', payload);
                break

        }
        yield put(getStrategyPlanSaga({scorecardId: payload.scorecardId}));
        yield put(setActionModalFromNavbar({
            isVisible: false,
            title: '',
            component: null
        }))
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${payload.type} successfully edited`,
        }));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
        yield put(setItemAndParentForAction({parent: null, item: null}));
    }
}

export function* addExistingPerspectivesSagaFn({perspectives, scorecardId}) {
    try {
        yield put(setLoader(true));
        const data = {
            perspectiveNames: perspectives.map((perspective) => perspective.name),
            scorecardId,
        };
        yield call(perspectiveService.addExistingPerspectives, data);
        yield put(setShowPerspectiveLibraryModal(false));
        yield put(getStrategyPlanSaga({scorecardId}));
    } catch (error) {
        console.log({error});
    } finally {
        yield put(setLoader(false));
    }
}

export function* moveItemSagaFn({
                                    item,
                                    parentId,
                                    parentType,
                                    retainContribution,
                                    isParentGoal,
                                    scorecardId,
                                    removeLinks,
                                    page
                                }) {
    try {
        yield put(setLoader(true));
        let data;
        switch (item.type) {
            case ROW_ITEM_TYPES.GOAL:
                data = {
                    parentId,
                    parentType,
                    retainContribution,
                    ...(removeLinks && {removeLinks}),
                };
                yield call(goalService.moveGoal, data, item.id);
                break;
            case ROW_ITEM_TYPES.KPI:
                data = {
                    goalId: parentId,
                };
                yield call(kpiService.moveKpi, data, item.id);
                break;
            case ROW_ITEM_TYPES.ACTION:
                data = {
                    parentId,
                    parentType,
                };
                yield call(actionService.moveAction, data, item.id);
                break;
            case ROW_ITEM_TYPES.PROJECT:
                data = {
                    goalId: parentId,
                };
                yield call(projectService.moveProject, data, item.id);
                break;
            default:
                return;
        }
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${item.name} ${item.type} successfully moved! `,
        }));
        yield put(setActionModalFromNavbar({
            isVisible: false,
            title: '',
            component: null,
        }));
        yield put(setGoalsAlignmentModal({
            isVisible: false,
            title: '',
            maxWidth: 'md',
            component: null,
        }))
        yield put(setModal({
            isVisible: false,
            title: '',
            maxWidth: 'md',
            component: null,
        }))
        const rightModal = yield select(rightModalSelector());
        yield put(refreshPageSaga({page: page ? page : rightModal.page, scorecardId, goalId: rightModal.goalId}));
        yield put(setWarningModal({isVisible: false}));
    } catch (error) {
        console.error({error});
        if (error.response.data?.additionalErrorProperty?.goalHasLinks) {
            yield put(setWarningModal({
                isVisible: true,
                title: '',
                maxWidth: 'md',
                component: null,
            }));
        }
    } finally {
        yield put(setLoader(false));
    }
}

export function* changeGoalParentSagaFn({
                                            item,
                                            parentId,
                                            parentType,
                                            retainContribution,
                                            isParentGoal,
                                            scorecardId,
                                            removeLinks
                                        }) {
    try {
        yield put(setLoader(true));
        const data = {
            parentId,
            parentType,
            retainContribution,
            ...(removeLinks && {removeLinks}),
        };
        yield call(goalService.moveGoal, data, item.id);
        yield put(setSnackbar({
            open: true,
            severity: 'success',
            message: `${item.name} ${item.type} successfully moved! `,
        }));
        yield put(setShowEditParent(false));
        yield put(getStrategyPlanSaga({scorecardId}));
        yield put(setWarningModal({isVisible: false}));
    } catch (error) {
        console.error({error});
        if (error.response.data?.additionalErrorProperty?.goalHasLinks) {
            yield put(setWarningModal({
                isVisible: true,
                title: '',
                maxWidth: 'md',
                component: null,
            }));
        }
    } finally {
        yield put(setLoader(false));
    }
}

export default function* perspectivesSaga() {
    yield takeLatest(UPDATE_PERSPECTIVE_SAGA, updatePerspectiveSagaFn);
    yield takeLatest(UPDATE_PERSPECTIVE_FORM_DASHBOARD_SAGA, updatePerspectiveFromDashboardSagaFn);
    yield takeLatest(ADD_EXISTING_PERSPECTIVES_SAGA, addExistingPerspectivesSagaFn);
    yield takeLatest(ADD_NEW_PERSPECTIVE_SAGA, addNewPerspectivesSagaFn);
    yield takeLatest(ADD_NEW_ITEM_FOR_PERSPECTIVE_SAGA, addNewItemForPerspectivesSagaFn);
    yield takeLatest(EDIT_ITEM_FOR_PERSPECTIVE_SAGA, editItemForPerspectivesSagaFn);
    yield takeLatest(EDIT_FULL_ITEM_SAGA, editFullItemSagaFn);
    yield takeLatest(MOVE_ITEM_SAGA, moveItemSagaFn);
    yield takeLatest(EDIT_ITEM_STATUS_SAGA, editStatusForItemsSagaFn);
    yield takeLatest(CHANGE_GOAL_PARENT_SAGA, changeGoalParentSagaFn);
}
