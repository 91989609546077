import React from 'react';
import {InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";

const CustomTextInput = ({
                             border,
                             disabled,
                             type = 'text',
                             onKeyUp,
                             onClick,
                             onClickSend,
                             autoFocus,
                             onFocus,
                             onBlur,
                             background,
                             placeholder,
                             value,
                             borderRadius = 8,
                             height = 48,
                             showSearchIcon = false,
                             showSendIcon = false,
                             handleInputChange
                         }) => {
    return (
        <TextField
            fullWidth={true}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autoFocus}
            onClick={onClick}
            onSubmit={onClickSend}
            onKeyUp={onKeyUp}
            type={type}
            disabled={disabled}
            inputProps={{onClick: (e) => e.currentTarget.select()}}
            InputProps={{
                sx: {
                    height: `${height}px`,
                    borderRadius: `${borderRadius}px`,
                    background: background,
                    border: border
                },
                autoComplete: 'off',
                startAdornment: showSearchIcon ? (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ) : null,
                endAdornment: showSendIcon ? (
                    <InputAdornment position="end">
                        <SendIcon style={{ cursor: 'pointer' }} onClick={onClickSend} />
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

export default CustomTextInput;
