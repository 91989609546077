import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from './ItemUpdatesComponentListItem.module.scss';
import {defaultFormatDate, formatUpdateText, getRangePercentage, timeAgo} from "../../utils/format";
import ProgressBarComponent from "../Shared/ProgressBar/ProgressBarComponent";
import profileImage from "../../assets/images/user.png";
import {ROW_ITEM_TYPES} from "../../constants/strings";
import {getMarksForProgressBar, getRowItemIcon} from "../../utils/perspective";
import {COLORS} from "../../constants/colors";
import {useSelector} from "react-redux";
import {terminologySelector} from "../../store/auth/selectors";

const ItemUpdatesComponentListItem = ({item, type, backgroundColor}) => {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [kpiMarks, setKpiMarks] = useState(null);

  const terminology = useSelector(terminologySelector());

  const showFullMessageFn = useCallback(() => {
    setShowFullMessage(!showFullMessage);
  }, [showFullMessage]);

  const actualProgress = useCallback(() => {
    switch (type) {
      case ROW_ITEM_TYPES.GOAL:
      case ROW_ITEM_TYPES.PROJECT:
      case ROW_ITEM_TYPES.ACTION:
        return item.value;
      case ROW_ITEM_TYPES.KPI:
        return getRangePercentage(item.value, item.kpi.min, item.kpi.max);
      default:
        return 0;
    }
  }, [item.kpi?.max, item.kpi?.min, item.value, type]);

  const getKpiMarks = useCallback(() => {
    if (type === ROW_ITEM_TYPES.KPI) {
      setKpiMarks(getMarksForProgressBar(item.kpi));
    }
  }, [item, type]);

  useEffect(() => {
    getKpiMarks();
  }, [getKpiMarks]);

  const getNestedObject = () => {
    switch (true) {
      case Boolean(item.childGoalUpdate):
        return {
          item: item.childGoalUpdate,
          type: ROW_ITEM_TYPES.GOAL,
        };
      case Boolean(item.kpiUpdate):
        return {
          item: item.kpiUpdate,
          type: ROW_ITEM_TYPES.KPI,
        };
      case Boolean(item.projectUpdate):
        return {
          item: item.projectUpdate,
          type: ROW_ITEM_TYPES.PROJECT,
        };
      case Boolean(item.actionUpdate):
        return {
          item: item.actionUpdate,
          type: ROW_ITEM_TYPES.ACTION,
        };
      default:
        return {
          item: null,
          type: null,
        };
    }
  };

  const getUpdatedItem = () => {
    switch (true) {
      case Boolean(item.kpi):
        return item.kpi;
      case Boolean(item.project):
        return item.project;
      case Boolean(item.action):
        return item.action;
      default:
        return {...item.goal, type: ROW_ITEM_TYPES.GOAL};
    }
  };

  return (
    <div className={styles.container} style={{backgroundColor: backgroundColor}}>
      <div className={styles.headerContainer}>
        <div className={styles.imageContainer}>
          <img src={item.user?.photo ? item.user?.photo : profileImage} className={styles.image} />
        </div>
        <div>
          <h4 className={styles.time}>{timeAgo(new Date(item.updatedAt))}</h4>
          <div className={styles.nameContainer}>
            {getRowItemIcon(getUpdatedItem(), !getUpdatedItem()?.goalId)}
            <h2 className={styles.name}>{getUpdatedItem()?.name}</h2>
          </div>
          <h2 className={styles.title}>{formatUpdateText({text: item.title, terminology})}</h2>
          <div className={styles.messageContainer}>
            <p>
              {(item.message?.length > 50 && !showFullMessage) ? item.message?.slice(0, 50) + '...' : item.message}
              {item.message?.length > 50 && (
                <span className={styles.showFullMessageText} onClick={showFullMessageFn}>{showFullMessage ? 'hide' : 'see more'}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.snapshotContainer}>
        <h4 className={styles.snapshot}>
          Snapshot: {defaultFormatDate(item.updatedAt)}
        </h4>
      </div>
      <ProgressBarComponent
        item={{
          ...item,
          ...(item.kpi && {...item.kpi}),
          actualProgress: actualProgress(),
          health: item.health,
        }}
        header
        headerValue={item.value}
        showHeaderBadge
        hideValue
        showHeaderProgress={(type === ROW_ITEM_TYPES.GOAL || type === ROW_ITEM_TYPES.PROJECT)}
        showHeaderUnitProgress={(type === ROW_ITEM_TYPES.KPI || type === ROW_ITEM_TYPES.ACTION)}
        type={type}
        kpiMarks={kpiMarks}
      />
      <div className={styles.nestedObjectContainer}>
        {getNestedObject()?.item && (
          <ItemUpdatesComponentListItem
            item={getNestedObject()?.item}
            key={getNestedObject()?.item?.id}
            type={getNestedObject()?.type}
            backgroundColor={COLORS.white}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ItemUpdatesComponentListItem);
