import React, {useEffect, useState} from 'react';
import styles from './Milestone.module.scss'
import DefaultButton from "../../Buttons/DefaultButton";
import ExpectedProgress from "../ExpectedProgress";
import {COLORS} from "../../../../constants/colors";
import MilestoneListComponent from "./MilestoneListComponent";
import dayjs from "dayjs";

export function MilestoneComponent({item, setFormValues, clearProp, errors, formValues, items}) {
  const INITIAL_MILESTONES = [{name: 'Start', date: dayjs(item?.startDate), percentage: 0}, { name: 'Milestone 1', date: dayjs(item?.startDate), percentage: 25}, { name: 'Milestone 2', date: dayjs(item?.startDate), percentage: 100}]
  const [milestones, setMilestones] = useState(INITIAL_MILESTONES)
  const onHandleMilestones = () => {
    if(milestones?.length > 0) {
      setMilestones([]);
      setFormValues({milestones: []})
    } else {
      setMilestones(INITIAL_MILESTONES)
      setFormValues({milestones: INITIAL_MILESTONES})
    }
  }

  useEffect(() => {
    if(items?.length > 0) {
      setFormValues({milestones: items})
      setMilestones(items)
    }
  }, [items])

  const onHandleRemoveInput = (val) => {
    let newArr = milestones.filter((item, index) => index !== val)
    setMilestones(newArr)
    setFormValues({milestones: newArr})
  }

  const onHandleSetItem =(val)=> {
    const newArr = [...milestones];
    newArr?.splice(val.index, 1, val.milestone)
    setMilestones(newArr)
    setFormValues({milestones: newArr})

  }

  const onHandleAddNew = (val) => {
    let newArr = [...milestones]
    newArr.splice(val+1, 0, {name: '', date: dayjs(item?.startDate), percentage: milestones[val].percentage < 99 ? milestones[val].percentage + 1 : 99})

    setMilestones(newArr)
  }

  return (
    <div className={styles.manuallyContainer}>
      <DefaultButton buttonStyles={{marginBottom: '8px'}} onClick={onHandleMilestones} type={'button'} title={milestones?.length > 0 ? 'Remove Milestones' : 'Add Milestones'} border={`1px solid ${COLORS.blue}`} leftIcon={milestones?.length > 0 ? '' : '+'} height={40} width={'40%'} inverted={true}/>
      <MilestoneListComponent formValues={formValues} errors={errors?.milestones} onSetItem={onHandleSetItem} items={milestones} onAddNew={onHandleAddNew} onRemoveInput={onHandleRemoveInput}/>
      <ExpectedProgress padding={0} item={item} clearProp={clearProp} title={'Expected progress'}
                        setFormValues={setFormValues}/>
    </div>
  )

}

export default MilestoneComponent;
