import React, {useState} from 'react';
import styles from './DetailsTab.module.scss';
import PopupComponent from "../../../Shared/Popup/PopupComponent";
import {menuConfig} from "../../menuConfig";
import ChartWithProgressComponent from "../../../Shared/ChartWithProgressComponent/ChartWithProgressComponent";
import DetailsComponent from "../../DetailsComponent";
import {DETAILS_TYPE} from "../../../../constants/strings";
import ContributesToComponent from "../../../Shared/ContributesToComponent";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import {setParentDataForContributesToComponent} from "../../../../utils/setDataForContributesToComponent";
import CommentSection from "../../../CommentSection";
import {ReactComponent as ArrowDown} from "../../../../assets/icons/arrowDown.svg";
import ExpectedProgress from "../../../Shared/TrackingComponent/ExpectedProgress";
import {CalendarMonth} from "@mui/icons-material";
import {terminologySelector} from "../../../../store/auth/selectors";

const DetailsTab = ({data, onAddSuccessCriteria}) => {
    const itemWithParent = useSelector(itemAndParentForActionSelector());
    const terminology = useSelector(terminologySelector());

    const [showScheduleFrequency, setShowScheduleFrequency] = useState(false);

    const detailList = [
        {id: 1, type: DETAILS_TYPE.OWNER, title: 'Owner', name: data.owner.name || data.owner.email, photo: data.owner.photo},
        {id: 2, type: DETAILS_TYPE.OWNERS, title: 'Sharing With', users: data.owners},
        {id: 3, type: DETAILS_TYPE.TIME_FRAME, title: 'Time Frame', startDate: data.startDate, endDate: data.endDate, alignment: 'start'},
        {id: 4, type: DETAILS_TYPE.DESCRIPTION, title: 'Description', description: data.description, alignment: 'start'},
        {id: 5, type: DETAILS_TYPE.UPDATE_SCHEDULE, title: 'Update Schedule', date: data.endDate},
    ];
    const onBoxShow = () => {
        setShowScheduleFrequency(!showScheduleFrequency);
    }

  return (
    <div className={styles.container}>
      <div className={styles.detailsWithoutComments}>
      <div className={styles.selectContainer}>
        <div className={styles.fieldContainer} />
        <PopupComponent
            config={menuConfig({
              onClick: onAddSuccessCriteria,
              item: data,
              button: true,
              terminology,
            })}
        />
      </div>
      <div style={{width: '100%'}}>
          <ChartWithProgressComponent data={itemWithParent.item}/>
      </div>
        <div className={styles.infoWrapper}>
            <DetailsComponent data={data} updateDate={data.nextUpdateDate} isBtnVisible={data.needsUpdate} items={detailList}/>
            <ContributesToComponent item={itemWithParent.item}
                                    parent={setParentDataForContributesToComponent(itemWithParent.parent, itemWithParent?.parent?.strategyPlanName)}
                                    showForms={false}
                                    canEditParent={false}
            />
            <div className={styles.scheduleFrequencyWrapper} style={{height: showScheduleFrequency ? 'auto' : '70px'}}>
                <div className={styles.titleWrapper}>
                    <div className={styles.leftTitleWrapper}>
                        <CalendarMonth/>
                        <p className={styles.titleText}>Update schedule frequency</p>
                    </div>
                    <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: showScheduleFrequency ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                        <ArrowDown/>
                    </button>
                </div>
                <ExpectedProgress showCheckbox={false} disableInputs={true} item={data} padding={'0'}
                                  setFormValues={()=>{}}/>
            </div>
        </div>
      </div>
      <div className={styles.commentSectionWrapper}>
        <CommentSection item={itemWithParent.item}/>
      </div>
    </div>
  );
};

export default DetailsTab;
