import {ROW_ITEM_TYPES, TASK_TYPES, TRACKING_TYPE} from "../constants/strings";
import {measurementData} from "../components/Shared/TrackingComponent/progressScheduleData";
import {formatNumberValueWithUnit} from "./dashboard";
import {v4 as uuidv4} from "uuid";
import SidebarPlanComponent from "../components/RightModal/Plan/SidebarPlanComponent";
import {rightModalOpen, setItemAndParentForAction} from "../store/helper/actions";
import scorecardService from "../services/ScorecardService";
import SidebarKpiComponent from "../components/RightModal/Kpi/SidebarKpiComponent";
import React from "react";
import {getItemParent} from "./perspective";
import SidebarGoalComponent from "../components/RightModal/Goal/SidebarGoalComponent";
import SidebarProjectComponent from "../components/RightModal/Project/SidebarProjectComponent";
import SidebarActionComponent from "../components/RightModal/Action/SidebarActionComponent";
import {setItemRow} from "../store/strategyPlan/actions";

export const generateDateRanges = () => {
  const currentDate = new Date();
  const nextQuarter = Math.floor((currentDate.getMonth() + 3) / 3) + 1;
  let currentYear = currentDate.getFullYear();

  const quarters = [];

  for (let i = nextQuarter; i < nextQuarter + 4; i++) {
    let quarter = i % 4;
    let year = currentYear + Math.floor((i - 1) / 4);

    if (quarter === 0) {
      quarter = 4;
    }

    const startDate = new Date(year, (quarter - 1) * 3, 1);
    const endDate = new Date(year, quarter * 3, 0);
    const label = `Quarter ${quarter} (${year})`;

    quarters.push({ id: quarter, startDate, endDate, label });
  }

  return quarters;
};

export const isDateInRange = (date, startDate, endDate) => {
  const normalizedDate = new Date(date);
  const normalizedStartDate = new Date(startDate);
  const normalizedEndDate = new Date(endDate);

  normalizedStartDate.setHours(0, 0, 0, 0);
  normalizedEndDate.setHours(23, 59, 59, 999);

  return (
    normalizedDate.getTime() >= normalizedStartDate.getTime() &&
    normalizedDate.getTime() <= normalizedEndDate.getTime()
  );
};

export const getMeasurementName = (value, prop) => {
  return measurementData.find((item) => item.value === value)['name'];
}

export const getKpiTarget = (kpi) => {
  if(kpi){
    let type = kpi?.trackingType;
    let upperTarget = kpi?.upperTarget
    let lowerTarget = kpi?.lowerTarget
    let unit = kpi.unit

    switch (type) {
      case TRACKING_TYPE.ACHIEVED:
        return formatNumberValueWithUnit(lowerTarget, unit)
      case TRACKING_TYPE.BELOW:
        return formatNumberValueWithUnit(upperTarget, unit)
      case TRACKING_TYPE.EXCEED:
        return formatNumberValueWithUnit(lowerTarget, unit)
      case TRACKING_TYPE.BETWEEN:
        return <div>
          <p>Between</p>
          <p>{`${formatNumberValueWithUnit(lowerTarget, unit)}/${formatNumberValueWithUnit(upperTarget, unit)}`}</p>
        </div>

    }
  }

}

export const getSourceType = (type) => {
  switch (type) {
    case TASK_TYPES.GOAL:
      return ROW_ITEM_TYPES.GOAL;
    case TASK_TYPES.GROUP:
      return ROW_ITEM_TYPES.GROUP;
    case TASK_TYPES.PERSPECTIVE:
      return ROW_ITEM_TYPES.VIEWPOINT;
    case TASK_TYPES.PLAN:
      return ROW_ITEM_TYPES.PLAN;
    case TASK_TYPES.OWNER:
      return ROW_ITEM_TYPES.OWNER;
  }
}

export const getSource = (data) => {
  switch (true) {
    case Boolean(data.owner):
      return {sourceType: TASK_TYPES.OWNER, selectedSource: {name: data.owner.name ? data.owner.name : data.owner.email}};
    case Boolean(data.perspective):
      return {sourceType: TASK_TYPES.PERSPECTIVE, selectedSource: {name: data.perspective.name}};
    case Boolean(data.scorecard):
      return {sourceType: TASK_TYPES.PLAN, selectedSource: {name: data.scorecard.name}};
    case Boolean(data.goal):
      return {sourceType: TASK_TYPES.GOAL, selectedSource: {name: data.goal.name}};
    case Boolean(data.group):
      return {sourceType: TASK_TYPES.GROUP, selectedSource: {name: data.group.name}};
    default:
      return {}

  }
}

export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const addFrontIdToEveryItem = (obj) => {
  if (obj.hasOwnProperty('items') && Array.isArray(obj.items)) {
    obj.items.forEach(item => {
      item.frontId = uuidv4();
      addFrontIdToEveryItem(item);
    });
  }
};

export const SIDEBAR_ITEM_TYPE = {
  SIDEBAR_PLAN: 'sidebar-plan',
  GOAL: 'goal',
  KPI: 'kpi',
  PROJECT: 'project',
  ACTION: 'action',
};

export const OPENED_TAB_TYPES = {
  DETAILS: 'details',
  SUCCESS_CRITERIA: 'success-criteria',
  RISKS: 'risk',
  UPDATES: 'updates',
  RELATIONSHIPS: 'relationships',
};

export const openRightSidebar = async ({
                                         dispatch,
                                         itemType,
                                         itemId,
                                         page,
                                         scorecardId,
                                       }) => {
  try {
    let component;
    let selectedItem;
    switch (itemType.toLowerCase()) {
      case SIDEBAR_ITEM_TYPE.SIDEBAR_PLAN:
        component = (
          <SidebarPlanComponent planId={itemId} />
        );
        break;
      case SIDEBAR_ITEM_TYPE.GOAL:
        selectedItem = await scorecardService.getGoalById(itemId);
        component = (
          <SidebarGoalComponent />
        );
        break;
      case SIDEBAR_ITEM_TYPE.KPI:
        selectedItem = await scorecardService.getKpiById(itemId);
        component = (
          <SidebarKpiComponent />
        );
        break;
      case SIDEBAR_ITEM_TYPE.PROJECT:
        selectedItem = await scorecardService.getProjectById(itemId);
        component = (
          <SidebarProjectComponent />
        );
        break;
      case SIDEBAR_ITEM_TYPE.ACTION:
        selectedItem = await scorecardService.getActionById(itemId);
        component = (
          <SidebarActionComponent />
        );
        break;
      default:
        component = (
          <div />
        );
    }

    if (
      itemType.toLowerCase() === SIDEBAR_ITEM_TYPE.GOAL ||
      itemType.toLowerCase() === SIDEBAR_ITEM_TYPE.KPI ||
      itemType.toLowerCase() === SIDEBAR_ITEM_TYPE.PROJECT ||
      itemType.toLowerCase() === SIDEBAR_ITEM_TYPE.ACTION
    ) {
      dispatch(setItemAndParentForAction({parent: getItemParent(selectedItem), item: selectedItem}));
      dispatch(setItemRow(selectedItem));
    }

    dispatch(rightModalOpen({
      isVisible: true,
      component,
      page,
      scorecardId,
    }));
  } catch (error) {
    console.error({error});
  }
};
