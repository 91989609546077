import React, {memo, useEffect, useState} from 'react';
import styles from './AddNewProjectModalContentComponent.module.scss';
import DefaultButton from "../../../../components/Shared/Buttons/DefaultButton";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../components/Shared/Inputs/InputField";
import TextareaField from "../../../../components/Shared/Inputs/TextareaField";
import SelectField from "../../../../components/Shared/Inputs/SelectField";
import {helperService} from "../../../../services/HelperService";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ReactComponent as AlignIcon} from '../../../../assets/icons/share.svg';
import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrowDown.svg';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AutocompleteField from "../../../../components/Shared/Inputs/AutocompleteField";
import {createProjectSchema} from "../../../../validation/scorecard";
import ContributesToComponent from "../../../../components/Shared/ContributesToComponent";
import {
  getScorecardId,
  setParentDataForContributesToComponent
} from "../../../../utils/setDataForContributesToComponent";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import {CalendarMonth} from "@mui/icons-material";
import ExpectedProgress from "../../../../components/Shared/TrackingComponent/ExpectedProgress";
import usersService from "../../../../services/UsersService";
import {terminologySelector} from "../../../../store/auth/selectors";

const AddNewProjectModalContentComponent = ({onFormSubmit, onCancel, canContributesToEditParent}) => {
    const [showSharingWith, setShowSharingWith] = useState(false);
    const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
    const [showScheduleFrequency, setShowScheduleFrequency] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [ownerIdValue, setOwnerIdValue] = useState(itemAndParentForAction?.item?.owner?.id || null);

    const terminology = useSelector(terminologySelector());

    const handleStartDateChange = (newDate, setFieldValue) => {
        setFieldValue('startDate', newDate);
    }

    const handleEndDateChange = (newDate, setFieldValue) => {
        setFieldValue('endDate', newDate);
    }

    const handleOnSubmit = async (values) => {
        let obj = {
            ...values,
            owners: values?.owners?.map((item) => item.id)
        }
        onFormSubmit(obj);
    }

    const onBoxShowScheduler = () => {
        setShowScheduleFrequency(!showScheduleFrequency);
    }

    const onBoxShow = () => {
        setShowSharingWith(!showSharingWith);
    }

    const setUpdateFrequencyValues = (setFieldValue, values) => {
        setFieldValue('period', values.period);
        setFieldValue('day', values.day);
    }

  const getUsers = async () => {
    try {
      let scorecardId = getScorecardId(itemAndParentForAction?.parent);
      if (scorecardId) {
        const res = await usersService.getUsersByScorecard(scorecardId);
        const owner = res.find((user) => user.id === ownerIdValue);
        if (itemAndParentForAction?.item?.owner && !owner) {
          res.push({...itemAndParentForAction?.item?.owner, disabled: true});
        }
        setUsersForOptions(res);
      }
    } catch (e) {
      console.log('err', {e});
    }
  };

  const setUsersForOptions = (users) => {
    setUserOptions(users.map((user) => {
      return {
        id: user.id,
        value: user.id,
        title: user.title ? user.title : user.name,
        subtitle: user.subtitle ? user.subtitle : user.email,
        disabled: user.id === ownerIdValue,
        image: user.image ? user.image : user.photo,
      }
    }))
  }

  useEffect(() => {
    getUsers()
  }, [itemAndParentForAction])

  useEffect(() => {
    setUsersForOptions(userOptions)
  }, [ownerIdValue])

  const setNewOwner = (id, values, setValues) => {
    setOwnerIdValue(id)
    let newArr = values.owners.filter((owner) => owner.id !== id)
    setValues({...values, owners: [...newArr], ownerId: id})
  }

    return (
        <div>
            <Formik
                initialValues={{
                    id: itemAndParentForAction?.item?.id || null,
                    name: itemAndParentForAction?.item?.name || '',
                    description: itemAndParentForAction?.item?.description || '',
                    goalId: itemAndParentForAction?.item?.goalId || itemAndParentForAction?.parent?.id,
                    ownerId: itemAndParentForAction?.item?.owner?.id || null,
                    startDate: itemAndParentForAction?.item?.startDate ? dayjs(itemAndParentForAction?.item?.startDate) : null,
                    endDate: itemAndParentForAction?.item?.endDate ? dayjs(itemAndParentForAction?.item?.endDate) : null,
                    period: itemAndParentForAction?.item?.period || '',
                    day: itemAndParentForAction?.item?.day || '',
                    owners: itemAndParentForAction?.item?.owners?.map((user) => {
                            return {
                                id: user.id,
                                value: user.id,
                                title: user.name,
                                subtitle: user.email,
                                image: user.photo
                            };
                        })
                        || [],
                }}
                onSubmit={handleOnSubmit}
                validationSchema={createProjectSchema}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {({setFieldValue, errors, values, setValues}) => {
                    return (
                        <Form>
                            <div className={styles.container}>
                                <div className={styles.formWrapper}>
                                    <Field
                                        component={InputField}
                                        name={'name'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.project} Name`}
                                        label={`${terminology.project} Name`}
                                    />
                                    <Field
                                        component={TextareaField}
                                        name={'description'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.project} Description`}
                                        label={`${terminology.project} Description`}
                                    />
                                    <Field
                                        component={SelectField}
                                        name={'ownerId'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: `${terminology.project} Owner`,
                                            icon: require('../../../../assets/icons/profile-owner.png'),
                                        }}
                                        label={`${terminology.project} Owner`}
                                        options={{
                                            options: userOptions,
                                            imageKey: 'photo',
                                            defaultImage: require('../../../../../src/assets/icons/profile-owner.png'),
                                            titleKey: 'name',
                                            subtitleKey: 'email',
                                        }}
                                        getFieldVal={(val) => setNewOwner(val, values, setValues)}
                                    />
                                    <div className={styles.dateInputsWrapper}>
                                        <div className={styles.singleDateInputWrapper}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'startDate'} value={ values.startDate || null}
                                                            onChange={(e) => handleStartDateChange(e, setFieldValue)}
                                                            label="Start Date"
                                                            minDate={dayjs(itemAndParentForAction.parent.startDate)}
                                                            maxDate={dayjs(itemAndParentForAction.parent.endDate)}
                                                />
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.startDate && errors.startDate}</p>
                                        </div>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'endDate'} value={values.endDate || null}
                                                            onChange={(e) => handleEndDateChange(e, setFieldValue)}
                                                            label="End Date"
                                                            minDate={values.startDate || dayjs(itemAndParentForAction.parent.startDate)}
                                                            maxDate={dayjs(itemAndParentForAction.parent.endDate)}/>
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.endDate && errors.endDate}</p>
                                        </div>
                                    </div>
                                    <div className={styles.wrapper} style={{height: showSharingWith ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <AlignIcon/>
                                                <p className={styles.titleText}>Sharing with</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: showSharingWith ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                        <Field
                                            component={AutocompleteField}
                                            name={'owners'}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            placeholder={{
                                                value: 'Start typing name or email',
                                            }}
                                            label={`Add teammates to collaborate with this ${terminology.project}. Search for a user by name or email.`}
                                            options={{options: userOptions, disabledText: 'Owner', subtitleKey: 'email', imageKey: 'photo', defaultImage: require('../../../../../src/assets/icons/profile-owner.png')}}
                                        />
                                    </div>
                                    <ContributesToComponent canEditParent={canContributesToEditParent} item={itemAndParentForAction.item} parent={setParentDataForContributesToComponent(itemAndParentForAction?.parent, itemAndParentForAction?.parent?.strategyPlanName)} showForms={false} setFormikField={(val) => setFieldValue('sharedGoalIds', val)}/>
                                    <div className={styles.wrapper} style={{height: showScheduleFrequency ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <CalendarMonth/>
                                                <p className={styles.titleText}>Update schedule frequency</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShowScheduler} className={styles.arrowDownBtn} style={{transform: showScheduleFrequency ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                        <ExpectedProgress showCheckbox={false} item={itemAndParentForAction?.item} padding={'0'} title={'Expected progress'}
                                                          setFormValues={(values) => setUpdateFrequencyValues(setFieldValue, values)}/>
                                        <p className={styles.errorMessageDay}>{errors.day}</p>
                                    </div>
                                </div>
                                <div className={styles.fixedSubmitWrapper}>
                                    <DefaultButton title={'Cancel'} type={'button'} secondary onClick={onCancel} />
                                    <DefaultButton title={'Save'} type={'submit'} buttonStyles={{marginLeft: '16px'}} />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default memo(AddNewProjectModalContentComponent);
