import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_SUBSCRIPTIONS: 'stripe/get-subscription',
  CHECKOUT: 'stripe/checkout',
  MANAGE_SUBSCRIPTION: 'stripe/create-portal-session',
  SEND_LETS_TALK_EMAIL: 'subscriptions/lets-talk',
  SWITCH_PLAN: 'subscriptions/switch-plan',
  CANCEL_RENEW_SUBSCRIPTION: 'subscriptions/cancel-plan',
  CONTACT_FOR_PLAN: 'subscriptions/contact-for-plan',
};

class BillingService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getSubscriptions = () => {
    return this.httpService.request({
      url: ROUTES.GET_SUBSCRIPTIONS,
      method: HTTP_METHODS.GET,
    });
  };

  contactForPlan = (data) => {
    return this.httpService.request({
      url: ROUTES.CONTACT_FOR_PLAN,
      method: HTTP_METHODS.POST,
      data
    });
  };

  switchPlan = (data) => {
    return this.httpService.request({
      url: ROUTES.SWITCH_PLAN,
      method: HTTP_METHODS.POST,
      data
    });
  };

  sendLetsTalkEmail = () => {
    return this.httpService.request({
      url: ROUTES.SEND_LETS_TALK_EMAIL,
      method: HTTP_METHODS.GET,
    });
  };

  manageSubscription = () => {
    return this.httpService.request({
      url: ROUTES.MANAGE_SUBSCRIPTION,
      method: HTTP_METHODS.POST,
    });
  };

  checkoutRequest = (data) => {
    return this.httpService.request({
      url: ROUTES.CHECKOUT,
      method: HTTP_METHODS.POST,
      data
    });
  };

  cancelRenewSubscription = (data) => {
    return this.httpService.request({
      url: ROUTES.CANCEL_RENEW_SUBSCRIPTION,
      method: HTTP_METHODS.POST,
      data
    });
  };
}

const billingService = new BillingService(httpService);

export default billingService;
