import httpService from './HttpService';
import {HTTP_METHODS} from "../constants/http";

const ROUTES = {
  GET_NOTIFICATIONS: 'notifications/:limit',
  UPDATE_NOTIFICATIONS_SETTINGS: 'users/notification-settings',
  DELETE_NOTIFICATION: 'notifications/:id',
};

class NotificationsService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getNotifications = (limit) => {
    return this.httpService.request({
      url: ROUTES.GET_NOTIFICATIONS.replace(':limit', limit ? limit : ''),
      method: HTTP_METHODS.GET,
    });
  };

  updateNotificationsSettings = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_NOTIFICATIONS_SETTINGS,
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  deleteNotification = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_NOTIFICATION.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };
}

const notificationsService = new NotificationsService(httpService);

export default notificationsService;
