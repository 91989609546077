import React from "react";
import styles from './multiplePlansSnapshot.module.scss'
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import GraphTableComponent from "../../Shared/GraphTableComponent";
function MultiplePlansSnapshot({
                                 data,
                                 label,
                                 onChartClick,
                                 type = ROW_ITEM_TYPES.PLAN,
                                 fromReport,
                               }) {

    return (
        <div className={styles.wrapper}>
            <GraphTableComponent data={data} fromReport={fromReport} />
        </div>
    );
}


export default MultiplePlansSnapshot;
