import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FETCH_AUTHENTICATED_USER_REQUEST,
  FETCH_AUTHENTICATED_USER_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SET_TOKEN,
  SESSION_EXPIRED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SOCIAL_AUTH_REQUEST,
  SOCIAL_AUTH_SUCCESS,
  SOCIAL_AUTH_ERROR,
  SET_LOGIN_ERROR,
  RESET_PASSWORD_SAGA,
  SET_USER_JWT_DATA,
  ON_GOOGLE_SIGN_UP,
  ON_LINKEDIN_SIGN_UP,
  SEND_TWO_FA_REQUEST_SAGA,
  SET_TWO_FA_ERROR,
  SET_SIDEBAR_STATE,
  SELECT_ORGANIZATION,
  CREATE_ORGANIZATION,
  SIGN_IN_MICROSOFT_SAGA,
  SET_REFRESH_TOKEN,
  SIGN_IN_FROM_INVITATION, SET_TERMINOLOGY,
} from './actionTypes'

export function login(email, password, setErrors) {
  return {
    type: LOGIN_REQUEST,
    email,
    password,
    meta: {
      setErrors,
    },
  };
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

export function fetchAuthenticatedUser() {
  return {
    type: FETCH_AUTHENTICATED_USER_REQUEST,
  };
}

export function fetchAuthenticatedUserSuccess(user) {
  return {
    type: FETCH_AUTHENTICATED_USER_SUCCESS,
    user,
  };
}

export function signInFromInvitation(payload) {
  return {
    type: SIGN_IN_FROM_INVITATION,
    payload,
  };
}

export function onGoogleSignUpSaga(payload) {
  return {
    type: ON_GOOGLE_SIGN_UP,
    payload,
  };
}

export function signInMicrosoftSaga(payload) {
  return {
    type: SIGN_IN_MICROSOFT_SAGA,
    payload,
  };
}

export function onLinkedInSignUpSaga(payload) {
  return {
    type: ON_LINKEDIN_SIGN_UP,
    payload,
  };
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function setRefreshToken(token) {
  return {
    type: SET_REFRESH_TOKEN,
    token,
  };
}

export function setUserJwtData(data) {
  return {
    type: SET_USER_JWT_DATA,
    data,
  };
}

export function sessionExpired() {
  return {
    type: SESSION_EXPIRED,
  };
}

export function forgotPassword(email, setErrors) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    email,
    meta: {
      setErrors,
    },
  };
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordError() {
  return {
    type: FORGOT_PASSWORD_ERROR,
  };
}

export function register(password, name, email, setErrors) {
  return {
    type: REGISTER_REQUEST,
    password,
    name,
    email,
    meta: {
      setErrors,
    },
  };
}

export function registerSuccess(payload) {
  return {
    type: REGISTER_SUCCESS,
      payload
  };
}

export function resetPasswordSaga(payload) {
  return {
    type: RESET_PASSWORD_SAGA,
    payload
  };
}

export function registerError() {
  return {
    type: REGISTER_ERROR,
  };
}

export function resetPassword(password, passwordConfirmation, setErrors) {
  return {
    type: RESET_PASSWORD_REQUEST,
    password,
    passwordConfirmation,
    meta: {
      setErrors,
    },
  };
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordError() {
  return {
    type: RESET_PASSWORD_ERROR,
  };
}

export function socialAuthentication(accessToken, provider) {
  return {
    type: SOCIAL_AUTH_REQUEST,
    accessToken,
    provider,
  };
}

export function socialAuthSuccess() {
  return {
    type: SOCIAL_AUTH_SUCCESS,
  };
}

export function socialAuthError(error) {
  return {
    type: SOCIAL_AUTH_ERROR,
    error,
  };
}

export function setLoginError(error) {
  return {
    type: SET_LOGIN_ERROR,
    error,
  };
}

export function setSidebarState(data) {
  return {
    type: SET_SIDEBAR_STATE,
    data,
  };
}

export function selectOrganizationSaga(organization) {
  return {
    type: SELECT_ORGANIZATION,
    organization
  };
}

export function sendTwoFASaga(payload) {
  return {
    type: SEND_TWO_FA_REQUEST_SAGA,
    payload
  };
}

export function setTwoFAError(payload) {
  return {
    type: SET_TWO_FA_ERROR,
    payload
  };
}

export function createOrganizationSaga(data) {
  return {
    type: CREATE_ORGANIZATION,
    data
  };
}

export function setTerminology(payload) {
  return {
    type: SET_TERMINOLOGY,
    payload
  };
}
