import React, {useEffect, useState} from 'react';
import styles from './GroupSelect.module.scss'
import {ListSubheader, MenuItem, Select, styled} from "@mui/material";

export function GroupSelect({title, selectOptions, value, handleChange, disabled = false}) {
  const [option, setOption] = useState(value)

  useEffect(() => {
      setOption(value)
  },[value])

  const StyledSelect = styled(Select)`

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
    padding: 6px 12px;
  }
`;


  return (
    <div className={styles.container}>
      <p className={styles.titleText}>{title}</p>
      <StyledSelect disabled={disabled} inputProps={{ 'aria-label': 'Without label' }} fullWidth renderValue={(p) => {
        return <p className={styles.render}>{p.icon} {p.title}</p>
      } } value={option} id="grouped-select"  onChange={handleChange} >
        {selectOptions?.reduce((acc, p) => {
          acc.push(
            <ListSubheader style={{fontSize: p.titleFontSize, padding: '0 0 0 16px'}} key={`subheader-${p.title}`}>{p.title}</ListSubheader>
          );
          acc.push(
            ...p.options.map(item => (
              <MenuItem disabled={item.disabled} key={item.id} value={item}>
                <div className={styles.optionWrapper}>
                  <div className={styles.titleWrapper}>
                    {item.icon}
                    <p className={styles.itemTitle}>{item.title}</p>
                  </div>
                  <p className={styles.subtitle}>{item.subtitle}</p>
                </div>
              </MenuItem>
            ))
          );
          return acc;
        }, [])}
      </StyledSelect>
    </div>
  );
}

export default GroupSelect;
