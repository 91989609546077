import React from 'react';
import styles from './TrackingComponent.module.scss'
import {addTrackingToKpiSchema} from "../../../validation/scorecard";
import {Form, Formik} from "formik";
import {
  selectTrackingOptions
} from "../../../containers/StrategyPlanPage/ItemRowComponent/Components/KpiModalContentComponent/trackingData";
import TrackingComponent from "./TrackingComponent";
import {ROW_ITEM_TYPES} from "../../../constants/strings";
import DefaultButton from "../Buttons/DefaultButton";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../store/helper/selectors";
import {terminologySelector} from "../../../store/auth/selectors";

export function TrackingModalContentComponent({onFormSubmit, padding = '20px', onCancel}) {
  const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
  const terminology = useSelector(terminologySelector());

  const handleOnSubmit = async (values) => {
    onFormSubmit(values)
  }

  return (
    <div className={styles.manuallyContainer} style={{padding: 0, paddingTop: padding, paddingBottom: padding}}>
      <Formik
        initialValues={{
          id: itemAndParentForAction?.item?.id,
          upperTarget: itemAndParentForAction?.item?.upperTarget || null,
          lowerTarget: itemAndParentForAction?.item?.lowerTarget,
          targetType: itemAndParentForAction?.item?.targetType,
          min: itemAndParentForAction?.item?.min,
          max: itemAndParentForAction?.item?.max,
          unit: itemAndParentForAction?.item?.unit,
          trackingType: itemAndParentForAction?.item?.trackingType,

        }}
        onSubmit={handleOnSubmit}
        validationSchema={addTrackingToKpiSchema}
        enableReinitialize={true}
        validateOnChange={false}
      >
        {({setFieldValue, errors, values, validateForm, setValues}) => {
          return (
            <Form>
              <div className={styles.containerForModal}>
                <div className={styles.formWrapper}>

                  {selectTrackingOptions &&
                    <TrackingComponent formValues={values} validateForm={validateForm} setValues={setValues} errors={errors} isInModal={true} item={itemAndParentForAction.item}
                                       itemType={ROW_ITEM_TYPES.KPI} setFieldValue={setFieldValue}
                                       selectTitle={`How do you want to track this ${terminology.kpi}`}
                                       selectOptions={selectTrackingOptions}/>}
                  <div className={styles.fixedSubmitWrapper}>
                    <DefaultButton title={'Cancel'} type={'button'} secondary onClick={onCancel} />
                    <DefaultButton title={'Save'} type={'submit'} buttonStyles={{marginLeft: '16px'}} />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

    </div>
  )

}

export default TrackingModalContentComponent;
