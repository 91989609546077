import {
  ADD_TRACKING_SAGA,
  CHANGE_PROGRESS_FROM_RIGHT_BAR_SAGA, REMOVE_KPI_CONNECTION_SAGA,
  RESET_STATE,
  UPDATE_KPI_PROGRESS_SAGA,
  UPDATE_KPI_SAGA,
} from './actionTypes';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function updateKpiProgressSaga(payload, kpiId, scorecardId, goalId, page) {
  return {
    type: UPDATE_KPI_PROGRESS_SAGA,
    payload,
    kpiId,
    scorecardId,
    goalId,
    page,
  };
}

export function changeProgressFromRightBarSaga(payload, kpiId, scorecardId, page) {
  return {
    type: CHANGE_PROGRESS_FROM_RIGHT_BAR_SAGA,
    payload,
    kpiId,
    scorecardId,
    page,
  };
}

export function setKpi(kpi) {
  return {
    type: UPDATE_KPI_PROGRESS_SAGA,
    kpi,
  };
}
export function addTrackingSaga(payload) {
  return {
    type: ADD_TRACKING_SAGA,
    payload,
  };
}

export function updateKpiSaga(payload) {
  return {
    type: UPDATE_KPI_SAGA,
    payload,
  };
}

export function removeConnectionSaga(payload) {
  return {
    type: REMOVE_KPI_CONNECTION_SAGA,
    payload,
  };
}
