import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  UPDATE_PERSPECTIVE_ACTION_PROGRESS_SAGA, UPDATE_PERSPECTIVE_ACTION_PROGRESS_WITH_CHART_SAGA,
} from './actionTypes';
import {setLoader} from "../loader/actions";
import scorecardService from "../../services/ScorecardService";
import actionService from "../../services/ActionService";
import {refreshPageSaga, setItemAndParentForAction} from "../helper/actions";
import goalService from "../../services/GoalService";
import {getItemParent} from "../../utils/perspective";

export function* updatePerspectiveActionProgressSagaFn({payload, perspectiveActionId, scorecardId, goalId, page}) {
  try {
    yield put(setLoader(true));
    if(payload.completedMilestoneId) {
      yield call(actionService.updateActionMilestone, payload, perspectiveActionId);
    } else {
      yield call(actionService.updateActionProgress, payload, perspectiveActionId);
    }
    if (goalId) {
      const goal = yield call(goalService.getGoalWithItems, goalId);
      yield put(setItemAndParentForAction({item: goal, parent: getItemParent(goal)}));
    }
    yield put(refreshPageSaga({page: page, scorecardId}));
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export function* updatePerspectiveActionProgressWithChartSagaFn({payload, perspectiveActionId, scorecardId, page}) {
  try {
    yield put(setLoader(true));
    if(payload.completedMilestoneId) {
      yield call(actionService.updateActionMilestone, payload, perspectiveActionId);
    } else {
      yield call(actionService.updateActionProgress, payload, perspectiveActionId);
    }
    yield put(refreshPageSaga({page, scorecardId}));
    const action = yield call(scorecardService.getActionById, perspectiveActionId)
    yield put(setItemAndParentForAction({parent: action.project || action.goal, item: action}))
  } catch (error) {
    console.log({error});
  } finally {
    yield put(setLoader(false));
  }
}

export default function* perspectiveActionsSaga() {
  yield takeLatest(UPDATE_PERSPECTIVE_ACTION_PROGRESS_SAGA, updatePerspectiveActionProgressSagaFn);
  yield takeLatest(UPDATE_PERSPECTIVE_ACTION_PROGRESS_WITH_CHART_SAGA, updatePerspectiveActionProgressWithChartSagaFn);
}
