import {GOALS_HEALTH} from "../constants/strings";
import styles from "../style.module.scss";
import {COLORS} from "../constants/colors";
import React from "react";

export const getNestedObjValue = (obj, desc) => {
  let arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};


export const getTableHealthStyle = (health) => {
  switch (health) {
    case GOALS_HEALTH.LATE:
      return <div className={styles.flexAndCenter}>
        <div className={styles.healthDot} style={{background: COLORS.progressBarLightRed}}/>
        <p className={styles.getHealthStyleText} style={{color: COLORS.progressBarLightRed}}>{GOALS_HEALTH.LATE}</p>
      </div>
    case GOALS_HEALTH.AT_RISK:
      return <div className={styles.flexAndCenter}>
        <div className={styles.healthDot} style={{background: COLORS.brightDarkerOrange}}/>
        <p className={styles.getHealthStyleTextAtRisk} style={{color: COLORS.brightDarkerOrange}}>{GOALS_HEALTH.AT_RISK}</p>
      </div>
    case GOALS_HEALTH.ACHIEVED:
      return <div className={styles.flexAndCenter}>
        <div className={styles.healthDot} style={{background: COLORS.progressBarGreen}}/>
        <p className={styles.getHealthStyleText} style={{color: COLORS.progressBarGreen}}>{GOALS_HEALTH.ACHIEVED}</p>
      </div>
    case GOALS_HEALTH.EXCEEDED:
      return <div className={styles.flexAndCenter}>
        <div className={styles.healthDot} style={{background: COLORS.progressBarLightRed}}/>
        <p className={styles.getHealthStyleText} style={{color: COLORS.progressBarLightRed}}>{GOALS_HEALTH.EXCEEDED}</p>
      </div>
    case GOALS_HEALTH.ON_TRACK:
      return <div className={styles.flexAndCenter}>
        <div className={styles.healthDot} style={{background: COLORS.lightBlue}}/>
        <p className={styles.getHealthStyleText} style={{color: COLORS.lightBlue}}>{GOALS_HEALTH.ON_TRACK}</p>
      </div>
    default: return <div className={styles.flexAndCenter}>
        <p>-</p>
    </div>
  }
}
