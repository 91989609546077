import InputField from "../../components/Shared/Inputs/InputField";
import SelectField from "../../components/Shared/Inputs/SelectField";

export const createWorkspaceFromConfig = (user) => {
    return {
        fields: [
            {
                id: 1,
                component: InputField,
                name: 'name',
                placeholder: 'Enter your name',
                label: 'Full name',
                value: user.name || null,
                required: true,
            },
            {
                id: 2,
                component: InputField,
                name: 'jobTitle',
                placeholder: 'Enter job title',
                label: 'Job title',
                value: null,
                required: true,
            },
            {
                id: 3,
                component: InputField,
                name: 'organizationName',
                placeholder: 'Enter workspace name',
                label: 'Workspace Name',
                value: null,
            },
            // {
            //     id: 4,
            //     component: SelectField,
            //     name: 'region',
            //     placeholder: {
            //         value: 'Choose Workspace region',
            //     },
            //     label: 'Workspace Region',
            //     options: {
            //         options: regions
            //     },
            //     value: null,
            //     canSelectEmpty: true,
            // },
        ],
        footer: [
            {id: 1, title: 'Create Workspace', type: 'submit', width: '100%'},
        ],
    };
};
