export const TABS = [
  {
    id: 1,
    title: 'Details',
  },
  {
    id: 2,
    title: 'Success Criteria',
  },
  {
    id: 3,
    title: 'Risks',
  },
  {
    id: 4,
    title: 'Updates',
  },
  {
    id: 5,
    title: 'Relationships',
    disabled: true,
  },
];
