import React, {memo, useEffect, useState} from 'react';
import styles from './AddGoalModalContentComponent.module.scss';
import DefaultButton from "../../../../components/Shared/Buttons/DefaultButton";
import {Field, Form, Formik} from "formik";
import InputField from "../../../../components/Shared/Inputs/InputField";
import TextareaField from "../../../../components/Shared/Inputs/TextareaField";
import SelectField from "../../../../components/Shared/Inputs/SelectField";
import {helperService} from "../../../../services/HelperService";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ReactComponent as AlignIcon} from '../../../../assets/icons/share.svg';
import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrowDown.svg';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AutocompleteField from "../../../../components/Shared/Inputs/AutocompleteField";
import {createGoalSchema} from "../../../../validation/scorecard";
import scorecardService from "../../../../services/ScorecardService";
import perspectiveService from "../../../../services/PerspectiveService";
import OutlinedSelectInput from "../../../../components/Shared/Inputs/OutlinedSelectInput";
import ContributesToComponent from "../../../../components/Shared/ContributesToComponent";
import { useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../store/helper/selectors";
import {
  setParentDataForContributesToComponent
} from "../../../../utils/setDataForContributesToComponent";
import {ROW_ITEM_TYPES} from "../../../../constants/strings";
import ExpectedProgress from "../../../../components/Shared/TrackingComponent/ExpectedProgress";
import {CalendarMonth} from "@mui/icons-material";
import usersService from "../../../../services/UsersService";
import {terminologySelector} from "../../../../store/auth/selectors";

const AddGoalModalContentComponent = ({
  planId,
  parentType,
  onFormSubmit,
  onCancel,
  scorecard,
  canContributesToEditParent,
}) => {
    const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
    const [itemAndParent, setItemAndParent] = useState(itemAndParentForAction.parent);
    const [perspectiveOptions, setPerspectiveOptions] = useState([]);
    const [showSharingWith, setShowSharingWith] = useState(false);
    const [showScheduleFrequency, setShowScheduleFrequency] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [ownerIdValue, setOwnerIdValue] = useState(itemAndParentForAction?.item?.owner?.id || null);

    const terminology = useSelector(terminologySelector());

    const getPerspectiveOptions = async (selectedPlanId) => {
        try {
            const perspectives = await perspectiveService.getPerspectivesForLibrary({scorecardId: selectedPlanId});
            const perspectiveOptions = perspectives.map(perspective => {
                return {
                    id: perspective.id,
                    value: perspective.id,
                    name: perspective.name,
                    scorecard: perspective.scorecard,
                }
            })
            setPerspectiveOptions(perspectiveOptions);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect( () => {
        if(planId) {
          getPerspectiveOptions(planId);
          getUsers(planId)
        }
    }, []);

    const handlePlanIdChange = async (fieldName, planId, setFieldValue, setValues, values) => {
        setValues({...values, owners: [], ownerId: null})
        setFieldValue(fieldName, planId);
        setFieldValue('parentId', '');
        getPerspectiveOptions(planId)
        getUsers(planId)
    }

    const handleStartDateChange = (newDate, setFieldValue) => {
        setFieldValue('startDate', newDate);
    }

    const handleEndDateChange = (newDate, setFieldValue) => {
        setFieldValue('endDate', newDate);
    }

    const handleOnSubmit = async (values) => {
        onFormSubmit(values)
    }

    const onBoxShow = () => {
        setShowSharingWith(!showSharingWith);
    }

    const onBoxShowScheduler = () => {
        setShowScheduleFrequency(!showScheduleFrequency);
    }

    const setPerspectiveItem = (value, setFieldValue) => {
        setFieldValue('parentId', value);
        let perspective = perspectiveOptions.find(item => item.id === value)
        if (perspective) {
            setItemAndParent({...perspective, type: 'Perspective'});
        }
    }

    const setUpdateFrequencyValues = (setFieldValue, values) => {
        setFieldValue('period', values.period);
        setFieldValue('day', values.day);
    }

  const getUsers = async (scorecardId) => {
    try {
      if (scorecardId) {
        const res = await usersService.getUsersByScorecard(scorecardId);
        const owner = res.find((user) => user.id === ownerIdValue);
        if (itemAndParentForAction?.item?.owner && !owner) {
          res.push({...itemAndParentForAction?.item?.owner, disabled: true});
        }
        setUsersForOptions(res);
      }
    } catch (e) {
      console.log('err', {e});
    }
  };

  const setUsersForOptions = (users) => {
    setUserOptions(users.map((user) => {
      return {
        id: user.id,
        value: user.id,
        title: user.title ? user.title : user.name,
        subtitle: user.subtitle ? user.subtitle : user.email,
        disabled: user.id === ownerIdValue,
        image: user.image ? user.image : user.photo,
      }
    }))
  }

  useEffect(() => {
    setUsersForOptions(userOptions)
  }, [ownerIdValue])

  const setNewOwner = (id, values, setValues) => {
    setOwnerIdValue(id)
    let newArr = values.owners.filter((owner) => owner.id !== id)
    setValues({...values, owners: [...newArr], ownerId: id})
  }


  return (
        <div>
            <Formik
                initialValues={{
                    name: itemAndParentForAction?.item?.name || '',
                    description: itemAndParentForAction?.item?.description || '',
                    ownerId: itemAndParentForAction?.item?.owner?.id || null,
                    startDate: itemAndParentForAction?.item?.startDate ? dayjs(itemAndParentForAction?.item?.startDate) : null,
                    endDate: itemAndParentForAction?.item?.endDate ? dayjs(itemAndParentForAction?.item?.endDate) : null,
                    themeId: itemAndParentForAction?.item?.themeId || null,
                    // budgetCurrency: null,
                    budget: itemAndParentForAction?.item?.budget || 0,
                    owners: itemAndParentForAction?.item?.owners?.map((user) => {
                            return {
                                id: user.id,
                                value: user.id,
                                title: user.name,
                                subtitle: user.email,
                                image: user.photo
                            };
                        })
                        || [],
                    scheduleFrequency: '',
                    period: itemAndParentForAction?.item?.period || '',
                    day: itemAndParentForAction?.item?.day || '',
                    successCriteria: '',
                    planId: planId || null,
                    parentType: parentType,
                    parentId: itemAndParentForAction?.parent?.id || null,
                }}
                onSubmit={handleOnSubmit}
                validationSchema={createGoalSchema}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {({setFieldValue, errors, values,setValues}) => {
                    return (
                        <Form>
                            <div className={styles.container}>
                                <div className={styles.formWrapper}>
                                    <Field
                                        component={InputField}
                                        name={'name'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.goal} Name`}
                                        label={`${terminology.goal} Name`}
                                    />
                                    {
                                        itemAndParent?.type !== ROW_ITEM_TYPES.GOAL &&
                                        <div className={styles.fullWidthWrapper}>
                                            <div className={styles.singleInputHalfWidth}>
                                                <Field disabled={Boolean(itemAndParentForAction?.item)}
                                                       component={SelectField}
                                                       name={'planId'}
                                                       setFieldValue={(fieldName, planId) => handlePlanIdChange(fieldName, planId, setFieldValue, setValues, values)}
                                                       errors={errors}
                                                       placeholder={{
                                                           value: terminology.plan,
                                                       }}
                                                       label={terminology.plan}
                                                       options={{
                                                           apiService: scorecardService,
                                                           apiServiceMethod: 'getScorecards',
                                                           titleKey: 'name',
                                                       }}
                                                />
                                            </div>
                                            <div className={styles.singleInputHalfWidth}>
                                                <OutlinedSelectInput height={54} disabled={Boolean(itemAndParentForAction?.item)} placeholder={`Select ${terminology.perspective}`}
                                                                     options={perspectiveOptions}
                                                                     value={itemAndParentForAction?.item ? itemAndParentForAction?.item?.perspectiveId : values?.parentId || ''}
                                                                     handleChange={(e) => setPerspectiveItem(e.target.value, setFieldValue)}
                                                />
                                                <p className={styles.errorMessageParentId}>{errors.parentId && errors.parentId}</p>
                                            </div>
                                        </div>
                                    }
                                    <Field
                                        component={TextareaField}
                                        name={'description'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.goal} Description`}
                                        label={`${terminology.goal} Description`}
                                    />
                                    <Field
                                        component={SelectField}
                                        name={'ownerId'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: `${terminology.goal} Owner`,
                                            icon: require('../../../../assets/icons/profile-owner.png'),
                                        }}
                                        label={`${terminology.goal} Owner`}
                                        options={{
                                            options: userOptions,
                                            imageKey: 'photo',
                                            defaultImage: require('../../../../../src/assets/icons/profile-owner.png'),
                                            titleKey: 'name',
                                            subtitleKey: 'email',
                                        }}
                                        getFieldVal={(val) => setNewOwner(val, values, setValues)}
                                    />
                                    <div className={styles.dateInputsWrapper}>
                                        <div className={styles.singleDateInputWrapper}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'startDate'} value={ values.startDate || null}
                                                            onChange={(e) => handleStartDateChange(e, setFieldValue)}
                                                            label="Start Date"
                                                            minDate={itemAndParent?.startDate && dayjs(itemAndParent?.startDate)}
                                                            maxDate={itemAndParent?.endDate && dayjs(itemAndParent?.endDate)}
                                                />
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.startDate && errors.startDate}</p>
                                        </div>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'endDate'} value={values.endDate || null}
                                                            onChange={(e) => handleEndDateChange(e, setFieldValue)}
                                                            label="End Date"
                                                            minDate={values.startDate || (itemAndParent?.startDate && dayjs(itemAndParent?.startDate))}
                                                            maxDate={itemAndParent?.endDate && dayjs(itemAndParent?.endDate)}/>
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.endDate && errors.endDate}</p>
                                        </div>
                                    </div>
                                    <div className={styles.themeAndBudgetWrapper}>
                                        {/*<div className={styles.singleInputHalfWidth}>*/}
                                            <Field
                                                component={SelectField}
                                                name={'themeId'}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                placeholder={{
                                                    value: 'Theme',
                                                }}
                                                label={'Theme'}
                                                options={{
                                                    apiService: helperService,
                                                    apiServiceMethod: 'getThemes',
                                                    titleKey: 'name',
                                                    colorKey: 'color'
                                                }}
                                            />
                                        {/*</div>*/}
                                        {/*<div className={styles.budgetInputsWrapper}>*/}
                                            {/*<div className={styles.currencyInputWrapper}>*/}
                                            {/*    <Field*/}
                                            {/*        component={SelectField}*/}
                                            {/*        name={'budgetCurrency'}*/}
                                            {/*        setFieldValue={setFieldValue}*/}
                                            {/*        errors={errors}*/}
                                            {/*        placeholder={{*/}
                                            {/*            value: 'Currency'*/}
                                            {/*        }}*/}
                                            {/*        label={'Budget Currency'}*/}
                                            {/*        options={{*/}
                                            {/*            // options: [*/}
                                            {/*            //     {*/}
                                            {/*            //         id: 1,*/}
                                            {/*            //         value: 1,*/}
                                            {/*            //         title: 'usd'*/}
                                            {/*            //     },*/}
                                            {/*            //     {*/}
                                            {/*            //         id: 2,*/}
                                            {/*            //         value: 2,*/}
                                            {/*            //         title: 'eur'*/}
                                            {/*            //     },*/}
                                            {/*            // ]*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            {/*<div className={styles.amountInputWrapper}>*/}
                                            {/*    <Field*/}
                                            {/*        component={InputField}*/}
                                            {/*        type={'number'}*/}
                                            {/*        name={'budget'}*/}
                                            {/*        setFieldValue={setFieldValue}*/}
                                            {/*        errors={errors}*/}
                                            {/*        placeholder={'Amount'}*/}
                                            {/*        label={'Amount'}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className={styles.wrapper} style={{height: showSharingWith ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <AlignIcon/>
                                                <p className={styles.titleText}>Sharing with</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: showSharingWith ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                    <Field
                                        component={AutocompleteField}
                                        name={'owners'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: 'Start typing name or email',
                                        }}
                                        label={`Add teammates to collaborate with this ${terminology.goal}. Search for a user or individual a new user by email`}
                                        options={{options: userOptions, disabledText: 'Owner', subtitleKey: 'email', imageKey: 'photo', defaultImage: require('../../../../../src/assets/icons/profile-owner.png')}}
                                    />
                                    </div>
                                    <div className={styles.wrapper} style={{height: showScheduleFrequency ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <CalendarMonth/>
                                                <p className={styles.titleText}>Update schedule frequency</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShowScheduler} className={styles.arrowDownBtn} style={{transform: showScheduleFrequency ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                        <ExpectedProgress showCheckbox={false} item={itemAndParentForAction?.item} padding={'0'} title={'Expected progress'}
                                                          setFormValues={(values) => setUpdateFrequencyValues(setFieldValue, values)}/>
                                        <p className={styles.errorMessageDay}>{errors.day}</p>
                                    </div>
                                    {
                                        itemAndParentForAction?.parent || perspectiveOptions.find((perspective) => values.parentId === perspective.id) ?
                                            <ContributesToComponent
                                              item={itemAndParentForAction.item}
                                              parent={setParentDataForContributesToComponent(itemAndParent, itemAndParentForAction?.parent?.strategyPlanName ? itemAndParentForAction?.parent?.strategyPlanName : itemAndParent?.scorecard?.name)}
                                              setFormikField={(val) => setFieldValue('sharedGoalIds', val)}
                                              scorecard={scorecard}
                                              canEditParent={canContributesToEditParent}
                                            /> : null
                                    }
                                    {/*<Field*/}
                                    {/*    component={SelectField}*/}
                                    {/*    name={'scheduleFrequency'}*/}
                                    {/*    setFieldValue={setFieldValue}*/}
                                    {/*    errors={errors}*/}
                                    {/*    placeholder={{*/}
                                    {/*        value: 'Update Schedule Frequency'*/}
                                    {/*    }}*/}
                                    {/*    options={{*/}
                                    {/*        options: scheduleFrequencyData*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    {/*<Field*/}
                                    {/*    name="successCriteria"*/}
                                    {/*    component={BulletListSelect}*/}
                                    {/*    options={*/}
                                    {/*        [*/}
                                    {/*            {*/}
                                    {/*                id: 1,*/}
                                    {/*                value: 1,*/}
                                    {/*                title: 'Add KPI',*/}
                                    {/*            },*/}
                                    {/*            {*/}
                                    {/*                id: 2,*/}
                                    {/*                value: 2,*/}
                                    {/*                title: 'Add Project',*/}
                                    {/*            },*/}
                                    {/*            {*/}
                                    {/*                id: 3,*/}
                                    {/*                value: 3,*/}
                                    {/*                title: 'Add Action',*/}
                                    {/*            },*/}
                                    {/*            {*/}
                                    {/*                id: 4,*/}
                                    {/*                value: 4,*/}
                                    {/*                title: 'Maybe later',*/}
                                    {/*            },*/}
                                    {/*        ]*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                </div>
                                <div className={styles.fixedSubmitWrapper}>
                                    <DefaultButton title={'Cancel'} type={'button'} secondary onClick={onCancel} />
                                    <DefaultButton title={'Save'} type={'submit'} buttonStyles={{marginLeft: '16px'}} />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default memo(AddGoalModalContentComponent);
