import React, {memo} from 'react';
import GoalItem from "./GoalItem";

const GoalList = ({data, selectedItems, onItemClick, perspectiveId}) => {
  return (
    <div>
      {data.map((item, index) => {
        return (
          <GoalItem
            item={item}
            selectedItems={selectedItems}
            onItemClick={onItemClick}
            lastItem={index === data.length - 1}
            isParent
            key={`${item.id} ${item.type}`}
            perspectiveId={perspectiveId}
          />
        );
      })}
    </div>
  );
};

export default memo(GoalList);
