import React from 'react';
import styles from './indexStyle.module.scss';
import GraphTableHeader from "./GraphTableHeader";
import GraphTableList from "./GraphTableList";

const GraphTableComponent = ({data, fromReport}) => {

    return (
        <div className={styles.container}>
            <GraphTableHeader/>
            <GraphTableList data={data} fromReport={fromReport} />
        </div>
    );
};

export default GraphTableComponent;
