import produce from 'immer';
import {
  RESET_STATE, SET_GOALS_LIBRARY_MODAL,
} from './actionTypes';

export const initialState = {
  goalsLibraryModal: {
    isVisible: false,
    goalId: null,
  },
};

/* eslint-disable default-case */
const goalsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case SET_GOALS_LIBRARY_MODAL:
        draft.goalsLibraryModal = action.payload;
        break;
    }
  });

export default goalsReducer;
